import {
    ChangeDetectorRef,
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import {
    Strategy,
    StrategyFn,
} from '../models/strategy.model';

import {
    isEmpty,
} from '../utils/function.util';

import {
    ScopesChecker,
} from '../services/scopes.service';


type StringOrStrategyFn = string | StrategyFn;
type StringAndStrategyFn = string | StrategyFn;

@Directive({
    selector: '[scopesOnly], [scopesExcept],[scopesAll]', // tslint:disable-line: directive-selector
})
export class ScopesDirective implements OnInit, OnChanges {

    private _state: 'unauthorized' | 'authorized' | undefined;

    @Output('authorized') // tslint:disable-line:no-output-rename
    protected authorizedEmitter: EventEmitter<any> = new EventEmitter();

    @Output('unauthorized') // tslint:disable-line:no-output-rename
    protected unauthorizedEmitter: EventEmitter<any> = new EventEmitter();

    @Input()
    public scopesOnly: string | string[];

    @Input()
    public scopesAll: string | string[];

    @Input()
    public scopesOnlyThen: TemplateRef<any>;

    @Input()
    public scopesAllThen: TemplateRef<any>;

    @Input()
    public scopesOnlyElse: TemplateRef<any>;

    @Input()
    public scopesAllElse: TemplateRef<any>;

    @Input()
    public scopesExcept: string | string[];

    @Input()
    public scopesExceptElse: TemplateRef<any>;

    @Input()
    public scopesExceptThen: TemplateRef<any>;

    @Input()
    public scopesThen: TemplateRef<any>;

    @Input()
    public scopesElse: TemplateRef<any>;

    @Input()
    public scopesOnlyAuthorisedStrategy: StringOrStrategyFn;

    @Input()
    public scopesAllAuthorisedStrategy: StringAndStrategyFn;

    @Input()
    public scopesOnlyUnauthorisedStrategy: StringOrStrategyFn;

    @Input()
    public scopesExceptUnauthorisedStrategy: StringOrStrategyFn;

    @Input()
    public scopesExceptAuthorisedStrategy: StringOrStrategyFn;

    @Input()
    public scopesUnauthorisedStrategy: StringOrStrategyFn;

    @Input()
    public scopesAuthorisedStrategy: StringOrStrategyFn;

    protected get authorizedStrategy(): any {
        return this.scopesOnlyAuthorisedStrategy
            || this.scopesExceptAuthorisedStrategy
            || this.scopesAuthorisedStrategy;
    }

    protected get authorizedAllStrategy(): any {
        return this.scopesAllAuthorisedStrategy;
    }

    protected get unauthorizedStrategy(): any {
        return this.scopesOnlyUnauthorisedStrategy
            || this.scopesExceptUnauthorisedStrategy
            || this.scopesUnauthorisedStrategy;
    }


    protected get authorisedTemplates(): TemplateRef<any> {
        return this.scopesOnlyThen
            || this.scopesExceptThen
            || this.scopesThen
            || this.scopesAllThen
            || this._templateRef;
    }

    protected get hasElseBlock(): boolean {
        return !!this.scopesExceptElse
            || !!this.scopesElse;
    }

    protected get hasThenBlock(): boolean {
        return !!this.scopesExceptThen
            || !!this.scopesThen;
    }

    constructor(
        private _vc: ViewContainerRef,
        private _cd: ChangeDetectorRef,
        private _templateRef: TemplateRef<any>,
        private _sc: ScopesChecker,
    ) { }

    public ngOnInit(): void { }

    public ngOnChanges(changes: SimpleChanges): void {
        const onlyChanges = changes['scopesOnly'];
        const exceptChanges = changes['scopesExcept'];
        const allChanges = changes['scopesAll'];

        if (onlyChanges || exceptChanges || allChanges) {

            if (!isEmpty(this.scopesExcept)) {
                return this.validateScopesExcept();
            }

            if (!isEmpty(this.scopesOnly)) {
                return this.validateScopesOnly();
            }

            if (!isEmpty(this.scopesAll)) {
                return this.validateScopesAll();
            }

            this.handleAuthorised(this.authorisedTemplates);
        }
    }

    private validateScopesExcept(): void {
        const authed = this._sc.isAuthedExcept(this.scopesExcept);
        if (!authed)
            return this.handleUnauthorised(this.scopesExceptElse || this.scopesElse);

        if (!!this.scopesOnly)
            return this.validateScopesOnly();

        if (!!this.scopesAll)
            return this.validateScopesAll();

        this.handleAuthorised(this.scopesExceptThen || this.scopesThen || this._templateRef);
    }

    private validateScopesOnly(): void {
        const authed = this._sc.isAuthedOnly(this.scopesOnly);
        if (!authed)
            return this.handleUnauthorised(this.scopesOnlyElse || this.scopesElse);

        this.handleAuthorised(this.scopesOnlyThen || this.scopesThen || this._templateRef);
    }

    private validateScopesAll(): void {
        const authed = this._sc.isAuthedAll(this.scopesAll);
        if (!authed)
            return this.handleUnauthorised(this.scopesOnlyElse || this.scopesElse);

        this.handleAuthorised(this.scopesAllThen|| this.scopesThen || this._templateRef);
    }

    private handleUnauthorised(template: TemplateRef<any>): void {
        if (this._state === 'unauthorized')
            return;

        this._state = 'unauthorized';
        this.unauthorizedEmitter.emit();

        if (this.unauthorizedStrategy) {
            this.applyStrategy(this.unauthorizedStrategy);
            return;
        }

        if (!this.hasElseBlock) {
            this.applyStrategy(Strategy.HIDE);
        } else {
            this.showTemplateBlockInView(template);
        }

    }

    private handleAuthorised(template: TemplateRef<any>): void {
        if (this._state === 'authorized')
            return;

        this._state = 'authorized';
        this.authorizedEmitter.emit();

         if (this.scopesAll && this.authorizedAllStrategy) {
            this.applyStrategy(this.authorizedAllStrategy);
            return;
        }

        if (this.authorizedStrategy) {
            this.applyStrategy(this.authorizedStrategy);
            return;
        }

        if (!this.hasThenBlock) {
            this.applyStrategy(Strategy.SHOW);
        } else {
            this.showTemplateBlockInView(template);
        }
    }

    private showTemplateBlockInView(template: TemplateRef<any>): void {
        this._vc.clear();
        if (!template) {
            return;
        }

        this._vc.createEmbeddedView(template);
        this._cd.markForCheck();
    }

    private applyStrategy(str: string): void {
        if (str === Strategy.SHOW) {
            this.showTemplateBlockInView(this._templateRef);
            return;
        }

        if (str === Strategy.HIDE) {
            this._vc.clear();
            return;
        }
    }
}
