import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  SelectionModel,
} from '@angular/cdk/collections';

import {
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  AdminScope,
} from '../../../sdk/models/admin-scope.model';

@Component({
  selector: 'nym-role-permission-list',
  templateUrl: './role-permission-list.component.html',
  styleUrls: ['./role-permission-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolePermissionListComponent implements OnInit, OnChanges {

  @HostBinding('class.nym-role-permission-list')
  protected get classes(): boolean { return true; }

  private _selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  private _displayedColumns: string[] = ['checkbox', 'permission'];

  @Input()
  public role: AdminRoleInternal;

  @Input()
  public scopes: AdminScope[];

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<string[]> = new EventEmitter();

  public get selection(): SelectionModel<string> { return this._selection; }
  public get displayedColumns(): string[] { return this._displayedColumns; }

  constructor() { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {

    if (!this.role || !this.scopes)
      return;

    const ids = this.scopes.map((s) => s.id);
    const selected = this.role.role.scopes.filter((s) => ids.indexOf(s) >= 0);

    this.selection.select(...selected);
  }

  public toggle(permission: AdminScope): void {
    this._selection.toggle(permission.id);
    this.selectEmitter.emit(this._selection.selected);
  }

  public masterSelected(): boolean {
    return this.selection.hasValue() && this._isAllSelected();
  }

  public someSelected(): boolean {
    return this.selection.hasValue() && !this._isAllSelected();
  }

  public masterToggle(): void {
    this.masterSelected()
      ? this._selection.clear()
      : this.scopes.forEach((r) => this._selection.select(r.id));

    this.selectEmitter.emit(this._selection.selected);
  }

  private _isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.scopes.length;
    return numSelected === numRows;
  }

}
