import {
    NymcardCmsWalletInternal,
} from '@michel.freiha/ng-sdk';

import {
    Balance,
} from '../models/balance.model';

import {
    Wallet,
} from '../models/wallet.model';


export class WalletBuilder {

    private _internal: NymcardCmsWalletInternal;

    public withInternal(value: NymcardCmsWalletInternal): WalletBuilder {
        this._internal = value;
        return this;
    }

    public build(): Wallet {

        const internal = this._internal;

        if (!internal)
            return undefined;

        const id = internal.internalId;
        const accountId = internal.accountId;

        const created = internal && internal.created || undefined as any;
        const modified = internal && internal.modified || undefined as any;

        const balances = Object.keys(internal.wallet.balances).map((currency) => {
            return new Balance({
                currency: currency.toUpperCase(),
                amount: internal.wallet.balances[currency],
            });
        });

        return new Wallet({
            id: id,
            accountId: accountId,
            created: created,
            modified: modified,
            balances: balances,
        });
    }

}
