import {
    CoreAccountRef,
    NymcardAccountLimits as AccountLimits,
    UserAccountVerificationStatus,
    UserDocuments,
    CoreAccount,
} from '@michel.freiha/ng-sdk';

import {
    AccountDocument,
} from './account-document.model';

import {
    AccountAddress,
} from './account-address.model';

import {
    AccountChannel,
} from './account-channel.model';

import {
    AccountProfile,
} from './account-profile.model';

import {
    AccountBusiness,
} from './account-business.model';

export type AccountType =
    | 'user'
    | 'agent'
    | undefined;

export class Account {

    public id: string;
    public type: AccountType;
    public verificationStatus: UserAccountVerificationStatus;
    public kyc2Agent: CoreAccount;
    public kyc2SubmittedBy: CoreAccount;
    public created: Date;
    public modified: Date;
    public limits: AccountLimits;
    public profile: AccountProfile;
    public business: AccountBusiness;
    public document: AccountDocument;
    public documents: UserDocuments ;
    public additionalDocument: AccountDocument ;
    public channel: AccountChannel;
    public address: AccountAddress;
    public createdBy: CoreAccountRef;

    constructor(ob?: Partial<Account>) {

        this.id = undefined;
        this.type = undefined;
        this.verificationStatus = undefined;
        this.kyc2Agent = undefined;
        this.kyc2SubmittedBy= undefined;
        this.created = undefined;
        this.modified = undefined;
        this.limits = undefined;
        this.profile = undefined;
        this.business = undefined;
        this.document = undefined;
        this.documents = {};
        this.additionalDocument=undefined;
        this.channel = undefined;
        this.address = undefined;
        this.createdBy = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.type !== undefined) { this.type = ob.type; }
        if (ob.verificationStatus !== undefined) { this.verificationStatus = ob.verificationStatus; }
        if (ob.kyc2Agent !== undefined) { this.kyc2Agent = ob.kyc2Agent; }
        if (ob.kyc2SubmittedBy !== undefined) { this.kyc2SubmittedBy = ob.kyc2SubmittedBy; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.limits !== undefined) { this.limits = new AccountLimits(ob.limits); }
        if (ob.profile !== undefined) { this.profile = new AccountProfile(ob.profile); }
        if (ob.business !== undefined) { this.business = new AccountBusiness(ob.business); }
        if (ob.document !== undefined) { this.document = new AccountDocument(ob.document); }
        if (ob.additionalDocument !== undefined) { this.additionalDocument = new AccountDocument(ob.additionalDocument); }
        if (ob.documents !== {}) { this.documents = new UserDocuments(ob.documents); }
        if (ob.channel !== undefined) { this.channel = new AccountChannel(ob.channel); }
        if (ob.address !== undefined) { this.address = new AccountAddress(ob.address); }
        if (ob.createdBy !== undefined) { this.createdBy = new CoreAccountRef(ob.createdBy); }

    }
}

