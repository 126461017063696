import {
    Component,
} from '@angular/core';

import {
    CreditCardComponent,
} from '../../../lib/ui/components/credit-card/credit-card.component';


@Component({
    selector: 'nym-credit-card',
    template: ``,
})
export class StubCreditCardComponent
    extends CreditCardComponent { }
