import {
    Component,
    HostListener,
} from '@angular/core';

import {
    PanFilterComponent,
} from '../../../lib/ui/components/pan-filter/pan-filter.component';


@Component({
    selector: 'nym-pan-filter',
    template: ``,
})
export class StubPanFilterComponent
    extends PanFilterComponent {

    @HostListener('click', ['$event'])
    public trigger(pan: string): void {
        this.submitEmitter.emit(pan);
    }
}
