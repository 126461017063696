import {
    Action,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignOut,
} from '@nymos/auth';

import {
    Select,
} from './roles-selected.actions';


export interface RolesSelectedStateModel {
    items: string[];
}

const stateDefaults: RolesSelectedStateModel = {
    items: [],
};

@State<RolesSelectedStateModel>({
    name: 'selected',
    defaults: stateDefaults,
})
export class RolesSelectedState {

    @Action(SignOut)
    public reset(ctx: StateContext<RolesSelectedStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(Select)
    public select(ctx: StateContext<RolesSelectedStateModel>, { payload: { roles } }: Select): any {
        ctx.patchState({ items: roles.map((r) => r.id) });
    }
}
