/* tslint:disable:no-bitwise */

import { util } from 'node-forge';

export const DEFAULT = 0;
export const NO_PADDING = 1;
export const NO_WRAP = 2;
export const CRLF = 4;
export const URL_SAFE = 8;
export const NO_CLOSE = 16;

export class Base64 {

    public static decode(input: string, flags: number = DEFAULT): string {
        if ((flags & URL_SAFE) !== 0)
            input = this._undoUrlSafe(input);

        if ((flags & NO_PADDING) !== 0)
            input = this._undoNoPadding(input);

        return util.decode64(input);
    }

    public static encode(input: string, flags: number = DEFAULT): string {
        input = util.encode64(input);

        if ((flags & URL_SAFE) !== 0)
            input = this._doUrlSafe(input);

        if ((flags & NO_PADDING) !== 0)
            input = this._doNoPadding(input);

        return input;
    }

    private static _doUrlSafe(input: string): string {
        return input.replace(/\+/g, '-').replace(/\//g, '_');
    }

    private static _doNoPadding(input: string): string {
        return input.replace(/\=+$/, '');
    }

    private static _undoUrlSafe(input: string): string {
        return input.replace(/-/g, '+').replace(/_/g, '/');
    }

    private static _undoNoPadding(input: string): string {
        if (input.length % 4 !== 0)
            input += ('===').slice(0, 4 - (input.length % 4));

        return input;
    }
}
