export enum CreditCardType {
    Maestro = 'maestro',
    Forbrugsforeningen = 'forbrugsforeningen',
    Dankort = 'dankort',
    Visa = 'visa',
    Mastercard = 'mastercard',
    Amex = 'amex',
    Dinersclub = 'dinersclub',
    Discover = 'discover',
    Unionpay = 'unionpay',
    Jcb = 'jcb',
}

export class CreditCard {

    public type: CreditCardType;
    public luhn: boolean;
    public format: any;
    public patterns: number[];
    public length: number[];
    public cvvLength: number[];

    public constructor(ob?: Partial<CreditCard>) {

        this.patterns = [];
        this.length = [];
        this.cvvLength = [];

        if (!ob)
            return;

        if (ob.type !== undefined) { this.type = ob.type; }
        if (ob.luhn !== undefined) { this.luhn = ob.luhn; }
        if (ob.format !== undefined) { this.format = ob.format; }
        if (ob.patterns !== undefined) { this.patterns = ob.patterns; }
        if (ob.length !== undefined) { this.length = ob.length; }
        if (ob.cvvLength !== undefined) { this.cvvLength = ob.cvvLength; }
    }
}
