import { Menu as Model } from './menu.model';
import { MockMenuItem } from './menu-item.model.mock';
import { MockMenuGroup } from './menu-group.model.mock';

export class MockMenu {

    public static create(): Model {
        return new Model({
            items: [
                MockMenuGroup.create(),
                MockMenuItem.create(),
            ],
        });
    }
}
