import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/bulkcards/';

@Injectable({
    providedIn: 'root',
})
export class BulkcardsAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('bulk', 'empty', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'bulk-empty.svg'));
    }

}
