import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';

import {
  Admin,
  AdminInternal,
  AdminProfile,
  CoreAccountStatus as AdminStatus,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

@Component({
  selector: 'nym-admin-edit-form',
  templateUrl: './admin-edit-form.component.html',
  styleUrls: ['./admin-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class AdminEditFormComponent implements OnInit, OnChanges {

  private _form: FormGroup;

  @ViewChild('ngForm', { static: true })
  private _ngForm: NgForm;

  @HostBinding('class.nym-admin-edit-form')
  protected get classes(): boolean { return true; }

  @Input()
  public admin: AdminInternal;

  @Input()
  public problem: Problem;

  public get form(): FormGroup { return this._form; }
  public get firstName(): AbstractControl { return this.form.get('firstName'); }
  public get lastName(): AbstractControl { return this.form.get('lastName'); }
  public get email(): AbstractControl { return this.form.get('email'); }
  public get mobile(): AbstractControl { return this.form.get('mobile'); }

  public get ngForm(): NgForm { return this._ngForm; }

  protected get fullName(): string { return `${this.firstName.value} ${this.lastName.value}`; }

  constructor(
    private _fb: FormBuilder,
    private _ph: ProblemHandler,
  ) {
    this._form = this._fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const problem = changes['problem'];
    const admin = changes['admin'];

    if (problem) {
      this._ph.handle(problem.currentValue, this.form);
    }

    if (admin) {
      this._setForm(admin.currentValue);
    }
  }

  private _setForm(data: AdminInternal): void {

    const element = this._getOrNewRole(data);

    const profile = element.admin.profile;

    this._form.patchValue({
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      email: profile.email || '',
      mobile: profile.mobile || '',
    });
  }

  private _getOrNewRole(admin: AdminInternal): AdminInternal {
    if (admin && admin.admin && admin.admin.profile)
      return admin;

    return new AdminInternal({
      id: '',
      admin: new Admin({
        profile: new AdminProfile({ firstName: '', lastName: '', email: '', mobile: '' }),
        status: AdminStatus.Active,
      }),
    });
  }

}
