import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'thm-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.components.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalResultList: number = 50;
  @Input() startRange: number = 1;
  @Input() endRange: number = 10;
  @Input() selectedPageSizeOption: number = 10;
  @Input() pageSizeOptions: number[] = [];
  @Input() current: number = 0;
  @Input() totalPages: number = 0;
  
  @Input() hasMore: boolean;

  @Output() goTo: EventEmitter<number> = new EventEmitter<number>();
  @Output() last: EventEmitter<number> = new EventEmitter<number>();
  @Output() first: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() range: EventEmitter<number> = new EventEmitter<number>();
  @Output() loadMore: EventEmitter<any> = new EventEmitter();

  pages: number[] = [];
  
  constructor() {
    this.onRange(this.selectedPageSizeOption);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.endRange = this.endRange === 0 ? 10 : this.endRange;

    if (this.selectedPageSizeOption === this.totalResultList && this.hasMore) {
      this.onLoadMore();
      this.totalPages = Math.ceil(this.totalResultList / this.selectedPageSizeOption);
      this.pages = this.getPages(this.current, this.totalPages);
      this.setResultsRange();
    }

    if (this.pageSizeOptions.length) {
      this.getPageSizeOptions(this.totalResultList, this.pageSizeOptions);
    }
    
    if (
      (changes.current && changes.current.currentValue) ||
      (changes.total && changes.total.currentValue)
    ) {
      this.pages = this.getPages(this.current, this.totalPages);
      
      if (this.current === this.totalPages && this.hasMore) {
        this.onLoadMore();
        this.totalPages = Math.ceil(this.totalResultList / this.selectedPageSizeOption);
        this.pages = this.getPages(this.current, this.totalPages);
      }
      this.setResultsRange();
    }
    
    if (changes.selectedPageSizeOption) {
      this.onRange(this.selectedPageSizeOption);
      this.pages = this.getPages(this.current, this.totalPages);
    }
    
    this.totalPages = Math.ceil(this.totalResultList / this.selectedPageSizeOption);
    
    if (this.current !== 1 && this.current > this.totalPages) {
      this.current = this.totalPages;
      this.setResultsRange();
    }
    
    if (!this.current) {
      this.current = 1;
      this.startRange = 1;
      this.endRange = 
        this.selectedPageSizeOption <= this.totalResultList 
          ? this.selectedPageSizeOption
          : this.totalResultList;
      this.onGoTo(this.current);
    }

    if (this.endRange > this.totalResultList) {
      this.endRange = this.totalResultList;
    }
    
    this.pages = this.getPages(this.current, this.totalPages);
  }

  onGoTo(page: number): void {
    this.goTo.emit(page);
    this.setResultsRange();
  }
  
  onLast(): void {
    this.last.emit(this.current);
    this.setResultsRange();
  }

  onNext(): void {
    this.next.emit(this.current);
    this.setResultsRange();
  }
  
  onFirst(): void {
    this.first.emit(this.current);
    this.setResultsRange();
  }

  onPrevious(): void {
    this.previous.emit(this.current);
    this.setResultsRange();
  }
  
  onRange(range): void {
    this.range.emit(range);
    this.setResultsRange();
  }
  
  onLoadMore(): void {
    this.loadMore.emit(this.current);
    this.setResultsRange();
  }
  
  setResultsRange(): void {
    if (this.endRange >= this.totalResultList) {
      this.endRange = this.totalResultList;
    }
    
    this.startRange = (this.selectedPageSizeOption * this.current) - (this.selectedPageSizeOption - 1);
    this.endRange = this.selectedPageSizeOption * this.current;

    if (this.startRange < 1) {
      this.startRange = 1;
    }
  }

  getPageSizeOptions(results: number, pageSizeOptions: number[]) {
    const resCalculated = Math.ceil(results / 10);
    
    this.pageSizeOptions = pageSizeOptions.filter(
      option => (option / 10) <= resCalculated
    );
  }

  private getPages(current: number, total: number): number[] {
    if (total <= 7) {
      return [...Array(total).keys()].map((x) => ++x);
    }

    if (current > 5) {
      if (current >= total - 4) {
        return [1, -1, total - 4, total - 3, total - 2, total - 1, total];
      } else {
        return [1, -1, current - 1, current, current + 1, -1, total];
      }
    }

    return [1, 2, 3, 4, 5, -1, total];
  }
}
