// tslint:disable:variable-name

export type StringFn = (...args: string[]) => string;

export namespace Texts {

    export class Action {
        public static readonly Failure: string = `Something went wrong`;
        public static readonly Loading: string = `Loading...`;
        public static readonly Issuing: string = `Issuing plastic cards...`;
        public static readonly Issued: string = `Bulk is successfully issued`;
        public static readonly UnableToLoadMoreTitle: string = `Could not load more entries`;
        public static readonly UnableToLoadMoreDetail: string = `Load more action was called before search method: no criteria defined`;
    }

    export class IssuanceDialog {
        public static readonly Title: string = `Create new Plastic Cards`;
    }
}


