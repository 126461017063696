// tslint:disable:variable-name

import {
    AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

export const ADMIN_ROLE_FILTER = ((data: AdminRoleInternal, filter: string) => {
    return !filter
        || data.id.toLowerCase().indexOf(filter) !== -1
        || data.role.name.toLowerCase().indexOf(filter) !== -1;
});
