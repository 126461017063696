
/* tslint:disable: no-console */

import {
    InjectionToken,
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    Store,
} from '@ngxs/store';

import {
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    DiscoveryService,
    ENV_NYMOS_GAE_PROJECT,
    ENV_NYMOS_K8S_DOMAIN,
} from './services/discovery.service';

import {
    GaeDiscoveryService,
} from './services/gae/gae-discovery.service';

import {
    K8sDiscoveryService,
} from './services/k8s/k8s-discovery.service';

import {
    EnvsState,
} from './store/envs.state';

export const NYM_DISCOVERY_SERVICE = new InjectionToken<DiscoveryService>('DiscoveryService');

export const discoveryServiceFactory = (
    service: DiscoveryService,
    store: Store,
) => {
    if (service)
        return service;

    const vars = store.selectSnapshot(EnvsState.vars);

    if (vars[ENV_NYMOS_GAE_PROJECT]) {
        console.info(`Providing GAE discovery service.`);
        return new GaeDiscoveryService(vars);
    } else if (vars[ENV_NYMOS_K8S_DOMAIN]) {
        console.info(`Providing K8S discovery service.`);
        return new K8sDiscoveryService(vars);
    }

    throw new Problem({ detail: 'Unknown server environment.' });
};

export const discoveryProvider: Provider = {
    provide: NYM_DISCOVERY_SERVICE,
    useFactory: discoveryServiceFactory,
    deps: [
        [new Optional(), new SkipSelf(), NYM_DISCOVERY_SERVICE],
        [Store],
    ],
};
