import {
  NgModule,
} from '@angular/core';

import {
  BrowserModule,
} from '@angular/platform-browser';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { HttpConfigInterceptor } from '@nymos/auth';


import {
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  NgxsRouterPluginModule,
} from '@ngxs/router-plugin';

import {
  NgxsReduxDevtoolsPluginModule,
} from '@ngxs/devtools-plugin';

import {
  NgxsStoragePluginModule,
} from '@ngxs/storage-plugin';

import {
  EnvsState,
} from '@nymos/discovery';

import {
  CoreModule,
} from './core/core.module';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  AppRoutingModule as RoutingModule,
} from './app.routing';

import {
  AppComponent,
} from './core/screens/main/app.component';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTooltipModule,

    // Ngxs
    NgxsModule.forRoot([EnvsState], { developmentMode: false }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.token',
        'profile.admin',
        'profile.scopes',
        'encryption.keys',
        'envs.vars',
        'envs.device',
      ],
    }),

    // App
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    RoutingModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
