import {
    Injectable,
} from '@angular/core';

import {
    filter,
} from 'rxjs/operators';

import {
    Store,
} from '@ngxs/store';

import {
    CoreNote,
} from '@michel.freiha/ng-sdk';

import {
    ConfirmComponent,
    Dialog,
    DialogRef,
} from '@nymos/theme';

import {
    NoteConfirmComponent,
} from '@nymos/notes';

import {
    Account,
    Texts,
    WalletActivity,
} from '@nymos/accounts/core';


@Injectable({
    providedIn: 'root',
})
export class WalletActivityHandler {

    private _ref: DialogRef<any, any>;

    constructor(
        private _dialog: Dialog,
        private _store: Store,
    ) { }

    public approve(
        account: Account,
        activity: WalletActivity,
        action: new (walletId: string, transactionId: string) => any,
    ): any {

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.TopupDialog.ApproveTitle,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(activity.walletId, activity.id));
        });
    }

    public reject(
        account: Account,
        activity: WalletActivity,
        action: new (walletId: string, transactionId: string, note: CoreNote) => any,
    ): any {
        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.TopupDialog.RejectTitle,
            hint: Texts.TopupDialog.RejectHint(account.profile.fullName),
            confirm: { label: 'Save' },
            cancel: { label: 'Cancel' },
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(activity.walletId, activity.id, note));
        });
    }

    public cancel(
        account: Account,
        activity: WalletActivity,
        action: new (walletId: string, transactionId: string, note: CoreNote) => any,
    ): any {
        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.TopupDialog.CancelTitle,
            hint: Texts.TopupDialog.CancelHint(account.profile.fullName),
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(activity.walletId, activity.id, note));
        });
    }

    public retry(
        account: Account,
        activity: WalletActivity,
        action: new (walletId: string, transactionId: string) => any,
    ): any {
        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.TopupDialog.RetryTitle,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(activity.walletId, activity.id));
        });
    }

}
