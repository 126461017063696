export class MenuItem {

    public title: string;
    public icon: string;
    public url?: string;
    public action?: any;
    public scopes?: string | string[];
    public children? :any

    public readonly type: string = 'item';

    constructor(ob?: Partial<MenuItem>) {

        if (!ob)
            return;

        if (ob.title !== undefined) { this.title = ob.title; }
        if (ob.icon !== undefined) { this.icon = ob.icon; }
        if (ob.url !== undefined) { this.url = ob.url; }
        if (ob.action !== undefined) { this.action = ob.action; }
        if (ob.scopes !== undefined) { this.scopes = ob.scopes; }
        if (ob.children !== undefined) { this.children = ob.children; }
    }
}
