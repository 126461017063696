import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import { ContentComponent } from './views/layout/content/content.component';
import { BulkHomepageComponent } from './views/containers/bulk-homepage/bulk-homepage.component';

// import {
//     HomepageComponent,
// } from './ui/containers/homepage/homepage.component';


export const routes: Routes = [
    {
        path: '',
        component: ContentComponent,
        children: [
            { path: '', component: BulkHomepageComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BulkcardsRoutingModule { }
