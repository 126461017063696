// tslint:disable: directive-selector

import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
} from '@angular/core';

import {
  CurrencyPipe,
} from '@angular/common';


@Directive({
  selector: 'input[currency]',
})
export class CurrencyDirective {

  private _pipe: CurrencyPipe;

  @Input()
  public currency: string;

  constructor(
    private _el: ElementRef,
    @Inject(LOCALE_ID) private _locale: string,
  ) {
    this._pipe = new CurrencyPipe(this._locale);
  }

  @HostListener('input', ['$event'])
  public onInputChange(event: Event): void {
    const initalValue = this._el.nativeElement.value;
    // this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    this._el.nativeElement.value = this._pipe.transform(initalValue, this.currency);

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }

  }

}
