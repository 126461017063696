// tslint:disable: member-ordering

import '@nymos/extensions/number';

import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core';

import {
    SearchCriteria,
    SearchFilter,
    SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
    BACKEND_FACTORY,
    BACKEND_OPTIONS,
    BackendOptions,
    MemoryBackend,
} from '@nymos/backend';

import {
    FakerToken,
} from '../fakers/token.faker';

import {
    Token,
} from '@michel.freiha/ng-sdk';

const DEFAULT_FACTORY = new FakerToken();

@Injectable({
    providedIn: 'root',
})
export class MemoryTokenDatastore extends MemoryBackend<Token> {

    constructor(
        @Optional() @Inject(BACKEND_OPTIONS) defaults: BackendOptions,
        @Optional() @Inject(BACKEND_FACTORY) factory: FakerToken,
    ) {
        super(defaults, factory ? factory : DEFAULT_FACTORY);

        if (!this.options.mock) {
            this.afterInit();
        }
    }

    public get(id: string): Token {
        return this.doLoad(id);
    }

    public create(token: Token): Token {
        return this.doCreate(token);
    }

    public update(token: Token): Token {
        return this.doUpdate(token);
    }

    public fetchByCardId(cardId: string): Token[] {
        const criteria = new SearchCriteria({
            filters: [
                new SearchFilter({ field: 'id', operator: SearchOperator.AllOf, values: [cardId] }),
            ],
        });

        return this.doSearch(criteria).data;
    }


    protected afterInit(): any {
        this.doCreate(this._factory.one('tokenId-0001|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0002|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0003|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0004|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0005|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0006|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0007|cardId-0001'));
        this.doCreate(this._factory.one('tokenId-0008|cardId-0001'));

        this.doCreate(this._factory.one('tokenId-0011|cardId-0002'));
        this.doCreate(this._factory.one('tokenId-0012|cardId-0002'));
        this.doCreate(this._factory.one('tokenId-0013|cardId-0002'));
        this.doCreate(this._factory.one('tokenId-0014|cardId-0002'));
        this.doCreate(this._factory.one('tokenId-0015|cardId-0002'));
        this.doCreate(this._factory.one('tokenId-0016|cardId-0002'));
    }

}
