import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Token,
  TokenStatus,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-token-card',
  templateUrl: './token-card.component.html',
  styleUrls: ['./token-card.component.scss'],
  // @NOTES(leandro): Set as Default because it's refreshing the element
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TokenCardComponent implements OnInit {

  @HostBinding('class.nym-token-card')
  protected get classes(): boolean { return true; }

  @Output('activate') // tslint:disable-line:no-output-rename
  protected activateEmitter: EventEmitter<Token> = new EventEmitter();

  @Output('resume') // tslint:disable-line:no-output-rename
  protected resumeEmitter: EventEmitter<Token> = new EventEmitter();

  @Output('suspend') // tslint:disable-line:no-output-rename
  protected suspendEmitter: EventEmitter<Token> = new EventEmitter();

  @Output('delete') // tslint:disable-line:no-output-rename
  protected deleteEmitter: EventEmitter<Token> = new EventEmitter();

  @Input()
  public token: Token;

  constructor() { }

  public ngOnInit(): void { }

  protected activate(): void {
    this.activateEmitter.emit(this.token);
  }

  protected resume(): void {
    this.resumeEmitter.emit(this.token);
  }

  protected suspend(): void {
    this.suspendEmitter.emit(this.token);
  }

  protected delete(): void {
    this.deleteEmitter.emit(this.token);
  }

  protected allowed(statuses: TokenStatus[]): boolean {
    return statuses.findIndex((s) => s === this.token.status) >= 0;
  }

}
