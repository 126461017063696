// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '../../texts/accounts.texts';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Searching: Notification = new Notification(Texts.UserAction.Searching);
    public static readonly Sorting: Notification = new Notification(Texts.UserAction.Sorting);
    public static readonly Loading: Notification = new Notification(Texts.UserAction.Loading);
    public static readonly LoadingMore: Notification = new Notification(Texts.UserAction.LoadingMore);
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
    public static readonly ResettingPasscode: Notification = new Notification(Texts.UserAction.ResettingPasscode);
    public static readonly PasscodeReseted: Notification = new Notification(Texts.UserAction.PasscodeReseted);
    public static readonly SearchFailure: Notification = new Notification(Texts.UserAction.UnableToSearch);
}


