/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    tap,
} from 'rxjs/operators';

import {
    SignIn,
     SignOut,
} from '@nymos/auth';

import {
    StatusMenu,
} from '../../models/status.model';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    LoadAccountChannelsFromApi,
} from './accounts-channels.actions';


export interface AccountsChannelsStateModel {
    items: { [name: string]: StatusMenu };
    loading: boolean;
}

const stateDefaults: AccountsChannelsStateModel = {
    items: {},
    loading: undefined,
};

@State<AccountsChannelsStateModel>({
    name: 'channels',
    defaults: stateDefaults,
})
export class AccountsChannelsState implements NgxsOnInit {

    @Selector()
    public static channels(state: AccountsChannelsStateModel): StatusMenu[] {
        const result = Object.keys(state.items).map(((id) => state.items[id])).filter((i) => i.final);
        return result;
    }

    public static channel(name: string): any {
        return createSelector([AccountsChannelsState], (state: AccountsChannelsStateModel) => {
            return state.items[name];
        });
    }

    @Selector()
    public static loading(state: AccountsChannelsStateModel): boolean {
        return state.loading;
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsChannelsStateModel>): any {
        ctx.dispatch(new LoadAccountChannelsFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsChannelsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountChannelsFromApi)
    public load(ctx: StateContext<AccountsChannelsStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountChannels().pipe(
            tap((items: StatusMenu[]) => {
                ctx.setState(produce((draft) => {
                    items.forEach((item) => {
                        draft.items[item.name] = item;
                    });
                    draft.loading = false;
                }));
            }),
        );
    }

}
