import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
} from '@angular/forms';

import {
  Observable,
} from 'rxjs';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminRole,
  AdminRoleInternal,
  AdminRoleStatus,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ConfirmComponent,
  Dialog,
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  AdminScope,
} from '../../../sdk/models/admin-scope.model';

import {
  ScopesState,
} from '../../../store/scopes/scopes.state';

import {
  RolesState,
} from '../../../store/roles/roles.state';

import {
  CreateRoleFromEditDialog,
  UpdateRoleFromEditDialog,
} from '../../../store/roles/roles.actions';

import {
  RoleEditFormComponent,
} from '../../components/role-edit-form/role-edit-form.component';


// tslint:disable-next-line:variable-name
export const RoleEditConfig: any = {
  width: '720px',
  autoFocus: false,
  panelClass: 'role-dialog',
  disableClose: true,
};

@Component({
  selector: 'nym-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleEditComponent extends ModalComponent implements OnInit, OnDestroy {

  private _scopes: string[] = [];
  private _role: AdminRoleInternal;

  @HostBinding('class.nym-role-edit')
  protected get classes(): boolean { return true; }

  @Select(RolesState.saving)
  public acting$: Observable<boolean>;

  @Select(RolesState.problem)
  public problem$: Observable<Problem>;

  @Select(ScopesState.scopes)
  public scopes$: Observable<AdminScope[]>;

  @ViewChild('edit', { static: true })
  protected edit: RoleEditFormComponent;

  public get role(): AdminRoleInternal { return this._role; }
  public get isNewData(): boolean { return !this._role; }
  public get formValid(): boolean { return this.edit.form.valid; }

  constructor(
    protected _store: Store,
    protected _actions$: Actions,
    protected _dialog: Dialog,
    protected _fb: FormBuilder,
    protected _ref: DialogRef<RoleEditComponent, any>,
    protected _cd: ChangeDetectorRef,
    @Inject(DIALOG_DATA) data: any,
  ) {
    super(_fb, _ref, _cd, null, data);
    this._role = data.role;
    this._scopes = this._role && this._role.role.scopes || [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(CreateRoleFromEditDialog, UpdateRoleFromEditDialog)).subscribe(() => this._ref.close());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // @Override
  protected initForm(data: any): void {
    this.form = this.edit.form;
    this.ngForm = this.edit.ngForm;
  }

  // @Override
  protected submit(): void {

    const data = new AdminRole({
      id: this.edit.id.value,
      name: this.edit.name.value,
      color: this.edit.color.value,
      category: this.edit.category.value,
      scopes: this._scopes,
      status: AdminRoleStatus.Active,
    });

    const action = this.isNewData
      ? new CreateRoleFromEditDialog({ data: data })
      : new UpdateRoleFromEditDialog({ id: data.id, data: data });

    const text = this.isNewData
      ? `Are you sure you want to create a new admin role?`
      : `Are you sure you want to save the role <b>${data.id}</b>?`;

    const ref = this._dialog.openConfirm(ConfirmComponent, { text: text });

    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;
      this._store.dispatch(action);
    });
  }

  protected select$$(scopes: string[]): void {
    this._scopes = scopes;
  }
}
