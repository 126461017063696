import {
    NgModule,
} from '@angular/core';

import {
    HeaderDirective,
} from './header.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        HeaderDirective,
    ],

    imports: [],

    exports: [
        HeaderDirective,
    ],

})
export class ThemeDirectivesHeaderModule { }
