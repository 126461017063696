import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'thm-toolbar-spacer, .thm-toolbar-spacer, [thm-toolbar-spacer], [thmToolbarSpacer]',
})
export class ContentToolbarSpacerDirective {

  @HostBinding('class.thm-toolbar-spacer')
  protected get classes(): boolean { return true; }

  constructor() { }

}
