import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  CanColor,
  CanColorCtor,
  mixinColor,
  ThemePalette,
} from '@angular/material/core';

import {
  Account,
  AccountProfile,
  ChannelColorPipe,
} from '@nymos/accounts/core';


export class AccountInfoBase {
  constructor(public _elementRef: ElementRef) { }
}

export const _AccountInfoMixinBase: CanColorCtor & typeof AccountInfoBase =
  mixinColor(AccountInfoBase);


@Component({
  selector: 'nym-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInfoComponent extends _AccountInfoMixinBase
  implements OnInit, OnDestroy, OnChanges, CanColor {

  @HostBinding('class.nym-account-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  @Input() 
  public isUserType:any;

  @Output()
  public resetPasscode: EventEmitter<any> = new EventEmitter();

  @Output()
  public editUser: EventEmitter<Account> = new EventEmitter();

  protected get profile(): AccountProfile { return this.account && this.account.profile; }
  protected get joined(): Date { return this.account && this.account.created; }
  protected get status(): string { return this.account && this.account.channel.channel; }

  constructor(
    elementRef: ElementRef,
  ) {
    super(elementRef);
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.account && this.account.channel) {
      this.color = new ChannelColorPipe().transform(this.account.channel.channel) as ThemePalette;
    }
  }

  public resetPasscode$$(): void {
    this.resetPasscode.emit();
  }

  public editUser$$(account: Account): void {
    this.editUser.emit(this.account);
  }

}
