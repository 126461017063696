import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  Observable, Subject, BehaviorSubject,
} from 'rxjs';

import {
  AccountsProvincesNewState,
} from '../../../../core/store/accounts-provinces-new/accounts-provinces-new.state';

import {
  Store,
} from '@ngxs/store';

import {
  Note,
} from '@nymos/notes';

import {
  Account,
  ChannelStatus,
  StatusMenu,
} from '@nymos/accounts/core';

import {
  Dialog,
} from '@nymos/theme';

import {
  AccountsChannelsState,
} from '../../../../core/store/accounts-channels/accounts-channels.state';
import { UserAccountVerificationStatus } from '@michel.freiha/ng-sdk';


@Component({
  selector: 'nym-user-details-aside',
  templateUrl: './user-details-aside.component.html',
  styleUrls: ['./user-details-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsAsideComponent implements OnInit, OnChanges {
  public isUserType:any;
  @HostBinding('class.nym-user-details-aside')
  protected get classes(): boolean { return true; }

  @Output('changeChannel') // tslint:disable-line:no-output-rename
  protected changeEmitter: EventEmitter<ChannelStatus> = new EventEmitter();

  @Output('addNote') // tslint:disable-line:no-output-rename
  protected addNoteEmitter: EventEmitter<ChannelStatus> = new EventEmitter();

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachmentsEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  protected resetPasscode: EventEmitter<any> = new EventEmitter();

    @Output()
  protected editUser: EventEmitter<Account> = new EventEmitter();

  protected statusMenu$: Observable<StatusMenu>;
  protected statusesMenu$: Observable<StatusMenu[]>;

  @Input()
  public account: Account;

  @Input()
  public notes: Note[];

  public verificationStatus$: Subject<any>;
  public verificationStatus: any;
  protected provincesNew$: Observable<string[]>;

  protected get channel(): string { return this.account && this.account.channel.channel; }

  protected get documentType(): string { return this.account && this.account.channel.documentType; }

  constructor(
    private _store: Store,
  ) {
    this.verificationStatus$ = new BehaviorSubject('');
    this.statusesMenu$ = this._store.select(AccountsChannelsState.channels);
    this.provincesNew$ = this._store.select(AccountsProvincesNewState.newprovinces);


  }

  public ngOnInit(): void {
    this.isUserType = {"name":'user'}
    this.verificationStatus$.subscribe((verificationStatus) => {
      this.verificationStatus = verificationStatus;
    })
   }

  public ngOnChanges(changes: SimpleChanges): void {
    const accountChanges = changes['account'];
    this.verificationStatus$.next(this.account.verificationStatus);
    if (accountChanges) {
      this.statusMenu$ = this._store.select(AccountsChannelsState.channel(this.channel));
    }
  }

  public showApproveKyc(): boolean {
    return (this.channel === 'verified' || this.channel === 'active'); 
  }

  public resetPasscode$$(): void {
    this.resetPasscode.emit();
  }

  public editUser$$(account: Account): void {
    this.editUser.emit(account);
  }

  protected addNote(): any {
    this.addNoteEmitter.emit();
  }

  protected changeChannel(channel: ChannelStatus): any {
    this.changeEmitter.emit(channel);
  }

  protected viewAttachments(event: any): void {
    this.viewAttachmentsEmitter.emit(event);
  }
}
