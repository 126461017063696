import {
  Pipe,
  PipeTransform,
} from '@angular/core';


@Pipe({ name: 'initials' })
export class NameInitialsPipe implements PipeTransform {

  public transform(name: string): string {
      if (!name) {
          return '';
      }

      name = name.trim();

      const split = name.split(' ');

      if (split.length < 2)
          return '';

      const firstName = split[0];
      const lastName = split[split.length - 1];

      return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  }
}
