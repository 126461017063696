import {
    Injectable,
} from '@angular/core';

import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    SearchBuilder,
} from '../services/search-builder.service';


@Injectable({
    providedIn: 'root',
})
export class SearchGuard implements CanActivate {
    constructor(
        private _store: Store,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const sb = new SearchBuilder(route.queryParams);

        const isSearchEmpty = sb.isEmpty;

        if (isSearchEmpty) {
            const path = state.url.slice(0, state.url.indexOf(route.url[route.url.length - 1].path));

            this._store.dispatch(new Navigate([path]));
        }

        return !isSearchEmpty;
    }
}
