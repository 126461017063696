import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import {
  NymcardAccountLimitDecimals,
  NymcardAccountLimitUnits,
} from '@michel.freiha/ng-sdk';


@Pipe({
  name: 'remaining',
})
export class LimitRemainingPipe implements PipeTransform {

  public transform(value: NymcardAccountLimitDecimals | NymcardAccountLimitUnits): number {
    if (!value)
      return undefined;

    return value.total - (value.used + value.reserved);
  }

}
