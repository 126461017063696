import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  AdminRoleInternal,
  SearchFilter,
} from '@michel.freiha/ng-sdk';

import {
  ShimmerHandler,
} from '@nymos/theme';

@Component({
  selector: 'nym-role-aside',
  templateUrl: './role-aside.component.html',
  styleUrls: ['./role-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleAsideComponent implements OnInit, OnChanges {

  private _shimmerHandler: ShimmerHandler<AdminRoleInternal> = new ShimmerHandler(5);
  private _role: AdminRoleInternal;

  @HostBinding('class.nym-role-aside')
  protected get classes(): boolean { return true; }

  @Input()
  public roles: AdminRoleInternal[];

  @Input()
  public loading: boolean;

  @Output('assign') // tslint:disable-line:no-output-rename
  public assignEmitter: EventEmitter<any> = new EventEmitter();

  @Output('search') // tslint:disable-line:no-output-rename
  public searchEmitter: EventEmitter<SearchFilter> = new EventEmitter();

  public get selected(): AdminRoleInternal { return this._role; }
  protected get data(): AdminRoleInternal[] { return this._shimmerHandler.data; }

  constructor() { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const loading = changes['loading'];
    const roles = changes['roles'];

    if (roles) {
      this._shimmerHandler.data = roles.currentValue;
    }

    if (loading) {
      this._shimmerHandler.loading = loading.currentValue;
    }
  }

  public assign(action: any): void {
    this.assignEmitter.emit(action);
  }

  public select(role: AdminRoleInternal): void {
    this._role = role;
  }

  public search(filter: SearchFilter): void {
    this.searchEmitter.emit(filter);
  }
}
