import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
} from '@angular/core';

import {
  AbstractControl,
  Validators,
} from '@angular/forms';

import {
  CoreNote,
} from '@michel.freiha/ng-sdk';

import {
  ConfirmComponent,
} from '@nymos/theme';


interface Option { value: string; text: string; }

@Component({
  selector: 'nym-refund-confirm',
  templateUrl: './refund-confirm.component.html',
  styleUrls: ['./refund-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundConfirmComponent extends ConfirmComponent<any>{

  @HostBinding('class.thm-note-confirm')
  protected get classes(): boolean { return true; }

  protected get hasOptions(): boolean { return this.customOptions.length > 0; }

  protected get customOptions(): Option[] { return this.data && this.data.options || []; }
  protected get defaultOption(): Option { return { text: 'Other', value: 'other' }; }

  protected get amount(): AbstractControl { return this.form.get('amount'); }
  protected get category(): AbstractControl { return this.form.get('category'); }

  protected get balance():number { return this.data && this.data.options || null; }

  protected showBalanceError:boolean;

  // @Override
  protected initForm(data: any): void {
    const category = this.hasOptions ? '' : this.defaultOption.value;

    this.showBalanceError=false;

    this.form = this._fb.group({
      category: category,
      amount: '',
    });

    this.category.setValidators([Validators.required]);
    this.amount.setValidators([Validators.required,this.isZeroValidator,this.doubleDot,this.startsWithZeroValidator,Validators.pattern(/^(?:(?:[0-9][.0-9]*)|0)$/)]);
    
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    
    this.showBalanceError=false;

    if (this.amount.value > this.data.balance){
      this.showBalanceError=true;
    }
    else{
    if (!this.form.valid)
      return;

    const note = {
      amount: this.amount.value,
      category: this.category.value,
    };
    this._ref.close(note);
    }
  }

  isZeroValidator(control:AbstractControl):any {
       
       return control.value <= 0 ? {'zeroErr': true} :  null;
  }

  startsWithZeroValidator(control:AbstractControl):any {
      if(control.value[0]== '0' && control.value[1]!= '.')
        return {'zeroErr': true};
      else
        return null;   
  }

  doubleDot(control:AbstractControl):any {

      var firstIndex = control.value.indexOf(".");

      var result = firstIndex != control.value.lastIndexOf(".") && firstIndex != -1;
      return result ? {'zeroErr': true} :  null;
  }

}
