import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import {
    ThemePipesModule,
} from '../../../pipes/pipes.module';

import {
    ShimmerComponent,
} from './containers/shimmer/shimmer.component';

import {
    ShimmerLoadingComponent,
} from './components/shimmer-loading/shimmer-loading.component';

import {
    ShimmerStaticComponent,
} from './components/shimmer-static/shimmer-static.component';

export const MATERIAL_MODULES = [
];


@NgModule({
    declarations: [
        ShimmerComponent,
        ShimmerLoadingComponent,
        ShimmerStaticComponent,
    ],

    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        FlexLayoutModule,
        ThemePipesModule,
    ],

    exports: [
        ShimmerComponent,
        ShimmerLoadingComponent,
        ShimmerStaticComponent,
    ],
})
export class ThemeComponentsShimmerModule { }
