import {
    NgModule,
} from '@angular/core';

import {
    SectionDirective,
} from './section.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        SectionDirective,
    ],

    imports: [],

    exports: [
        SectionDirective,
    ],

})
export class ThemeDirectivesSectionModule { }
