import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
} from '@angular/core';


@Directive({
  selector: '[thmSelected]',
})
export class SelectedDirective {

  private readonly SELECTED_CLASS: string = 'thm-selected';

  private _selected: boolean = false;

  @Input()
  get thmSelected(): boolean { return this._selected; }
  set thmSelected(value: boolean) { this._toSelected(value); }

  constructor(
    private _renderer: Renderer2,
    private _element: ElementRef,
  ) { }

  private _toSelected(value: boolean): void {
    const selected = value;

    if (selected) {
      this._renderer.addClass(this._element.nativeElement, this.SELECTED_CLASS);
    } else {
      this._renderer.removeClass(this._element.nativeElement, this.SELECTED_CLASS);
    }

    this._selected = selected;
  }

}
