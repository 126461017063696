import {
    Admin,
    Problem,
} from '@michel.freiha/ng-sdk';

export class StoreProfileFromApi {
    public static readonly type: string = '[Profile API] Store profile';
    constructor(public admin: Admin, public scopes: string[]) { }
}

export class FailFromApi {
    public static readonly type: string = '[Profile API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
