import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Select, Store,
} from '@ngxs/store';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Credentials,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  Auth,
  Init,
} from '../../../store/auth/auth.actions';

import {
  AuthState,
} from '../../../store/auth/auth.state';
import { SigninService } from '../../../services/signin.service';

import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'nym-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public loading: boolean;
  private _subscription: Subscription = new Subscription();

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public get username(): AbstractControl {
    return this.form.get('username');
  }

  public get problem$(): Observable<Problem> {
    return this._problem$;
  }

  public resetError: any = '';
  public resetErrorMsg: any = '';
  public partailLink: any = '';

  public resetSuccess = false;
  public resetSuccessMsg = ''; 

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
    private _signinService: SigninService,
    private _nc: MatSnackBar,
    private cd: ChangeDetectorRef,
  ) {
    this.form = this._fb.group({
      username: ['', [Validators.required,Validators.email]],
    });
  }

  public ngOnInit(): void {
    // this._subscription.add(this._problem$.subscribe((problem) => {
    //   if (problem) {
    //     console.log(problem);
    //     this.resetError = problem;
    //     this.loading = false;
    //     if(this.resetError.error && this.resetError.error.detail){
    //       this.resetErrorMsg = this.resetError.error.detail;
    //     }
    //     if(this.resetError.error){
    //       this._ph.handle(new Problem(this.resetError.error), this.form);
    //     }
    //   }
    // }));

    this.partailLink = '/'+ window.localStorage.getItem('apikey')+ '/account/signin';
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {
    this.loading = true;
    this.form.setErrors(null);
    
    if (!this.form.valid){
      this.loading = false;
      return;
    }
    
    var email =  this.form.value['username'];

    // const credentials = new Credentials({
    //   password: this.form.value['password'],
    // });

    this._signinService.forgetPassword(email).subscribe((data) => {
          this.resetError = null;
          this.resetErrorMsg = '';
          this.resetSuccess = true;
          this.resetSuccessMsg = 'Please Check your mail. Password reset request send to you mail';
          this.loading = false;
          this._nc.open('Please Check your mail. Password reset request send to you mail','', {duration: 5000});
          this.cd.detectChanges();
        }, (error) =>{
          this.resetSuccess = false;
          this.resetSuccessMsg = '';
          this.loading = false;
          this.resetError = error;
          if(this.resetError.error.title == 'Not Found'){
            this._nc.open('Email does not exist.Please enter valid email','', {duration: 5000});
          }
          if(this.resetError && this.resetError.error.detail){
            this.resetErrorMsg = this.resetError.error.detail;
          }
          this.cd.detectChanges();
        });

    // this._store.dispatch(new Auth({ credentials: credentials }));
    // console.log(res);
  }

  public togglePassword(): void {
    this.passwordMode.toggle();
  }
}
