import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  interval,
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Admin,
  Problem,
  SearchCriteria,
  SearchFilter,
  SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
  Dialog,
} from '@nymos/theme';

import {
  AddNoteFromPage,
  LoadUserNotesFromPage,
  Note,
  NoteConfirmComponent,
  NotesState,
} from '@nymos/notes';

import {
  Account,
  AccountsAgentsState,
  ChannelStatus,
  AccountChannel,
  Texts,
  Wallet,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  BlockAccountFromAgentDetailsPage,
  GetAccountFromAgentDetailsPage,
  RefreshAccountFromAgentDetailsPage,
  RejectAccountFromAgentDetailsPage,
  ResetPasscodeFromAgentDetailsPage,
  UnblockAccountFromAgentDetailsPage,
  VerifyAccountFromAgentDetailsPage,
} from '@nymos/accounts/core';

import {
  WalletsState,
} from '../../../core/store/wallets/wallets.state';

import {
  LoadWalletsFromAgentDetailsPage,
  RefreshWalletsFromAgentDetailsPage,
} from '../../../core/store/wallets/wallets.actions';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  ApproveActivityFromAgentDetailsPage,
  CancelActivityFromAgentDetailsPage,
  LoadWalletActivitiesFromAgentDetailsPage,
  RefreshWalletActivitiesFromAgentDetailsPage,
  RejectActivityFromAgentDetailsPage,
  RetryActivityFromAgentDetailsPage,
} from '../../../core/store/wallet-activities/wallet-activities.actions';

import {
  ChannelHandler,
} from '../../../services/channel-handler.service';

import {
  WalletActivityHandler,
} from '../../../services/wallet-activity-handler.service';

import {
  UserWalletActivityComponent,
} from '../user-wallet-activity/user-wallet-activity.component';

import {
  WalletTopupConfirmComponent,
} from '../wallet-topup-confirm/wallet-topup-confirm.component';

import {
  WalletTopupRefundComponent,
} from '../wallet-topup-refund/wallet-topup-refund.component';

import {
  InitKycFromResultsPage,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

@Component({
  selector: 'nym-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentDetailsComponent implements OnInit, OnDestroy {

  private _subscription: Subscription = new Subscription();

  private _account: Account;

  private _channel: AccountChannel;

  public refundPermission$:boolean;

  public channelStatus:boolean;

  public walletStatus:boolean;

  @HostBinding('class.nym-agent-details')
  protected get classes(): boolean { return true; }

  protected account$: Observable<Account>;

  @Select(AccountsAgentsState.loading)
  public accountLoading$: Observable<boolean>;

  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  @Select(WalletsState.loading)
  public walletLoading$: Observable<boolean>;

  @Select(WalletsState.problem)
  public walletProblem$: Observable<Problem>;

  @Select(WalletActivitiesState.userActivities)
  public walletActivities$: Observable<WalletActivity[]>;

  @Select(WalletActivitiesState.loading)
  public walletActivitiesLoading$: Observable<boolean>;

  protected notes$: Observable<Note[]>;

  protected accountId: string;

  constructor(
    private _store: Store,
    private _dialog: Dialog,
    private _channelHandler: ChannelHandler,
    private _walletActivityHandler: WalletActivityHandler,
  ) {
     
    const router = this._store.selectSnapshot((state) => state.router.state);

    this.accountId = router.params.id;

    const criteria = new SearchCriteria({
      filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [this.accountId] })],
    });

    this.account$ = this._store.select(AccountsAgentsState.account(this.accountId))

    this.notes$ = this._store.select(NotesState.userNotes(this.accountId)); 

    this.account$.subscribe((account)=>{this._account=account;})
    if(this._account && (this._account.channel.channel=='active' || this._account.channel.channel=='verified')){
             this._store.dispatch([
              new LoadWalletsFromAgentDetailsPage(this.accountId),
             ]);
             this.walletStatus=false;
          }else{
            this.walletStatus=true;
          }
      
    this._store.dispatch([
      new GetAccountFromAgentDetailsPage(this.accountId),
      new LoadWalletActivitiesFromAgentDetailsPage(this.accountId),
      new LoadUserNotesFromPage(criteria),
     ]);

    this._subscription.add(interval(5000).subscribe(() => {
     if(this._account && (this._account.channel.channel==='active' || this._account.channel.channel ==='verified')){
     this.walletStatus=false;
      this._store.dispatch([
        new RefreshWalletsFromAgentDetailsPage(this.accountId),
       ]);
      }else{
        this.walletStatus=true;
      }
      }));

    this._subscription.add(interval(10000).subscribe(() => {
     if(this._account && (this._account.channel.channel==='active' || this._account.channel.channel ==='verified')){
     this.walletStatus=false;
      this._store.dispatch([
        new RefreshAccountFromAgentDetailsPage(this.accountId),
        new RefreshWalletsFromAgentDetailsPage(this.accountId),
        new RefreshWalletActivitiesFromAgentDetailsPage(this.accountId),
       ]);
      }else{
        this.walletStatus=true;
      }
      }));
  }

  public ngOnInit(): void {
    
     this._store.selectSnapshot((state) =>{
       if(state.profile.scopes.indexOf('p:wallettrans:debit')>-1){
         this.refundPermission$ = true;
       }else{
          this.refundPermission$=false;
       }
     })


    this._subscription.add(this.account$.subscribe((account) =>this._account = account));
    
    
   
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

 
  protected viewWalletActivities(): void {
    const account = this._account;
    this._dialog.openAsFullwidth(UserWalletActivityComponent, { data: { account } });
  }

  protected viewTopup(): void {
    const text = Texts.TopupDialog.TopupTitle(this._account.profile.fullName);
    const account = this._account;

    this._dialog.openAsModal(WalletTopupConfirmComponent, { data: {
      text,
      account,
      confirm: { label: 'Done' },
      cancel: { label: 'Cancel' },
    }});
  }


    

  protected viewTopupRefund(): void {
     const text = Texts.TopupDialog.RefundTitle(this._account.profile.fullName);
      let account = this._account;
      this._dialog.openAsModal(WalletTopupRefundComponent, { 
         width: '400px',
          data: {
          text,
          account,
          confirm: { label: 'Refund' },
          cancel: { label: 'Cancel' },
         }});
    }

  protected loadWallet(): void {
    this._store.dispatch(new LoadWalletsFromAgentDetailsPage(this.accountId));
  }

  protected editAgent(): void {
    this._store.dispatch([
      new InitKycFromResultsPage(this._account.profile.mobile),
      new Navigate([`accounts/agent/new`]),
    ]);
  }

  protected resetPasscode$$(): void {
    const ref = this._dialog.openConfirm(NoteConfirmComponent, {
      text: Texts.UserAction.ResetPasscode(this._account.profile.fullName, this._account.profile.mobile),
    });

    ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note) => {
      this._store.dispatch(new ResetPasscodeFromAgentDetailsPage(this._account.id, note));
    });
  }

  protected viewAttachments(event: any): void {
    const urls = event.attachments;
    const index = event.index;

    this._dialog.openPreview({ urls, index });
  }

  protected addNote(): any {
    const ref = this._dialog.openConfirm(NoteConfirmComponent, {
      text: Texts.Notes.AddNew,
    });

    ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note) => {
      return this._store.dispatch(new AddNoteFromPage(this._account.id, note));
    });
  }

  protected changeChannel(channel: ChannelStatus): any {
    // tslint:disable-next-line: switch-default
    switch (channel) {
      case 'rejected':
        return this._channelHandler.reject(this._account, RejectAccountFromAgentDetailsPage);

      case 'verified':
          return this._channelHandler.verify(this._account, VerifyAccountFromAgentDetailsPage);

      case 'blocked':
        console.log('this is what we call ', this._account)
        return this._channelHandler.block(this._account, BlockAccountFromAgentDetailsPage);

      case 'unblocked' as ChannelStatus:
        return this._channelHandler.unblock(this._account, UnblockAccountFromAgentDetailsPage);
    }
  }

  protected manageWalletActivity({ activity, action }: any): any {
    // tslint:disable-next-line: switch-default
    switch (action) {
      case 'approve':
        return this._walletActivityHandler.approve(this._account, activity, ApproveActivityFromAgentDetailsPage);

      case 'reject':
        return this._walletActivityHandler.reject(this._account, activity, RejectActivityFromAgentDetailsPage);

      case 'cancel':
        return this._walletActivityHandler.cancel(this._account, activity, CancelActivityFromAgentDetailsPage);

      case 'retry':
        return this._walletActivityHandler.retry(this._account, activity, RetryActivityFromAgentDetailsPage);
    }
  }

}
