import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Account,
} from '@nymos/accounts/core';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  Store,
} from '@ngxs/store';


@Component({
  selector: 'nym-user-listing-table',
  templateUrl: './user-listing-table.component.html',
  styleUrls: ['./user-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListingTableComponent implements OnInit {

  @HostBinding('class.nym-user-full-table')
  protected get classes(): boolean { return true; }

  @Output('view') // tslint:disable-line:no-output-rename
  protected viewEmitter: EventEmitter<Account> = new EventEmitter();

  @Output('loadBy')
  protected sortByColumn: EventEmitter<any> = new EventEmitter();

  @Input()
  public users: Account[] = [];

  @Input()
  public columns: string[];

  @Input()
  public loading: boolean;

  private prevCol:string;

  private prevDir:string;

  constructor(protected _store: Store) { }

  public ngOnInit(): void {
    this.prevCol=null;
    this.prevDir="desc";
   }

  public view(account: Account): void {
    this.viewEmitter.emit(account);
  }

  public sortLoad(col: string): void {

    if(this.prevCol !== col){
      this.prevDir = "asc";
    }
    else{
      if(this.prevDir=="asc"){
        this.prevDir= "desc";
      }
      else
      {
        this.prevDir= "asc";
      }
    }  

    let params= {
      "col":col,
      "direction":this.prevDir
    }

    this.sortByColumn.emit(params);

    this.prevCol=col;
  }


  public left_click(account_id,account_type){
     this._store.dispatch(new Navigate([`accounts/${account_id}/${account_type}/details`]));
     event.preventDefault();
  }
}
