import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  AdminInternal,
  AdminProfile,
  CoreAccountStatus as AdminStatus,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-admin-info',
  templateUrl: './admin-info.component.html',
  styleUrls: ['./admin-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminInfoComponent implements OnInit {

  @HostBinding('class.nym-admin-info')
  protected get classes(): boolean { return true; }

  @Input()
  public admin: AdminInternal;

  public get profile(): AdminProfile {
    return this.admin && this.admin.admin && this.admin.admin.profile;
  }

  public get status(): AdminStatus {
    return this.admin && this.admin.admin && this.admin.admin.status;
  }

  constructor() { }

  public ngOnInit(): void { }

}
