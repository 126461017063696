import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
} from '@angular/forms';

import {
  Observable,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  Admin,
  AdminInternal,
  AdminProfile,
  AdminRoleInternal,
  CoreAccountStatus as AdminStatus,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ConfirmComponent,
  Dialog,
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  AdminFullNamePipe,
} from '../../../pipes/admin-fullname.pipe';


import {
  AdminsState,
} from '../../../store/admins/admins.state';

import {
  CreateAdminFromEditDialog,
  RefreshAdminFromEditDialog,
  ResetAdminPasscodeFromEditDialog,
  UpdateAdminFromEditDialog,
  UpdateAdminStatusFromEditDialog,
} from '../../../store/admins/admins.actions';

import {
  RolesState,
} from '../../../store/roles/roles.state';

import {
  RefreshRolesFromEditDialog,
} from '../../../store/roles/roles.actions';

import {
  AdminEditFormComponent,
} from '../../components/admin-edit-form/admin-edit-form.component';


// tslint:disable-next-line:variable-name
export const AdminEditConfig: any = {
  width: '844px',
  autoFocus: false,
  panelClass: 'nym-admin-edit-dialog',
};

@Component({
  selector: 'nym-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminEditComponent extends ModalComponent implements OnInit, OnDestroy {

  private _selected: string[] = [];

  private _isEditing: boolean = false;

  private _admin: AdminInternal = null;

  @HostBinding('class.nym-admin-edit')
  protected get classes(): boolean { return true; }

  @Select(AdminsState.problem)
  public problem$: Observable<Problem>;

  @Select(RolesState.roles)
  public roles$: Observable<AdminRoleInternal[]>;

  @Select(AdminsState.saving)
  public acting$: Observable<boolean>;

  @ViewChild('edit', { static: true })
  public edit: AdminEditFormComponent;

  public _admin$: Observable<AdminInternal>;

  public get isEditing(): boolean { return this._isEditing; }
  public get isNewData(): boolean { return !this._admin; }

  public get id(): string { return this._admin && this._admin.id; }
  public get admin(): AdminInternal { return this._admin; }
  public get selected(): string[] { return this._selected; }

  constructor(
    protected _store: Store,
    protected _actions$: Actions,
    protected _dialog: Dialog,
    protected _fb: FormBuilder,
    protected _ref: DialogRef<AdminEditFormComponent, any>,
    protected _cd: ChangeDetectorRef,
    @Inject(DIALOG_DATA) data: any,
  ) {
    super(_fb, _ref, _cd, null, data);
    this.loadRoles();
    this._setAdmin(data.admin);

    this._admin$ = this._store.select(AdminsState.admin(this.id));
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(CreateAdminFromEditDialog, UpdateAdminFromEditDialog)).subscribe(() => this._ref.close());

    this.subscription.add(this._admin$.pipe(filter((a) => a !== undefined)).subscribe((admin) => {
      this._admin = admin;
      this._selected = admin.admin.roles;
      this._cd.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected loadRoles(): void {
    this._store.dispatch(new RefreshRolesFromEditDialog());
  }

  // @Override
  protected initForm(data: any): void {
    this.form = this.edit.form;
    this.ngForm = this.edit.ngForm;
  }

  // @Override
  protected submit(): void {

    const data = new Admin({
      id: this.id,
      profile: new AdminProfile({
        firstName: this.edit.firstName.value,
        lastName: this.edit.lastName.value,
        email: this.edit.email.value,
        mobile: this.edit.mobile.value,
      }),
      roles: this._selected,
      status: AdminStatus.Active,
    });

    const action = this.isNewData
      ? new CreateAdminFromEditDialog({ data: data })
      : new UpdateAdminFromEditDialog({ id: data.id, data: data });

    const fullName = new AdminFullNamePipe().transform(data.profile);

    const text = this.isNewData
      ? `Are you sure you want to create a new admin account?`
      : `Are you sure you want to save <b>${fullName}</b>'s account?`;

    const ref = this._dialog.openConfirm(ConfirmComponent, { text: text });

    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;
      this._store.dispatch(action);
    });
  }

  protected markAsEditing$$(): void {
    this._isEditing = true;
  }

  protected toggleStatus$$(status: AdminStatus): void {

    const verb = status === AdminStatus.Blocked ? 'block' : 'unblock';

    const fullName = new AdminFullNamePipe().transform(this._admin.admin.profile);
    const text = `Are you sure sure you want to ${verb} <b>${fullName}</b>'s account?`;
    const ref = this._dialog.openConfirm(ConfirmComponent, {
      text: text,
      confirm: { label: verb },
      cancel: { label: 'Cancel' },
    });

    const data = new Admin(this._admin.admin);
    data.status = status;

    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;
      this._store.dispatch(new UpdateAdminStatusFromEditDialog({ id: this._admin.id, data: data }));
    });
  }

  protected resetPasscode$$(): void {
    const fullName = new AdminFullNamePipe().transform(this._admin.admin.profile);
    const text = `Are you sure you want to reset <b>${fullName}</b>'s passcode?`;
    const ref = this._dialog.openConfirm(ConfirmComponent, {
      text: text,
      confirm: { label: 'Reset' },
      cancel: { label: 'Cancel' },
    });

    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;
      this._store.dispatch(new ResetAdminPasscodeFromEditDialog({ id: this._admin.id }));
    });
  }

  protected selectRoles$$(roles: string[]): void {
    this._selected = roles;
  }


  private _setAdmin(admin: AdminInternal): void {
    this._isEditing = !admin;

    if (!admin) {
      return;
    }

    this._admin = admin;
    this._store.dispatch(new RefreshAdminFromEditDialog({ id: admin.id }));
  }

}
