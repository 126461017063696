import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  ConfirmComponent,
  Dialog,
} from '@nymos/theme';

import {
  MobileVerification,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  Texts,
} from '../../../core/texts/devtools.texts';

import {
  ClearThrottlingFromDevtoolsPage,
} from '../../../store/throttling/throttling.actions';

import {
  ThrottlingState,
} from '../../../store/throttling/throttling.state';

import {
  OtpsState,
} from '../../../store/otps/otps.state';

import {
  FetchOtpFromDevtoolsPage,
} from '../../../store/otps/otps.actions';


@Component({
  selector: 'nym-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit {

  @HostBinding('class.nym-homepage')
  protected get classes(): boolean { return true; }

  @Select(ThrottlingState.problem)
  public problem$: Observable<Problem>;

  @Select(OtpsState.otps)
  public otps$: Observable<MobileVerification[]>;

  @Select(OtpsState.loading)
  public loadingOtp$: Observable<boolean>;

  @Select(ThrottlingState.cleaning)
  public cleaningThrottling$: Observable<boolean>;

  constructor(
    private _store: Store,
    private _dialog: Dialog,
  ) { }

  public ngOnInit(): void { }

  protected clearThrottling$$(): void {
    const ref = this._dialog.openConfirm(ConfirmComponent, {
      text: Texts.Throttling.ClearThrottling,
      confirm: { label: 'Clear' },
      cancel: { label: 'Cancel' },
    });

    ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
      return this._store.dispatch(new ClearThrottlingFromDevtoolsPage());
    });
  }

  protected fetchOtp$$(phoneNumber: string): void {
    this._store.dispatch(new FetchOtpFromDevtoolsPage(phoneNumber));
  }
}
