import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/tokens/';
const TOKEN_CARD_FOLDER = ASSETS_FOLDER + 'token-card/';
const CREDIT_CARD_FOLDER = ASSETS_FOLDER + 'credit-card/';
const DEVICE_FOLDER = ASSETS_FOLDER + 'device/';
const NETWORK_FOLDER = ASSETS_FOLDER + 'network/';

@Injectable({
    providedIn: 'root',
})
export class TokensAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('token', 'active', sanitizer.bypassSecurityTrustResourceUrl(TOKEN_CARD_FOLDER + 'active.svg'));
        iconRegistry.addSvgIconInNamespace('token', 'suspended', sanitizer.bypassSecurityTrustResourceUrl(TOKEN_CARD_FOLDER + 'suspended.svg'));
        iconRegistry.addSvgIconInNamespace('token', 'inactive', sanitizer.bypassSecurityTrustResourceUrl(TOKEN_CARD_FOLDER + 'inactive.svg'));
        iconRegistry.addSvgIconInNamespace('token', 'deleted', sanitizer.bypassSecurityTrustResourceUrl(TOKEN_CARD_FOLDER + 'deleted.svg'));

        iconRegistry.addSvgIconInNamespace('card', 'logo', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'nym-card-logo.svg'));

        iconRegistry.addSvgIconInNamespace('card', 'green', sanitizer.bypassSecurityTrustResourceUrl(CREDIT_CARD_FOLDER + 'green.svg'));

        iconRegistry.addSvgIconInNamespace('device', 'phone', sanitizer.bypassSecurityTrustResourceUrl(DEVICE_FOLDER + 'phone.svg'));
        iconRegistry.addSvgIconInNamespace('device', 'watch', sanitizer.bypassSecurityTrustResourceUrl(DEVICE_FOLDER + 'watch.svg'));

        iconRegistry.addSvgIconInNamespace('network', 'visa', sanitizer.bypassSecurityTrustResourceUrl(NETWORK_FOLDER + 'visa.svg'));
        iconRegistry.addSvgIconInNamespace('network', 'mastercard', sanitizer.bypassSecurityTrustResourceUrl(NETWORK_FOLDER + 'mastercard.svg'));
    }

}
