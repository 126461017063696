import {
    Problem,
} from '@michel.freiha/ng-sdk';


export class FetchOtpFromDevtoolsPage {
    public static readonly type: string = '[Devtools Page] Fetch OTP';
    constructor(public phoneNumber: string) { }
}

export class FailFromApi {
    public static readonly type: string = '[OTP API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
