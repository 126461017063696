import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'last' })
export class StringLastPipe implements PipeTransform {

    public transform(input: string, chars: number = 0): any {
        if (!input)
            return '';

        return input.substr(input.length - chars);
    }
}
