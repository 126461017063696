
import {
  Component,
  HostListener,
} from '@angular/core';

import {
  TokenCardComponent,
} from '../../../lib/ui/components/token-card/token-card.component';

@Component({
  selector: 'nym-token-card',
  template: ``,
})
export class StubTokenCardComponent
  extends TokenCardComponent {

  @HostListener('click', ['$event'])
  public trigger(action: string): void {
    switch (action) {
      case 'activate':
        return this.activate();

      case 'resume':
        return this.resume();

      case 'suspend':
        return this.suspend();

      case 'delete':
        return this.delete();

      default:
        throw new Error('Error on trigger');
    }
  }
}
