/* tslint:disable:no-bitwise */

import {
    cipher,
    md,
    pki,
    util,
} from 'node-forge';

import {
    Base64,
    NO_PADDING,
    NO_WRAP,
    URL_SAFE,
} from './base64.util';

import {
    PrivateKey,
    PublicKey,
    RSA,
} from './rsa.util';

import {
    RsaPair,
} from '../model/rsa-pair.model';


const PROTOCOL: string = 'ncrypt';
const VERSION: string = '1';
const SEPARATOR: string = ':';
const PROTOCOL_VERSION: string = PROTOCOL + SEPARATOR + VERSION;
const CIPHER_MODE: cipher.Algorithm = 'AES-CBC';
const ENCRYPTION_MODE: pki.rsa.EncryptionScheme = 'RSA-OAEP';

export class Session {

    private _base64Fingerprint: string;

    private _pair: RsaPair;

    private get _publicKey(): PublicKey { return this._pair.publicKey; }
    private get _privateKey(): PrivateKey { return this._pair.privateKey; }

    public get privatePem(): string { return this._pair.privatePem; }
    public set privatePem(pem: string) { this._pair.privatePem = pem; }

    public get publicPem(): string { return this._pair.publicPem; }
    public set publicPem(pem: string) { this._pair.publicPem = pem; }

    constructor(ob: Partial<RsaPair>) {
        this._pair = new RsaPair(ob);
    }

    public encrypt(text: string): string {
        if (!text)
            return null;

        if (!this._publicKey)
            throw new Error(`PublickKey hasn't been initialized`);

        const iv = RSA.iv();
        const secretKey = RSA.secretKey();

        const ec = cipher.createCipher(CIPHER_MODE, secretKey);
        ec.start({ iv: iv });
        ec.update(util.createBuffer(text));
        ec.finish();
        const encText = ec.output.bytes();

        const wrapOptions = { md: md.sha512.create() };
        const encSecretKey = this._publicKey.encrypt(secretKey, ENCRYPTION_MODE, wrapOptions);

        const base64Iv = Session.base64encode(iv);
        const base64EncText = Session.base64encode(encText);
        const base64EncSecretKey = Session.base64encode(encSecretKey);

        return [PROTOCOL, VERSION, this._base64Fingerprint, base64Iv, base64EncSecretKey, base64EncText].join(SEPARATOR);
    }

    public decrypt(encrypted: string): string {
        if (!encrypted)
            return null;

        if (!this._privateKey)
            throw new Error(`PrivateKey hasn't been initialized`);

        const parts = encrypted.split(SEPARATOR);

        if (parts.length !== 6 || !encrypted.startsWith(PROTOCOL_VERSION))
            throw new Error(`Data hasn't been encrypted with ${PROTOCOL_VERSION}`);

        const iv = Session.base64decode(parts[3]);
        const encSecretKey = Session.base64decode(parts[4]);
        const encText = Session.base64decode(parts[5]);

        const wrapOptions = { md: md.sha512.create() };
        const secretKey = this._privateKey.decrypt(encSecretKey, ENCRYPTION_MODE, wrapOptions);

        const dc = cipher.createDecipher(CIPHER_MODE, secretKey);
        dc.start({ iv: util.createBuffer(iv) });
        dc.update(util.createBuffer(encText));
        dc.finish();
        const text = dc.output.toString();

        return text;
    }

    public static base64encode(text: string): string {
        return Base64.encode(text, NO_WRAP | NO_PADDING | URL_SAFE);
    }

    public static base64decode(text: string): string {
        return Base64.decode(text, NO_WRAP | NO_PADDING | URL_SAFE);
    }


}
