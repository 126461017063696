import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'thm-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePhotoComponent implements OnInit {

  @HostBinding('class.thm-profile-photo')
  protected get classes(): boolean { return true; }

  @Input()
  public photo: string;

  constructor() { }

  public ngOnInit(): void { }

}
