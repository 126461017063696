// tslint:disable:variable-name

import {
    Token,
} from '@michel.freiha/ng-sdk';

import {
    StringLastPipe,
} from '@nymos/theme';


export type TokenFn = (token: Token) => string;

export class Texts {

    public static readonly Activate: TokenFn = ((token) => Texts._confirm('activate', token));

    public static readonly Resume: TokenFn = ((token) => Texts._confirm('resume', token));

    public static readonly Suspend: TokenFn = ((token) => Texts._confirm('suspend', token));

    public static readonly Delete: TokenFn = ((token) => Texts._confirm('delete', token));

    public static readonly DeleteHint: string =
        `Deleting a token might have some negative impact ` +
        `on all transactions that used this token.`;

    private static _confirm(verb: string, token: Token): string {
        const tokenId = new StringLastPipe().transform(token.id, 4);
        return `Are you sure sure you want to ${verb} token <b>${tokenId}</b>?`;
    }
}


