// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '../../texts/bulkcards.texts';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Loading: Notification = new Notification(Texts.Action.Loading);
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
    public static readonly Issuing: Notification = new Notification(Texts.Action.Issuing);
    public static readonly Issued: Notification = new Notification(Texts.Action.Issued);
}


