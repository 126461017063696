import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
} from '@angular/core';

import {
  AbstractControl,
  Validators,
} from '@angular/forms';

import {
  CoreNote,
} from '@michel.freiha/ng-sdk';

import {
  ConfirmComponent,
} from '@nymos/theme';


interface Option { value: string; text: string; }

@Component({
  selector: 'nym-note-confirm',
  templateUrl: './note-confirm.component.html',
  styleUrls: ['./note-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteConfirmComponent extends ConfirmComponent<CoreNote> {

  @HostBinding('class.thm-note-confirm')
  protected get classes(): boolean { return true; }

  protected get hasOptions(): boolean { return this.customOptions.length > 0; }

  protected get customOptions(): Option[] { return this.data && this.data.options || []; }
  protected get defaultOption(): Option { return { text: 'Other', value: 'other' }; }

  protected get title(): AbstractControl { return this.form.get('title'); }
  protected get category(): AbstractControl { return this.form.get('category'); }
  protected get description(): AbstractControl { return this.form.get('description'); }

  // @Override
  protected initForm(data: any): void {
    const category = this.hasOptions ? '' : this.defaultOption.value;

    this.form = this._fb.group({
      category: category,
      title: '',
      description: '',
    });

    this.category.setValidators([Validators.required]);
    this.description.setValidators([Validators.required]);

    this._checkTitleValidators(category);
    this.subscription.add(this.category.valueChanges.subscribe((change) => {
      this._checkTitleValidators(change);
    }));
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
      return;

    const note = new CoreNote({
      title: this.title.value,
      category: this.category.value,
      description: this.description.value,
    });
    this._ref.close(note);
  }

  private _checkTitleValidators(category: string): void {
    if (category === this.defaultOption.value)
      this.title.setValidators([Validators.required]);
    else
      this.title.clearValidators();
    this.title.updateValueAndValidity();
  }

}
