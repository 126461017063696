import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  Card,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardComponent implements OnInit {

  @HostBinding('class.nym-credit-card')
  protected get classes(): boolean { return true; }

  @Input()
  public card: Card;

  constructor() { }

  public ngOnInit(): void { }

}
