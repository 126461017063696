
const SHIMMER_LINES: number = 5;


export class ShimmerHandler<T> {

    private _shimmerData: T[];

    private _loading: boolean = false;
    private _realData: T[] = [];

    public set data(values: T[]) { this._realData = values; }
    public set loading(value: boolean) { this._loading = value; }

    public get data(): T[] { return this._getData(); }

    constructor(
        count: number = SHIMMER_LINES,
    ) {
        this._shimmerData =  <T[]>Array.from(Array(count), () => ({}));
    }

    private _getData(): T[] {
        return this._loading ? this._shimmerData : this._realData;
    }
}
