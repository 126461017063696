/* tslint:disable:member-ordering */

import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    tap,
} from 'rxjs/operators';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountType,
} from '@nymos/accounts/core';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    LoadAccountTypesFromApi,
} from './accounts-types.actions';


export interface AccountsTypesStateModel {
    items: AccountType[];
    loading: boolean;
}

const stateDefaults: AccountsTypesStateModel = {
    items: [],
    loading: undefined,
};

@State<AccountsTypesStateModel>({
    name: 'types',
    defaults: stateDefaults,
})
export class AccountsTypesState implements NgxsOnInit {

    @Selector()
    public static types(state: AccountsTypesStateModel): AccountType[] {
        return state.items;
    }

    @Selector()
    public static loading(state: AccountsTypesStateModel): boolean {
        return state.loading;
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsTypesStateModel>): any {
        ctx.dispatch(new LoadAccountTypesFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsTypesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountTypesFromApi)
    public load(ctx: StateContext<AccountsTypesStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountTypes().pipe(
            tap((items: AccountType[]) => {
                ctx.setState({ items: items, loading: false });
            }),
        );
    }

}
