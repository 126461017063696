/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    Province,
} from '../../models/province.model';

import {
    LoadAccountProvincesFromApi,
} from './accounts-provinces.actions';


export interface AccountsProvincesStateModel {
    loading: boolean;
    provinces: string[];
    cities: { [province: string]: string[] };
}

const stateDefaults: AccountsProvincesStateModel = {
    loading: undefined,
    provinces: [],
    cities: {},
};

@State<AccountsProvincesStateModel>({
    name: 'provinces',
    defaults: stateDefaults,
})
export class AccountsProvincesState implements NgxsOnInit {

    @Selector()
    public static loading(state: AccountsProvincesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static provinces(state: AccountsProvincesStateModel): string[] {
        return state.provinces;
    }

    public static cities(province: string): any {
        return createSelector([AccountsProvincesState], (state: AccountsProvincesStateModel) => {
            return state.cities[province];
        });
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsProvincesStateModel>): void {
        ctx.dispatch(new LoadAccountProvincesFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsProvincesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountProvincesFromApi)
    public load(ctx: StateContext<AccountsProvincesStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountPronvinces().pipe(
            tap((provinces: Province[]) => {
                ctx.setState(produce((draft) => {
                    draft.provinces = provinces.map((p) => p.name);
                    provinces.forEach((p) => draft.cities[p.name] = p.cities);
                }));
            }),
            finalize(() => {
              ctx.patchState({ loading: false });
            }),
        );
    }
}

