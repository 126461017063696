/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    Action,
    NgxsOnInit,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    throwError,
} from 'rxjs';

import {
    catchError,
    tap,
} from 'rxjs/operators';

import {
    Device,
} from '@michel.freiha/ng-sdk';

import {
    ENCRYPTION_DEVICE,
    EncryptionService,
} from '../services/encryption.service';

import {
    Enroll,
} from './encryption.actions';

import {
    RsaPair,
} from '../model/rsa-pair.model';




export interface EncryptionStateModel {
    keys: RsaPair;
}

const stateDefaults: EncryptionStateModel = {
    keys: undefined,
};

@State<EncryptionStateModel>({
    name: 'encryption',
    defaults: stateDefaults,
})
export class EncryptionState implements NgxsOnInit {

    constructor(
        private _encryptionService: EncryptionService,
        @Inject(ENCRYPTION_DEVICE) private _device: Device,
    ) { }

    public ngxsOnInit(ctx: StateContext<EncryptionStateModel>): void {
        // ctx.dispatch(new Enroll({ device: this._device }));
    }

    @Action(Enroll)
    public enroll(ctx: StateContext<EncryptionStateModel>, { payload: { device } }: Enroll): any {

        const state = ctx.getState();

        return this._encryptionService.enroll(device, state.keys).pipe(

            tap((keys: RsaPair) => {
                ctx.setState(produce((draft) => { draft.keys = keys; }));
            }),

            catchError((problem) => {
                return throwError(problem);
            }),
        );
    }
}
