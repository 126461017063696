import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminInternal,
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  Dialog,
} from '@nymos/theme';

import {
  SearchBuilder,
  SearchSnapshot,
} from '@nymos/dashboard/shared';

import {
  AdminsState,
} from '../../../store/admins/admins.state';

import {
  LoadMoreAdminsFromListingPage,
  SearchAdminsFromListingPage,
} from '../../../store/admins/admins.actions';

import {
  RolesState,
} from '../../../store/roles/roles.state';

import {
  LoadRolesFromListingPage as LoadRoles,
} from '../../../store/roles/roles.actions';

import {
  AdminEditComponent,
  AdminEditConfig,
} from '../admin-edit/admin-edit.component';




@Component({
  selector: 'nym-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessManagementComponent implements OnInit, OnDestroy {

  private _sb: SearchBuilder = new SearchBuilder();
  private _subscription: Subscription = new Subscription();
  private _search$: Observable<SearchSnapshot>;

  @HostBinding('class.nym-access-management')
  protected get classes(): boolean { return true; }

  @Select(RolesState.roles)
  public roles$: Observable<AdminRoleInternal[]>;

  @Select(RolesState.loading)
  public rolesLoading$: Observable<boolean>;

  @Select(AdminsState.admins)
  public admins$: Observable<AdminInternal[]>;

  @Select(AdminsState.loading)
  public adminsLoading$: Observable<boolean>;

  @Select(AdminsState.hasMore)
  public adminsHasMore$: Observable<boolean>;

  public get search(): SearchBuilder { return this._sb; }

  constructor(
    private _store: Store,
    private _dialog: Dialog,
  ) {
    this.loadInitial();

    this._search$ = this._sb.build();
  }

  public ngOnInit(): void {
    this._subscription.add(this._search$.subscribe((snap) => {
      this.load(snap);
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public load(snap: SearchSnapshot): void {
    this._store.dispatch(new SearchAdminsFromListingPage({ criteria: snap.criteria }));
  }

  public loadMore(): void {
    this._store.dispatch(new LoadMoreAdminsFromListingPage());
  }

  public loadInitial(): void {
    this._store.dispatch(new LoadRoles());
  }

  public refresh(): void {
    this._store.dispatch(new SearchAdminsFromListingPage({ criteria: this._sb.criteria }));
  }

  public addNew(): void {
    const text = 'Add new Admin';
    this._dialog.openAsModal(AdminEditComponent, { ...AdminEditConfig, data: { text } });
  }

  public edit(admin: AdminInternal): void {
    const text = 'Manage Admin';
    this._dialog.openAsModal(AdminEditComponent, { ...AdminEditConfig, data: { text, admin } });
  }

}
