import {
    Inject, Injectable, INJECTOR,
} from '@angular/core';

import {
    filter,
} from 'rxjs/operators';

import {
    Store,
} from '@ngxs/store';

import {
    CoreNote,
    SearchCriteria,
    SearchFilter,
    Problem,
    SearchOperator
} from '@michel.freiha/ng-sdk';

import {
    ConfirmComponent,
    Dialog,
    DialogRef,
} from '@nymos/theme';

import {
    ACCOUNT_ME,
    AccountMe,
} from '@nymos/auth';

import {
    NoteConfirmComponent,
    LoadUserNotesFromPage,
    NotesState
} from '@nymos/notes';

import {
    Notification,
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    Account,
    Texts,
} from '@nymos/accounts/core';


import { CheckAgentWalletForKycApproval, CheckTravelCardIssuanceForKycApproval } from '../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

import { SetStatusLoadingFromStatusMenu } from '../core/store/accounts-users/accounts-users.actions';

import * as faker from 'faker';


@Injectable({
    providedIn: 'root',
})
export class ChannelHandler {

    private _ref: DialogRef<any, any>;

    constructor(
        private _nc: NotificationCenter,
        private _dialog: Dialog,
        private _store: Store,
        @Inject(ACCOUNT_ME) private _me: AccountMe,
    ) { }

    public create(account: Account, action: new (account: Account) => any): any {

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.ChannelDialog.CreateTitle,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(account));
        });
    }

    public reject(account: Account, action: new (id: string, note: CoreNote,docType:string) => any): any {

        const createdById = account.createdBy && account.createdBy.id;
        if (this._me.id() === createdById) {
            this._nc.show(new Notification(Texts.UserAction.UnableToReview));
            return;
        }

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.ChannelDialog.RejectTitle(account.profile.fullName),
            hint: Texts.ChannelDialog.RejectHint,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(account.id, note,account.channel.documentType)).subscribe((res)=>{
             setTimeout(()=>{                    
                     this.isSync(account.id)
                    }, 2000);
            });
        });
    }

    public verify(account: Account, action: new (id: string,docType:string) => any): any {
        const createdById = account.createdBy && account.createdBy.id;
        let transactionId = faker.random.uuid();
        let documentType = account.channel.documentType;
        if (this._me.id() === createdById) {
            this._nc.show(new Notification(Texts.UserAction.UnableToReview));
            return;
        }

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.ChannelDialog.VerifyTitle(account.profile.fullName),
        });

        this._ref.afterClosed().subscribe((d: boolean) => {

                if(d !== undefined){
                        if(documentType.toString()=='KYC2'){
                 let agentId = account.kyc2Agent.id
                   let secondUUID= faker.random.uuid();
                    return this._store.dispatch(new CheckTravelCardIssuanceForKycApproval(account.id, account.id, secondUUID, true)).subscribe((issues_card_res_dry)=>{
                      return this._store.dispatch(new CheckAgentWalletForKycApproval(agentId,account.id,  agentId, transactionId, secondUUID,true)).subscribe((debitcard_res_dry)=>{
                        return this._store.dispatch(new action(account.id,account.channel.documentType)).subscribe((approve_kyc_res)=>{
                           return this._store.dispatch(new CheckAgentWalletForKycApproval(agentId,account.id,  agentId, transactionId,secondUUID, false)).subscribe((debitcard_res)=>{
                               return this._store.dispatch(new CheckTravelCardIssuanceForKycApproval(account.id, account.id, secondUUID, false)).subscribe((res)=>{
                                return this._store.dispatch(new SetStatusLoadingFromStatusMenu(true))
                               })
                           })
                        })
                    })
                 })
               }else{
                 return this._store.dispatch(new action(account.id,account.channel.documentType))
               }
            }
            else{
                 return this._store.dispatch(new SetStatusLoadingFromStatusMenu(false))
            }
        });
    }

    public block(account: Account, action: new (id: string, note: CoreNote) => any): any {

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.ChannelDialog.BlockTitle(account.profile.fullName),
            hint: Texts.ChannelDialog.BlockHint,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
          console.log('this is the note ', note)
            return this._store.dispatch(new action(account.id, note)).subscribe((res)=>{
               if(res){
                 setTimeout(()=>{                    
                     this.isSync(account.id)
                    }, 3000);
                 }
            });
        });
    }

    public unblock(account: Account, action: new (id: string) => any): any {

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.ChannelDialog.UnblockTitle(account.profile.fullName),
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(account.id));
        });
    }

    public isSync(accountId:string){
     const criteria = new SearchCriteria({
      filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [accountId] })],
     });

    this._store.dispatch([
       new LoadUserNotesFromPage(criteria),
    ]);
     this._store.select(NotesState.userNotes(accountId));
    }

}
