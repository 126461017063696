// tslint:disable:variable-name

export type StringFn = (str: string) => string;

export namespace Texts {
    export class Notes {
        public static readonly AddNew: string = `Add new Note`;
        public static readonly Failure: string = `Something went wrong`;
        public static readonly Saving: string = `Saving note`;
    }
}


