import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';


export const PIPES = [];

@NgModule({
    declarations: [
        ...PIPES,
    ],

    imports: [
        CommonModule,
    ],

    exports: [
        ...PIPES,
    ],
})
export class DevtoolsCoreModule { }
