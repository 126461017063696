import {
    Problem,
} from '@michel.freiha/ng-sdk';

export class LoadScopesFromApi {
    public static readonly type: string = '[Scopes API] Load scopes';
}

export class FailFromApi {
    public static readonly type: string = '[Scopes API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
