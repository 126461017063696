/* tslint:disable: component-class-suffix */

import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    ViewEncapsulation,
} from '@angular/core';

import {
    matDialogAnimations as animation,
} from '@angular/material/dialog';

import {
    DialogContainer,
} from '../dialog.container';


@Component({
    selector: 'thm-fullwidth-dialog',
    templateUrl: './fullwidth.container.html',
    styleUrls: ['./fullwidth.container.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [animation.dialogContainer],
})
export class FullwidthContainer extends DialogContainer {

    @HostBinding('class.thm-fullwidth-dialog')
    protected get classes(): boolean { return true; }

}
