

const LOZENGES_MAX = 2;
const NAME_BASE_WIDTH = 240;
const NAME_MAX_WIDTH = 100;

interface Dimension { width?: number; height?: number; }

export class AdminShimmer implements Dimension {
    private _lozenges: number = 0;
    private _name: Dimension = {};

    public get header(): number { return 56; }
    public get height(): number { return 48; }

    public get lozenges(): number { return this._lozenges; }
    public get name(): Dimension { return this._name; }

    constructor(random: boolean = true) {
        this._lozenges = random ? Math.floor(Math.random() * LOZENGES_MAX) : LOZENGES_MAX;
        this._name.width = NAME_BASE_WIDTH + (random ? Math.floor(Math.random() * NAME_MAX_WIDTH) : 0);
    }

    public row(index: number, offset: number | boolean = 0): number {
        if (index === 0) {
            return 0 + this._offset(this.header, offset);
        }

        return (this.header + (index - 1) * this.height) + this._offset(this.height, offset);
    }

    private _offset(height: number, offset: number | boolean): number {
        return typeof offset === 'number'
                ? this._center(height, offset)
                : this._border(height, offset);
    }

    private _center(height: number, offset: number): number {
        return (height - offset) / 2;
    }

    private _border(height: number, end: boolean): number {
        return end === true ? height : 0;
    }

    public lozenge(index: number): number {
        return index < this._lozenges ? 1 : 0;
    }
}
