import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import {
  CustomValidators,
} from 'ngx-custom-validators';

import {
  AgentAccount,
  AgentProfile,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

const IRAQ_NUMBER_PATTERN = /^\+?964|\D+$/g;

@Component({
  selector: 'nym-agent-personal-edit',
  templateUrl: './agent-personal-edit.component.html',
  styleUrls: ['./agent-personal-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class AgentPersonalEditComponent implements OnInit, OnChanges {

  private _form: FormGroup;

  @HostBinding('class.nym-agent-personal-edit')
  protected get classes(): boolean { return true; }

  @Input()
  public account: AgentAccount;

  @Input()
  public personalInfoError: boolean;

  @Input()
  public problem: Problem;

  protected get form(): FormGroup { return this._form; }
  public get valid(): boolean { return this._form.valid; }

  public get fullName(): AbstractControl { return this.form.get('fullName'); }
  public get mobile(): AbstractControl { return this.form.get('mobile'); }
  public get email(): AbstractControl { return this.form.get('email'); }


  public get agentProfile(): AgentProfile {

    const mobile = `+964${this.mobile.value.replace(IRAQ_NUMBER_PATTERN, '')}`;
    return new AgentProfile({
      fullName: this.fullName.value,
      email: this.email.value,
      mobile: mobile,
    });
  }

  constructor(
    private _fb: FormBuilder,
    private _ph: ProblemHandler,
  ) {
    this._form = this._fb.group({
      fullName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32),
        Validators.pattern(/^[a-zA-Z ]*$/),
        this.noWhitespaceValidator
      ]],
      mobile: ['', [
        Validators.required,
        Validators.pattern(/^(\+964)?(7[3-9])[0-9]{8}$/),
      ]],
      email: ['', [
        Validators.required,
        CustomValidators.email,
      ]],
    });
  }

  public ngOnInit(): void { }

  noWhitespaceValidator(control:AbstractControl):any {
      var regex= /^(?!\s)(?!.*\s$)/;
      return !regex.test(control.value) ? {'whitespace': true} :  null;
   }

  public ngOnChanges(changes: SimpleChanges): void {

    const accountChanges = changes['account'];

    if (this.personalInfoError) {
      this.validateAllFormFields(this.form);
    }

    if (this.problem) {
      this._ph.handle(this.problem, this.form);
    }

    if (accountChanges) {
      this._setForm(accountChanges.currentValue);
    }
  }

  private _setForm(account: AgentAccount): void {
    const profile = account && account.profile;
    if (!profile)
      return;

    this._form.patchValue({
      fullName: profile.fullName || '',
      mobile: profile.mobile.replace(IRAQ_NUMBER_PATTERN, '') || '',
      email: profile.email || '',
    });
  }

  validateAllFormFields(formGroup: FormGroup): void {       
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);      
    if (control instanceof FormControl) {      
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) { 
      this.validateAllFormFields(control);     
    }
  });
}
}
