import { FormGroup } from '@angular/forms';

export class CustomValidators {
    public static match(passwordName: string, confirmName: string): any {
        return (group: FormGroup) => {
            let password = group.controls[passwordName];
            let confirm = group.controls[confirmName];

            if (password.value === confirm.value)
                return;

            if (confirm.errors)
                confirm.errors.match = true;
            else
                confirm.setErrors({ match: true });
        };
    }
}
