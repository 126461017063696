import {
    AccountChannelIndex,
    AgentAccountInternal,
    ClientOptions,
    CoreAccountRef,
    NymcardAccountLimits as AccountLimits,
    NymcardAccountLimitsInternal as AccountLimitsInternal,
    UserAccountInternal,
    UserAccountVerificationStatus,
    UserDocuments,
    CoreAccount,
} from '@michel.freiha/ng-sdk';

import {
    Account,
    AccountType,
} from '../models/account.model';

import {
    AccountAddressBuilder,
} from './account-address.builder';

import {
    AccountBusinessBuilder,
} from './account-business.builder';

import {
    AccountChannelBuilder,
} from './account-channel.builder';

import {
    AccountDocumentBuilder,
} from './account-document.builder';

import {
    AccountProfileBuilder,
} from './account-profile.builder';

import {
    AccountAdditionalDocumentBuilder,
} from './account-additional-document.builder';


import { AccountProfile } from '../models/account-profile.model';


export class AccountBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;
    private _index: AccountChannelIndex;
    private _limits: AccountLimitsInternal;

    constructor(private _options: ClientOptions) { }

    public withUser(value: UserAccountInternal): AccountBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountBuilder {
        this._agent = value;
        return this;
    }

    public withIndex(value: AccountChannelIndex): AccountBuilder {
        this._index = value;
        return this;
    }

    public withLimits(value: AccountLimitsInternal): AccountBuilder {
        this._limits = value;
        return this;
    }

    public build(): Account {

        let id: string;
        let type: AccountType;
        let verificationStatus: UserAccountVerificationStatus;
        let kyc2Agent: CoreAccount;
        let kyc2SubmittedBy: CoreAccount;
        let created: Date;
        let modified: Date;
        let limits: AccountLimits;
        let createdBy: CoreAccountRef;
        let documents: UserDocuments;

        if (!this._index && !this._user && !this._agent)
            return undefined;

        if (this._index) {
            id = this._index.accountId;
            type = this._index.accountType as AccountType;
            created = new Date(this._index.joined);
        }

        if (this._limits) {
            limits = this.getLimits(this._limits);
        }

        if (this._user) {
            id = this._user.id;
            verificationStatus = this._user.verificationStatus as UserAccountVerificationStatus;
            kyc2Agent = this._user.kyc2Agent;
            kyc2SubmittedBy = this._user.kyc2SubmittedBy;
            created = this._user.created;
            modified = this._user.modified;
            documents = this._user.userAccount.documents;

        } else if (this._agent) {
            id = this._agent.id;
            created = this._agent.created;
            modified = this._agent.modified;
            createdBy = this._agent.createdBy;
        }

        const channel = new AccountChannelBuilder()
            .withUser(this._user)
            .withAgent(this._agent)
            .withIndex(this._index)
            .build();

        const profile = new AccountProfileBuilder()
            .withUser(this._user)
            .withAgent(this._agent)
            .withIndex(this._index)
            .build();

        const business = new AccountBusinessBuilder(this._options)
            .withAgent(this._agent)
            .build();

        const document = new AccountDocumentBuilder(this._options)
            .withUser(this._user)
            .withAgent(this._agent)
            .build();

        const additionalDocument = new AccountAdditionalDocumentBuilder(this._options)
            .withUser(this._user)
            .withAgent(this._agent)
            .build();

        const address = new AccountAddressBuilder(this._options)
            .withUser(this._user)
            .withAgent(this._agent)
            .build();


        return new Account({
            id: id,
            type: type,
            verificationStatus: verificationStatus,
            kyc2Agent: kyc2Agent,
            kyc2SubmittedBy:kyc2SubmittedBy,
            created: created,
            modified: modified,
            limits: limits,
            channel: channel,
            profile: profile,
            business: business,
            address: address,
            document: document,
            documents:documents,
            additionalDocument:additionalDocument,
            createdBy: createdBy,
        });
    }

    // NOTES(@leandro): Remove this method when backend fix the ISO currency code issue
    private getLimits(internal: AccountLimitsInternal): AccountLimits {

        if (!internal)
            return undefined;

        const limits = internal.limits;

        if (internal.limits.yearly)
            limits.yearly.currency = limits.yearly.currency.toUpperCase();

        if (internal.limits.monthly)
            limits.monthly.currency = limits.monthly.currency.toUpperCase();

        return limits;
    }

}
