import {
  NgModule,
} from '@angular/core';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatTableModule,
} from '@angular/material/table';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  BulkcardsCoreModule,
  IssuancesState,
} from './core';

import {
  BulkcardsRoutingModule,
} from './bulkcards.routing';

// LAYOUT

import { ContentComponent } from './views/layout/content/content.component';

// CONTAINERS

import {
  BulkHomepageComponent,
} from './views/containers/bulk-homepage/bulk-homepage.component';

import {
  BulkIssuanceComponent,
} from './views/containers/bulk-issuance/bulk-issuance.component';

// CONTAINERS

import {
  IssuanceListingComponent,
} from './views/components/issuance-listing/issuance-listing.component';

import {
  IssuanceListingHeaderComponent,
} from './views/components/issuance-listing-header/issuance-listing-header.component';

import {
  IssuanceListingNoResultsComponent,
} from './views/components/issuance-listing-no-results/issuance-listing-no-results.component';

import {
  IssuanceListingTableComponent,
} from './views/components/issuance-listing-table/issuance-listing-table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
];

@NgModule({
  declarations: [
    ContentComponent,
    BulkHomepageComponent,
    IssuanceListingComponent,
    IssuanceListingHeaderComponent,
    IssuanceListingNoResultsComponent,
    IssuanceListingTableComponent,
    BulkIssuanceComponent,
  ],
  imports: [
    ...MATERIAL_MODULES,
    SharedModule,
    ReactiveFormsModule,

    BulkcardsCoreModule,
    BulkcardsRoutingModule,
    NgxsModule.forFeature([
      IssuancesState,
    ]),

  ],

  entryComponents: [
    BulkIssuanceComponent,
  ],
})
export class BulkcardsModule {}
