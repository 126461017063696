import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';


@Component({
  selector: 'thm-perfect-scrollbar',
  templateUrl: './perfect-scrollbar.component.html',
  styleUrls: ['./perfect-scrollbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerfectScrollbarComponent implements OnInit {

  @HostBinding('class.thm-perfect-scrollbar')
  protected get classes(): boolean { return true; }

  @Output('bottom') // tslint:disable-line:no-output-rename
  protected bottomEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public hasMore: boolean = false;

  constructor(
    @Optional() @Inject(PERFECT_SCROLLBAR_CONFIG) public config: PerfectScrollbarConfigInterface,
  ) { }

  public ngOnInit(): void { }

  protected bottom(): void {
    if (!this.hasMore)
      return;

    this.bottomEmitter.emit();
  }

}
