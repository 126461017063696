import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const ANIMATION_TIMING = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

export const thmAnimation: {
    readonly loading: AnimationTriggerMetadata;

} = {
    loading: trigger('fade', [
        state('fadeOut', style({ opacity: 0 })),
        state('fadeIn', style({ opacity: 1 })),
        transition('* => fadeIn', animate(ANIMATION_TIMING)),
    ]),
};
