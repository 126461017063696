import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  SearchFilter,
  SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
  ConfirmComponent,
  Dialog,
} from '@nymos/theme';

import {
  SearchBuilder,
  SearchSnapshot,
} from '@nymos/dashboard/shared';

import {
  Account,
  AccountChannel,
  AccountType,
  Texts,
} from '@nymos/accounts/core';

import {
  AccountsState,
} from '../../../core/store/accounts/accounts.state';

import {
  AccountsTypesState,
} from '../../../core/store/accounts-types/accounts-types.state';

import {
  SearchFromResultsPage,
} from '../../../core/store/accounts/accounts.actions';

import {
  AgentCreationConfirmComponent,
} from '../agent-creation-confirm/agent-creation-confirm.component';


@Component({
  selector: 'nym-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  protected _sb: SearchBuilder;
  protected _subscription: Subscription = new Subscription();

  @HostBinding('class.nym-search-results')
  protected get classes(): boolean { return true; }

  @Select(AccountsState.accounts)
  public accounts$: Observable<Account[]>;

  @Select(AccountsState.loading)
  public loading$: Observable<boolean>;

  @Select(AccountsState.hasMore)
  public hasMore$: Observable<boolean>;

  @Select((state) => state.router.state)
  public router$: Observable<any>;

  @Select(AccountsTypesState.types)
  public accountTypes$: Observable<AccountType[]>;

  protected search$: Observable<SearchSnapshot>;

  protected get title(): string { return Texts.ResultPage.SearchTitle(this._sb.criteria.query); }
  protected get columns(): string[] {
    return [
      'users',
      'channel',
      'joined',
      'hasNotes',
      'documentType',
    ];
  }

  constructor(
    protected _store: Store,
    protected _dialog: Dialog,
  ) {
    this._sb = new SearchBuilder();
    this.search$ = this._sb.build();
  }

  public ngOnInit(): void {
    this._subscription.add(this.router$.subscribe((router) => {
      this._sb.params = router.queryParams;
    }));

    this._subscription.add(this.search$.subscribe((snap) => {
      this.load(snap);
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected load(snap: SearchSnapshot): void {
    if (snap.isEmpty)
      return;

    this._store.dispatch(new SearchFromResultsPage(snap.criteria.query));
  }

  protected create(type: string): any {

    const text = 'Enter mobile';
    const ref = this._dialog.openAsModal(AgentCreationConfirmComponent, { data: { text: text } });

    return ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
      this._store.dispatch(new Navigate([`accounts/${type}/new`]));
    });
  }

  protected view(account: Account): void {
    if (!account.id)
      return;

    this._store.dispatch(new Navigate([`accounts/${account.id}/${account.type}/details`]));
  }

  protected filter(types: string[]): void {
    const sf = new SearchFilter({ field: 'account_type', operator: SearchOperator.AnyOf, values: types });
    this._sb.addFilter(sf);
  }

  protected documentFilter(types: string[]): void {
    const sf = new SearchFilter({ field: 'document_type', operator: SearchOperator.AnyOf, values: types });
    this._sb.addFilter(sf);
  }

}
