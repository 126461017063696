import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  AdminInternal,
  CoreAccountStatus as AdminStatus,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-admin-actions',
  templateUrl: './admin-actions.component.html',
  styleUrls: ['./admin-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminActionsComponent implements OnInit {

  @HostBinding('class.nym-admin-actions')
  protected get classes(): boolean { return true; }

  @Input()
  public admin: AdminInternal;

  @Output('edit') // tslint:disable-line:no-output-rename
  public editEmitter: EventEmitter<any> = new EventEmitter();

  @Output('passcode') // tslint:disable-line:no-output-rename
  public passcodeEmitter: EventEmitter<any> = new EventEmitter();

  @Output('status') // tslint:disable-line:no-output-rename
  public statusEmitter: EventEmitter<AdminStatus> = new EventEmitter();

  public get status(): AdminStatus {
    return this.admin && this.admin.admin && this.admin.admin.status === AdminStatus.Active
      ? AdminStatus.Blocked
      : AdminStatus.Active;
  }

  public get statusTooltip(): string {
    return this.status === AdminStatus.Blocked
      ? 'Block account'
      : 'Unblock account';
  }

  constructor() { }

  public ngOnInit(): void { }

  public edit(): void {
    this.editEmitter.emit();
  }

  public toggleStatus(): void {
    this.statusEmitter.emit(this.status);
  }

  public resetPasscode(): void {
    this.passcodeEmitter.emit();
  }

}
