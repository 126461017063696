import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'badge',
})
export class AccountBadgePipe implements PipeTransform {

  public transform(status: string): string {

    switch (status) {
      case 'agent':
        return 'business_center';
      case 'user':
      default:
        return '';

    }
  }

}