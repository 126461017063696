export { };


Number.prototype.pad = function(size?: number): string {
    let s = String(this);
    while (s.length < (size || 2)) {
        s = '0' + s;
    }
    return s;
};

declare global {
    interface Number {
        pad(this: number, size?: number): string;
    }
}
