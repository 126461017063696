import {
  NgModule,
} from '@angular/core';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  EncryptionState,
} from './store/encryption.state';


@NgModule({

  imports: [
    NgxsModule.forFeature([EncryptionState]),
  ],

})
export class NymEncryptionModule { }
