import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';

import {
  AdminRoleInternal,
  SearchFilter,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-role-aside-header',
  templateUrl: './role-aside-header.component.html',
  styleUrls: ['./role-aside-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleAsideHeaderComponent implements OnInit {

  @HostBinding('class.nym-role-aside-header')
  protected get classes(): boolean { return true; }

  @Output('filter') // tslint:disable-line:no-output-rename
  public filterEmitter: EventEmitter<SearchFilter> = new EventEmitter();

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<AdminRoleInternal> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void { }

  @HostListener('click', ['$event'])
  public select(event: Event): void {
    const filter = new SearchFilter({
      field: 'roles',
    });

    this.filterEmitter.emit(filter);
    this.selectEmitter.emit(null);
  }
}
