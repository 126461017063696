import {
    InjectionToken,
    Provider,
} from '@angular/core';

import {
    Store,
} from '@ngxs/store';

import {
    Admin,
    AdminProfile,
} from '@michel.freiha/ng-sdk';

import {
    ProfileState,
} from './store/profile/profile.state';

export const ACCOUNT_ME = new InjectionToken<AccountMe>('AccountMe');

export class AccountMe {

    constructor(private _fn: () => Admin) { }

    public profile(): AdminProfile { return this._fn().profile; }

    public id(): string { return this._fn().id; }
}

export const accountMeFactory = (store: Store) => {
    const account = () => store.selectSnapshot<Admin>((ProfileState.admin));
    return new AccountMe(account);
};

export const accountMeProvider: Provider = {
    provide: ACCOUNT_ME,
    useFactory: accountMeFactory,
    deps: [
        [Store],
    ],
};
