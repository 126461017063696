import {
    AccountChannelIndex,
    AgentAccountInternal,
    Gender,
    UserAccountInternal,
} from '@michel.freiha/ng-sdk';

import {
    AccountProfile,
} from '../models/account-profile.model';


export class AccountProfileBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;
    private _index: AccountChannelIndex;

    public withUser(value: UserAccountInternal): AccountProfileBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountProfileBuilder {
        this._agent = value;
        return this;
    }

    public withIndex(value: AccountChannelIndex): AccountProfileBuilder {
        this._index = value;
        return this;
    }

    public build(): AccountProfile {

        let fullName: string;
        let email: string;
        let mobile: string;
        let arabicName: string;
        let gender: Gender;
        let nationality: string;
        let dateOfBirth: Date;
        let placeOfBirth: string;
        let phoneNumber: string;

        if (!this._index && !this._user && !this._agent)
            return undefined;

        if (this._index) {
            const profile = this._index;
            fullName = profile.fullName;
            email = profile.email;
            mobile = profile.mobile;
            arabicName = profile.arabicName;
        }

        if (this._user) {
            const profile = this._user.userAccount.profile;
            if (profile) {
                fullName = profile.fullName;
                email = profile.email;
                mobile = profile.mobile;
                arabicName = profile.arabicName;
                gender = profile.gender;
                nationality = profile.nationality;
                dateOfBirth = profile.dateOfBirth;
                placeOfBirth = profile.placeOfBirth;
            }

        } else if (this._agent) {
            const profile = this._agent.agentAccount.profile;
            if (profile) {
                fullName = profile.fullName;
                email = profile.email;
                mobile = profile.mobile;
            }
        }


        return new AccountProfile({
            fullName: fullName,
            email: email,
            mobile: mobile,
            arabicName: arabicName,
            gender: gender,
            nationality: nationality,
            dateOfBirth: dateOfBirth,
            placeOfBirth: placeOfBirth,
            phoneNumber: phoneNumber,
        });
    }

}
