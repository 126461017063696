import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  MenuItem,
} from '../../models/menu-item.model';

import { Router } from '@angular/router'; 
@Component({
  selector: 'thm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit,OnDestroy {

  @HostBinding('class.thm-menu-item')
  protected get classes(): boolean { return true; }

  @Input()
  public item: MenuItem;

  @Input()
  public selIndex: any;

  selectedChild:any

  tabName:any;
  @Input()
  selectedChild1:any;

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();

  @Output() checkChildren : EventEmitter<any> = new EventEmitter();

  constructor(
    private router : Router
  ) { 
   this.tabName='';
   this.selectedChild='';
  }

  public ngOnInit(): void { 
    var currentUrl = this.router.url;
    // console.log(this.item);
    if(this.item.children){
      this.item.children.forEach(ele => {
        // console.log(ele)
        if(currentUrl == ele.url){
          this.selectedChild1 = ele.title;
          
          this.checkChildren.emit(ele.title)
        }
      });
    }else if(currentUrl == this.item.url){
      this.selectedChild1 = this.item.title;
      
      this.checkChildren.emit(this.item.title)
    }
   }

  public ngOnDestroy() {}

  public onClick(navigation: any): void {
    this.navigationEmitter.emit(navigation);
    this.checkChildren.emit(this.item)
  }

  public onChildren(item: any): void {
    if(!item.children){
      this.selectedChild = this.selectedChild1 //item.title
      }
      this.checkChildren.emit(item)
  }

  public selectedChildren(children_object){
    this.selectedChild1 = children_object.title;
  }

}
