// tslint:disable: member-ordering

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    Admin,
    AdminProfile,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    transformStringToArray,
} from '../../utils/function.util';

import {
    FailFromApi,
    StoreProfileFromApi,
} from './profile.actions';

import {
    SignOut,
} from '../auth/auth.actions';




export class ProfileStateModel {
    public admin: Admin;
    public scopes: string[];
    public problem: Problem;
}

const stateDefaults: ProfileStateModel = {
    admin: undefined,
    scopes: [],
    problem: undefined,
};

@State<ProfileStateModel>({
    name: 'profile',
    defaults: stateDefaults,
})
export class ProfileState {

    @Selector()
    public static admin(state: ProfileStateModel): Admin { return state.admin; }

    @Selector()
    public static profile(state: ProfileStateModel): AdminProfile { return state.admin.profile; }

    @Selector()
    public static problem(state: ProfileStateModel): Problem { return state.problem; }

    @Selector()
    public static scopes(state: ProfileStateModel): string[] { return state.scopes; }

    @Action(SignOut)
    public reset(ctx: StateContext<ProfileStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(StoreProfileFromApi)
    public storeProfile(ctx: StateContext<ProfileStateModel>, { admin, scopes }: StoreProfileFromApi): any {
        ctx.patchState({ admin: admin, scopes: scopes });
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<ProfileStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
    }
}
