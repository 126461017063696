import {
  NgModule,
} from '@angular/core';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatTableModule,
} from '@angular/material/table';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  CommonModule,
} from '@angular/common';

import {
  TableComponent,
} from './containers/table/table.component';


export const MATERIAL_MODULES = [
  MatIconModule,
  MatTableModule,
];


@NgModule({

  declarations: [
    TableComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    TableComponent,
  ],

})
export class ThemeComponentsTableModule { }
