import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select, Store,
} from '@ngxs/store';

import {
  Dialog,
} from '@nymos/theme';

import {
  BulkIssuance,
  IssuancesState,
  LoadFromHomePage,
  Texts,
} from '@nymos/bulkcards/core';

import {
  BulkIssuanceComponent,
} from '../bulk-issuance/bulk-issuance.component';

import {
  LoadMoreFromHomePage,
} from '../../../core/store/issuances/issuances.actions';


@Component({
  selector: 'nym-search-homepage',
  templateUrl: './bulk-homepage.component.html',
  styleUrls: ['./bulk-homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkHomepageComponent implements OnInit, OnDestroy {

  @HostBinding('class.nym-bulk-homepage')
  protected get classes(): boolean { return true; }

  @Select(IssuancesState.issuances)
  public issuances$: Observable<BulkIssuance[]>;

  @Select(IssuancesState.loading)
  public loading$: Observable<boolean>;

  @Select(IssuancesState.hasMore)
  public hasMore$: Observable<boolean>;

  @Select((state) => state.router.state)
  public router$: Observable<any>;

  protected get title(): string { return 'Bulk Issuances'; }
  protected get columns(): string[] {
    return [
      'token',
      'status',
      'number',
      'type',
      'money',
      'created',
    ];
  }

  constructor(
    protected _store: Store,
    protected _dialog: Dialog,
  ) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public ngOnDestroy(): void { }

  protected load(): void {
    this._store.dispatch(new LoadFromHomePage());
  }

  protected create$$(issuance: BulkIssuance): any {
    const text = Texts.IssuanceDialog.Title;
    this._dialog.openAsModal(BulkIssuanceComponent, { data: {
      text,
      issuance,
      confirm: { label: 'Order' },
      cancel: { label: 'Cancel' },
    }});
  }

  protected loadMore(): void {
    this._store.dispatch(new LoadMoreFromHomePage());
  }


}
