import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';

import {
  MatMenuTrigger,
} from '@angular/material/menu';

import {
  AdminRole,
  AdminRoleCategory,
  AdminRoleInternal,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  AdminRoleColor,
} from '../../../sdk/models/admin-role-color.model';

import {
  ProblemHandler,
} from '@nymos/problems';


@Component({
  selector: 'nym-role-edit-form',
  templateUrl: './role-edit-form.component.html',
  styleUrls: ['./role-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class RoleEditFormComponent implements OnInit, OnChanges {


  private _form: FormGroup;

  @ViewChild('ngForm', { static: true })
  private _ngForm: NgForm;

  @HostBinding('class.nym-role-edit-form')
  protected get classes(): boolean { return true; }


  @ViewChild('menuTrigger', { static: true })
  public menuTrigger: MatMenuTrigger;

  @Input()
  public role: AdminRoleInternal;

  @Input()
  public problem: Problem;

  public get col(): string { return this.color.value; }
  public set col(value: string) { this.color.setValue(value); }

  public get cat(): string { return this.category.value; }
  public set cat(value: string) { this.category.setValue(value); }

  public get form(): FormGroup { return this._form; }
  public get id(): AbstractControl { return this._form.get('id'); }
  public get name(): AbstractControl { return this._form.get('name'); }
  public get color(): AbstractControl { return this._form.get('color'); }
  public get category(): AbstractControl { return this._form.get('category'); }

  public get ngForm(): NgForm { return this._ngForm; }

  constructor(
    private _fb: FormBuilder,
    private _ph: ProblemHandler,
  ) {
    this._form = this._fb.group({
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      color: ['', [Validators.required]],
      category: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const problem = changes['problem'];
    const role = changes['role'];

    if (problem) {
      this._ph.handle(problem.currentValue, this.form, ['id']);
    }

    if (role) {
      this._setForm(role.currentValue);
    }
  }

  private _setForm(data: AdminRoleInternal): void {

    const element = this._getOrNewRole(data);

    this._form.patchValue({
      name: element.role.name || '',
      id: element.id || '',
      color: element.role.color || '',
      category: element.role.category || '',
    });

    this._disableForm(data);
  }

  private _disableForm(data: AdminRoleInternal): void {
    if (!data)
      return;

    this.id.disable();
  }

  private _getOrNewRole(data: AdminRoleInternal): AdminRoleInternal {
    if (data)
      return data;

    return new AdminRoleInternal({
      id: '',
      role: new AdminRole({ name: '', color: AdminRoleColor.Generic, category: AdminRoleCategory.Generic }),
    });
  }

}
