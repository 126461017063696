
import * as camelcase from 'camelcase';

import {
    InjectionToken,
} from '@angular/core';

import {
    Cursors,
    Pageable,
    Paging,
    SearchCriteria,
    SearchFilter,
    SearchMatcher,
    SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
    produce,
} from 'immer';

export class BackendOptions {
    public length: number = 0;
    public mock?: boolean = false;
    public data?: any = {};

    constructor(config: Partial<BackendOptions> = {}) {
        if (!config)
            return;

        if (config.length !== undefined) { this.length = config.length; }
        if (config.mock !== undefined) { this.mock = config.mock; }
        if (config.data !== undefined) { this.data = config.data; }
    }
}

export const mockOptions = new BackendOptions({ mock: true, length: 1 });
export const fakeOptions = new BackendOptions({ mock: false, length: 10 });
export const emptyOptions = new BackendOptions({ mock: false, length: 0 });


type StateOperator<M> = (existing: Readonly<M>) => M;

class ModelMap<T> {
    public items: { [id: string]: any } = {};
    public indexes: string[] = [];

    public get length(): number { return this.indexes.length; }
}

class ModelCollection<T> extends Pageable {
    public data?: T[];

    constructor(ob?: Partial<ModelCollection<T>>) {
        super(ob);

        this.data = [];

        if (!ob) { return; }

        if (ob.data !== undefined) { ob.data.forEach((o) => this.data.push(o)); }
    }
}

export class ModelFactory<T> {

    constructor(
        private _one: (id?: string, data?: any) => T,
        private _key?: (model: T) => string,
    ) { }

    public key(model: T): string {
        if (!!this._key)
            return this._key(model);

        return (model as any).id;
    }

    public one(id?: string, data?: any): T {
        return this._one(id, data);
    }

    public many(options: BackendOptions = mockOptions): T[] {
        const n = options.length;
        const mock = options.mock;
        const data = options.data;
        return Array.from(new Array(n), (o, i) => this._one(i.toString()), data);
    }

    public map(options: BackendOptions = mockOptions): any {
        const array = this.many(options);
        return array.reduce<any>((map, model) => {
            map[this.key(model)] = model;
            return map;
        }, {});
    }

    public collection(options: BackendOptions = mockOptions): ModelCollection<T> {
        const values = this.many(options);
        return new ModelCollection<T>({
            data: values,
            paging: new Paging({
                cursors: new Cursors({ after: 'after-cursors' }),
            }),
        });
    }
}

export const BACKEND_OPTIONS = new InjectionToken<BackendOptions>('BackendOptions');
export const BACKEND_FACTORY = new InjectionToken<ModelFactory<any>>('BackendFactory');

export abstract class MemoryBackend<T> {

    private data: ModelMap<T> = new ModelMap<T>();

    protected options: BackendOptions;

    constructor(
        protected _defaults: BackendOptions,
        protected _factory: ModelFactory<T>,
    ) {
        this.options = new BackendOptions(_defaults);

        // Insert random
        if (this.options.length > 0) {
            this.doInit(_factory.map(this.options));
        }
    }

    public gen(id?: string, data?: any): T {
        return this._factory.one(id, data);
    }

    public random(): T {
        return this.doRandom();
    }

    public snapshot(): ModelMap<T> {
        return this.data;
    }

    protected abstract afterInit(): any;

    protected doInit(map: any): void {
        this._setState(produce((draft) => {
            draft.items = map;
            draft.indexes = Object.keys(map);
        }));
    }

    protected doLoad(id: string, forceCreation: boolean = false): T {
        const item = this.data.items[id];

        if (item || !forceCreation)
            return item;

        return this.doCreate(this._factory.one(), id);

    }

    protected doCreate(model: T, id?: string): T {

        const index = id ? id : this._factory.key(model);

        if (!index)
            throw new Error(`Could not create model. Index not provided or no model key`);


        this._setState(produce((draft) => {
            draft.items[index] = model;
            draft.indexes.push(index);
        }));

        return model;
    }

    protected doUpdate(model: T): T {
        this._setState(produce((draft) => {
            draft.items[this._factory.key(model)] = model;
        }));

        return model;
    }

    protected doDelete(model: T): void {
        const index = this._factory.key(model);
        this._setState(produce((draft) => {
            delete draft.items[index];
            draft.indexes = draft.indexes.filter((o) => o !== index);
        }));
    }

    protected doSearch(criteria: SearchCriteria): ModelCollection<T> {
        let items = Object.values(this.data.items);

        if (!criteria) {
            return this._createCollection(items);
        }

        if (criteria.query) {
            items = this._filterByQuery(items, criteria.query);
        }

        if (criteria.filters) {
            if (!criteria.matcher || criteria.matcher === SearchMatcher.And) {
                items = this._filterByAnyMatcher(items, criteria.filters);
            } else {
                items = this._filterByOrMatcher(items, criteria.filters);
            }
        }

        if (criteria) {
            items = this.filter(items, criteria);
        }

        return this._createCollection(items);
    }

    protected doRandom(): T {
        const index = Math.floor(Math.random() * this.data.indexes.length);
        return this.doLoad(this.data.indexes[index]);
    }

    protected values(): T[] {
        return Object.values(this.data.items);
    }

    // @Override
    protected filter(items: T[], criteria: SearchCriteria): T[] {
        return items;
    }

    private _createCollection(items: T[]): ModelCollection<T> {
        return new ModelCollection<T>({
            data: items,
            paging: new Paging({
                cursors: new Cursors({ after: 'after-cursors' }),
            }),
        });
    }

    private _setState(val: ModelMap<T> | StateOperator<ModelMap<T>>): ModelMap<T> {
        return this._isStateOperator(val)
            ? this._setStateFromOperator(val)
            : this._setStateFromValue(val);
    }

    private _setStateFromValue(val: ModelMap<T>): any {
        this.data = val;
        return this.data;
    }

    private _setStateFromOperator(op: StateOperator<ModelMap<T>>): ModelMap<T> {
        const val = op(this.data);
        this.data = val;
        return this.data;
    }

    private _isStateOperator(value: ModelMap<T> | StateOperator<ModelMap<T>>): value is StateOperator<ModelMap<T>> {
        return typeof value === 'function';
    }

    private _filterByQuery(items: T[], query: string): T[] {
        return items.filter((i) => this._queryPredicate(i, query));
    }

    private _queryPredicate: ((item: T, query: string) => boolean) = (item: T, query: string): boolean => {
        const dataStr = Object.keys(item).reduce((currentTerm: string, key: string) => {
            return currentTerm + (item as { [key: string]: any })[key] + '◬';
        }, '').toLowerCase();

        const transformedFilter = query.trim().toLowerCase();

        return dataStr.indexOf(transformedFilter) !== -1;
    }


    private _filterByAnyMatcher(items: T[], filters: SearchFilter[]): T[] {

        filters.forEach((f) => {

            switch (f.operator) {
                case SearchOperator.AllOf:
                    items = items.filter((i) => this._allOfPredicate(i, f));
                    break;

                case SearchOperator.AnyOf:
                default:
                    items = items.filter((i) => this._anyOfPredicate(i, f));
                    break;
            }
        });

        return items;
    }

    private _filterByOrMatcher(items: T[], filters: SearchFilter[]): T[] {
        let hashMap = {};
        filters.forEach((f) => {
            let filter = [];
            switch (f.operator) {
                case SearchOperator.AllOf:
                    filter = items.filter((i) => this._allOfPredicate(i, f));
                    break;
                case SearchOperator.AnyOf:
                default:
                    filter = items.filter((i) => this._anyOfPredicate(i, f));
                    break;
            }
            filter.forEach((o) => hashMap[this._factory.key(o)] = o);
        });
        const results = Object.keys(hashMap).map(((id) => hashMap[id]));
        return results;
    }

    private _anyOfPredicate: ((data: T, filter: SearchFilter) => boolean) = (data: T, filter: SearchFilter): boolean => {
        for (let value of filter.values) {

            const filterStr = camelcase.default(filter.field);

            const fieldValue = data[filterStr];
            if (!fieldValue)
                continue;

            const fieldLowerCase = fieldValue.toLowerCase();
            const transformedValue = value.trim().toLowerCase();

            if (fieldLowerCase === transformedValue)
                return true;
        }

        return false;
    }

    private _allOfPredicate: ((data: T, filter: SearchFilter) => boolean) = (data: T, filter: SearchFilter): boolean => {
        for (let value of filter.values) {

            const filterStr = camelcase.default(filter.field);

            const fieldValue = data[filterStr];
            if (!fieldValue)
                continue;

            const fieldLowerCase = fieldValue.toLowerCase();
            const transformedValue = value.trim().toLowerCase();

            if (fieldLowerCase.indexOf(transformedValue) !== -1)
                return true;
        }

        return false;
    }

}

