import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import {
  Account,
  Card,
  StatusMenu,
} from '@nymos/accounts/core';

import {
  CardStatusMenuService,
} from '@nymos/accounts/data';

import {
  Note,
} from '@nymos/notes';

@Component({
  selector: 'nym-card-details-aside',
  templateUrl: './card-details-aside.component.html',
  styleUrls: ['./card-details-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetailsAsideComponent implements OnInit,OnChanges {

  @HostBinding('class.nym-card-details-aside')
  protected get classes(): boolean { return true; }

   @Input()
  public notes: Note[];

  @Output('changeStatus') // tslint:disable-line:no-output-rename
  protected changeEmitter: EventEmitter<string> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public card: Card;

  private filteredNotes:Note[];

  protected get status(): string { return this.card && this.card.status; }
  protected get statusMenu(): StatusMenu { return this.status && this._service.get(this.status); }
  protected get statusesMenu(): StatusMenu[] { return this._service.all(); }

  protected get channel(): string { return this.account && this.account.channel.channel; }

  constructor(
    private _service: CardStatusMenuService,
  ) { }

  public ngOnInit(): void { }

  protected changeStatus(status: string): any {
    this.changeEmitter.emit(status);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.notes)
    this.filteredNotes= this.notes.filter((o) => o.links[1].entityId===this.card.id);
  }

}
