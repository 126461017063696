
export enum AdminRoleColor {
    Generic = '#e0ecf0',
    Green = '#79f2c0',
    Blue = '#79e2f2',
    DarkBlue = '#b3d4ff',
    Purple = '#bfb5f2',
    Orange = '#ffbdad',
    Yellow = '#ffe3b0',
}
