import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    HomepageComponent,
} from './ui/containers/homepage/homepage.component';


export const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DevtoolsRoutingModule { }
