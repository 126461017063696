import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select,
} from '@ngxs/store';

import {
  Admin,
} from '@michel.freiha/ng-sdk';

import {
  DIALOG_DATA,
} from '@nymos/theme';

import {
  Account,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  WalletActivityHandler,
} from '../../../services/wallet-activity-handler.service';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  ApproveActivityFromWalletActivitiesPage,
  CancelActivityFromWalletActivitiesPage,
  RejectActivityFromWalletActivitiesPage,
  RetryActivityFromWalletActivitiesPage,
} from '../../../core/store/wallet-activities/wallet-activities.actions';


@Component({
  selector: 'nym-user-wallet-activity',
  templateUrl: './user-wallet-activity.component.html',
  styleUrls: ['./user-wallet-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWalletActivityComponent implements OnInit {

  @HostBinding('class.nym-user-wallet-activity')
  protected get classes(): boolean { return true; }

  @Select(WalletActivitiesState.userActivities)
  public activities$: Observable<WalletActivity[]>;

  @Select(WalletActivitiesState.loading)
  public loading$: Observable<boolean>;

  @Select(WalletActivitiesState.hasMore)
  public hasMore$: Observable<boolean>;

  public account: Account;

  constructor(
    private _walletActivityHandler: WalletActivityHandler,
    @Inject(DIALOG_DATA) data: any,
  ) {
    this.account = data.account;
  }

  public ngOnInit(): void { }

  protected manageWalletActivity({ activity, action }: any): any {
    // tslint:disable-next-line: switch-default
    switch (action) {
      case 'approve':
        return this._walletActivityHandler.approve(this.account, activity, ApproveActivityFromWalletActivitiesPage);

      case 'reject':
        return this._walletActivityHandler.reject(this.account, activity, RejectActivityFromWalletActivitiesPage);

      case 'cancel':
        return this._walletActivityHandler.cancel(this.account, activity, CancelActivityFromWalletActivitiesPage);

      case 'retry':
        return this._walletActivityHandler.retry(this.account, activity, RetryActivityFromWalletActivitiesPage);
    }
  }

}
