import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCharacter',
})
export class RemoveCharacterPipe implements PipeTransform {

    public transform(value: string, args: string): string {
        if (!value || !args)
            return null;

        value = value.replace(args, ' ');
        value = value.charAt(0).toUpperCase() + value.slice(1);
        return value;
    }
}
