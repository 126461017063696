import {
    Profile as Model,
} from './profile.model';

import {
    Menu,
} from '../../menu/models/menu.model';

import {
    MockMenuItem,
} from '../../menu/models/menu-item.model.mock';


export class MockProfile {
    public static create(): Model {
        return new Model({
            name: 'Name Surname',
            email: 'login@nymcard.com',
            photo: 'https://via.placeholder.com/48x48',
            menu: new Menu({ items: [MockMenuItem.create()] }),
        });
    }
}
