
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  ContentComponent,
} from './containers/content/content.component';

import {
  ContentHeaderDirective,
} from './components/content-header/content-header.directive';

import {
  ContentToolbarComponent,
} from './components/content-toolbar/content-toolbar.component';

import {
  ContentToolbarSpacerDirective,
} from './components/content-toolbar-spacer/content-toolbar-spacer.directive';



export const MATERIAL_MODULES = [
  MatProgressBarModule,
];

@NgModule({
  declarations: [
    ContentComponent,
    ContentHeaderDirective,
    ContentToolbarComponent,
    ContentToolbarSpacerDirective,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    ContentComponent,
    ContentHeaderDirective,
    ContentToolbarComponent,
    ContentToolbarSpacerDirective,
  ],
})
export class ThemeComponentsContentModule { }
