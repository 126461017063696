import {
    Env,
    ENV_NYMOS_K8S_DOMAIN,
    ENV_NYMOS_K8S_ENV,
    ENV_NYMOS_K8S_MICSRVC_CONN,
    ENV_NYMOS_K8S_NAME,
    ENV_NYMOS_K8S_PROJECT,
} from '../discovery.service';

import {
    AbstractDiscoveryService,
} from '../base/base-discovery.service';


const DEFAULT_DOMAIN: string = '{name}.{env}.svc.cluster.local';
const DEFAULT_CONN: string = 'https://{domain}';

export class K8sDiscoveryService extends AbstractDiscoveryService {

    public get projectId(): string {
        if (this.map[ENV_NYMOS_K8S_PROJECT])
            return this.map[ENV_NYMOS_K8S_PROJECT];

        return 'default';
    }

    public get serviceId(): string {
        if (this.map[ENV_NYMOS_K8S_NAME])
            return this.map[ENV_NYMOS_K8S_NAME];

        throw new Error(`${ENV_NYMOS_K8S_NAME} hasn't been set`);
    }

    public get serviceEnv(): Env {
        const env = this.map[ENV_NYMOS_K8S_ENV];

        if (env === 'dev')
            return Env.Dev;

        if (env === 'stag')
            return Env.Stag;

        if (env === 'prod')
            return Env.Prod;

        return Env.Dev;
    }

    public getDomain(serviceId: string): string {
        return super.applyTemplates(serviceId, this.map[ENV_NYMOS_K8S_DOMAIN] || DEFAULT_DOMAIN);
    }

    public getUrl(serviceId?: string): string {
        return super.applyTemplates(serviceId || this.serviceId, this.map[ENV_NYMOS_K8S_MICSRVC_CONN] || DEFAULT_CONN);
    }

}
