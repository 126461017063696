import { MenuGroup as Model } from './menu-group.model';
import { MockMenuItem } from './menu-item.model.mock';

export class MockMenuGroup {

    public static create(): Model {
        return new Model({
            title: 'group-title',
            items: [
                MockMenuItem.create(),
                MockMenuItem.create(),
                MockMenuItem.create(),
            ],
        });
    }
}
