import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';

import {
    MobileVerification,
} from '@michel.freiha/ng-sdk';


@Component({
    selector: 'nym-otp-listing',
    templateUrl: './otp-listing.component.html',
    styleUrls: ['./otp-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpListingComponent implements OnInit {

    private _form: FormGroup;

    @HostBinding('class.nym-otp-listing')
    protected get classes(): boolean { return true; }

    @Output()
    protected fetchOtp: EventEmitter<any> = new EventEmitter();

    protected get form(): FormGroup { return this._form; }
    public get valid(): boolean { return this._form.valid; }

    public get phoneNumber(): AbstractControl { return this.form.get('phoneNumber'); }

    @Input()
    public otps: MobileVerification[];

    @Input()
    public loading: boolean;

    constructor(private _fb: FormBuilder) {
        this._form = this._fb.group({
            phoneNumber: ['', Validators.required],
        });
    }

    public ngOnInit(): void { }

    public fetchOtp$$(): void {
        if (this.form.valid)
            this.fetchOtp.emit(this.phoneNumber.value);
    }

    public fetchAllOtp$$(): void {
        this.fetchOtp.emit();
    }
}
