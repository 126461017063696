import * as faker from 'faker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  CurrencyPipe,
} from '@angular/common';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import {
  MatHorizontalStepper,
} from '@angular/material/stepper';

import {
  Observable,
} from 'rxjs';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  NymcardCmsWalletTransactionDetails as TransactionDetails,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  Account,
  Texts,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  CreditWalletFromUserDetailsPage,
  DebitWalletFromUserDetailsPage,
  TransferBetweenWalletAgentFromUserDetailsPage,
  DryRunTransferBetweenWalletAgentFromUserDetailsPage,
  DryRunWalletFromUserDetailsPage,
  DryRunDebitWalletFromUserDetailsPage
} from '../../../core/store/wallet-activities/wallet-activities.actions';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  UserLimitInfoComponent,
} from '../../components/user-limit-info/user-limit-info.component';

import {
  RefreshWalletsFromUserDetailsPage,
} from '../../../core/store/wallets/wallets.actions';

import {
  InitKycFromResultsPage,
  LoadAccountFromMobile,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';


type State = 'limits' | 'fees';

const IRAQ_NUMBER_PATTERN = /^\+?964|\D+$/g;

@Component({
  selector: 'nym-wallet-topup-refund',
  templateUrl: './wallet-topup-refund.component.html',
  styleUrls: ['./wallet-topup-refund.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ProblemHandler],
})
export class WalletTopupRefundComponent extends ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  private _id: string;
  private _state: State = 'limits';
  private _valid: boolean = false;

  protected get form(): FormGroup { return this._form; }
  public get valid(): boolean { return this._form.valid; }

  private _form: FormGroup;

  @HostBinding('class.nym-wallet-topup-refund')
  protected get classes(): boolean { return true; }

  @Select(WalletActivitiesState.loading)
  public acting$: Observable<boolean>;

  @Select(WalletActivitiesState.problem)
  public problem$: Observable<Problem>;

  protected topupActivity$: Observable<WalletActivity>;

  @ViewChild('limit', { static: true })
  protected limit: UserLimitInfoComponent;

  @ViewChild('stepper', { static: true })
  protected stepper: MatHorizontalStepper;

  protected get account(): Account { return this.data && this.data.account; }
  protected get currency(): string { return this.account.limits.yearly.currency; }
  protected get amount(): number { return this.topuprefund.valid ? +this.topuprefund.value : 0; }
   
  protected get topuprefund(): AbstractControl { return this.form.get('topuprefund'); }
  
  protected get mobile(): AbstractControl { return this.form.get('mobile'); }
  protected get walletDestination(): AbstractControl { return                    this.form.get('walletDestination'); } 
  
  public errorMessage:boolean;
  public walletStatus:string;

  constructor(
    protected _fb: FormBuilder,
    protected _ref: DialogRef<any, any>,
    protected _cd: ChangeDetectorRef,
    protected _ph: ProblemHandler,
    protected _store: Store,
    protected _actions$: Actions,
    @Inject(LOCALE_ID) protected locale: string,
    @Inject(DIALOG_DATA) protected data: any,
  ) {
    super(_fb, _ref, _cd, locale, data);
    this._id = faker.random.uuid();
    this.topupActivity$ = this._store.select(WalletActivitiesState.topupActivity(this._id));
     
    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(DebitWalletFromUserDetailsPage)).subscribe(() => this._ref.close());
    this._actions$.pipe(ofActionSuccessful(DryRunDebitWalletFromUserDetailsPage)).subscribe(() => this._valid = true);
    this.subscription.add(this.problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form);
    }));
 }

  public ngAfterViewInit(): void {
   super.ngAfterViewInit();
    this.topuprefund.setValidators([Validators.required,Validators.max(1)]);
    this.mobile.setValidators([Validators.required,Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
    this.walletDestination.setValidators([Validators.required]);
     this.topuprefund.updateValueAndValidity();
     this.mobile.updateValueAndValidity();
     this.walletDestination.updateValueAndValidity();
     this._store.dispatch(new RefreshWalletsFromUserDetailsPage(this.account.id)).subscribe((res) => {
       let wallet_info = res.wallets.items[this.account.id].balances[0].amount;
       this.topuprefund.setValidators([
       Validators.required,
       Validators.max(wallet_info),
       Validators.pattern('^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$'),
     ]);
     this.topuprefund.updateValueAndValidity();
    })    
  }

  // @Override
  protected initForm(data: any): void {
    this._form = this._fb.group({
      topuprefund: ['', [Validators.required]],
       mobile: ['', [Validators.required,
                     Validators.pattern(/^(7[1-9])[0-9]{8}$/)
         ]],
       walletDestination:['', [Validators.required]],
    });
  }


  changeDropdown(a:string):void{
    if(this.walletStatus==='agent_wallet'){
       this.mobile.setValidators([Validators.required,Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
           this.mobile.updateValueAndValidity();
    }else{
     this.mobile.setValidators([Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
     this._form.patchValue({mobile:''})
     this.walletDestination.setValidators(null);
     this.walletDestination.updateValueAndValidity();
     this.mobile.updateValueAndValidity();
    }
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    
    if (!this.form.valid)
      return;

    if(this.walletDestination.value ==='INC_wallet' || this.account.type=='agent')  
     this._dryRunWallet()
     else
     this.checkAgentByMobile();
  }

  protected topupTouched(): void {
        this.topuprefund.markAsTouched();
        if(this.walletDestination.value ==='INC_wallet' || this.account.type=='agent'){
           this.mobile.setValidators([Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
           this.mobile.updateValueAndValidity();
           this.walletDestination.setValidators(null);
           this.walletDestination.updateValueAndValidity();
        }else if(this.walletDestination.value ==='agent_wallet'){
          this.mobile.setValidators([Validators.required,Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
           this.mobile.updateValueAndValidity();
        }
  }

protected mobileTouched():void {
  let index : number;
  if(this.mobile.status ==='VALID'){
     const mobile = `+964${this.mobile.value.replace(IRAQ_NUMBER_PATTERN, '')}`;
      this._store.dispatch(new LoadAccountFromMobile(mobile)).subscribe((res)=>{
         if(!res.accounts.agent.problem){
            index = res.accounts.agent.agents.findIndex(
                  agent=> agent.accountId ===res.accounts.agent.agent.id);
           }
         if(res.accounts.agent.problem ||
           res.accounts.agent.agent.approvalStatus=='pending' ||
           res.accounts.agent.agent.approvalStatus=='rejected' ||
           res.accounts.agent.agent.approvalStatus=='none' || index <= -1){
           this._form.patchValue({mobile:this.mobile.value+' '})
          }
      })
   }
}  

private checkAgentByMobile():any{
    const mobile = `+964${this.mobile.value.replace(IRAQ_NUMBER_PATTERN, '')}`;
    this._store.dispatch(new LoadAccountFromMobile(mobile)).subscribe((res)=>{
      if(!res.accounts.agent.problem){
         this._ref.close(true)
         this._dryRuntransferWallet(res.accounts.agent.agent.id);
      }
    })
}

  private _dryRunWallet():void{
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);
    const walletId = this.account.id;
    const transactionId = this._id;
    const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.DebitWallet(money),
    });
     this._ref.close();
     this._store.dispatch(new DryRunDebitWalletFromUserDetailsPage(walletId, transactionId,  transaction)).subscribe((res)=>{
       this._valid = true;
       this._debitWallet()
     })
  }





private _dryRuntransferWallet(agent_id:any):void {
   const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);
      const walletId = this.account.id;
      const transactionId = this._id;
      const agentId = agent_id;
      const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.DebitTransferWallet(money),
    });

   this._store.dispatch(new DryRunTransferBetweenWalletAgentFromUserDetailsPage(walletId,walletId, transactionId,agentId,transaction, money)).subscribe((res)=>{
    if(res){
       this._transferWallet(agentId)
      }
   });
  
}

   private _debitWallet(): void {
      const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);
      const walletId = this.account.id;
      const transactionId = this._id;
      const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.DebitWallet(money),
    });

     this._store.dispatch(new DebitWalletFromUserDetailsPage(walletId, transactionId, transaction, money));
  }


  private _transferWallet(agentDetails:any):void {
   const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);
      const walletId = this.account.id;
      const transactionId = this._id;
      const agentId = agentDetails;
      const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.DebitTransferWallet(money),
    });

   this._store.dispatch(new TransferBetweenWalletAgentFromUserDetailsPage(walletId,walletId, transactionId,agentId,transaction, money));
  
}

}
