import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  AdminRoleCategory,
} from '@michel.freiha/ng-sdk';

import {
  AdminRoleColor,
} from '../../../sdk/models/admin-role-color.model';


@Component({
  selector: 'nym-role-edit-menu',
  templateUrl: './role-edit-menu.component.html',
  styleUrls: ['./role-edit-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleEditMenuComponent implements OnInit {

  @HostBinding('class.nym-role-edit-menu')
  protected get classes(): boolean { return true; }

  private _colors: string[] = Object.values(AdminRoleColor);
  private _categories: string[] = Object.values(AdminRoleCategory);

  @Input()
  public col: AdminRoleColor;

  @Input()
  public cat: AdminRoleCategory;

  @Output()
  public colChange: EventEmitter<AdminRoleColor> = new EventEmitter();

  @Output()
  public catChange: EventEmitter<AdminRoleCategory> = new EventEmitter();

  public get colors(): string[] {
    return this._colors;
  }

  public get categories(): string[] {
    return this._categories;
  }

  constructor() { }

  public ngOnInit(): void { }

  public chooseColor(color: AdminRoleColor): void {
    this.colChange.emit(color);
  }

  public chooseCategory(category: AdminRoleCategory): void {
    this.catChange.emit(category);
  }

}
