// tslint:disable:variable-name

export type StringFn = (str: string) => string;

export namespace Texts {

    export class Action {
        public static readonly Failure: string = `Something went wrong`;
    }

    export class Throttling {
        public static readonly ClearThrottling: string = `Clear throttling`;
    }

    export class Otp {
        public static readonly FetchOtp: string = `Fetch OTPs`;
    }
}


