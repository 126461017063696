/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    pipe,
    throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Card,
    Problem,
    Token,
    TokenStatus,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    EncryptionService,
} from '@nymos/encryption';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    ActivateToken,
    DeleteToken,
    Enquire,
    Fail,
    ResumeToken,
    SuspendToken,
} from './cards.actions';

import {
    IssuerService,
} from '@nymos/mocks/tokens';


export interface CardsStateModel {
    items: { [id: string]: Card };
    loading: boolean;
    saving: boolean;
    problem: Problem;
}

const stateDefaults: CardsStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
};

@State<CardsStateModel>({
    name: 'issuer',
    defaults: stateDefaults,
})
export class CardsState {

    @Selector()
    public static cards(state: CardsStateModel): Card[] {
        return Object.keys(state.items).map(((id) => state.items[id]));
    }

    @Selector()
    public static problem(state: CardsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: CardsStateModel): boolean {
        return state.loading;
    }

    public static card(id: string): any {
        return createSelector([CardsState], (state: CardsStateModel) => {
            return state.items[id];
        });
    }

    constructor(
        private _nc: NotificationCenter,
        private _issuerService: IssuerService,
        private _encryptionService: EncryptionService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<CardsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(Enquire)
    public enquire(ctx: StateContext<CardsStateModel>, { payload: { pan } }: Enquire): any {

        ctx.patchState({ loading: true });

        // pan = this._encryptionService.encrypt(pan);
        return this._issuerService.searchCardsByPan(pan).pipe(

            tap((cards: Card[]) => {
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    cards.forEach((index) => draft.items[index.id] = index);
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ loading: false });
            }),
        );
    }

    @Action(ActivateToken)
    public activate(ctx: StateContext<CardsStateModel>, { payload: { card, token, reason } }: ActivateToken): any {

        const update = this._update(ctx, card.id, token, TokenStatus.Active);
        return this._issuerService.activateToken(card.network, token.id, reason).pipe(update);
    }

    @Action(ResumeToken)
    public resume(ctx: StateContext<CardsStateModel>, { payload: { card, token, reason } }: ResumeToken): any {
        const update = this._update(ctx, card.id, token, TokenStatus.Active);
        return this._issuerService.resumeToken(card.network, token.id, reason).pipe(update);
    }

    @Action(SuspendToken)
    public suspend(ctx: StateContext<CardsStateModel>, { payload: { card, token, reason } }: SuspendToken): any {
        const update = this._update(ctx, card.id, token, TokenStatus.Suspended);
        return this._issuerService.suspendToken(card.network, token.id, reason).pipe(update);
    }

    @Action(DeleteToken)
    public delete(ctx: StateContext<CardsStateModel>, { payload: { card, token, reason } }: DeleteToken): any {
        const update = this._update(ctx, card.id, token, TokenStatus.Deleted);
        return this._issuerService.deleteToken(card.network, token.id, reason).pipe(update);
    }

    @Action(Fail)
    public fail(ctx: StateContext<CardsStateModel>, { payload: { problem } }: Fail): void {
        ctx.patchState({ problem: problem });
    }

    private _update(ctx: StateContext<CardsStateModel>, id: string, token: Token, status: TokenStatus): any {

        ctx.patchState({ saving: true });

        return (source) =>
            source.pipe(
                tap(() => {
                    ctx.setState(produce((draft) => {
                        const card = draft.items[id];
                        const update = card.tokens.find((t) => t.id === token.id);
                        update.status = status;
                    }));
                }),

                catchError((problem) => {
                    return ctx.dispatch(new Fail({ problem: problem }));
                }),

                finalize(() => {
                    ctx.patchState({ saving: false });
                }),
            );
    }
}
