import {
    Injectable,
} from '@angular/core';

import {
    StatusMenu,
} from '../../lib/core/models/status.model';

import {
    NymcardStatusMenuDatastore,
} from '../datastore/nymcard-status.datastore';


@Injectable({
    providedIn: 'root',
})
export class CardStatusMenuService {

    constructor(
        private _statusDatastore: NymcardStatusMenuDatastore,
    ) { }

    public get(id: string): StatusMenu {
        return this._statusDatastore.get(id);
    }

    public all(): StatusMenu[] {
        return this._statusDatastore.all();
    }
}
