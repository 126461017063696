import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
} from '@angular/core';

import {
  AbstractControl,
  Validators,
} from '@angular/forms';

import {
  ConfirmComponent,
} from '@nymos/theme';

import {
  Result,
} from '../../../models/result.model';

@Component({
  selector: 'nym-token-confirm',
  templateUrl: './token-confirm.component.html',
  styleUrls: ['./token-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenConfirmComponent extends ConfirmComponent<Result> {

  @HostBinding('class.thm-token-confirm')
  protected get classes(): boolean { return true; }

  protected get options(): string[] { return this.data && this.data.options || []; }

  protected get selected(): AbstractControl { return this.form.get('selected'); }
  protected get comment(): AbstractControl { return this.form.get('comment'); }

  // @Override
  protected initForm(data: any): void {
    this.form = this._fb.group({ selected: '', comment: '' });

    this.selected.setValidators([Validators.required]);

    this.subscription.add(this.selected.valueChanges.subscribe((selected) => {

      if (selected === 'other')
        this.comment.setValidators([Validators.required]);
      else
        this.comment.clearValidators();

      this.comment.updateValueAndValidity();
    }));
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
      return;

    const result = new Result({ option: this.selected.value, comment: this.comment.value });
    this._ref.close(result);
  }

}
