/* tslint:disable: component-class-suffix */
/* tslint:disable: member-ordering */

import {
    ChangeDetectorRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Optional,
    ViewChild,
} from '@angular/core';

import {
    DOCUMENT,
} from '@angular/common';

import {
    AnimationEvent,
} from '@angular/animations';

import {
    CdkPortalOutlet,
} from '@angular/cdk/portal';

import {
    FocusTrapFactory,
} from '@angular/cdk/a11y';

import {
    DialogRole,
    MatDialogConfig as DialogConfig,
    MatDialogContainer,
} from '@angular/material/dialog';


export class DialogContainer extends MatDialogContainer {

    private _loading: boolean = false;
    private _formValid: boolean = true;

    private _closeButtonClicked: EventEmitter<Event> = new EventEmitter();
    private _submitButtonClicked: EventEmitter<Event> = new EventEmitter();

    @HostBinding('class.thm-dialog')
    protected get classes(): boolean { return true; }

    public get loading(): boolean { return this._loading; }
    public set loading(value: boolean) { this._setLoading(value); }

    public get formValid(): boolean { return this._formValid; }
    public set formValid(value: boolean) { this._setFormValid(value); }

    @HostBinding('attr.id')
    public id: string;

    @HostBinding('attr.tabindex')
    public get tabindex(): number { return -1; }

    @HostBinding('attr.role')
    public get ariaRole(): DialogRole { return this._config.role; }

    @HostBinding('attr.aria-modal')
    public get ariaModal(): boolean { return true; }

    @HostBinding('attr.aria-label')
    public get ariaLabel(): string { return this._config.ariaLabel; }

    // @HostBinding('attr.aria-labelledby')
    // public get ariaLabelledby(): string { return this._config.ariaLabel ? null : this._config.ariaLabelledBy || null; }

    @HostBinding('attr.aria-describedby')
    public get ariaDescribedby(): string { return this._config.ariaDescribedBy || null; }

    @HostBinding('@dialogContainer')
    public get state(): 'void' | 'enter' | 'exit' { return this._state; }

    @HostListener('@dialogContainer.start', ['$event'])
    public onAnimationStart(event: AnimationEvent): void { this._onAnimationStart(event); }

    @HostListener('@dialogContainer.done', ['$event'])
    public onAnimationDone(event: AnimationEvent): void { this._onAnimationDone(event); }

    @ViewChild(CdkPortalOutlet, { static: true })
    public _portalOutlet: CdkPortalOutlet;

    public get closeButtonClicked(): EventEmitter<Event> { return this._closeButtonClicked; }
    public get submitButtonClicked(): EventEmitter<Event> { return this._submitButtonClicked; }
    public get animationStateChanged(): EventEmitter<AnimationEvent> { return this._animationStateChanged; }

    public get config(): DialogConfig { return this._config; }
    public get data(): any { return this._config.data; }

    constructor(
        _er: ElementRef,
        _ft: FocusTrapFactory,
        _conf: DialogConfig,
        private _cd: ChangeDetectorRef,
        @Optional() @Inject(DOCUMENT) _doc: any,
    ) {
        super(_er, _ft, _cd, _doc, _conf);
    }

    public close(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.closeButtonClicked.emit(event);
    }

    public submit(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        this.submitButtonClicked.emit(event);
    }

    private _setLoading(value: boolean): void {
        this._loading = value;
        this._cd.markForCheck();
    }

    private _setFormValid(value: boolean): void {
        this._formValid = value;
        this._cd.markForCheck();
    }
}
