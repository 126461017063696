/* tslint:disable:no-bitwise */

import {
    asn1,
    md,
    pki,
    random,
} from 'node-forge';

import {
    Base64,
    NO_PADDING,
    NO_WRAP,
    URL_SAFE,
} from './base64.util';

export type PrivateKey = pki.rsa.PrivateKey;
export type PublicKey = pki.rsa.PublicKey;

export class RSA {

    public static iv(): string {
        return random.getBytesSync(16);
    }

    public static secretKey(): string {
        return random.getBytesSync(16);
    }

    public static pair(): pki.rsa.KeyPair {
        // TODO(@leandro): ADD SHA1PRNG
        return pki.rsa.generateKeyPair({ bits: 2048 });
    }

    public static publicKeyToPem(key: pki.rsa.PublicKey): string {
        return pki.publicKeyToPem(key);
    }

    public static publicKeyFromPem(pem: string): PublicKey {
        return pki.publicKeyFromPem(pem) as PublicKey;
    }

    public static privateKeyToPem(key:  pki.rsa.PrivateKey): string {
        return pki.privateKeyToPem(key);
    }

    public static privateKeyFromPem(pem: string): PrivateKey {
        return pki.privateKeyFromPem(pem) as PrivateKey;
    }

    public static fingerprint(key: PublicKey): string {
        const options = { md: md.sha256.create() };
        const fingerprint = RSA.getPublicKeyFingerprint(key, options).getBytes();
        return Base64.encode(fingerprint, NO_WRAP | NO_PADDING | URL_SAFE);
    }

    public static getPublicKeyFingerprint(key: PublicKey, options?: { md?: md.MessageDigest, type?: string, encoding?: string, delimiter?: string }): any {
        options = options || {};
        const m = options.md || md.sha1.create();

        let bytes = asn1.toDer(pki.publicKeyToRSAPublicKey(key as PublicKey)).getBytes();

        (m as any).start();
        m.update(bytes, 'utf8');
        return m.digest();
    }
}
