// tslint:disable: member-ordering

import {
  Injectable,
} from '@angular/core';

import {
  emptyOptions,
  MemoryBackend,
  ModelFactory,
} from '@nymos/backend';

import {
  AccountType,
} from '../../lib/core/models/account.model';

class Factory extends ModelFactory<AccountType> {
  constructor() {
      super(null, (m) => m);
  }
}


@Injectable({
  providedIn: 'root',
})
export class AccountTypeDatastore extends MemoryBackend<AccountType> {

  constructor(
  ) {
      super(emptyOptions, new Factory());

      this.afterInit();
  }

  public all(): AccountType[] {
      return this.values();
  }

  protected afterInit(): any {
      this.doCreate('user');
      this.doCreate('agent');
  }
}
