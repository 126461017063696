import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  filter,
  map,
} from 'rxjs/operators';

import {
  Store,
} from '@ngxs/store';

import {
  StatusMenu,
} from '@nymos/accounts/core';

import {
  AccountsChannelsState,
} from '../../../core/store/accounts-channels/accounts-channels.state';


@Component({
  selector: 'nym-channel-layout',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelComponent implements OnInit {

  @HostBinding('class.nym-channel-layout')
  protected get classes(): boolean { return true; }

  protected channels$: Observable<string[]>;

  constructor(
    private _store: Store,
  ) {
    this.channels$ = this._store.select(AccountsChannelsState.channels).pipe(
      filter((menus) => menus !== undefined),
      map((menus: StatusMenu[]) => menus.map((m) => m.name)),
    );
  }

  public ngOnInit(): void { }
}
