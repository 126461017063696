import * as faker from 'faker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  CurrencyPipe,
} from '@angular/common';

import {
  AbstractControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

import {
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import {
  MatHorizontalStepper,
} from '@angular/material/stepper';

import {
  Observable,
} from 'rxjs';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  NymcardCmsWalletTransactionDetails as TransactionDetails,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  Account,
  Texts,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  CreditWalletFromUserDetailsPage,
  DryRunWalletFromUserDetailsPage,
} from '../../../core/store/wallet-activities/wallet-activities.actions';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  UserLimitInfoComponent,
} from '../../components/user-limit-info/user-limit-info.component';


type State = 'limits' | 'fees';

@Component({
  selector: 'nym-wallet-topup-confirm',
  templateUrl: './wallet-topup-confirm.component.html',
  styleUrls: ['./wallet-topup-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ProblemHandler],
})
export class WalletTopupConfirmComponent extends ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  private _id: string;
  private _state: State = 'limits';
  private _valid: boolean = false;

  @HostBinding('class.nym-wallet-topup-confirm')
  protected get classes(): boolean { return true; }

  @Select(WalletActivitiesState.loading)
  public acting$: Observable<boolean>;

  @Select(WalletActivitiesState.problem)
  public problem$: Observable<Problem>;

  protected topupActivity$: Observable<WalletActivity>;

  @ViewChild('limit', { static: true })
  protected limit: UserLimitInfoComponent;

  @ViewChild('stepper', { static: true })
  protected stepper: MatHorizontalStepper;

  protected get account(): Account { return this.data && this.data.account; }
  protected get currency(): string { return this.account.limits.yearly.currency; }
  protected get amount(): number { return this.topup.valid ? +this.topup.value : 0; }

  protected get topup(): AbstractControl { return this.form.get('topup'); }

  constructor(
    protected _fb: FormBuilder,
    protected _ref: DialogRef<any, any>,
    protected _cd: ChangeDetectorRef,
    protected _ph: ProblemHandler,
    protected _store: Store,
    protected _actions$: Actions,
    @Inject(LOCALE_ID) protected locale: string,
    @Inject(DIALOG_DATA) protected data: any,
  ) {
    super(_fb, _ref, _cd, locale, data);

    this._id = faker.random.uuid();
    this.topupActivity$ = this._store.select(WalletActivitiesState.topupActivity(this._id));

    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this._actions$.pipe(ofActionSuccessful(CreditWalletFromUserDetailsPage)).subscribe(() => this._ref.close());
    this._actions$.pipe(ofActionSuccessful(DryRunWalletFromUserDetailsPage)).subscribe(() => this._valid = true);

    this.subscription.add(this.problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form);
    }));
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.topup.setValidators([
      Validators.required,
      Validators.max(this.limit.remaining),
      Validators.min(0),
      Validators.pattern('^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$'),
    ]);
    this.topup.updateValueAndValidity();
  }

  // @Override
  protected initForm(data: any): void {
    this.form = this._fb.group({
      topup: ['', [Validators.required]],
    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
      return;

    if (this._state === 'limits')
      this.stepper.next();
    else if (this._valid)
      this._creditWallet();
  }

  protected topupTouched(): void {
    this.topup.markAsTouched();
  }

  protected stateChange(event: StepperSelectionEvent): void {
    this._state = event.selectedStep.state as State;
    this._valid = this._valid && this._state === 'fees';

    if (this._state === 'fees')
      this._dryRunWallet();
  }

  private _dryRunWallet(): void {
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);
    const walletId = this.account.id;
    const transactionId = this._id;
    const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.CreditWallet(money),
    });

    this._store.dispatch(new DryRunWalletFromUserDetailsPage(walletId, transactionId, transaction));
  }

  private _creditWallet(): void {
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.currency);

    const walletId = this.account.id;
    const transactionId = this._id;
    const transaction = new TransactionDetails({
      amount: this.amount,
      currency: this.account.limits.yearly.currency.toLowerCase(),
      description: Texts.TopupDialog.CreditWallet(money),
    });

    this._store.dispatch(new CreditWalletFromUserDetailsPage(walletId, transactionId, transaction, money));
  }

}
