// tslint:disable: directive-selector

import {
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: 'input[numbers-only], input[digits]',
})
export class NumberOnlyDirective {

  constructor(
    private _el: ElementRef,
  ) { }

  @HostListener('input', ['$event'])
  public onInputChange(event: Event): void {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }

  }

}
