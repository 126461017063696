import {
  Injectable,
} from '@angular/core';

import {
  Observable,
  of,
} from 'rxjs';

import {
  AccountType,
} from '../../lib/core/models/account.model';

import {
  Province,
} from '../../lib/core/models/province.model';

import {
  NewProvince,
} from '../../lib/core/models/new-province.model';

import {
  ProvinceNaming,
} from '../../lib/core/models/province-naming.model';

import {
  CityNaming,
} from '../../lib/core/models/city-naming.model';

import {
  Country,
} from '../../lib/core/models/country.model';

import {
  StatusMenu,
} from '../../lib/core/models/status.model';

import {
  AccountTypeDatastore,
} from '../datastore/account-type.datastore';

import {
  ChannelStatusMenuDatastore,
} from '../datastore/channel-status.datastore';

import {
  AccountProvinceDatastore,
} from '../datastore/account-province.datastore';

import {
  AccountProvinceNewDatastore,
} from '../datastore/account-province-new.datastore';

import {
  AccountCountryDatastore,
} from '../datastore/account-country.datastore';

import {
  ProvinceMapDatastore,
} from '../datastore/province-map.datastore';

import {
  CityMapDatastore,
} from '../datastore/city-map.datastore';


@Injectable({
  providedIn: 'root',
})
export class AccountsInternalInMemory {

  constructor(
    private _typeDatastore: AccountTypeDatastore,
    private _channelsDatastore: ChannelStatusMenuDatastore,
    private _accountProvinceDatastore: AccountProvinceDatastore,
    private _accountProvinceNewDatastore: AccountProvinceNewDatastore,
    private _accountCountryDatastore: AccountCountryDatastore,
    private _provinceMapDatastoreDatastore: ProvinceMapDatastore,
    private _cityMapDatastoreDatastore: CityMapDatastore,
  ) { }

  public getAccountTypes(): Observable<AccountType[]> {
    return of(this._typeDatastore.all());
  }

  public getAccountChannels(): Observable<StatusMenu[]> {
    return of(this._channelsDatastore.all());
  }

  public getAccountPronvinces(): Observable<Province[]> {
    return of(this._accountProvinceDatastore.all());
  }

  public getAccountPronvincesNew(): Observable<NewProvince[]> {
    return of(this._accountProvinceNewDatastore.all());
  }

  public getAccountCountries(): Observable<Country[]> {
    return of(this._accountCountryDatastore.all());
  }

  public getAccountsProvincesNamings(): Observable<ProvinceNaming[]> {
    return of(this._provinceMapDatastoreDatastore.all());
  }

  public getAccountsCitiesNamings(): Observable<CityNaming[]> {
    return of(this._cityMapDatastoreDatastore.all());
  }
}
