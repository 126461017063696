import {
  NgModule,
} from '@angular/core';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatSelectModule,
} from '@angular/material/select';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  MatElevationModule,
} from '@michel.freiha/material';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  TokensRoutingModule,
} from './tokens.routing';

import {
  TokenPipe,
} from './pipes/token-icon.pipe';

import {
  CardsState,
} from './store/cards/cards.state';

import {
  HomepageComponent,
} from './ui/containers/homepage/homepage.component';

import {
  ListingComponent,
} from './ui/containers/listing/listing.component';

import {
  PanFilterComponent,
} from './ui/components/pan-filter/pan-filter.component';

import {
  TokenCardComponent,
} from './ui/components/token-card/token-card.component';

import {
  TokenConfirmComponent,
} from './ui/components/token-confirm/token-confirm.component';

import {
  CreditCardComponent,
} from './ui/components/credit-card/credit-card.component';

import {
  CardResultItemComponent,
} from './ui/components/card-result-item/card-result-item.component';



export const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatElevationModule,
];

export const PIPES = [
  TokenPipe,
];


@NgModule({
  declarations: [
    ...PIPES,
    HomepageComponent,
    ListingComponent,
    PanFilterComponent,
    TokenCardComponent,
    TokenConfirmComponent,
    CreditCardComponent,
    CardResultItemComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    SharedModule,
    ReactiveFormsModule,

    TokensRoutingModule,
    NgxsModule.forFeature([CardsState]),
  ],

  entryComponents: [
    TokenConfirmComponent,
  ],

})
export class TokensModule { }
