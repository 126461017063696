import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'title, thm-title, .thm-title, [thm-title], [thmTitle]',
})
export class TitleDirective {

  @HostBinding('class.thm-title')
  protected get classes(): boolean { return true; }

  constructor() { }

}
