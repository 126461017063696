import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  ShimmerHandler,
} from '@nymos/theme';

import {
  Account,
  Card,
  CardAction,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-card-listing',
  templateUrl: './card-listing.component.html',
  styleUrls: ['./card-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListingComponent implements OnInit, OnChanges {

  private _shimmerHandler: ShimmerHandler<Card> = new ShimmerHandler();

  @HostBinding('class.nym-card-listing')
  protected get classes(): boolean { return true; }

  @Output()
  protected manage: EventEmitter<any> = new EventEmitter();

  @Output()
  protected select: EventEmitter<Card> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public cards: Card[];

  @Input()
  public loading: boolean;

  protected get data(): Card[] { return this._shimmerHandler.data; }
  protected get channel(): string { return this.account && this.account.channel.channel; }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const loading = changes['loading'];
    const cards = changes['cards'];

    if (cards) {
      this._shimmerHandler.data = cards.currentValue;
    }

    if (loading) {
      this._shimmerHandler.loading = loading.currentValue;
    }
  }

  protected status(card: Card): string { return card && card.status; }

  protected select$$(card: Card): void {
    this.select.emit(card);
  }

  protected manage$$(card: Card, action: CardAction): void {
    this.manage.emit({ card, action });
  }

  protected checkBalance(balance:number): boolean {
   if(balance>0)
   return false;
   else
   return true;
  }

}
