/* tslint:disable:member-ordering */

import * as faker from 'faker';

import {
    createSelector,
    Selector,
    State,
} from '@ngxs/store';

import {
    Device,
    DeviceOS,
    DevicePlatform,
} from '@michel.freiha/ng-sdk';

import {
    ENV_NYMOS_K8S_WEB_APP_ID,
    ENV_NYMOS_K8S_WEB_CONSUMER_KEYS,
} from '../services/discovery.service';


interface StringMap {
    [id: string]: string;
}

export interface EnvsStateModel {
    device: Device;
    vars: StringMap;
}

const stateDefaults: EnvsStateModel = {
    device: new Device({ id: faker.random.uuid(), name: 'dashbaord', platform: new DevicePlatform({ os: DeviceOS.Web }) }),
    vars: {
        //GAE_SERVICE: 'dashboard',
        NYMOS_K8S_MICSRVC_CONN: 'https://api.dev.platform.nm-1.nymcard.com/',
        NYMOS_K8S_DOMAIN: "dashboard", 
        NYMOS_K8S_NAME: 'dashboard' 
        //GOOGLE_CLOUD_PROJECT: 'nymos-dev',
        // NYMOS_K8S_WEB_APP_ID: 'generic-web',
        // NYMOS_K8S_WEB_CONSUMER_KEYS: 'eyJhbGciOiJSUzI1NiJ9.eyJ1aWQiOiJnZW5lcmljLXdlYiIsIm9zIjoid2ViIiwicHJvdmlkZXIiOiJhcHBzIiwicHJvamVjdF9pZCI6Im55bXBheSIsInNjb3BlcyI6InJvbGU6YXBwcyIsInR5cGUiOiJjb25zdW1lciIsImV4cCI6MTg3MTMyODAzNywiaWF0IjoxNTU1OTY4MDM3LCJhcHBfaWQiOiJnZW5lcmljLXdlYiJ9.tPnkiqtLaPJaFZ4wHoZPKftirUJKGE5hbj7CinQj-h6iEnH-7zGKQkPvi6k0Cu7LmC8BF_mqHxCEqWrOhsVmvcRSEi0bMwJLxEttHG4Nni8251wdWcq5mtr44TpZoNCIKYBc8ZbpZn0cKtTdwX8NlnHHUbV2ejOW-tuDkaQ1IDh4s5WQkH545MbiPvyHA-BrIp9G59ho7onml1NJjiD50g04KgMpBKujXuKDxTCVu78jQU3gIcGIr-gQPHDc1BOavf2zNvo7MTUlzjKT_ZW8ryznyMDPVH_gJNF_NOLmUn4vtdkoYjRXiRB8Ab7msjx3Xoc4nN-2TmNMT5kLtNoPNg',
    },
};

@State<EnvsStateModel>({
    name: 'envs',
    defaults: stateDefaults,
})
export class EnvsState {

    @Selector()
    public static vars(state: EnvsStateModel): StringMap {
        const result = state.vars;
        const set = result && Object.values(result).some((x) => !!x);

        return set ? result : stateDefaults.vars;
    }

    public static var(id: string): any {
        return createSelector([EnvsState], (state: EnvsStateModel) => {
            return state.vars[id];
        });
    }

    @Selector()
    public static appId(state: EnvsStateModel): string {
        return state.vars[ENV_NYMOS_K8S_WEB_APP_ID];
    }

    @Selector()
    public static consumerKey(state: EnvsStateModel): string {
        return state.vars[ENV_NYMOS_K8S_WEB_CONSUMER_KEYS];
    }

    @Selector()
    public static device(state: EnvsStateModel): Device {
        return state.device;
    }
}
