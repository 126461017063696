import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  WalletActivity,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-wallet-topup-fees-info',
  templateUrl: './wallet-topup-fees-info.component.html',
  styleUrls: ['./wallet-topup-fees-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletTopupFeesInfoComponent implements OnInit {

  @HostBinding('class.nym-wallet-topup-fees-info')
  protected get classes(): boolean { return true; }

  @Input()
  public activity: WalletActivity;

  @Input()
  public loading: boolean;

  constructor() { }

  public ngOnInit(): void { }

}
