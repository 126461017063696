import { Pipe, PipeTransform } from '@angular/core';
import { Texts } from '../texts/accounts.texts';

@Pipe({
  name: 'channelText',
})
export class ChannelTextPipe implements PipeTransform {

  public transform(channel: string): string {

    switch (channel) {
      case 'active':
        return Texts.ResultPage.ActiveTitle;
      case 'verified':
        return Texts.ResultPage.VerifiedTitle;
      case 'pending':
        return Texts.ResultPage.PendingTitle;
      case 'incomplete':
        return Texts.ResultPage.IncompleteTitle;
      case 'rejected':
        return Texts.ResultPage.RejectedTitle;
      case 'blocked':
        return Texts.ResultPage.BlockedTitle;
      default:
        return '';
    }
  }
}
