// tslint:disable: member-ordering

import '@nymos/extensions/number';

import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core';

import {
    Card,
    SearchCriteria,
    SearchFilter,
    SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
    BACKEND_FACTORY,
    BACKEND_OPTIONS,
    BackendOptions,
    MemoryBackend,
} from '@nymos/backend';

import {
    FakerCard,
} from '../fakers/card.faker';

const DEFAULT_FACTORY = new FakerCard();

@Injectable({
    providedIn: 'root',
})
export class MemoryCardDatastore extends MemoryBackend<Card> {

    constructor(
        @Optional() @Inject(BACKEND_OPTIONS) defaults: BackendOptions,
        @Optional() @Inject(BACKEND_FACTORY) factory: FakerCard,
    ) {
        factory = factory ? factory : DEFAULT_FACTORY;
        super(defaults, factory);

        if (!this.options.mock) {
            this.afterInit();
        }
    }

    public get(id: string): Card {
        return this.doLoad(id);
    }

    public create(card: Card): Card {
        return this.doCreate(card);
    }

    public update(card: Card): Card {
        return this.doUpdate(card);
    }

    // public delete(card: Card): Observable<void> {
    //     return throwError('Not Implemented');
    // }

    public fetchByPan(pan: string): Card[] {

        const last4 = pan.substr(pan.length - 4);
        const criteria = new SearchCriteria({
            filters: [
                new SearchFilter({ field: 'last4', operator: SearchOperator.AnyOf, values: [last4] }),
            ],
        });

        return this.doSearch(criteria).data;
    }

    protected afterInit(): any {
        const fake1 = this._factory.one('cardId-0001');
        fake1.last4 = '4321';
        this.doCreate(fake1);

        const fake2 = this._factory.one('cardId-0002');
        fake2.last4 = '1111';
        this.doCreate(fake2);
    }

}
