import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'hyphen' })
export class StringHyphenPipe implements PipeTransform {

    public transform(input: string, condition?: boolean): any {
        if (!input)
            return '--';

        if (condition)
            return '--';

        return input;
    }
}
