// tslint:disable: member-ordering

import {
  Injectable,
} from '@angular/core';

import {
  emptyOptions,
  MemoryBackend,
  ModelFactory,
} from '@nymos/backend';

import {
  Country,
} from '../../lib/core/models/country.model';

class Factory extends ModelFactory<Country> {
    constructor() {
        super(null, (m: Country) => m.name);
    }
}

@Injectable({
    providedIn: 'root',
})
export class AccountCountryDatastore extends MemoryBackend<Country> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): Country {
        return this.doLoad(id);
    }

    public all(): Country[] {
        return this.values();
    }

  protected afterInit(): any {

      this.doCreate(new Country({name: "Afghanistan"}));
      this.doCreate(new Country({name: "Albania"}));
      this.doCreate(new Country({name: "Algeria"}));
      this.doCreate(new Country({name: "American Samoa"}));
      this.doCreate(new Country({name: "Andorra"}));
      this.doCreate(new Country({name: "Angola"}));
      this.doCreate(new Country({name: "Anguilla"}));
      this.doCreate(new Country({name: "Antarctica"}));
      this.doCreate(new Country({name: "Antigua and Barbuda"}));
      this.doCreate(new Country({name: "Argentina"}));
      this.doCreate(new Country({name: "Armenia"}));
      this.doCreate(new Country({name: "Aruba"}));
      this.doCreate(new Country({name: "Australia"}));
      this.doCreate(new Country({name: "Austria"}));
      this.doCreate(new Country({name: "Azerbaijan"}));
      this.doCreate(new Country({name: "Bahamas"}));
      this.doCreate(new Country({name: "Bahrain"}));
      this.doCreate(new Country({name: "Bangladesh"}));
      this.doCreate(new Country({name: "Barbados"}));
      this.doCreate(new Country({name: "Belarus"}));
      this.doCreate(new Country({name: "Belgium"}));
      this.doCreate(new Country({name: "Belize"}));
      this.doCreate(new Country({name: "Benin"}));
      this.doCreate(new Country({name: "Bermuda"}));
      this.doCreate(new Country({name: "Bhutan"}));
      this.doCreate(new Country({name: "Bolivia"}));
      this.doCreate(new Country({name: "Bosnia and Herzegovina"}));
      this.doCreate(new Country({name: "Botswana"}));
      this.doCreate(new Country({name: "Bouvet Island"}));
      this.doCreate(new Country({name: "Brazil"}));
      this.doCreate(new Country({name: "British Indian Ocean Territory"}));
      this.doCreate(new Country({name: "Brunei Darussalam"}));
      this.doCreate(new Country({name: "Bulgaria"}));
      this.doCreate(new Country({name: "Burkina Faso"}));
      this.doCreate(new Country({name: "Burundi"}));
      this.doCreate(new Country({name: "Cambodia"}));
      this.doCreate(new Country({name: "Cameroon"}));
      this.doCreate(new Country({name: "Canada"}));
      this.doCreate(new Country({name: "Cape Verde"}));
      this.doCreate(new Country({name: "Cayman Islands"}));
      this.doCreate(new Country({name: "Central African Republic"}));
      this.doCreate(new Country({name: "Chad"}));
      this.doCreate(new Country({name: "Chile"}));
      this.doCreate(new Country({name: "China"}));
      this.doCreate(new Country({name: "Christmas Island"}));
      this.doCreate(new Country({name: "Cocos (Keeling) Islands"}));
      this.doCreate(new Country({name: "Colombia"}));
      this.doCreate(new Country({name: "Comoros"}));
      this.doCreate(new Country({name: "Congo"}));
      this.doCreate(new Country({name: "Congo, the Democratic Republic of the"}));
      this.doCreate(new Country({name: "Cook Islands"}));
      this.doCreate(new Country({name: "Costa Rica"}));
      this.doCreate(new Country({name: "Cote D'Ivoire"}));
      this.doCreate(new Country({name: "Croatia"}));
      this.doCreate(new Country({name: "Cuba"}));
      this.doCreate(new Country({name: "Cyprus"}));
      this.doCreate(new Country({name: "Czech Republic"}));
      this.doCreate(new Country({name: "Denmark"}));
      this.doCreate(new Country({name: "Djibouti"}));
      this.doCreate(new Country({name: "Dominica"}));
      this.doCreate(new Country({name: "Dominican Republic"}));
      this.doCreate(new Country({name: "Ecuador"}));
      this.doCreate(new Country({name: "Egypt"}));
      this.doCreate(new Country({name: "El Salvador"}));
      this.doCreate(new Country({name: "Equatorial Guinea"}));
      this.doCreate(new Country({name: "Eritrea"}));
      this.doCreate(new Country({name: "Estonia"}));
      this.doCreate(new Country({name: "Ethiopia"}));
      this.doCreate(new Country({name: "Falkland Islands (Malvinas)"}));
      this.doCreate(new Country({name: "Faroe Islands"}));
      this.doCreate(new Country({name: "Fiji"}));
      this.doCreate(new Country({name: "Finland"}));
      this.doCreate(new Country({name: "France"}));
      this.doCreate(new Country({name: "French Guiana"}));
      this.doCreate(new Country({name: "French Polynesia"}));
      this.doCreate(new Country({name: "French Southern Territories"}));
      this.doCreate(new Country({name: "Gabon"}));
      this.doCreate(new Country({name: "Gambia"}));
      this.doCreate(new Country({name: "Georgia"}));
      this.doCreate(new Country({name: "Germany"}));
      this.doCreate(new Country({name: "Ghana"}));
      this.doCreate(new Country({name: "Gibraltar"}));
      this.doCreate(new Country({name: "Greece"}));
      this.doCreate(new Country({name: "Greenland"}));
      this.doCreate(new Country({name: "Grenada"}));
      this.doCreate(new Country({name: "Guadeloupe"}));
      this.doCreate(new Country({name: "Guam"}));
      this.doCreate(new Country({name: "Guatemala"}));
      this.doCreate(new Country({name: "Guinea"}));
      this.doCreate(new Country({name: "Guinea-Bissau"}));
      this.doCreate(new Country({name: "Guyana"}));
      this.doCreate(new Country({name: "Haiti"}));
      this.doCreate(new Country({name: "Heard Island and Mcdonald Islands"}));
      this.doCreate(new Country({name: "Holy See (Vatican City State)"}));
      this.doCreate(new Country({name: "Honduras"}));
      this.doCreate(new Country({name: "Hong Kong"}));
      this.doCreate(new Country({name: "Hungary"}));
      this.doCreate(new Country({name: "Iceland"}));
      this.doCreate(new Country({name: "India"}));
      this.doCreate(new Country({name: "Indonesia"}));
      this.doCreate(new Country({name: "Iran, Islamic Republic of"}));
      this.doCreate(new Country({name: "Iraq"}));
      this.doCreate(new Country({name: "Ireland"}));
      this.doCreate(new Country({name: "Israel"}));
      this.doCreate(new Country({name: "Italy"}));
      this.doCreate(new Country({name: "Jamaica"}));
      this.doCreate(new Country({name: "Japan"}));
      this.doCreate(new Country({name: "Jordan"}));
      this.doCreate(new Country({name: "Kazakhstan"}));
      this.doCreate(new Country({name: "Kenya"}));
      this.doCreate(new Country({name: "Kiribati"}));
      this.doCreate(new Country({name: "North Korea"}));
      this.doCreate(new Country({name: "South Korea"}));
      this.doCreate(new Country({name: "Kuwait"}));
      this.doCreate(new Country({name: "Kyrgyzstan"}));
      this.doCreate(new Country({name: "Lao People's Democratic Republic"}));
      this.doCreate(new Country({name: "Latvia"}));
      this.doCreate(new Country({name: "Lebanon"}));
      this.doCreate(new Country({name: "Lesotho"}));
      this.doCreate(new Country({name: "Liberia"}));
      this.doCreate(new Country({name: "Libya"}));
      this.doCreate(new Country({name: "Liechtenstein"}));
      this.doCreate(new Country({name: "Lithuania"}));
      this.doCreate(new Country({name: "Luxembourg"}));
      this.doCreate(new Country({name: "Macao"}));
      this.doCreate(new Country({name: "Madagascar"}));
      this.doCreate(new Country({name: "Malawi"}));
      this.doCreate(new Country({name: "Malaysia"}));
      this.doCreate(new Country({name: "Maldives"}));
      this.doCreate(new Country({name: "Mali"}));
      this.doCreate(new Country({name: "Malta"}));
      this.doCreate(new Country({name: "Marshall Islands"}));
      this.doCreate(new Country({name: "Martinique"}));
      this.doCreate(new Country({name: "Mauritania"}));
      this.doCreate(new Country({name: "Mauritius"}));
      this.doCreate(new Country({name: "Mayotte"}));
      this.doCreate(new Country({name: "Mexico"}));
      this.doCreate(new Country({name: "Micronesia, Federated States of"}));
      this.doCreate(new Country({name: "Moldova, Republic of"}));
      this.doCreate(new Country({name: "Monaco"}));
      this.doCreate(new Country({name: "Mongolia"}));
      this.doCreate(new Country({name: "Montserrat"}));
      this.doCreate(new Country({name: "Morocco"}));
      this.doCreate(new Country({name: "Mozambique"}));
      this.doCreate(new Country({name: "Myanmar"}));
      this.doCreate(new Country({name: "Namibia"}));
      this.doCreate(new Country({name: "Nauru"}));
      this.doCreate(new Country({name: "Nepal"}));
      this.doCreate(new Country({name: "Netherlands"}));
      this.doCreate(new Country({name: "New Caledonia"}));
      this.doCreate(new Country({name: "New Zealand"}));
      this.doCreate(new Country({name: "Nicaragua"}));
      this.doCreate(new Country({name: "Niger"}));
      this.doCreate(new Country({name: "Nigeria"}));
      this.doCreate(new Country({name: "Niue"}));
      this.doCreate(new Country({name: "Norfolk Island"}));
      this.doCreate(new Country({name: "North Macedonia, Republic of"}));
      this.doCreate(new Country({name: "Northern Mariana Islands"}));
      this.doCreate(new Country({name: "Norway"}));
      this.doCreate(new Country({name: "Oman"}));
      this.doCreate(new Country({name: "Pakistan"}));
      this.doCreate(new Country({name: "Palau"}));
      this.doCreate(new Country({name: "Palestinian Territory, Occupied"}));
      this.doCreate(new Country({name: "Panama"}));
      this.doCreate(new Country({name: "Papua New Guinea"}));
      this.doCreate(new Country({name: "Paraguay"}));
      this.doCreate(new Country({name: "Peru"}));
      this.doCreate(new Country({name: "Philippines"}));
      this.doCreate(new Country({name: "Pitcairn"}));
      this.doCreate(new Country({name: "Poland"}));
      this.doCreate(new Country({name: "Portugal"}));
      this.doCreate(new Country({name: "Puerto Rico"}));
      this.doCreate(new Country({name: "Qatar"}));
      this.doCreate(new Country({name: "Reunion"}));
      this.doCreate(new Country({name: "Romania"}));
      this.doCreate(new Country({name: "Russian Federation"}));
      this.doCreate(new Country({name: "Rwanda"}));
      this.doCreate(new Country({name: "Saint Helena"}));
      this.doCreate(new Country({name: "Saint Kitts and Nevis"}));
      this.doCreate(new Country({name: "Saint Lucia"}));
      this.doCreate(new Country({name: "Saint Pierre and Miquelon"}));
      this.doCreate(new Country({name: "Saint Vincent and the Grenadines"}));
      this.doCreate(new Country({name: "Samoa"}));
      this.doCreate(new Country({name: "San Marino"}));
      this.doCreate(new Country({name: "Sao Tome and Principe"}));
      this.doCreate(new Country({name: "Saudi Arabia"}));
      this.doCreate(new Country({name: "Senegal"}));
      this.doCreate(new Country({name: "Seychelles"}));
      this.doCreate(new Country({name: "Sierra Leone"}));
      this.doCreate(new Country({name: "Singapore"}));
      this.doCreate(new Country({name: "Slovakia"}));
      this.doCreate(new Country({name: "Slovenia"}));
      this.doCreate(new Country({name: "Solomon Islands"}));
      this.doCreate(new Country({name: "Somalia"}));
      this.doCreate(new Country({name: "South Africa"}));
      this.doCreate(new Country({name: "South Georgia and the South Sandwich Islands"}));
      this.doCreate(new Country({name: "Spain"}));
      this.doCreate(new Country({name: "Sri Lanka"}));
      this.doCreate(new Country({name: "Sudan"}));
      this.doCreate(new Country({name: "Suriname"}));
      this.doCreate(new Country({name: "Svalbard and Jan Mayen"}));
      this.doCreate(new Country({name: "Eswatini"}));
      this.doCreate(new Country({name: "Sweden"}));
      this.doCreate(new Country({name: "Switzerland"}));
      this.doCreate(new Country({name: "Syrian Arab Republic"}));
      this.doCreate(new Country({name: "Taiwan"}));
      this.doCreate(new Country({name: "Tajikistan"}));
      this.doCreate(new Country({name: "Tanzania, United Republic of"}));
      this.doCreate(new Country({name: "Thailand"}));
      this.doCreate(new Country({name: "Timor-Leste"}));
      this.doCreate(new Country({name: "Togo"}));
      this.doCreate(new Country({name: "Tokelau"}));
      this.doCreate(new Country({name: "Tonga"}));
      this.doCreate(new Country({name: "Trinidad and Tobago"}));
      this.doCreate(new Country({name: "Tunisia"}));
      this.doCreate(new Country({name: "Turkey"}));
      this.doCreate(new Country({name: "Turkmenistan"}));
      this.doCreate(new Country({name: "Turks and Caicos Islands"}));
      this.doCreate(new Country({name: "Tuvalu"}));
      this.doCreate(new Country({name: "Uganda"}));
      this.doCreate(new Country({name: "Ukraine"}));
      this.doCreate(new Country({name: "United Arab Emirates"}));
      this.doCreate(new Country({name: "United Kingdom"}));
      this.doCreate(new Country({name: "United States of America"}));
      this.doCreate(new Country({name: "United States Minor Outlying Islands"}));
      this.doCreate(new Country({name: "Uruguay"}));
      this.doCreate(new Country({name: "Uzbekistan"}));
      this.doCreate(new Country({name: "Vanuatu"}));
      this.doCreate(new Country({name: "Venezuela"}));
      this.doCreate(new Country({name: "Vietnam"}));
      this.doCreate(new Country({name: "Virgin Islands, British"}));
      this.doCreate(new Country({name: "Virgin Islands, U.S."}));
      this.doCreate(new Country({name: "Wallis and Futuna"}));
      this.doCreate(new Country({name: "Western Sahara"}));
      this.doCreate(new Country({name: "Yemen"}));
      this.doCreate(new Country({name: "Zambia"}));
      this.doCreate(new Country({name: "Zimbabwe"}));
      this.doCreate(new Country({name: "Åland Islands"}));
      this.doCreate(new Country({name: "Bonaire, Sint Eustatius and Saba"}));
      this.doCreate(new Country({name: "Curaçao"}));
      this.doCreate(new Country({name: "Guernsey"}));
      this.doCreate(new Country({name: "Isle of Man"}));
      this.doCreate(new Country({name: "Jersey"}));
      this.doCreate(new Country({name: "Montenegro"}));
      this.doCreate(new Country({name: "Saint Barthélemy"}));
      this.doCreate(new Country({name: "Saint Martin (French part)"}));
      this.doCreate(new Country({name: "Serbia"}));
      this.doCreate(new Country({name: "Sint Maarten (Dutch part)"}));
      this.doCreate(new Country({name: "South Sudan"}));
      this.doCreate(new Country({name: "Kosovo"}));
      // this.doCreate(new Country({name:"أذربيجان"}));
      // this.doCreate(new Country({name:"أرمينيا"}));
      // this.doCreate(new Country({name:"أروبا"}));
      // this.doCreate(new Country({name:"أستراليا"}));
      // this.doCreate(new Country({name:"أفغانستان"}));
      // this.doCreate(new Country({name:"ألبانيا"}));
      // this.doCreate(new Country({name:"ألمانيا"}));
      // this.doCreate(new Country({name:"أنتاركتيكا"}));
      // this.doCreate(new Country({name:"أنتيغوا وبربودا"}));
      // this.doCreate(new Country({name:"أندورا"}));
      // this.doCreate(new Country({name:"أنغولا"}));
      // this.doCreate(new Country({name:"أنغويلا"}));
      // this.doCreate(new Country({name:"أورغواي"}));
      // this.doCreate(new Country({name:"أوزبكستان"}));
      // this.doCreate(new Country({name:"أوغندا"}));
      // this.doCreate(new Country({name:"أوكرانيا"}));
      // this.doCreate(new Country({name:"أيرلندا"}));
      // this.doCreate(new Country({name:"إثيوبيا"}));
      // this.doCreate(new Country({name:"إريتريا"}));
      // this.doCreate(new Country({name:"إسبانيا"}));
      // this.doCreate(new Country({name:"إستونيا"}));
      // this.doCreate(new Country({name:"إسرائيل"}));
      // this.doCreate(new Country({name:"إسواتيني"}));
      // this.doCreate(new Country({name:"إندونيسيا"}));
      // this.doCreate(new Country({name:"إيران"}));
      // this.doCreate(new Country({name:"إيطاليا"}));
      // this.doCreate(new Country({name:"الأراضي الفلسطينية"}));
      // this.doCreate(new Country({name:"الأرجنتين"}));
      // this.doCreate(new Country({name:"الأردن"}));
      // this.doCreate(new Country({name:"الأقاليم الجنوبية الفرنسية"}));
      // this.doCreate(new Country({name:"الإقليم البريطاني في المحيط الهندي"}));
      // this.doCreate(new Country({name:"الإكوادور"}));
      // this.doCreate(new Country({name:"الإمارات العربية المتحدة"}));
      // this.doCreate(new Country({name:"البحرين"}));
      // this.doCreate(new Country({name:"البرازيل"}));
      // this.doCreate(new Country({name:"البرتغال"}));
      // this.doCreate(new Country({name:"البهاما"}));
      // this.doCreate(new Country({name:"البوسنة والهرسك"}));
      // this.doCreate(new Country({name:"التشيك"}));
      // this.doCreate(new Country({name:"الجبل الأسود"}));
      // this.doCreate(new Country({name:"الجزائر"}));
      // this.doCreate(new Country({name:"الدانمرك"}));
      // this.doCreate(new Country({name:"الرأس الأخضر"}));
      // this.doCreate(new Country({name:"السلفادور"}));
      // this.doCreate(new Country({name:"السنغال"}));
      // this.doCreate(new Country({name:"السودان"}));
      // this.doCreate(new Country({name:"السويد"}));
      // this.doCreate(new Country({name:"الصحراء الغربية"}));
      // this.doCreate(new Country({name:"الصومال"}));
      // this.doCreate(new Country({name:"العراق"}));
      // this.doCreate(new Country({name:"الغابون"}));
      // this.doCreate(new Country({name:"الفاتيكان"}));
      // this.doCreate(new Country({name:"الفلبين"}));
      // this.doCreate(new Country({name:"الكاميرون"}));
      // this.doCreate(new Country({name:"الكونغو - برازافيل"}));
      // this.doCreate(new Country({name:"الكونغو - كينشاسا"}));
      // this.doCreate(new Country({name:"الكويت"}));
      // this.doCreate(new Country({name:"المغرب"}));
      // this.doCreate(new Country({name:"المكسيك"}));
      // this.doCreate(new Country({name:"المملكة العربية السعودية"}));
      // this.doCreate(new Country({name:"المملكة المتحدة"}));
      // this.doCreate(new Country({name:"النرويج"}));
      // this.doCreate(new Country({name:"النمسا"}));
      // this.doCreate(new Country({name:"النيجر"}));
      // this.doCreate(new Country({name:"الهند"}));
      // this.doCreate(new Country({name:"الولايات المتحدة"}));
      // this.doCreate(new Country({name:"اليابان"}));
      // this.doCreate(new Country({name:"اليمن"}));
      // this.doCreate(new Country({name:"اليونان"}));
      // this.doCreate(new Country({name:"بابوا غينيا الجديدة"}));
      // this.doCreate(new Country({name:"باراغواي"}));
      // this.doCreate(new Country({name:"باكستان"}));
      // this.doCreate(new Country({name:"بالاو"}));
      // this.doCreate(new Country({name:"بر الصين الرئيسي"}));
      // this.doCreate(new Country({name:"بربادوس"}));
      // this.doCreate(new Country({name:"برمودا"}));
      // this.doCreate(new Country({name:"بروناي"}));
      // this.doCreate(new Country({name:"بلجيكا"}));
      // this.doCreate(new Country({name:"بلغاريا"}));
      // this.doCreate(new Country({name:"بليز"}));
      // this.doCreate(new Country({name:"بنغلاديش"}));
      // this.doCreate(new Country({name:"بنما"}));
      // this.doCreate(new Country({name:"بنين"}));
      // this.doCreate(new Country({name:"بوتان"}));
      // this.doCreate(new Country({name:"بوتسوانا"}));
      // this.doCreate(new Country({name:"بورتوريكو"}));
      // this.doCreate(new Country({name:"بوركينا فاسو"}));
      // this.doCreate(new Country({name:"بوروندي"}));
      // this.doCreate(new Country({name:"بولندا"}));
      // this.doCreate(new Country({name:"بوليفيا"}));
      // this.doCreate(new Country({name:"بولينيزيا الفرنسية"}));
      // this.doCreate(new Country({name:"بيرو"}));
      // this.doCreate(new Country({name:"بيلاروس"}));
      // this.doCreate(new Country({name:"تايلاند"}));
      // this.doCreate(new Country({name:"تايوان"}));
      // this.doCreate(new Country({name:"تركمانستان"}));
      // this.doCreate(new Country({name:"تركيا"}));
      // this.doCreate(new Country({name:"تريستان دا كونا"}));
      // this.doCreate(new Country({name:"ترينيداد وتوباغو"}));
      // this.doCreate(new Country({name:"تشاد"}));
      // this.doCreate(new Country({name:"تشيلي"}));
      // this.doCreate(new Country({name:"تنزانيا"}));
      // this.doCreate(new Country({name:"توغو"}));
      // this.doCreate(new Country({name:"توفالو"}));
      // this.doCreate(new Country({name:"توكيلو"}));
      // this.doCreate(new Country({name:"تونس"}));
      // this.doCreate(new Country({name:"تونغا"}));
      // this.doCreate(new Country({name:"تيمور - ليشتي"}));
      // this.doCreate(new Country({name:"جامايكا"}));
      // this.doCreate(new Country({name:"جبل طارق"}));
      // this.doCreate(new Country({name:"جزر آلاند"}));
      // this.doCreate(new Country({name:"جزر القمر"}));
      // this.doCreate(new Country({name:"جزر الكناري"}));
      // this.doCreate(new Country({name:"جزر المارتينيك"}));
      // this.doCreate(new Country({name:"جزر المالديف"}));
      // this.doCreate(new Country({name:"جزر الولايات المتحدة النائية"}));
      // this.doCreate(new Country({name:"جزر بيتكيرن"}));
      // this.doCreate(new Country({name:"جزر توركس وكايكوس"}));
      // this.doCreate(new Country({name:"جزر سليمان"}));
      // this.doCreate(new Country({name:"جزر فارو"}));
      // this.doCreate(new Country({name:"جزر فوكلاند"}));
      // this.doCreate(new Country({name:"جزر فيرجن البريطانية"}));
      // this.doCreate(new Country({name:"جزر فيرجن التابعة للولايات المتحدة"}));
      // this.doCreate(new Country({name:"جزر كايمان"}));
      // this.doCreate(new Country({name:"جزر كوك"}));
      // this.doCreate(new Country({name:"جزر كوكوس (كيلينغ)"}));
      // this.doCreate(new Country({name:"جزر مارشال"}));
      // this.doCreate(new Country({name:"جزر ماريانا الشمالية"}));
      // this.doCreate(new Country({name:"جزر والس وفوتونا"}));
      // this.doCreate(new Country({name:"جزيرة أسينشيون"}));
      // this.doCreate(new Country({name:"جزيرة بوفيه"}));
      // this.doCreate(new Country({name:"جزيرة كريسماس"}));
      // this.doCreate(new Country({name:"جزيرة كليبيرتون"}));
      // this.doCreate(new Country({name:"جزيرة مان"}));
      // this.doCreate(new Country({name:"جزيرة نورفولك"}));
      // this.doCreate(new Country({name:"جزيرة هيرد وجزر ماكدونالد"}));
      // this.doCreate(new Country({name:"جمهورية أفريقيا الوسطى"}));
      // this.doCreate(new Country({name:"جمهورية الدومينيكان"}));
      // this.doCreate(new Country({name:"جنوب أفريقيا"}));
      // this.doCreate(new Country({name:"جنوب السودان"}));
      // this.doCreate(new Country({name:"جورجيا"}));
      // this.doCreate(new Country({name:"جورجيا الجنوبية وجزر ساندويتش الجنوبية"}));
      // this.doCreate(new Country({name:"جيبوتي"}));
      // this.doCreate(new Country({name:"جيرسي"}));
      // this.doCreate(new Country({name:"دومينيكا"}));
      // this.doCreate(new Country({name:"دييغو غارسيا"}));
      // this.doCreate(new Country({name:"رواندا"}));
      // this.doCreate(new Country({name:"روسيا"}));
      // this.doCreate(new Country({name:"رومانيا"}));
      // this.doCreate(new Country({name:"روينيون"}));
      // this.doCreate(new Country({name:"زامبيا"}));
      // this.doCreate(new Country({name:"زيمبابوي"}));
      // this.doCreate(new Country({name:"ساحل العاج"}));
      // this.doCreate(new Country({name:"ساموا"}));
      // this.doCreate(new Country({name:"ساموا الأمريكية"}));
      // this.doCreate(new Country({name:"سان بارتليمي"}));
      // this.doCreate(new Country({name:"سان بيير ومكويلون"}));
      // this.doCreate(new Country({name:"سان مارتن"}));
      // this.doCreate(new Country({name:"سان مارينو"}));
      // this.doCreate(new Country({name:"سانت فنسنت وجزر غرينادين"}));
      // this.doCreate(new Country({name:"سانت كيتس ونيفيس"}));
      // this.doCreate(new Country({name:"سانت لوسيا"}));
      // this.doCreate(new Country({name:"سانت مارتن"}));
      // this.doCreate(new Country({name:"سانت هيلينا"}));
      // this.doCreate(new Country({name:"ساو تومي وبرينسيبي"}));
      // this.doCreate(new Country({name:"سريلانكا"}));
      // this.doCreate(new Country({name:"سفالبارد وجان ماين"}));
      // this.doCreate(new Country({name:"سلوفاكيا"}));
      // this.doCreate(new Country({name:"سلوفينيا"}));
      // this.doCreate(new Country({name:"سنغافورة"}));
      // this.doCreate(new Country({name:"سوريا"}));
      // this.doCreate(new Country({name:"سورينام"}));
      // this.doCreate(new Country({name:"سويسرا"}));
      // this.doCreate(new Country({name:"سيراليون"}));
      // this.doCreate(new Country({name:"سيشل"}));
      // this.doCreate(new Country({name:"سيوتا وميليلا"}));
      // this.doCreate(new Country({name:"صربيا"}));
      // this.doCreate(new Country({name:"طاجيكستان"}));
      // this.doCreate(new Country({name:"عُمان"}));
      // this.doCreate(new Country({name:"غامبيا"}));
      // this.doCreate(new Country({name:"غانا"}));
      // this.doCreate(new Country({name:"غرينادا"}));
      // this.doCreate(new Country({name:"غرينلاند"}));
      // this.doCreate(new Country({name:"غواتيمالا"}));
      // this.doCreate(new Country({name:"غوادلوب"}));
      // this.doCreate(new Country({name:"غوام"}));
      // this.doCreate(new Country({name:"غويانا الفرنسية"}));
      // this.doCreate(new Country({name:"غيانا"}));
      // this.doCreate(new Country({name:"غيرنزي"}));
      // this.doCreate(new Country({name:"غينيا"}));
      // this.doCreate(new Country({name:"غينيا الاستوائية"}));
      // this.doCreate(new Country({name:"غينيا بيساو"}));
      // this.doCreate(new Country({name:"فانواتو"}));
      // this.doCreate(new Country({name:"فرنسا"}));
      // this.doCreate(new Country({name:"فنزويلا"}));
      // this.doCreate(new Country({name:"فنلندا"}));
      // this.doCreate(new Country({name:"فيتنام"}));
      // this.doCreate(new Country({name:"فيجي"}));
      // this.doCreate(new Country({name:"قبرص"}));
      // this.doCreate(new Country({name:"قطر"}));
      // this.doCreate(new Country({name:"قيرغيزستان"}));
      // this.doCreate(new Country({name:"كازاخستان"}));
      // this.doCreate(new Country({name:"كاليدونيا الجديدة"}));
      // this.doCreate(new Country({name:"كرواتيا"}));
      // this.doCreate(new Country({name:"كمبوديا"}));
      // this.doCreate(new Country({name:"كندا"}));
      // this.doCreate(new Country({name:"كوبا"}));
      // this.doCreate(new Country({name:"كوراساو"}));
      // this.doCreate(new Country({name:"كوريا الجنوبية"}));
      // this.doCreate(new Country({name:"كوريا الشمالية"}));
      // this.doCreate(new Country({name:"كوستاريكا"}));
      // this.doCreate(new Country({name:"كوسوفو"}));
      // this.doCreate(new Country({name:"كولومبيا"}));
      // this.doCreate(new Country({name:"كيريباتي"}));
      // this.doCreate(new Country({name:"كينيا"}));
      // this.doCreate(new Country({name:"لاتفيا"}));
      // this.doCreate(new Country({name:"لاوس"}));
      // this.doCreate(new Country({name:"لبنان"}));
      // this.doCreate(new Country({name:"لوكسمبورغ"}));
      // this.doCreate(new Country({name:"ليبيا"}));
      // this.doCreate(new Country({name:"ليبيريا"}));
      // this.doCreate(new Country({name:"ليتوانيا"}));
      // this.doCreate(new Country({name:"ليختنشتاين"}));
      // this.doCreate(new Country({name:"ليسوتو"}));
      // this.doCreate(new Country({name:"ماكاو"}));
      // this.doCreate(new Country({name:"مالطا"}));
      // this.doCreate(new Country({name:"مالي"}));
      // this.doCreate(new Country({name:"ماليزيا"}));
      // this.doCreate(new Country({name:"مايوت"}));
      // this.doCreate(new Country({name:"مدغشقر"}));
      // this.doCreate(new Country({name:"مصر"}));
      // this.doCreate(new Country({name:"مقدونيا الشمالية"}));
      // this.doCreate(new Country({name:"ملاوي"}));
      // this.doCreate(new Country({name:"منغوليا"}));
      // this.doCreate(new Country({name:"موريتانيا"}));
      // this.doCreate(new Country({name:"موريشيوس"}));
      // this.doCreate(new Country({name:"موزمبيق"}));
      // this.doCreate(new Country({name:"مولدوفا"}));
      // this.doCreate(new Country({name:"موناكو"}));
      // this.doCreate(new Country({name:"مونتسرات"}));
      // this.doCreate(new Country({name:"ميانمار (بورما)"}));
      // this.doCreate(new Country({name:"ميكرونيزيا"}));
      // this.doCreate(new Country({name:"ناميبيا"}));
      // this.doCreate(new Country({name:"ناورو"}));
      // this.doCreate(new Country({name:"نيبال"}));
      // this.doCreate(new Country({name:"نيجيريا"}));
      // this.doCreate(new Country({name:"نيكاراغوا"}));
      // this.doCreate(new Country({name:"نيوزيلندا"}));
      // this.doCreate(new Country({name:"نيوي"}));
      // this.doCreate(new Country({name:"هايتي"}));
      // this.doCreate(new Country({name:"هندوراس"}));
      // this.doCreate(new Country({name:"هنغاريا"}));
      // this.doCreate(new Country({name:"هولندا"}));
      // this.doCreate(new Country({name:"هولندا الكاريبية"}));
      // this.doCreate(new Country({name:"هونج كونج"}));
  }
}
