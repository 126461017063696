import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'mapToIterable' })
export class MapToIterablePipe implements PipeTransform {

    public transform(dict: Object): any {
        if (!dict)
            return [];

        return Object.keys(dict).map((id) => ({ id: id, value: dict[id] }));
    }
}
