import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  Color,
} from '../../models/color.model';

@Component({
  selector: 'thm-shimmer-static',
  templateUrl: './shimmer-static.component.html',
})
export class ShimmerStaticComponent implements OnInit {

  @HostBinding('class.thm-shimmer-static')
  protected _classes: boolean = true;

  @Input()
  public id: string = Math.random().toString(36).substring(2);

  @Input()
  @HostBinding('style.width')
  public width: number;

  @Input()
  @HostBinding('style.height')
  public height: number;

  @Input()
  public style: any;

  public get fillStyle(): any { return { fill: `url(${window.location.href}#${this.gradientId})` }; }

  public get clipStyle(): string { return `url(${window.location.href}#${this.clipId})`; }

  public get clipId(): string { return `clip-${this.id}`; }

  public get gradientId(): string { return `gradient-${this.id}`; }

  public ngOnInit(): void { }
}
