import {
    Injectable, TemplateRef,
} from '@angular/core';

import {
    OverlayRef,
} from '@angular/cdk/overlay';

import {
    ComponentPortal,
    ComponentType,
} from '@angular/cdk/portal';

import {
    MatDialogConfig as DialogConfig,
} from '@angular/material/dialog';

import {
    DialogContainer,
} from '../outlets/dialog.container';

import {
    DialogRef,
} from '../dialog.ref';

import {
    DialogService,
} from '../services/dialog.service';

import {
    CreateDialog,
} from '../create-dialog';

@Injectable()
export class PreviewFactory implements CreateDialog {

    constructor(
        private _dialog: DialogService,
    ) { }

    public createDialog<C extends DialogContainer, T, D, R>(
        overlay: OverlayRef,
        container: ComponentType<C>,
        content: ComponentType<T> | TemplateRef<T>,
        config?: DialogConfig<D>,
    ): DialogRef<T, R> {
        const containerInstance = this._attachDialogContainer(overlay, config, container);
        const ref = new DialogRef<T, R>(overlay, containerInstance, config.id);

        ref.updateSize(config.width, config.height).updatePosition();

        return ref;
    }

    private _attachDialogContainer<C extends DialogContainer>(
        overlay: OverlayRef,
        config: DialogConfig,
        container: ComponentType<C>,
    ): C {
        const injector = this._dialog.createInjector(config, undefined, undefined);

        const portal = new ComponentPortal(container, undefined, injector);
        const containerRef = overlay.attach(portal);

        return containerRef.instance;
    }
}
