import {
    ActivateTokenReason,
    Card,
    DeleteTokenReason,
    Problem,
    ResumeTokenReason,
    SuspendTokenReason,
    Token,
} from '@michel.freiha/ng-sdk';


export class Enquire {
    public static readonly type: string = '[Cards] Enquire by pan';
    constructor(public payload: { pan: string }) { }
}

export class Fail {
    public static readonly type: string = '[Cards] Fail';
    constructor(public payload: { problem: Problem }) { }
}
export class ActivateToken {
    public static readonly type: string = '[Tokens] Activate token';
    constructor(public payload: { card: Card, token: Token, reason: ActivateTokenReason }) { }
}

export class ResumeToken {
    public static readonly type: string = '[Tokens] Resume token';
    constructor(public payload: { card: Card, token: Token, reason: ResumeTokenReason }) { }
}

export class SuspendToken {
    public static readonly type: string = '[Tokens] Suspend Token';
    constructor(public payload: { card: Card, token: Token, reason: SuspendTokenReason }) { }
}

export class DeleteToken {
    public static readonly type: string = '[Tokens] Delete Token';
    constructor(public payload: { card: Card, token: Token, reason: DeleteTokenReason }) { }
}

