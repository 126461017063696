import {
    AgentAddress,
    AgentBusiness,
    AgentDocuments,
    AgentProfile,
    Problem,
} from '@michel.freiha/ng-sdk';


export class NavigateKycFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Nagivate kyc';
    constructor(public current: string) { }
}

export class InitKycFromResultsPage {
    public static readonly type: string = '[Search Results Page] Init kyc';
    constructor(public mobile: string) { }
}
export class LoadAccountFromMobile {
    public static readonly type: string = '[Search Results Page] Load Agent';
    constructor(public mobile: string) { }
}


export class SaveProfileFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Save profile info';
    constructor(public profile: AgentProfile) { }
}

export class GetAgentListForKycApproval {
  public static readonly type: string = '[Agent Details Page] Get Agent list for approval';
  constructor(public profile: any, public searchString: string) { }
}


export class CheckAgentWalletForKycApproval {
  public static readonly type: string = '[Agent Details Page] Check Agent wallet for approval';
  constructor(public accountId: string,public userId: string, public walletId: string, public transactionId: string, public referenceId:string,public dryRun: boolean) { }
}

export class CheckTravelCardIssuanceForKycApproval {
  public static readonly type: string = '[Agent Details Page] Check Travel card issuance for approval';
  constructor(public accountId: string, public walletId: string, public transactionId: string, public dryRun: boolean) { }
}

export class UserKycApproval {
  public static readonly type: string = '[Agent Details Page] User KYC approval';
  constructor(public accountId: string, public agentId: string) { }
}

export class SaveDocumentsFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Save legal documents';
    constructor(public documents: AgentDocuments) { }
}

export class SaveAddressFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Save address info';
    constructor(public address: AgentAddress) { }
}

export class SaveBusinessFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Save business info';
    constructor(public business: AgentBusiness) { }
}

export class SubmitKycFromAgentCreationPage {
    public static readonly type: string = '[Agent Creation Page] Submit kyc';
    constructor() { }
}

export class SetupNewAgentForKycFromApi {
    public static readonly type: string = '[Account Agent KYC API] New agent';
    constructor(public mobile: string) { }
}

export class FailFromApi {
    public static readonly type: string = '[Account Agent KYC API] Fail';
    constructor(public problem: Problem) { }
}
