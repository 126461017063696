import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  NymcardAccountLimit as AccountLimit,
} from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-card-listing-header',
  templateUrl: './card-listing-header.component.html',
  styleUrls: ['./card-listing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListingHeaderComponent implements OnInit {

  @HostBinding('class.nym-card-listing-header')
  protected get classes(): boolean { return true; }

  @Input()
  public limit: AccountLimit;

  public ngOnInit(): void { }

}
