import {
    Balance,
} from './balance.model';


export class Wallet {

    public id: string;
    public accountId: string;
    public created: Date;
    public modified: Date;
    public balances: Balance[];

    constructor(ob?: Partial<Wallet>) {

        this.id = undefined;
        this.accountId = undefined;
        this.created = undefined;
        this.modified = undefined;
        this.balances = [];

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.balances !== undefined) { ob.balances.forEach((o) => this.balances.push(new Balance(o))); }
    }
}
