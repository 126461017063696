import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  Subscription,
} from 'rxjs';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  AgentAccount,
  AgentAddress,
  CoreUploadsService,
  FileUploadRef,
  Problem,
  ProofOfAddress,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  ImageService,
} from '../../../services/image.service';


@Component({
  selector: 'nym-agent-address-edit',
  templateUrl: './agent-address-edit.component.html',
  styleUrls: ['./agent-address-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class AgentAddressEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subscription: Subscription = new Subscription();
  private _form: FormGroup;

  public image1: FileUploadRef;
  public image1Url: string;

  public image2: FileUploadRef;
  public image2Url: string;

  protected doc2Error:boolean;

  @HostBinding('class.nym-agent-address-edit')
  protected get classes(): boolean { return true; }

  @Output()
  protected provinceChange: EventEmitter<string> = new EventEmitter();

  @Input()
  public account: AgentAccount;

  @Input()
  public provinces: string[];

  @Input()
  public cities: string[];

  @Input()
  public problem: Problem;

  @Input()
  public addressInfoError: boolean;

  protected get form(): FormGroup { return this._form; }
  public get valid(): boolean { return this._form.valid; }

  public get province(): AbstractControl { return this.form.get('province'); }
  public get district(): AbstractControl { return this.form.get('district'); }
  public get city(): AbstractControl { return this.form.get('city'); }
  public get street(): AbstractControl { return this.form.get('street'); }
  public get building(): AbstractControl { return this.form.get('building'); }

  public get agentAddress(): AgentAddress {
    return new AgentAddress({
      state: this.province.value,
      city: this.city.value,
      district: this.district.value,
      street: this.street.value,
      building: this.building.value,
      proofOfAddress: new ProofOfAddress({
        images: [this.image1, this.image2].filter((i) => i !== undefined),
      }),
    });
  }

  constructor(
    private _fb: FormBuilder,
    private _service: CoreUploadsService,
    private _cd: ChangeDetectorRef,
    private _ph: ProblemHandler,
    private _imageService: ImageService,
  ) {
    this._form = this._fb.group({
      province: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(16),
      ]],
      district: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9 ]*$/),
        Validators.minLength(1),
        Validators.maxLength(32),
      ]],
      city: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
      ]],
      street: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4),
        Validators.pattern(/^[0-9]+$/),
      ]],
      building: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
        Validators.pattern(/^(?=.*\d)[0-9!"\#$%&'()*+,\-./:;<=>?@\[\\\]^_‘{|}~]*$/),
      ]],
    });
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {

    const accountChanges = changes['account'];

    if (this.problem) {
      this._ph.handle(this.problem, this.form);
    }

    if (this.addressInfoError) {
      this.validateAllFormFields(this.form);
    }

    if (accountChanges) {
      this._setForm(accountChanges.currentValue);
    }

    if(!this.image1 || !this.image2 || !this.image1Url || !this.image2Url )
      this.doc2Error= true;
    else
      this.doc2Error=false;
  }

  public provinceChange$$(province: string): void {
    this.provinceChange.emit(province);
  }

  protected uploadImage1$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImage1(ref)));
  }

  protected uploadImage2$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImage2(ref)));
  }

  protected clearImage1$$(): void {
    this.image1 = null;
  }

  protected clearImage2$$(): void {
    this.image2 = null;
  }

  private _setForm(account: AgentAccount): void {
    const address = account && account.address;

    if (!address)
      return;

    this._form.patchValue({
      province: address.state || '',
      district: address.district || '',
      city: address.city || '',
      street: address.street || '',
      building: address.building || '',
    });

    this.provinceChange.emit(address.state);

    const images = address.proofOfAddress.images;

    if (images) {
      this._setImage1(images[0]);
      this._setImage2(images[1]);
    }
  }

  private _setImage1(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.image1 = ref;
    this.image1Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImage2(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.image2 = ref;
    this.image2Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  validateAllFormFields(formGroup: FormGroup): void {       
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);      
    if (control instanceof FormControl) {      
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) { 
      this.validateAllFormFields(control);     
    }
  });
}
}
