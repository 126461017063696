import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  StringIntoPipe,
} from '@nymos/theme';

import {
  ACCOUNT_ME,
  AccountMe,
} from '@nymos/auth';

import {
  WalletActivity,
} from '@nymos/accounts/core';


const DEFAULT_COLUMNS = ['category', 'title', 'amount', 'payment', 'receiverNumber', 'datetime', 'source', 'reviewed', 'status', 'actions'];

@Component({
  selector: 'nym-wallet-activity-listing-table',
  templateUrl: './wallet-activity-listing-table.component.html',
  styleUrls: ['./wallet-activity-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletActivityListingTableComponent implements OnInit {

  @HostBinding('class.nym-wallet-activity-full-table')
  protected get classes(): boolean { return true; }

  @Output('manage') // tslint:disable-line:no-output-rename
  protected manageEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public activities: WalletActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public columns: string[] = DEFAULT_COLUMNS;

  protected get shimmer(): boolean { return this.loading && !(this.activities && this.activities.length); }

  constructor(
    @Inject(ACCOUNT_ME) private _me: AccountMe,
  ) { }

  public ngOnInit(): void { }

  public manage(activity: WalletActivity, action: string): void {
    this.manageEmitter.emit({ activity, action });
  }

  protected enableActionMenu(activity: WalletActivity): boolean {

    if (!activity)
      return false;

    const createdById = activity.createdBy && activity.createdBy.id;

    if (this._me.id() === createdById)
      return false;

    const belongs = new StringIntoPipe().transform(activity.status, ['pending', 'processing', 'cancelling']);
    return belongs;
  }
}
