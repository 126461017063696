import {
  NymcardCardBulkIssuanceInternal,
} from '@michel.freiha/ng-sdk';

import {
  BulkIssuance,
  Color,
  Status,
} from '../models/bulk-issuance.model';


export class BulkIssuanceBuilder {
  private _internal: NymcardCardBulkIssuanceInternal;

  public withInternal(value: NymcardCardBulkIssuanceInternal): BulkIssuanceBuilder {
    this._internal = value;
    return this;
  }

  public build(): BulkIssuance {

    const internal = this._internal;

    if (!internal)
      return undefined;

    const index = internal.token;
    const created = internal.created;
    const modified = internal.modified;
    const amount = internal.details.amount;
    const currency = internal.details.currency.toUpperCase();
    const numberOfCards = internal.details.numberOfCards;
    const status = this._status(internal);
    const type = this._type(internal);


    return new BulkIssuance({
      id: index,
      token: index,
      created: created,
      modified: modified,
      amount: amount,
      currency: currency,
      numberOfCards: numberOfCards,
      status: status,
      type: type,
    });
  }



  private _type(internal: NymcardCardBulkIssuanceInternal): string {
    switch (internal.details.cardProductId) {
      case '005':
      case 'neoprepaid':
        return 'Neo Gift';

      default:
        return undefined;
    }
  }

  private _status(internal: NymcardCardBulkIssuanceInternal): { color: Color, value: Status } {
    switch (internal.status) {
      case 'failed':
      case 'error':
        return { color: 'red', value: 'failed' };

      case 'pending':
        return { color: 'yellow', value: 'pending' };

      case 'completed':
        return { color: 'green', value: 'completed' };

      default:
        return { color: 'primary', value: undefined };
    }
  }
}
