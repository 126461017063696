import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  MatSort,
} from '@angular/material/sort';

import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

import {
  ShimmerHandler,
} from '../../../shimmer/handlers/shimmer.handler';


@Component({
  selector: 'thm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent<T> implements OnInit, OnChanges, AfterContentInit {

  private _shimmerHandler: ShimmerHandler<T> = new ShimmerHandler(10);
  private _dataSource: MatTableDataSource<T> = new MatTableDataSource([]);

  @HostBinding('class.thm-table')
  protected get classes(): boolean { return true; }

  @Input()
  @HostBinding('class.thm-table-shimmer')
  public shimmer: boolean = false;

  @Input()
  public data: T[];

  @Input()
  public set filter(predicate: (data: T, filter: string) => boolean) { this._setFilter(predicate); }

  @ViewChild(MatTable, { static: true })
  public table: MatTable<T>;

  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  @ContentChildren(MatHeaderRowDef)
  public headerRowDefs: QueryList<MatHeaderRowDef>;

  @ContentChildren(MatRowDef)
  public rowDefs: QueryList<MatRowDef<T>>;

  @ContentChildren(MatColumnDef)
  public columnDefs: QueryList<MatColumnDef>;

  public get dataSource(): MatTableDataSource<T> { return this._dataSource; }
  public get hasData(): boolean { return this._dataSource && this._dataSource.filteredData.length > 0; }

  constructor(
    private _cd: ChangeDetectorRef,
  ) {
    this._dataSource.sort = this.sort;
  }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    const shimmer = changes['shimmer'];
    const data = changes['data'];

    if (data) {
      this._shimmerHandler.data = data.currentValue;
    }

    if (shimmer) {
      this._shimmerHandler.loading = shimmer.currentValue;
    }

    this._setData(this._shimmerHandler.data);
  }

  public ngAfterContentInit(): void {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach((rowDef) => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach((headerRowDef) => this.table.addHeaderRowDef(headerRowDef));
  }

  public query(query: string): void {
    this._dataSource.filter = query;
    this._cd.markForCheck();
  }

  private _setData(data: T[] = []): void {
    this._dataSource.data = data;
    this._cd.markForCheck();
  }

  private _setFilter(predicate: (data: T, filter: string) => boolean): void {
    this._dataSource.filterPredicate = predicate;
  }
}
