import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Account,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListingComponent implements OnInit {

  @HostBinding('class.nym-user-listing')
  protected get classes(): boolean { return true; }

  @Output()
  protected filter: EventEmitter<string[]> = new EventEmitter();

  @Output()
  protected documentFilter: EventEmitter<string[]> = new EventEmitter();

  @Output()
  protected create: EventEmitter<string> = new EventEmitter();

  @Output()
  protected loadMore: EventEmitter<any> = new EventEmitter();

  @Output()
  protected view: EventEmitter<Account> = new EventEmitter();

  @Output('loadBy')
  protected sortByColumn: EventEmitter<any> = new EventEmitter();

  @Input()
  public title: string;

  @Input()
  public types: string[];

  @Input()
  public accounts: Account[];

  @Input()
  public loading: boolean;

  @Input()
  public hasMore: boolean;

  @Input()
  public columns: string[];

  constructor() { }

  public ngOnInit(): void {   }

  protected create$$(type: string): void {
    this.create.emit(type);
  }

  protected filter$$(types: string[]): void {
    this.filter.emit(types);
  }

  protected documentFilter$$(types: string[]): void {
    this.documentFilter.emit(types);
  }

  protected loadMore$$(): void {
    this.loadMore.emit();
  }

  protected view$$(account: Account): void {
    this.view.emit(account);
  }

  public sortLoad$(params: any): void {
    this.sortByColumn.emit(params);
  }

}
