import {
    CoreNote,
    Problem,
    UserAccountUpdateRequest
} from '@michel.freiha/ng-sdk';


export class GetAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Get account';
    constructor(public id: string) { }
}

export class GetAccountFromUserExistsGuard {
    public static readonly type: string = '[User Guard] Get account';
    constructor(public id: string) { }
}

export class RefreshAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Refresh account';
    constructor(public id: string) { }
}

export class RefreshAccountFromUserApi {
    public static readonly type: string = '[User API] Refresh account';
    constructor(public id: string) { }
}

export class VerifyAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Verify account';
    constructor(public id: string,public docType:string) { }
}

export class UpdateAccountFromUserEditDetailsPage {
    public static readonly type: string = '[User Edit Details Page] Update account';
    constructor(public id: string, public user: UserAccountUpdateRequest) { }
}

export class RejectAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Reject account';
    constructor(public id: string, public note: CoreNote,public docType : string) { }
}

export class BlockAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Block account';
    constructor(public id: string, public note: CoreNote) { }
}

export class UnblockAccountFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Unblock account';
    constructor(public id: string) { }
}

export class ResetPasscodeFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Reset passcode';
    constructor(public id: string, public note: CoreNote) { }
}

export class FailFromApi {
    public static readonly type: string = '[Account User API] Fail';
    constructor(public payload: { problem: Problem }) { }
}

export class SetStatusLoadingFromStatusMenu {
    public static readonly type: string = '[User Details Page] Reset status loading';
    constructor(public flag:boolean) { }
}
