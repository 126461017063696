import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'thm-loader-empty, .thm-loader-empty, [thm-loader-empty], [thmLoaderEmpty]',
})
export class LoaderEmptyDirective {

  @HostBinding('class.thm-loader-empty')
  protected get classes(): boolean { return true; }

  constructor() { }

}
