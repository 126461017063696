import {
    Component,
    HostListener,
    OnInit,
} from '@angular/core';

import {
    Token,
} from '@michel.freiha/ng-sdk';

import {
    CardResultItemComponent,
} from '../../../lib/ui/components/card-result-item/card-result-item.component';

@Component({
    selector: 'nym-card-result-item',
    template: ``,
})
export class StubCardResultItemComponent
    extends CardResultItemComponent
    implements OnInit {

    @HostListener('click', ['$event'])
    public trigger({ action, token }: { action: string, token: Token }): void {
        switch (action) {
            case 'activate':
                return this.activate(token);

            case 'resume':
                return this.resume(token);

            case 'suspend':
                return this.suspend(token);

            case 'delete':
                return this.delete(token);

            default:
                throw new Error('Error on trigger');
        }
    }
}
