import {
    Store,
} from '@ngxs/store';

import {
    AccountchannelsSearchClientBuilder,
    AccountsClientBuilder,
    AdminsClientBuilder,
    AdminsSearchClientBuilder,
    AgentsClientBuilder,
    BearerCredentials,
    ClientParams,
    CoreClientBuilder,
    DefaultUrlFactory,
    Device,
    DevicesClientBuilder,
    DevtoolsClientBuilder,
    EmptyCredentials,
    NotesSearchClientBuilder,
    NotificationsDevtoolsClientBuilder,
    NymcardsClientBuilder,
    NymcardsSearchClientBuilder,
    Oauth2ClientBuilder,
    Oauth2ScopeClientBuilder,
    PasscodesClientBuilder,
    UploadsClientBuilder,
    UsersClientBuilder,
    WalletsClientBuilder,
    AgentsSearchClientBuilder,
} from '@michel.freiha/ng-sdk';

import {
    DiscoveryService,
    EnvsState,
} from '@nymos/discovery';


const clientParams = (device: Device, appId: string, consumerKey: string) => {
    return new ClientParams(
        appId,
        '1.3.0',
        device.id,
        null,
        null,
        null,
        consumerKey,
    );
};

export const encryptionDeviceFactory = (store: Store) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    return device;
};
export const coreOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    return new CoreClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('core')))
        .withCredentials(new EmptyCredentials())
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const oauth2OptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    return new Oauth2ClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('oauth2')))
        .withCredentials(new EmptyCredentials())
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const oauth2ScopesOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new Oauth2ScopeClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('oauth2')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const devicesOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new DevicesClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('devices')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const uploadsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new UploadsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('uploads')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const accountsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AccountsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('accounts')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};


export const adminsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AdminsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('admins')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};


export const adminsSearchOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AdminsSearchClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('admins')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const usersOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new UsersClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('users')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const agentsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AgentsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('agents')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};


export const accountchannelsSearchOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new AccountchannelsSearchClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('accountchannels')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const devtoolsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new DevtoolsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('devtools')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const notificationsDevtoolsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new NotificationsDevtoolsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('devtools')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const nymcardsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new NymcardsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('nymcards')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const nymcardsSearchOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new NymcardsSearchClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('nymcards')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const walletsOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new WalletsClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('wallets')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const notesSearchOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new NotesSearchClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('notes')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};

export const agentsSearchOptionsFactory = (store: Store, discovery: DiscoveryService) => {
  const device = store.selectSnapshot<Device>((EnvsState.device));
  const appId = store.selectSnapshot<string>((EnvsState.appId));
  const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
  const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
  return new AgentsSearchClientBuilder()
      .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('agents')))
      .withCredentials(new BearerCredentials(token))
      .withClientParams(clientParams(device, appId, consumerKey))
      .build();
};

export const passcodesOptionsFactory = (store: Store, discovery: DiscoveryService) => {
    const device = store.selectSnapshot<Device>((EnvsState.device));
    const appId = store.selectSnapshot<string>((EnvsState.appId));
    const consumerKey = store.selectSnapshot<string>(EnvsState.consumerKey);
    const token = () => (store.selectSnapshot((state) => state.auth && state.auth.token && state.auth.token.accessToken));
    return new PasscodesClientBuilder()
        .withBasePathFactory(new DefaultUrlFactory(discovery.getUrl('passcodes')))
        .withCredentials(new BearerCredentials(token))
        .withClientParams(clientParams(device, appId, consumerKey))
        .build();
};
