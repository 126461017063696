import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    catchError,
    filter,
    finalize,
    mergeMap,
    tap,
} from 'rxjs/operators';

import {
    AdminSession,
    AdminSessionFlow,
    AdminsInternalService,
    Credentials,
    Oauth2AccessToken,
    Oauth2Service,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    Auth,
    AuthFail,
    ChangePassword,
    ConfirmPasscode,
    Init,
    RefreshToken,
    SignIn,
    SignOut,
} from './auth.actions';

import {
    StoreProfileFromApi,
} from '../profile/profile.actions';

import {
    AuthService,
} from '../../services/auth.service';

import { SigninService } from '../../services/signin.service';


export class AuthStateModel {
    public token: Oauth2AccessToken;
    public credentials: Credentials;
    public session: any;
    public problem: Problem;
    public authenticating: boolean;
}

const stateDefaults: AuthStateModel = {
    token: undefined,
    credentials: undefined,
    session: undefined,
    problem: undefined,
    authenticating: undefined,
};

@State<AuthStateModel>({
    name: 'auth',
    defaults: stateDefaults,
})
export class AuthState {

    constructor(
        private _adminService: AdminsInternalService,
        private _oauth2Service: Oauth2Service,
        private _service: AuthService,
        private _signinService : SigninService

    ) { }

    @Action(Auth)
    public auth(ctx: StateContext<AuthStateModel>, { payload: { credentials } }: Auth): any {

        ctx.patchState({ credentials: credentials, authenticating: false });

        return this._signinService.initiateAuthFlow(credentials).pipe(
            tap((session: any) => {
                ctx.patchState({ session: session });
            }),

            filter((session: AdminSession) => session.flow === AdminSessionFlow.CustomTokenLogin),

            mergeMap((session: AdminSession) =>
                ctx.dispatch([new SignIn({ customToken: session.customToken })]),
            ),

            catchError((problem) => ctx.dispatch(new AuthFail({ problem: problem }))),

            finalize(() => {
                ctx.patchState({ authenticating: false });
            }),
        );
    }


    @Action(RefreshToken)
    public refresh(ctx: StateContext<AuthStateModel>, { payload: { token } }: RefreshToken): any {
        ctx.patchState({ token: token });
    }

    @Action(ChangePassword)
    public changePassword(ctx: StateContext<AuthStateModel>, { payload: { id, passwordChange } }: ChangePassword): any {
        
        if(passwordChange.oldPassword == '' && passwordChange.newPassword != ''){
            
            ctx.patchState({ authenticating: true });
    
            return this._signinService.passwordChangeByLink(id, passwordChange).pipe(
                tap((session: any) =>{

                    const credentials = new Credentials({
                        username: session.admin.profile.email,
                        password: passwordChange.newPassword,
                      });

                    ctx.patchState({ credentials: credentials, session: session })
                }),
    
                filter((session: any) => session.flow === AdminSessionFlow.CustomTokenLogin),
    
                mergeMap((session: any) =>
                    ctx.dispatch(new SignIn({ customToken: session.customToken })),
                ),
    
                catchError((problem) => {
                    return ctx.dispatch(new AuthFail({ problem: problem }));
                }),
    
                finalize(() => {
                    ctx.patchState({ authenticating: false });
                }),
            );

        } else {
           ctx.patchState({ authenticating: true });
    
            return this._signinService.continueAuthWithPasswordChange(id, passwordChange).pipe(
                tap((session: any) =>
                    ctx.patchState({ session: session }),
                ),
    
                filter((session: any) => session.flow === AdminSessionFlow.CustomTokenLogin),
    
                mergeMap((session: any) =>
                    ctx.dispatch(new SignIn({ customToken: session.customToken })),
                ),
    
                catchError((problem) => {
                    return ctx.dispatch(new AuthFail({ problem: problem }));
                }),
    
                finalize(() => {
                    ctx.patchState({ authenticating: false });
                }),
            ); 
        }
    }

    @Action(ConfirmPasscode)
    public confirmPasscode(ctx: StateContext<AuthStateModel>, { payload: { id, code } }: ConfirmPasscode): any {

        ctx.patchState({ authenticating: true });

        return this._signinService.continueAuthWithMobileVerification(id, code).pipe(
            tap((session: any) =>
            {
                ctx.patchState({ session: session })
            }
            ),

            filter((session: any) => session.flow === AdminSessionFlow.CustomTokenLogin),

            mergeMap((session: any) =>
                ctx.dispatch(new SignIn({ customToken: session.customToken })),
            ),

            catchError((problem) => {
                return ctx.dispatch(new AuthFail({ problem: problem }));
            }),

            finalize(() => {
                ctx.patchState({ authenticating: false });
            }),
        );
    }

    @Action(SignIn)
    public signin(ctx: StateContext<AuthStateModel>, { payload: { customToken } }: SignIn): any {

        return this._signinService.signinWithCustomToken(customToken.token, customToken.provider).pipe(
            tap((token: any) => {
                ctx.patchState({ token: token });
            }),

            mergeMap(() => {
                const state = ctx.getState();
                return ctx.dispatch([new StoreProfileFromApi(state.session.admin, state.session.customToken.scopes), new Navigate(['/'])]);
            }),

            catchError((problem) => {
                return ctx.dispatch(new AuthFail({ problem: problem }));
            }),
        );
    }

    @Action(Init)
    @Action(SignOut)
    public signout(ctx: StateContext<AuthStateModel>): void {
        const apiKey = localStorage.getItem('apikey');
        ctx.setState(stateDefaults);
        ctx.dispatch(new Navigate([`/${apiKey}/account/signin`]));
    }

    @Action(AuthFail)
    public authFail(ctx: StateContext<AuthStateModel>, { payload: { problem } }: AuthFail): void {
        ctx.patchState({ authenticating: false });
        ctx.patchState({ problem: problem });
    }

    @Selector()
    public static accessToken(state: AuthStateModel): string { return state.token.accessToken; }

    @Selector()
    public static session(state: AuthStateModel): AdminSession { return state.session; }

    @Selector()
    public static username(state: AuthStateModel): string { return state.credentials.username; }

    @Selector()
    public static password(state: AuthStateModel): string { return state.credentials.password; }

    @Selector()
    public static problem(state: AuthStateModel): Problem { return state.problem; }

    @Selector()
    public static authenticating(state: AuthStateModel): boolean { return state.authenticating; }
}
