import {
    NgModule,
} from '@angular/core';

import {
    TitleDirective,
} from './title.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        TitleDirective,
    ],

    imports: [],

    exports: [
        TitleDirective,
    ],

})
export class ThemeDirectivesTitleModule { }
