import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  discoveryProvider,
} from './discovery.provider';


@NgModule({
  imports: [
    CommonModule,
    // Invoking EnvsState on AppModule.forRoot
  ],
})
export class NymDiscoveryModule {
  constructor(
    @Optional() @SkipSelf() parentModule?: NymDiscoveryModule,
  ) {
    if (parentModule) {
      throw new Error(
        'NymDiscoveryModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NymDiscoveryModule,
      providers: [
        discoveryProvider,
      ],
    };
  }

}
