import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  FormsModule,
} from '@angular/forms';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatIconModule,
} from '@angular/material/icon';

import { LogoutComponent } from './containers/logout/logout.component';

export const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
];


@NgModule({

  declarations: [
    LogoutComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
  ],

  exports: [
    LogoutComponent,
  ],

})
export class ThemeComponentsLogoutModule { }

