import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';


// PIPES

import {
    AccountBadgePipe,
} from './pipes/account-badge.pipe';

import {
    CardActivityColorPipe,
} from './pipes/card-activity-color.pipe';

import {
    CardStatusIconPipe,
} from './pipes/card-status-icon.pipe';

import {
    CardStatusColorPipe,
} from './pipes/card-status-color.pipe';

import {
    ChannelColorPipe,
} from './pipes/channel-color.pipe';

import {
    ChannelColumnsPipe,
} from './pipes/channel-columns.pipe';

import {
    ChannelTextPipe,
} from './pipes/channel-text.pipe';

import {
    ChannelIconPipe,
} from './pipes/channel-icon.pipe';

import {
    LegalFormTextPipe,
} from './pipes/legal-form-text.pipe';

import {
    LimitAddedPipe,
} from './pipes/limit-added.pipe';

import {
    LimitRemainingPipe,
} from './pipes/limit-remaining.pipe';

import {
    ReasonTextPipe,
} from './pipes/reason-text.pipe';

import {
    RemoveCharacterPipe,
} from './pipes/remove-charcater.pipe';

import {
    RemoveUnderScorePipe,
}  from './pipes/remove-underscore.pipe';

import {
    WalletActivityColorPipe,
} from './pipes/wallet-activity-color.pipe';


export const PIPES = [
    AccountBadgePipe,
    CardActivityColorPipe,
    CardStatusIconPipe,
    CardStatusColorPipe,
    ChannelColorPipe,
    ChannelColumnsPipe,
    ChannelIconPipe,
    ChannelTextPipe,
    LegalFormTextPipe,
    LimitAddedPipe,
    LimitRemainingPipe,
    ReasonTextPipe,
    RemoveCharacterPipe,
    RemoveUnderScorePipe,
    WalletActivityColorPipe,
];


@NgModule({
    declarations: [
        ...PIPES,
    ],

    imports: [
        CommonModule,
    ],

    exports: [
        ...PIPES,
    ],
})
export class AccountsCoreModule { }
