/* tslint:disable:member-ordering */

import * as faker from 'faker';

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    MonoTypeOperatorFunction, throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    NymcardCmsWalletTransactionInternal,
    NymcardCmsWalletTransactionInternalCollection,
    NymcardsSearchService,
    NymcardsWalletInternalService,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    WalletActivity,
} from '@nymos/accounts/core';

import {
    WalletActivityBuilder,
} from '../../builders/wallet-activity.builder';

import {
    ApproveActivityFromAgentDetailsPage,
    ApproveActivityFromUserDetailsPage,
    ApproveActivityFromWalletActivitiesPage,
    CancelActivityFromAgentDetailsPage,
    CancelActivityFromUserDetailsPage,
    CancelActivityFromWalletActivitiesPage,
    CreditWalletFromAgentDetailsPage,
    CreditWalletFromUserDetailsPage,
    TransferBetweenWalletFromUserDetailsPage,
    TransferBetweenWalletAgentFromUserDetailsPage,
    TransferBetweenWalletAgentFromAgentDetailsPage,
    DebitWalletFromAgentDetailsPage,
    DebitWalletFromUserDetailsPage,
    DryRunWalletFromAgentDetailsPage,
    DryRunWalletFromUserDetailsPage,
    DryRunDebitWalletFromAgentDetailsPage,
    DryRunDebitWalletFromUserDetailsPage,
    DryRunTransferBetweenWalletFromUserDetailsPage,
    DryRunTransferBetweenWalletAgentFromUserDetailsPage,
    DryRunTransferBetweenWalletAgentFromAgentDetailsPage,
    FailFromApi,
    LoadWalletActivitiesFromAgentDetailsPage,
    LoadWalletActivitiesFromUserDetailsPage,
    RefreshWalletActivitiesFromAgentDetailsPage,
    RefreshWalletActivitiesFromUserDetailsPage,
    RejectActivityFromAgentDetailsPage,
    RejectActivityFromUserDetailsPage,
    RejectActivityFromWalletActivitiesPage,
    RetryActivityFromAgentDetailsPage,
    RetryActivityFromUserDetailsPage,
    RetryActivityFromWalletActivitiesPage,
} from './wallet-activities.actions';

import {
    Notifications,
} from './wallet-activities.notifications';



export interface WalletActivitiesStateModel {
    items: { [id: string]: WalletActivity };
    temp: { [id: string]: WalletActivity };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: WalletActivitiesStateModel = {
    items: {},
    temp: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<WalletActivitiesStateModel>({
    name: 'wallet_activities',
    defaults: stateDefaults,
})
export class WalletActivitiesState {

    @Selector()
    public static userActivities(state: WalletActivitiesStateModel): WalletActivity[] {
        const result = Object.keys(state.items).map(((id) => state.items[id]));
        return result;
    }

    public static topupActivity(id: string): any {
        return createSelector([WalletActivitiesState], (state: WalletActivitiesStateModel) => {
            return state.temp[id];
        });
    }

    @Selector()
    public static problem(state: WalletActivitiesStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: WalletActivitiesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static hasMore(state: WalletActivitiesStateModel): boolean {
        return !!(state.next && state.next.cursors && state.next.cursors.after);
    }

    constructor(
        private _nc: NotificationCenter,
        private _walletService: NymcardsWalletInternalService,
        private _searchService: NymcardsSearchService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<WalletActivitiesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(ApproveActivityFromUserDetailsPage)
    @Action(ApproveActivityFromAgentDetailsPage)
    @Action(ApproveActivityFromWalletActivitiesPage)
    public approveWalletActivity(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId }: any,
    ): any {
        this._nc.show(Notifications.Approving);
        return this._walletService.approveWalletTransaction(wId, wId, tId).pipe(this._update(ctx));
    }

    @Action(RejectActivityFromUserDetailsPage)
    @Action(RejectActivityFromAgentDetailsPage)
    @Action(RejectActivityFromWalletActivitiesPage)
    public rejectWalletActivity(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, note }: any,
    ): any {
        this._nc.show(Notifications.Declining);
        return this._walletService.rejectWalletTransaction(wId, wId, tId, note).pipe(this._update(ctx));
    }

    @Action(RetryActivityFromUserDetailsPage)
    @Action(RetryActivityFromAgentDetailsPage)
    @Action(RetryActivityFromWalletActivitiesPage)
    public retryWalletActivity(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId }: any,
    ): any {
        this._nc.show(Notifications.Retrying);
        return this._walletService.retryWalletTransaction(wId, wId, tId).pipe(this._update(ctx));
    }

    @Action(CancelActivityFromUserDetailsPage)
    @Action(CancelActivityFromAgentDetailsPage)
    @Action(CancelActivityFromWalletActivitiesPage)
    public cancelWalletActivity(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, note }: any,
    ): any {
        this._nc.show(Notifications.Cancelling);
        return this._walletService.cancelWalletTransaction(wId, wId, tId, note).pipe(this._update(ctx));
    }

    @Action(CreditWalletFromUserDetailsPage)
    @Action(CreditWalletFromAgentDetailsPage)
    public creditWallet(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, transaction, money }: any,
    ): any {
        transaction.dryRun = false;
        this._nc.show(Notifications.Crediting(money));
        return this._walletService.creditWallet(wId, wId, tId, transaction).pipe(this._update(ctx));
    }


    
    @Action(TransferBetweenWalletFromUserDetailsPage)
    public transferBetweenWallets(
        ctx: StateContext<WalletActivitiesStateModel>,
        { aId,wId,tId,cId,transaction,money}: any,
    ): any {
        
        transaction.dryRun = false;

        if(transaction.description.includes("termination"))
        this._nc.show(Notifications.Transfering(money));
        else
        if(transaction.description.includes("refund"))
        this._nc.show(Notifications.Refunding(money));

        return this._walletService.cardRefund(aId, wId, tId,cId,transaction).pipe(this._update(ctx));
    }


@Action(TransferBetweenWalletAgentFromUserDetailsPage)
@Action(TransferBetweenWalletAgentFromAgentDetailsPage)
    public transferBetweenWalletsAgent(
        ctx: StateContext<WalletActivitiesStateModel>,
        { aId,wId,tId,rId,transaction,money}: any,
    ): any {
        transaction.dryRun = false;
        this._nc.show(Notifications.DebitingWallet(money));
        return this._walletService.transferBetweenWallets(aId, wId, tId,rId,transaction).pipe(this._update(ctx));
    }


@Action(DryRunTransferBetweenWalletAgentFromUserDetailsPage)
@Action(DryRunTransferBetweenWalletAgentFromAgentDetailsPage)
    public transferBetweenWalletsAgentAsDryRun(
        ctx: StateContext<WalletActivitiesStateModel>,
        { aId,wId,tId,rId,transaction,money}: any,
    ): any {
        transaction.dryRun = true;
      return this._walletService.transferBetweenWallets(aId, wId, tId,rId,transaction).pipe(
            tap((internal: NymcardCmsWalletTransactionInternal) => {
                return ctx.setState(produce((draft) => {
                    draft.temp = {};
                    draft.temp[tId] = new WalletActivityBuilder().withInternal(internal).build();
                }));
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }  


     @Action(DebitWalletFromUserDetailsPage)
     @Action(DebitWalletFromAgentDetailsPage)
    public debitWallet(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, transaction, money }: any,
    ): any {
        transaction.dryRun = false;
        this._nc.show(Notifications.Debiting(money));
        return this._walletService.debitWallet(wId, wId, tId, transaction).pipe(this._update(ctx));
    }

    @Action(DryRunWalletFromUserDetailsPage)
    @Action(DryRunWalletFromAgentDetailsPage)
    public creditWalletAsDryRun(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, transaction }: any,
    ): any {
        transaction.dryRun = true;

        return this._walletService.creditWallet(wId, wId, tId, transaction).pipe(
            tap((internal: NymcardCmsWalletTransactionInternal) => {
                ctx.setState(produce((draft) => {
                    draft.temp = {};
                    draft.temp[tId] = new WalletActivityBuilder().withInternal(internal).build();
                }));
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }


    @Action(DryRunTransferBetweenWalletFromUserDetailsPage)
    public transferBetweenWalletAsDryRun(
        ctx: StateContext<WalletActivitiesStateModel>,
        { aId,wId,tId,cId,transaction,money }: any,
    ): any {
        transaction.dryRun = true;
        return this._walletService.cardRefund(aId, wId,tId, cId, transaction).pipe(
            tap((internal: NymcardCmsWalletTransactionInternal) => {
                ctx.setState(produce((draft) => {
                    draft.temp = {};
                    draft.temp[tId] = new WalletActivityBuilder().withInternal(internal).build();
                }));
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }

    @Action(DryRunDebitWalletFromUserDetailsPage)
    @Action(DryRunDebitWalletFromAgentDetailsPage)
    public debitWalletAsDryRun(
        ctx: StateContext<WalletActivitiesStateModel>,
        { wId, tId, transaction }: any,
    ): any {
        transaction.dryRun = true;

        return this._walletService.debitWallet(wId, wId, tId, transaction).pipe(
            tap((internal: NymcardCmsWalletTransactionInternal) => {
                return ctx.setState(produce((draft) => {
                    draft.temp = {};
                    draft.temp[tId] = new WalletActivityBuilder().withInternal(internal).build();
                }));
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }

    @Action(LoadWalletActivitiesFromUserDetailsPage)
    @Action(LoadWalletActivitiesFromAgentDetailsPage)
    public loadWalletActivities(ctx: StateContext<WalletActivitiesStateModel>, { id }: any): any {
        ctx.setState({ ...stateDefaults, loading: true });
        return this._loadWalletActivities(ctx, id).pipe(
            finalize(() => { ctx.patchState({ loading: false }); }),
        );
    }

    @Action(RefreshWalletActivitiesFromUserDetailsPage)
    @Action(RefreshWalletActivitiesFromAgentDetailsPage)
    public refreshWalletActivities(ctx: StateContext<WalletActivitiesStateModel>, { id }: any): any {
        return this._loadWalletActivities(ctx, id);
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<WalletActivitiesStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    private _loadWalletActivities(
        ctx: StateContext<WalletActivitiesStateModel>,
        id: string,
    ): any {
        return this._walletService.loadWalletTransactions(id, id).pipe(
            tap((transactions: NymcardCmsWalletTransactionInternalCollection) => {
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    transactions.data.forEach((internal) =>
                        draft.items[internal.internalId] = new WalletActivityBuilder().withInternal(internal).build(),
                    );
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }

    private _update(
        ctx: StateContext<WalletActivitiesStateModel>,
    ): MonoTypeOperatorFunction<any> {

        ctx.patchState({ saving: true });

        return (source) =>
            source.pipe(
                tap((internal: NymcardCmsWalletTransactionInternal) => {
                    this._nc.dismiss();
                    ctx.setState(produce((draft) => {
                        draft.items[internal.internalId] = new WalletActivityBuilder().withInternal(internal).build();
                    }));
                }),

                catchError((problem) => {
                    return ctx.dispatch(new FailFromApi({ problem: problem }));
                }),

                finalize(() => {
                    ctx.patchState({ saving: false });
                }),
            );
    }
}
