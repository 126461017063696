// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '../../texts/accounts.texts';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
    public static readonly Loading: Notification = new Notification(Texts.UserAction.Loading);
    public static readonly PasscodeReset: Notification = new Notification(Texts.UserAction.ResettingPasscode);
    public static readonly PasscodeReseted: Notification = new Notification(Texts.UserAction.PasscodeReseted);
}


