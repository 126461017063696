// tslint:disable:component-class-suffix
// tslint:disable:no-host-metadata-property

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {
  MatStepHeader, MatStepperIntl,
} from '@angular/material/stepper';

import {
  KycStepperIntl,
} from '../../i18n/kyc-stepper.i18n';

import {
  FocusMonitor,
} from '@angular/cdk/a11y';


@Component({
  moduleId: module.id,
  selector: 'thm-kyc-step-header',
  templateUrl: './kyc-step-header.component.html',
  styleUrls: ['./kyc-step-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'role': 'tab',
  },
  providers: [
    { provide: MatStepHeader, useExisting: KycStepHeader },
    { provide: MatStepperIntl, useExisting: KycStepperIntl },
  ],
})
export class KycStepHeader extends MatStepHeader {

  @HostBinding('class.mat-step-header')
  @HostBinding('class.thm-kyc-step-header')
  protected get classes(): boolean { return true; }

  @Input()
  public completed: boolean;

  constructor(
    public _intl: KycStepperIntl,
    _fm: FocusMonitor,
    _er: ElementRef<HTMLElement>,
    _cd: ChangeDetectorRef,
  ) {
    super(_intl, _fm, _er, _cd);
  }

}
