import {
    UserDocuments,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from './attachment.model';


export type DocumentType =
    | 'passport'
    | 'nationalId'
    | 'civilStatus'
    | 'certificateOfNationality'
    | 'visa'
    | 'residencyCard'
    | undefined;

export class AccountDocument {

    public type: DocumentType;
    public info: any;
    public attachments: Attachment[];

    constructor(ob?: Partial<AccountDocument>) {

        this.type = undefined;
        this.info = undefined;
        this.attachments = [];

        if (!ob) { return; }

        if (ob.type !== undefined) { this.type = ob.type; }
        if (ob.info !== undefined) { this.info = ob.info; }
        if (ob.attachments !== undefined) { ob.attachments.forEach((o) => this.attachments.push(new Attachment(o))); }
    }
}

