import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import { MatButtonModule, MatPaginatorModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';

import { PaginationComponent } from './containers/pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonModule
  ],
  exports: [PaginationComponent]
})
export class ThemeComponentsPaginationModule { }
