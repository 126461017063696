import {
    Token,
    TokenStatus,
} from '@michel.freiha/ng-sdk';

import {
    ModelFactory,
} from '@nymos/backend';


export class MockToken extends ModelFactory<Token> {

    constructor() {
        super(MockToken.create, (m: Token) => m.id);
    }

    public static create(id?: string): Token {

        const index = id ? id : 'mock##id';
        const status = TokenStatus.Active;

        const token = new Token({
            'id': index,
            'status': status,
        });

        return token;
    }
}

