import {
    BusinessLegalFormType,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from './attachment.model';


export class AccountBusiness {

    public type: string;
    public legalForm: BusinessLegalFormType;

    public attachments: Attachment[];

    constructor(ob?: Partial<AccountBusiness>) {

        this.type = undefined;
        this.legalForm = undefined;

        this.attachments = [];

        if (!ob) { return; }

        if (ob.type !== undefined) { this.type = ob.type; }
        if (ob.legalForm !== undefined) { this.legalForm = ob.legalForm; }

        if (ob.attachments !== undefined) { ob.attachments.forEach((o) => this.attachments.push(new Attachment(o))); }
    }
}
