import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import * as faker from 'faker';
import { DiscoveryService, NYM_DISCOVERY_SERVICE } from '@nymos/discovery';

const httpOptions = {
  headers: new HttpHeaders({
    "x-nymos-idempotency-key": faker.random.uuid(),
    // "X-Consumer-Username": 'tenant1',
    // "x-nymos-idempotency-key": "tenant1-1a15ad8f-4015-48d2-a59a-fe0237a0e094"
    // "apikey": "tenant1-1a15ad8f-4015-48d2-a59a-fe0237a0e094",
    // "Content-Type": "application/json"
  })
};

@Injectable({
  providedIn: 'root'
})
export class CallCenterService {
  callCenterSearch$: BehaviorSubject<string> = new BehaviorSubject('');

  private clearSearch = new BehaviorSubject<any>({});  
        clearSearchResult = this.clearSearch.asObservable();

  constructor(private http: HttpClient,@Inject(NYM_DISCOVERY_SERVICE) private  serviceDiscovery: DiscoveryService) { }

  searchCallCenterUser(mobileNumber: string): Observable<any> {
    // const searchHttpOptions = {
    //   headers: new HttpHeaders({
    //     "x-nymos-idempotency-key": faker.random.uuid(),
    //     // "X-Consumer-Username": 'tenant1',
    //     // "Apikey": 'tenant1-key'
    //   }),
    // };

    let searchHttpOptions = {
      headers: new HttpHeaders({
        "x-nymos-idempotency-key": faker.random.uuid(),
        "x-no-authorization": 'TRUE'
      }),
    };

    const encodedQueryParamValue = mobileNumber.replace("+", "%2B");
    const correctUrl = this.serviceDiscovery.getUrl() + "internal/v1/admins/users/multitenant:search?mobileNumber=" + encodedQueryParamValue;

    //working query param is ?mobileNumber=%2B9613803314
    return this.http.get<any>(
      correctUrl, searchHttpOptions
    )
      .pipe(catchError(this.handleError));
  }

  getCardLimitInfo(cardId: any, tenantId: any): Observable<any> {
    return this.http.get<any>(this.serviceDiscovery.getUrl() + `v1/admins/tenant/${tenantId}/cards/${cardId}:limitInfo:`, httpOptions);
    // return this.http.get<any>(this.serviceDiscovery.getUrl() + `v1/cards/${cardId}:limitInfo`, httpOptions)
  }

  changeCardStatus(cardId: string, tenantId: string, payload: any): Observable<any> {
    let httpOptionsWithNoOath = {
      headers: new HttpHeaders({
        "x-nymos-idempotency-key": faker.random.uuid(),
        "x-no-authorization": 'TRUE'
      }),
    };
    return this.http.put<any>(this.serviceDiscovery.getUrl() + `internal/v1/tenants/${tenantId}/cards/${cardId}:statuschange`, payload, httpOptionsWithNoOath);
  }

  resetPinRetries(cardId:string, data: any){
    return this.http
      .put<any>(this.serviceDiscovery.getUrl() + `v1/admins/cards/${cardId}:resetPinRetries`,
        data,
        httpOptions)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);

    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error);
  }

  setSearchResultQuery(query: string) {
    this.callCenterSearch$.next(query);
  }

  searchRecentTransactions(payload: any): Observable<any> {
    let httpOptionsWithNoOath = {
      headers: new HttpHeaders({
        "x-nymos-idempotency-key": faker.random.uuid(),
        "x-no-authorization": 'TRUE',
        "x-no-api-key": 'TRUE'
      }),
    };
    return this.http
      .post<any>(this.serviceDiscovery.getUrl() + 'internal/v1/transactions:search', payload, httpOptionsWithNoOath)
      .pipe(catchError(this.handleError));
  }

  getVelocityLimit(id: any): Observable<any> {
    return this.http.get<any>(this.serviceDiscovery.getUrl() + `v1/velocitylimits/${id}`, httpOptions);
  }

  getBalanceLimit(id: any): Observable<any> {
    return this.http.get<any>(this.serviceDiscovery.getUrl() + `v1/balancelimits/${id}`, httpOptions);
  }

  clearResult(event) {  
      this.clearSearch.next(event);  
  }

}
