import {
    CoreNote,
    NymcardCmsWalletTransactionDetails as WalletTransactionDetails,
    Problem,
} from '@michel.freiha/ng-sdk';


export class DryRunWalletFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Credit wallet (dry run)';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
    ) { }
}

export class DryRunWalletFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Credit wallet (dry run)';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
    ) { }
}

export class DryRunTransferBetweenWalletFromUserDetailsPage {
    public static readonly type: string = '[Agent Details Page] Transfer between wallet (dry run)';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public cId: string,
        public transaction: WalletTransactionDetails,
        public money:string,
    ) { }
}


export class DryRunDebitWalletFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Debit wallet (dry run)';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
    ) { }
}

export class DryRunDebitWalletFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Debit wallet (dry run)';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
    ) { }
}

export class CreditWalletFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Credit wallet';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}


export class TransferBetweenWalletFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Transfer between wallet';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public cId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}


export class TransferBetweenWalletAgentFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Transfer between wallet Agent';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public rId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}

export class TransferBetweenWalletAgentFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Transfer between wallet Agent';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public rId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}


export class DryRunTransferBetweenWalletAgentFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Transfer between wallet Agent (dry run)';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public rId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}

export class DryRunTransferBetweenWalletAgentFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Transfer between wallet Agent (dry run)';
    constructor(
        public aId: string,
        public wId: string,
        public tId: string,
        public rId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}

export class DebitWalletFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Debit wallet';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}

export class DebitWalletFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Debit wallet';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}
export class CreditWalletFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Credit wallet';
    constructor(
        public wId: string,
        public tId: string,
        public transaction: WalletTransactionDetails,
        public money: string,
    ) { }
}

export class LoadWalletActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load wallet activities';
    constructor(public id: string) { }
}

export class LoadWalletActivitiesFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Load wallet activities';
    constructor(public id: string) { }
}

export class RefreshWalletActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Refresh wallet activities';
    constructor(public id: string) { }
}

export class RefreshWalletActivitiesFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Refresh wallet activities';
    constructor(public id: string) { }
}

export class LoadMoreWalletActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load more wallets';
}

export class LoadMoreWalletActivitiesFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Load more wallets';
}

export class ApproveActivityFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Approve wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class ApproveActivityFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Approve wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class ApproveActivityFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Approve wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class RejectActivityFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Reject wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class RejectActivityFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Reject wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class RejectActivityFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Reject wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class RetryActivityFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Retry wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class RetryActivityFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Retry wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class RetryActivityFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Retry wallet activity';
    constructor(public wId: string, public tId: string) { }
}

export class CancelActivityFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Cancel wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class CancelActivityFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Cancel wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class CancelActivityFromWalletActivitiesPage {
    public static readonly type: string = '[Wallet Activities Page] Cancel wallet activity';
    constructor(public wId: string, public tId: string, public note: CoreNote) { }
}

export class FailFromApi {
    public static readonly type: string = '[Wallet Activities API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
