/* tslint:disable:member-ordering */

import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    produce,
} from 'immer';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    MobileVerification,
    NotificationsDevtoolsService,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    FailFromApi,
    FetchOtpFromDevtoolsPage,
} from './otps.actions';

import {
    Notifications,
} from './otps.notifications';


export interface OtpsStateModel {
    items: MobileVerification[];
    loading: boolean;
    problem: Problem;
}

const stateDefaults: OtpsStateModel = {
    items: [],
    loading: undefined,
    problem: undefined,
};

@State<OtpsStateModel>({
    name: 'otps',
    defaults: stateDefaults,
})
export class OtpsState {

    @Selector()
    public static otps(state: OtpsStateModel): MobileVerification[] {
        return state.items;
    }

    @Selector()
    public static loading(state: OtpsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static problem(state: OtpsStateModel): Problem {
        return state.problem;
    }

    constructor(
        private _nc: NotificationCenter,
        private _notificationsDevtoolsService: NotificationsDevtoolsService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<OtpsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<OtpsStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    @Action(FetchOtpFromDevtoolsPage)
    public fetchOtp(ctx: StateContext<OtpsStateModel>, { phoneNumber }: any): any {
        this._nc.show(Notifications.FetchingOtps);
        ctx.patchState({ loading: true });

        return this._notificationsDevtoolsService.loadOTPsNotExpired(phoneNumber).pipe(
            tap((otps: MobileVerification[]) => {
                ctx.setState(produce((draft) => {
                    draft.items = otps;
                }));
            }),
            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
            finalize(() => {
                ctx.patchState({ loading: false });
            }),
        );
    }
}
