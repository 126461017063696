import {
  Directive,
  ElementRef,
  Injectable,
  Optional,
} from '@angular/core';

import {
  BaseDirective2,
  MediaMarshaller,
  StyleBuilder,
  StyleDefinition,
  StyleUtils,
} from '@angular/flex-layout/core';

@Injectable({ providedIn: 'root' })
export class SizeStyleBuilder extends StyleBuilder {
  public buildStyles(input: string): StyleDefinition {

    const styles: StyleDefinition = {};

    if (!input)
      return styles;

    input = input.replace('px', '') + 'px';

    styles['height'] = input;
    styles['min-height'] = input;
    styles['width'] = input;
    styles['min-width'] = input;

    return styles;
  }
}

const inputs = [
  'size', 'size.xs', 'size.sm', 'size.md',
  'size.lg', 'size.xl', 'size.lt-sm', 'size.lt-md',
  'size.lt-lg', 'size.lt-xl', 'size.gt-xs', 'size.gt-sm',
  'size.gt-md', 'size.gt-lg',
];
const selector = `
  [size], [size.xs], [size.sm], [size.md],
  [size.lg], [size.xl], [size.lt-sm], [size.lt-md],
  [size.lt-lg], [size.lt-xl], [size.gt-xs], [size.gt-sm],
  [size.gt-md], [size.gt-lg]
`;


const sizeCache: Map<string, StyleDefinition> = new Map();

export class SizeBaseDirective extends BaseDirective2 {

  protected DIRECTIVE_KEY: string = 'size';

  protected styleCache: Map<string, StyleDefinition> = sizeCache;

  constructor(
    protected elRef: ElementRef,
    protected styleUtils: StyleUtils,
    @Optional() protected styleBuilder: SizeStyleBuilder,
    protected marshal: MediaMarshaller,
  ) {
    super(elRef, styleBuilder, styleUtils, marshal);
    this.init();
  }
}

@Directive({
  selector,
  inputs,  // tslint:disable-line:use-input-property-decorator
})
export class SizeDirective extends SizeBaseDirective {
  protected inputs: string[] = inputs;
}
