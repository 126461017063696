/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    throwError,
    EMPTY,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Admin,
    AdminIndex,
    AdminIndexCollection,
    AdminInternal,
    AdminProfile,
    AdminsInternalService,
    AdminsSearchService,
    CoreAccountStatus as AdminStatus,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    CreateAdminFromEditDialog,
    FailFromApi,
    LoadMoreAdminsFromListingPage,
    RefreshAdminFromEditDialog,
    ResetAdminPasscodeFromEditDialog,
    SearchAdminsFromListingPage,
    UpdateAdminFromEditDialog,
    UpdateAdminStatusFromEditDialog,
} from './admins.actions';

import {
    Notifications,
} from './admins.notifications';


export interface AdminsStateModel {
    items: { [id: string]: AdminInternal };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: AdminsStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<AdminsStateModel>({
    name: 'admins',
    defaults: stateDefaults,
})
export class AdminsState {

    @Selector()
    public static admins(state: AdminsStateModel): AdminInternal[] {
        return Object.keys(state.items).map(((id) => state.items[id]));
    }

    @Selector()
    public static problem(state: AdminsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: AdminsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static hasMore(state: AdminsStateModel): boolean {
        return !!(state.next && state.next.cursors && state.next.cursors.after);
    }

    @Selector()
    public static saving(state: AdminsStateModel): boolean {
        return state.saving;
    }

    public static admin(id: string): any {
        return createSelector([AdminsState], (state: AdminsStateModel) => {
            return state.items[id];
        });
    }


    constructor(
        private _nc: NotificationCenter,
        private _adminService: AdminsInternalService,
        private _searchService: AdminsSearchService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<AdminsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(RefreshAdminFromEditDialog)
    public load(ctx: StateContext<AdminsStateModel>, { payload: { id } }: RefreshAdminFromEditDialog): any {

        return this._adminService.loadAdmin(id).pipe(

            tap((admin: AdminInternal) => {
                ctx.setState(produce((draft) => {
                    draft.items[admin.id] = admin;
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }

    @Action(SearchAdminsFromListingPage)
    public search(ctx: StateContext<AdminsStateModel>, { payload }: SearchAdminsFromListingPage): any {

        const criteria = payload ? payload.criteria : new SearchCriteria();
        ctx.patchState({ loading: true });

        return this._searchService.search(criteria).pipe(

            tap((collection: AdminIndexCollection) => {
                criteria.cursors = collection.paging && collection.paging.cursors;
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    collection.data.forEach((index) => draft.items[index.id] = this._toAdminInternal(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ next: criteria, loading: false });
            }),
        );
    }

    @Action(LoadMoreAdminsFromListingPage)
    public loadMore(ctx: StateContext<AdminsStateModel>): any {

        const state = ctx.getState();
        const criteria = state.next;

        if (!criteria)
            return ctx.dispatch(new FailFromApi({ problem: undefined }));

        ctx.patchState({ loading: true });

        return this._searchService.search(criteria).pipe(
            tap((collection: AdminIndexCollection) => {
                criteria.cursors = collection.paging && collection.paging.cursors;
                ctx.setState(produce((draft) => {
                    collection.data.forEach((index) => draft.items[index.id] = this._toAdminInternal(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ next: criteria, loading: false });
            }),
        );
    }

    @Action(CreateAdminFromEditDialog)
    public create(ctx: StateContext<AdminsStateModel>, { payload: { data } }: CreateAdminFromEditDialog): any {

        this._nc.show(Notifications.Saving);
        ctx.patchState({ saving: true });

        return this._adminService.createAdmin(data).pipe(
            tap((admin: AdminInternal) => {
                this._nc.show(Notifications.Created);

                ctx.setState(produce((draft) => {
                    draft.items[admin.id] = admin;
                }));
            }),

            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return EMPTY;
            }),

            finalize(() => {
                ctx.patchState({ saving: false });
            }),
        );
    }

    @Action([UpdateAdminFromEditDialog, UpdateAdminStatusFromEditDialog], { cancelUncompleted: true })
    public update(ctx: StateContext<AdminsStateModel>, { payload: { id, data } }: UpdateAdminFromEditDialog): any {

        this._nc.show(Notifications.Saving);
        ctx.patchState({ saving: true });

        return this._adminService.updateAdmin(id, data).pipe(
            tap((admin: AdminInternal) => {
                this._nc.show(Notifications.Updated);

                ctx.setState(produce((draft) => {
                    draft.items[admin.id] = admin;
                }));
            }),

            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return EMPTY;
            }),

            finalize(() => {
                ctx.patchState({ saving: false });
            }),
        );
    }

    @Action(ResetAdminPasscodeFromEditDialog)
    public resetCredentials(ctx: StateContext<AdminsStateModel>, { payload: { id } }: ResetAdminPasscodeFromEditDialog): any {

        this._nc.show(Notifications.Resetting);

        return this._adminService.resetAdminCredentials(id).pipe(
            tap(() => {
                this._nc.show(Notifications.Reset);
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }


    @Action(FailFromApi)
    public fail(ctx: StateContext<AdminsStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    private _toAdminInternal(adminIndex: AdminIndex): AdminInternal {
        return new AdminInternal({
            id: adminIndex.id,
            admin: new Admin({
                id: adminIndex.id,
                profile: new AdminProfile({
                    firstName: adminIndex.firstName,
                    lastName: adminIndex.lastName,
                    mobile: adminIndex.verifiedMobile,
                    email: adminIndex.verifiedEmail,
                }),
                roles: adminIndex.roles ? adminIndex.roles.split('|') : [],
                status: <AdminStatus>adminIndex.status,
            }),
            verifiedMobile: adminIndex.verifiedMobile,
            verifiedEmail: adminIndex.verifiedEmail,
            created: new Date(adminIndex.registrationDate),
        });
    }
}
