import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  CreditcardComponent,
} from './containers/creditcard/creditcard.component';


export const MATERIAL_MODULES = [
  MatIconModule,
];

@NgModule({
  declarations: [
    CreditcardComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    CreditcardComponent,
  ],

})
export class ThemeComponentsCreditcardModule { }
