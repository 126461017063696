import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import {
    ThemePipesModule,
} from '../../../pipes/pipes.module';

import {
    ProgressBarComponent,
} from './containers/progress-bar/progress-bar.component';



export const MATERIAL_MODULES = [
    MatProgressBarModule,
];


@NgModule({
    declarations: [
        ProgressBarComponent,
    ],

    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        FlexLayoutModule,
        ThemePipesModule,
    ],

    exports: [
        ProgressBarComponent,
    ],
})
export class ThemeComponentsProgressBarModule { }
