/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    Country,
} from '../../models/country.model';

import {
    LoadAccountCountriesFromApi,
} from './accounts-countries.actions';


export interface AccountsCountriesStateModel {
    loading: boolean;
    countries: string[];
}

const stateDefaults: AccountsCountriesStateModel = {
    loading: undefined,
    countries: []
    };

@State<AccountsCountriesStateModel>({
    name: 'countries',
    defaults: stateDefaults,
})
export class AccountsCountriesState implements NgxsOnInit {

    @Selector()
    public static loading(state: AccountsCountriesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static countries(state: AccountsCountriesStateModel): string[] {
        return state.countries;
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsCountriesStateModel>): void {
        ctx.dispatch(new LoadAccountCountriesFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsCountriesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountCountriesFromApi)
    public load(ctx: StateContext<AccountsCountriesStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountCountries().pipe(
            tap((countries: Country[]) => {
                ctx.setState(produce((draft) => {
                    draft.countries = countries.map((p) => p.name);
                }));
            }),
            finalize(() => {
              ctx.patchState({ loading: false });
            }),
        );
    }
}

