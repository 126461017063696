
import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    HttpClientModule,
} from '@angular/common/http';

import {
    secureImageProvider,
} from './services/secure-image.provider';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],

    exports: [
    ],

    providers: [
        secureImageProvider,
    ],
})
export class ThemeCoreModule { }
