import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Account,
  AccountAddress,
  Attachment,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-agent-address-info',
  templateUrl: './agent-address-info.component.html',
  styleUrls: ['./agent-address-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentAddressInfoComponent implements OnInit {

  @HostBinding('class.nym-agent-address-info')
  protected get classes(): boolean { return true; }

  @Output()
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  protected get address(): AccountAddress { return this.account && this.account.address; }

  protected get attachments(): Attachment[] {
    return this.account && this.account.address && this.account.address.attachments || [];
  }

  protected get urls(): string[] {
    return this.attachments.map((a) => a.url) || [];
  }

  constructor() { }

  public ngOnInit(): void { }

  protected viewAttachments$$(index: number): void {
    const attachments = this.attachments;
    this.viewAttachments.emit({ attachments, index });
  }
}
