// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    StatusMenu,
} from '../../lib/core/models/status.model';

class Factory extends ModelFactory<StatusMenu> {
    constructor() {
        super(null, (m: StatusMenu) => m.name);
    }
}


@Injectable({
    providedIn: 'root',
})
export class ChannelStatusMenuDatastore extends MemoryBackend<StatusMenu> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): StatusMenu {
        return this.doLoad(id);
    }

    public all(): StatusMenu[] {
        return this.values();
    }

    protected afterInit(): any {
        this.doCreate(new StatusMenu({
            name: 'active',
            color: 'green',
            final: true,
            next: {
                'blocked': 'blocked',
            },
        }));
        this.doCreate(new StatusMenu({
            name: 'verified',
            color: 'blue',
            final: true,
            next: {
                'blocked': 'blocked',
            },
            scopes: ['p:accounts:approvereject'],
        }));
        this.doCreate(new StatusMenu({
            name: 'pending',
            color: 'yellow',
            final: true,
            next: {
                'verified': 'verified',
                'rejected': 'rejected',
                'blocked': 'blocked',
            },
        }));
        this.doCreate(new StatusMenu({
            name: 'incomplete',
            color: 'purple',
            final: true,
            next: {
                'blocked': 'blocked',
            },
        }));
        this.doCreate(new StatusMenu({
            name: 'rejected',
            color: 'red',
            final: true,
            next: {
                'blocked': 'blocked',
            },
            scopes: ['p:accounts:approvereject'],
        }));
        this.doCreate(new StatusMenu({
            name: 'blocked',
            color: 'menu',
            final: true,
            next: {
                'unblocked': 'unblocked',
            },
            scopes: ['p:accounts:blockunblock'],
        }));
        this.doCreate(new StatusMenu({
            name: 'unblocked',
            color: 'primary',
            scopes: ['p:accounts:blockunblock'],
        }));
        this.doCreate(new StatusMenu({
            name: 'new agent',
            color: 'black',
            next: {
                'pending': 'pending',
            },
            scopes: ['p:accounts:write'],
        }));
    }
}
