import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import {
  NymcardAccountLimitDecimals,
  NymcardAccountLimitUnits,
} from '@michel.freiha/ng-sdk';


@Pipe({
  name: 'added',
})
export class LimitAddedPipe implements PipeTransform {

  public transform(value: NymcardAccountLimitDecimals | NymcardAccountLimitUnits): number | string {
    if (!value)
      return '';

    return value.used + value.reserved;
  }

}
