import {
    Problem,
} from '@michel.freiha/ng-sdk';

export type FlowStep =
    | 'personal'
    | 'documents'
    | 'address'
    | 'business'
    | 'submit'
    | undefined;

export class AgentKycFlow {

    public current: FlowStep;
    public personal: { completed: boolean };
    public documents: { completed: boolean };
    public address: { completed: boolean };
    public business: { completed: boolean };

    constructor(ob?: Partial<AgentKycFlow>) {

        this.current = undefined;
        this.personal = { completed: false };
        this.documents = { completed: false };
        this.address = { completed: false };
        this.business = { completed: false };

        if (!ob) { return; }

        if (ob.current !== undefined) { this.current = ob.current; }
        if (ob.personal !== undefined) { this.personal = { completed: ob.personal.completed }; }
        if (ob.documents !== undefined) { this.documents = { completed: ob.documents.completed }; }
        if (ob.address !== undefined) { this.address = { completed: ob.address.completed }; }
        if (ob.business !== undefined) { this.business = { completed: ob.business.completed }; }

    }
}
