/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    CityNaming,
} from '../../models/city-naming.model';

import {
    LoadAccountsCitiesNamingsFromApi,
} from './accounts-cities-namings.actions';


export interface AccountsCitiesNamingsStateModel {
    loading: boolean;
    accountsCitiesNamings: string[];
}

const stateDefaults: AccountsCitiesNamingsStateModel = {
    loading: undefined,
    accountsCitiesNamings: []
};

@State<AccountsCitiesNamingsStateModel>({
    name: 'accountscitiesNamings',
    defaults: stateDefaults,
})
export class AccountsCitiesNamingsState implements NgxsOnInit {

    @Selector()
    public static loading(state: AccountsCitiesNamingsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static AccountsCitiesNamings(state: AccountsCitiesNamingsStateModel): string[] {
        return state.accountsCitiesNamings ;
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsCitiesNamingsStateModel>): void {
        ctx.dispatch(new LoadAccountsCitiesNamingsFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsCitiesNamingsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountsCitiesNamingsFromApi)
    public load(ctx: StateContext<AccountsCitiesNamingsStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountsCitiesNamings ().pipe(
            tap((AccountsCitiesNamings: CityNaming[]) => {
                ctx.setState(produce((draft) => {
                    draft.accountsCitiesNamings  = AccountsCitiesNamings.map((p) => p);
                }));
            }),
            finalize(() => {
              ctx.patchState({ loading: false });
            }),
        );
    }
}

