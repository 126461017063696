import {
    UserAccountVerification as Verification,
} from '@michel.freiha/ng-sdk';

export class AccountKyc {

    // public status: Status;
    public verifications: Verification[];

    public get verification(): Verification {
        return this.verifications && this.verifications[0];
    }

    constructor(ob?: Partial<AccountKyc>) {

        // this.status = undefined;
        this.verifications = [];

        if (!ob) { return; }

        // if (ob.status) { this.status = ob.status; }
        if (ob.verifications !== undefined) { ob.verifications.forEach((o) => this.verifications.push(new Verification(o))); }

    }

}

