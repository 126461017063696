// tslint:disable:variable-name

export * from './ui/components/shimmer/models/color.model';
export * from './ui/components/menu/models/menu.model';
export * from './ui/components/menu/models/menu-item.model';
export * from './ui/components/menu/models/menu-group.model';
export * from './ui/components/profile/models/profile.model';
export * from './ui/layouts/app-layout/models/nav-mode.model';

import { MockMenu } from './ui/components/menu/models/menu.model.mock';
import { MockMenuItem } from './ui/components/menu/models/menu-item.model.mock';
import { MockMenuGroup } from './ui/components/menu/models/menu-group.model.mock';
import { MockProfile } from './ui/components/profile/models/profile.model.mock';

export namespace Mock {
    export const Menu = MockMenu;
    export const MenuItem = MockMenuItem;
    export const MenuGroup = MockMenuGroup;
    export const Profile = MockProfile;
}
