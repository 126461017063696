import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  AgentProfile,
} from '@michel.freiha/ng-sdk';

import {
  Account,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-agent-personal-info',
  templateUrl: './agent-personal-info.component.html',
  styleUrls: ['./agent-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentPersonalInfoComponent implements OnInit {

  @HostBinding('class.nym-agent-personal-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  protected get profile(): AgentProfile { return this.account && this.account.profile; }

  constructor() { }

  public ngOnInit(): void { }

}
