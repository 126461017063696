import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatSelectModule,
} from '@angular/material/select';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  StoreModule,
} from './core/store/store.module';

import {
  NoteInfoComponent,
} from './ui/components/note-info/note-info.component';

import {
  NoteListingComponent,
} from './ui/components/note-listing/note-listing.component';

import {
  NoteConfirmComponent,
} from './ui/components/note-confirm/note-confirm.component';

import {
  RefundConfirmComponent,
} from './ui/components/refund-confirm/refund-confirm.component';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
];

@NgModule({
  imports: [
    StoreModule,
  ],
})
export class NymNotesRootModule {
  constructor(
    @Optional() @SkipSelf() parentModule: NymNotesRootModule,
  ) {
    if (parentModule) throw new Error('NymNotesRootModule is already loaded. Import it in the CoreModule only');
  }
}

@NgModule({
  declarations: [
    NoteInfoComponent,
    NoteListingComponent,
    NoteConfirmComponent,
    RefundConfirmComponent
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    StoreModule,
  ],

  exports: [
    NoteInfoComponent,
    NoteListingComponent,
    NoteConfirmComponent,
    RefundConfirmComponent
  ],

  entryComponents: [
    NoteConfirmComponent,
    RefundConfirmComponent
  ],
})
export class NymNotesModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NymNotesModule,
    };
  }
}
