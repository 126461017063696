export type Status =
    | 'pending'
    | 'completed'
    | 'failed'
    | undefined;

export type Color =
    | 'primary'
    | 'green'
    | 'yellow'
    | 'red'
    | undefined;


export class BulkIssuance {

    public id: string;
    public token: string;
    public created: Date;
    public modified: Date;
    public status: { color: Color, value: Status };
    public type: string;
    public numberOfCards: number;
    public currency: string;
    public amount: number;

    constructor(ob?: Partial<BulkIssuance>) {

        this.id = undefined;
        this.token = undefined;
        this.created = undefined;
        this.modified = undefined;
        this.status = undefined;
        this.type = undefined;
        this.numberOfCards = undefined;
        this.currency = undefined;
        this.amount = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.token !== undefined) { this.token = ob.token; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.status !== undefined) { this.status = ob.status; }
        if (ob.type !== undefined) { this.type = ob.type; }
        if (ob.numberOfCards !== undefined) { this.numberOfCards = ob.numberOfCards; }
        if (ob.currency !== undefined) { this.currency = ob.currency; }
        if (ob.amount !== undefined) { this.amount = ob.amount; }



    }
}

