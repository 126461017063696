import {
  NgModule,
} from '@angular/core';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatTableModule,
} from '@angular/material/table';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  DevtoolsCoreModule,
} from '@nymos/devtools/core';

import {
  DevtoolsRoutingModule,
} from './devtools.routing';

import {
  ThrottlingState,
} from './store/throttling/throttling.state';

import {
  OtpsState,
} from './store/otps/otps.state';

// COMPONENTS

import {
  ClearThrottlingComponent,
} from './ui/components/clear-throttling/clear-throttling.component';

import {
  HomepageComponent,
} from './ui/containers/homepage/homepage.component';

import {
  OtpListingComponent,
} from './ui/components/otp-listing/otp-listing.component';

import {
  OtpListingNoResultsComponent,
} from './ui/components/otp-listing-no-results/otp-listing-no-results.component';

import {
  OtpListingTableComponent,
} from './ui/components/otp-listing-table/otp-listing-table.component';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTableModule,
];

@NgModule({
  imports: [
    ...MATERIAL_MODULES,
    SharedModule,
    ReactiveFormsModule,

    DevtoolsCoreModule,
    DevtoolsRoutingModule,
    NgxsModule.forFeature([
      OtpsState,
      ThrottlingState,
    ]),
  ],
  declarations: [
    ClearThrottlingComponent,
    HomepageComponent,
    OtpListingComponent,
    OtpListingNoResultsComponent,
    OtpListingTableComponent,
  ],
})
export class DevtoolsModule {}
