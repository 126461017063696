// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    StatusMenu,
} from '../../lib/core/models/status.model';


class Factory extends ModelFactory<StatusMenu> {
    constructor() {
        super(null, (m: StatusMenu) => m.name);
    }
}


@Injectable({
    providedIn: 'root',
})
export class NymcardStatusMenuDatastore extends MemoryBackend<StatusMenu> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): StatusMenu {
        return this.doLoad(id);
    }

    public all(): StatusMenu[] {
        return this.values();
    }

    protected afterInit(): any {
        this.doCreate(new StatusMenu({
            name: 'active',
            color: 'green',
            next: {
                'suspend': 'suspended',
                'terminate': 'terminated',
            },
            scopes: ['p:cards:lifecycle'],
        }));
        this.doCreate(new StatusMenu({
            name: 'inactive',
            color: 'red',
            next: {
                'activate': 'active',
                'terminate': 'terminated',
            },
            scopes: ['p:cards:lifecycle'],
        }));
        this.doCreate(new StatusMenu({
            name: 'suspended',
            color: 'yellow',
            next: {
                'resume': 'active',
                'terminate': 'terminated',
            },
            scopes: ['p:cards:lifecycle'],
        }));
        this.doCreate(new StatusMenu({
            name: 'terminated',
            color: 'menu',
            scopes: ['p:cards:lifecycle'],
        }));
    }
}
