import {
  Component,
  OnInit,
} from '@angular/core';

import {
  ThemeAssets,
} from '@nymos/theme';

import {
  ProblemsAssets,
} from '@nymos/problems';

import {
  AdminsAssets,
} from '@nymos/dashboard/front-end/admins';

import {
  AccountsAssets,
} from '@nymos/dashboard/front-end/accounts';

import {
  BulkcardsAssets,
} from '@nymos/dashboard/front-end/bulkcards';

import {
  DevtoolsAssets,
} from '@nymos/dashboard/front-end/devtools';

import {
  TokensAssets,
} from '@nymos/dashboard/front-end/tokens';

import {
  ConsoleAssets,
} from '../../../console/console.assets';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    themeAssets: ThemeAssets,
    adminsAssets: AdminsAssets,
    accountsAssets: AccountsAssets,
    bulkcardsAssets: BulkcardsAssets,
    tokensAssets: TokensAssets,
    devtoolsAssets: DevtoolsAssets,
    problemsAssets: ProblemsAssets,
    consoleAssets: ConsoleAssets,
  ) { }

  public ngOnInit(): void { }
}
