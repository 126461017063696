// tslint:disable:component-class-suffix

import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {
  ErrorStateMatcher,
} from '@angular/material/core';

import {
  MatStep,
} from '@angular/material/stepper';

import {
  CdkStep,
} from '@angular/cdk/stepper';

@Component({
  moduleId: module.id,
  exportAs: 'kycStep',
  selector: 'thm-kyc-step',
  templateUrl: './kyc-step.component.html',
  styleUrls: ['./kyc-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: ErrorStateMatcher, useExisting: KycStep },
    { provide: MatStep, useExisting: KycStep },
    { provide: CdkStep, useExisting: KycStep },
  ],
})
export class KycStep extends MatStep {

}
