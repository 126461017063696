import {
    Injectable,
} from '@angular/core';

import {
    CanActivate,
} from '@angular/router';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    AuthState,
} from '../store/auth/auth.state';


@Injectable()
export class SessionGuard implements CanActivate {
    constructor(
        private _store: Store,
    ) {  }

    public canActivate(): boolean {
        const state = this._store.selectSnapshot(AuthState);
        const isAuthing = (!!state.credentials && !!state.session);

        if (!isAuthing) {
            this._store.dispatch(new Navigate(['neo/account/signin']));
        }

        return isAuthing;
    }
}
