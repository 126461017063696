import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  RouterModule,
} from '@angular/router';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatRippleModule,
} from '@angular/material/core';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatMenuModule,
} from '@angular/material/menu';

import {
  KycStepper,
} from './views/kyc-stepper/kyc-stepper.component';

import {
  KycStep,
} from './views/kyc-step/kyc-step.component';

import {
  KycStepHeader,
} from './views/kyc-step-header/kyc-step-header.component';

import {
  KycStepLabel,
} from './views/kyc-step-label/kyc-step-label.directive';
import { MatStepperModule } from '@angular/material/stepper';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatRippleModule,
  MatStepperModule,
];

@NgModule({

  declarations: [
    KycStep,
    KycStepHeader,
    KycStepLabel,
    KycStepper,
  ],

  imports: [
    ...MATERIAL_MODULES,
    FlexLayoutModule,
    RouterModule,
    CommonModule,
  ],

  exports: [
    KycStep,
    KycStepLabel,
    KycStepper,
  ],

})
export class ThemeComponentsKycModule { }
