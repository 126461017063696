import {
  Injectable,
} from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';

import {
  Observable,
  of,
} from 'rxjs';

import {
  switchMap,
} from 'rxjs/operators';

import {
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  AccountsAgentsState,
  AccountsState,
  GetAccountFromAgentExistsGuard,
} from '@nymos/accounts/core';


@Injectable({
  providedIn: 'root',
})
export class AgentExistsGuard implements CanActivate {

  constructor(
    private _store: Store,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const id = route.params.id;
    const account = this._store.selectSnapshot(AccountsState.account(id));
    const agent = this._store.selectSnapshot(AccountsAgentsState.account(id));

    if (!!account || !!agent)
      return true;

    return this._store.dispatch(new GetAccountFromAgentExistsGuard(id)).pipe(
      switchMap(() => of(this.hasUser(id))),
    );
  }

  public hasUser(id: string): boolean {
    const agent = this._store.selectSnapshot(AccountsAgentsState.account(id));

    if (!agent)
      this._store.dispatch(new Navigate(['/']));

    return !!agent;
  }
}
