import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select,
  Store
} from '@ngxs/store';

import {
  CardActivity,
} from '@nymos/accounts/core';

import {
  CardActivitiesState,
} from '../../../core/store/card-activities/card-activities.state';

import {
  LoadMoreCardActivitiesFromCardDetailsPage,
} from '../../../core/store/card-activities/card-activities.actions';


@Component({
  selector: 'nym-user-card-activity',
  templateUrl: './user-card-activity.component.html',
  styleUrls: ['./user-card-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardActivityComponent implements OnInit {

  @HostBinding('class.nym-user-card-activity')
  protected get classes(): boolean { return true; }

  @Select(CardActivitiesState.userActivities)
  public activities$: Observable<CardActivity[]>;

  @Select(CardActivitiesState.loading)
  public loading$: Observable<boolean>;

  @Select(CardActivitiesState.hasMore)
  public hasMore$: Observable<boolean>;

  constructor(protected _store: Store) { }

  public ngOnInit(): void { }

  protected loadMore(): void {
    this._store.dispatch(new LoadMoreCardActivitiesFromCardDetailsPage());
  }
}
