import {
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  NgxJsonViewerModule,
} from 'ngx-json-viewer';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  ThemeModule,
} from '@nymos/theme';

import {
  ProblemsRoutingModule,
} from './problems.routing';

import {
  ProblemsState,
} from './store/problems.state';

import {
  NymosErrorHandler,
} from './handlers/error.handler';

import {
  ErrorComponent,
} from './ui/containers/error/error.component';



export const MATERIAL_MODULES = [
  MatIconModule,
];


@NgModule({
  imports: [
    NgxsModule.forFeature([ProblemsState]),
  ],
})
export class NymProblemsRootModule {
  constructor(
    @Optional() @SkipSelf() parentModule: NymProblemsRootModule,
  ) {
    if (parentModule) throw new Error('NymProblemsRootModule is already loaded. Import it in the AppModule only');
  }
}

@NgModule({
  declarations: [
    ErrorComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    NgxJsonViewerModule,
    ThemeModule,

    ProblemsRoutingModule,
  ],
})
export class NymProblemsModule {
  constructor(
    @Optional() @SkipSelf() parentModule: NymProblemsModule,
  ) {
    if (parentModule) {
      throw new Error(
        'NymProblemsModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NymProblemsRootModule,
      providers: [
        { provide: ErrorHandler, useClass: NymosErrorHandler },
      ],
    };
  }
}

