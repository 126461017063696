import * as faker from 'faker';

import {
    Card,
    Network,
} from '@michel.freiha/ng-sdk';

import {
    ModelFactory,
} from '@nymos/backend';


export class FakerCard extends ModelFactory<Card> {

    constructor() {
        super(FakerCard.create, (m: Card) => m.id);
    }

    public static create(id?: string): Card {

        const index = id ? id : 'fake##' + faker.random.uuid();

        const last4 = faker.phone.phoneNumber('####');

        const network = faker.random.arrayElement([
            Network.Mastercard,
            Network.Visa,
        ]);

        const card = new Card({
            'id': index,
            'last4': last4,
            'network': network,
        });

        return card;
    }
}
