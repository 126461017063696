import * as faker from 'faker';

import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl, FormBuilder, FormGroup, Validators,
} from '@angular/forms';

import {
  CustomValidators,
} from 'ngx-custom-validators';

import {
  Actions,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';

import {
  NymcardCardCreateBulkDetails as BulkDetails,
} from '@michel.freiha/ng-sdk';

import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  IssueFromHomePage,
} from '@nymos/bulkcards/core';

@Component({
  selector: 'nym-bulk-issuance',
  templateUrl: './bulk-issuance.component.html',
  styleUrls: ['./bulk-issuance.component.scss'],
})
export class BulkIssuanceComponent extends ModalComponent implements OnInit, OnDestroy {

  private _id: string;

  @HostBinding('class.nym-bulk-issuance')
  protected get classes(): boolean { return true; }

  protected form: FormGroup;

  public get type(): AbstractControl { return this.form.get('type'); }
  public get quantity(): AbstractControl { return this.form.get('quantity'); }
  public get currency(): AbstractControl { return this.form.get('currency'); }
  public get amount(): AbstractControl { return this.form.get('amount'); }

  constructor(
    fb: FormBuilder,
    ref: DialogRef<any, any>,
    cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DIALOG_DATA) data: any,
    protected _store: Store,
    protected _actions$: Actions,
  ) {
    super(fb, ref, cd, locale, data);

    this._id = faker.random.uuid();
    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(IssueFromHomePage)).subscribe(() => this._ref.close());
  }

  // @Override
  protected initForm(data: any): void {

    this.form = this._fb.group({

      type: [{ value: '005', disabled: true }, [
        Validators.required,
      ]],
      quantity: ['', [
        Validators.required,
        CustomValidators.gt(0),
        CustomValidators.lte(100),
      ]],
      currency: [{ value: 'usd', disabled: true }, [
        Validators.required,
      ]],
      amount: ['', [
        Validators.required,
        CustomValidators.gt(0),
      ]],
    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
      return;

    const details = new BulkDetails({
      token: this._id,
      cardProductId: this.type.value,
      numberOfCards: +this.quantity.value,
      currency: this.currency.value,
      amount: +this.amount.value,
    });

    this._store.dispatch(new IssueFromHomePage(details));
  }

}
