import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import {
    ThemeComponentsShimmerModule,
} from '../shimmer/shimmer.module';

import {
    LozengeComponent,
} from './containers/lozenge/lozenge.component';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        LozengeComponent,
    ],

    imports: [
        ...MATERIAL_MODULES,
        FlexLayoutModule,
        CommonModule,
        ThemeComponentsShimmerModule,
    ],

    exports: [
        LozengeComponent,
    ],

})
export class ThemeComponentsLozengeModule { }
