import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardActivityColor',
})
export class CardActivityColorPipe implements PipeTransform {

  public transform(status: string): string {

    switch (status) {
      case 'settled':
        return 'green';
      case 'declined':
        return 'red';
      case 'authorized':
        return 'yellow';
      case 'cancelled':
      default:
        return 'menu';

    }
  }

}
