import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'into' })
export class StringIntoPipe implements PipeTransform {

    public transform(input: string | string[], container: string | string[]): any {

        if (!Array.isArray(input))
            input = [input];

        if (!Array.isArray(container))
            container = [container];

        return container.find((v) => input.indexOf(v) >= 0);
    }
}
