import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'header, thm-header, .thm-header, [thm-header], [thmHeader]',
})
export class HeaderDirective {

  @HostBinding('class.thm-header')
  protected get classes(): boolean { return true; }

  constructor() { }

}
