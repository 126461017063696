import {
  Injectable,
} from '@angular/core';

import {
  MatStepperIntl,
} from '@angular/material/stepper';

@Injectable({
  providedIn: 'root',
})
export class KycStepperIntl extends MatStepperIntl {
  public completedLabel: string = 'Completed';
}
