import {
    Injectable,
} from '@angular/core';

import {
    Store,
} from '@ngxs/store';

import {
    hasScopes,hasAllScopes,
} from '../utils/function.util';

import {
    ProfileState,
} from '../store/profile/profile.state';


@Injectable()
export class ScopesChecker {

    constructor(
        private _store: Store,
    ) { }

    public isAuthedOnly(scopes: string | string[]): boolean {
        const profile = this._store.selectSnapshot(ProfileState.scopes);
        const authed = hasScopes(profile, scopes);
        return authed;
    }

    public isAuthedAll(scopes: string | string[]): boolean {
        const profile = this._store.selectSnapshot(ProfileState.scopes);
        const authed = hasAllScopes(profile, scopes);
        return authed;
    }

    public isAuthedExcept(scopes: string | string[]): boolean {
        const profile = this._store.selectSnapshot(ProfileState.scopes);
        const authed = !hasScopes(profile, scopes);
        return authed;
    }

}
