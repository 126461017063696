// tslint:disable:directive-selector

import {
  Directive,
} from '@angular/core';

import {
  MatStepLabel,
} from '@angular/material/stepper';

@Directive({
  selector: '[kycStepLabel]',
  providers: [
    { provide: MatStepLabel, useExisting: KycStepLabel },
  ],
})
export class KycStepLabel extends MatStepLabel { }
