import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardStatusIcon',
})
export class CardStatusIconPipe implements PipeTransform {

  public transform(status: string): string {

    switch (status) {
      case 'active':
        return 'check_circle_outline';
      case 'inactive':
        return 'remove_circle_outline';
      case 'suspended':
        return 'access_time';
      case 'terminated':
        return 'highlight_off';
      default:
        return 'not-known';
    }
  }

}


