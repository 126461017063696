import {
    ErrorHandler,
    Inject,
    Injectable,
    Injector,
} from '@angular/core';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';
import { AddProblemFromErrorHandler } from '../store/problems.actions';
import { Problem } from '@michel.freiha/ng-sdk';

@Injectable()
export class NymosErrorHandler extends ErrorHandler {

    private _storeCache: Store;

    constructor(
        @Inject(Injector) private _injector: Injector,
    ) {
        super();
    }

    private get _store(): Store {
        if (!this._storeCache)
            this._storeCache = this._injector.get(Store);
        return this._storeCache;
    }

    public handleError(error: any): void {

        if (!error || !error.type)
            return super.handleError(error);

        if (this._store) {
            this._store.dispatch([
                new AddProblemFromErrorHandler(error),
                new Navigate(['/error']),
            ]);
        }

        super.handleError(error);
    }

}
