import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Profile,
} from '../../models/profile.model';


@Component({
  selector: 'thm-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {

  @HostBinding('class.thm-profile')
  protected get classes(): boolean { return true; }

  @Input()
  public profile: Profile;

  @Output('action') // tslint:disable-line:no-output-rename
  public actionEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void { }

  public onAction(action: any): void {
    this.actionEmitter.emit(action);
  }
}
