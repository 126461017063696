import {
  NgModule,
} from '@angular/core';

import {
  MatSnackBarModule,
} from '@angular/material/snack-bar';

import {
  creditCardProvider,
} from './credit-card.provider';

export const MATERIAL_MODULES = [
  MatSnackBarModule,
];


@NgModule({
  imports: [MATERIAL_MODULES],

  declarations: [],

  exports: [],

  providers: [
    creditCardProvider,
  ],
})
export class CreditCardModule { }
