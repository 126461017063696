import {
    AdminRole,
    Problem,
} from '@michel.freiha/ng-sdk';


export class LoadRolesFromListingPage {
    public static readonly type: string = '[Roles Listing Page] Load roles';
    constructor() { }
}

export class RefreshRolesFromEditDialog {
    public static readonly type: string = '[Roles Listing Page] Refresh roles';
    constructor() { }
}

export class CreateRoleFromEditDialog {
    public static readonly type: string = '[Role Edit Dialog] Create new role';
    constructor(public payload: { data: AdminRole }) { }
}

export class UpdateRoleFromEditDialog {
    public static readonly type: string = '[Role Edit Dialog] Update existing role';
    constructor(public payload: { id: string; data: AdminRole }) { }
}

export class FailFromApi {
    public static readonly type: string = '[Role API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
