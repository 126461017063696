
export class Country {
    public name: string;

    constructor(ob?: Partial<Country>) {
        this.name = undefined;
        if (!ob) { return; }
        if (ob.name !== undefined) { this.name = ob.name; }
    }
}
