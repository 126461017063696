import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    throwError,
    EMPTY,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    AdminRoleInternal,
    AdminRoleInternalCollection,
    AdminsInternalService,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    CreateRoleFromEditDialog,
    FailFromApi,
    LoadRolesFromListingPage,
    RefreshRolesFromEditDialog,
    UpdateRoleFromEditDialog,
} from './roles.actions';

import {
    RolesSelectedState,
    RolesSelectedStateModel,
} from '../roles-selected/roles-selected.state';

import {
    Notifications,
} from './roles.notifications';


export interface RolesStateModel {
    items: { [id: string]: AdminRoleInternal };
    loading: boolean;
    saving: boolean;
    problem: Problem;
}

const stateDefaults: RolesStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
};

@State<RolesStateModel>({
    name: 'roles',
    defaults: stateDefaults,
    children: [RolesSelectedState],
})
export class RolesState {

    constructor(
        private _nc: NotificationCenter,
        private _adminService: AdminsInternalService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<RolesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(LoadRolesFromListingPage)
    public load(ctx: StateContext<RolesStateModel>): any {

        this._nc.show(Notifications.Loading);
        ctx.patchState({ loading: true });

        return this._adminService.loadAdminRoles().pipe(
            tap((collection: AdminRoleInternalCollection) => {
                this._nc.dismiss();

                const items = collection.data.reduce<any>((map, r) => { map[r.id] = r; return map; }, {});
                ctx.patchState({ items: items });
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ loading: false });
            }),
        );
    }

    @Action(RefreshRolesFromEditDialog)
    public refresh(ctx: StateContext<RolesStateModel>): any {

        return this._adminService.loadAdminRoles().pipe(
            tap((collection: AdminRoleInternalCollection) => {
                this._nc.dismiss();

                const items = collection.data.reduce<any>((map, r) => { map[r.id] = r; return map; }, {});
                ctx.patchState({ items: items });
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }

    @Action(CreateRoleFromEditDialog)
    public create(ctx: StateContext<RolesStateModel>, { payload: { data } }: CreateRoleFromEditDialog): any {

        this._nc.show(Notifications.Saving);
        ctx.patchState({ saving: true });

        return this._adminService.createAdminRole(data).pipe(
            tap((role: AdminRoleInternal) => {
                this._nc.show(Notifications.Created(role.role.name));

                const state = ctx.getState();
                ctx.patchState({ items: { ...state.items, [role.id]: role } });
            }),

            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return EMPTY;
            }),

            finalize(() => {
                ctx.patchState({ saving: false });
            }),
        );
    }

    @Action(UpdateRoleFromEditDialog, { cancelUncompleted: true })
    public update(ctx: StateContext<RolesStateModel>, { payload: { id, data } }: UpdateRoleFromEditDialog): any {

        this._nc.show(Notifications.Saving);
        ctx.patchState({ saving: true });

        return this._adminService.updateAdminRole(id, data).pipe(
            tap((role: AdminRoleInternal) => {
                this._nc.show(Notifications.Updated(role.role.name));

                const state = ctx.getState();
                ctx.patchState({ items: { ...state.items, [role.id]: role } });
            }),

            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return EMPTY;
            }),

            finalize(() => {
                ctx.patchState({ saving: false });
            }),
        );
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<RolesStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    @Selector()
    public static roles(state: RolesStateModel): AdminRoleInternal[] {
        return Object.keys(state.items).map(((id) => state.items[id]));
    }

    @Selector([RolesSelectedState])
    public static selected(state: RolesStateModel, selectedState: RolesSelectedStateModel): AdminRoleInternal[] {
        return selectedState.items.map(((id) => state.items[id]));
    }

    @Selector()
    public static problem(state: RolesStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: RolesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static saving(state: RolesStateModel): boolean {
        return state.saving;
    }
}
