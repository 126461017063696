import { MenuItem } from './menu-item.model';

export class MenuGroup extends MenuItem {

    public items: MenuItem[];
    public readonly type: string = 'group';

    constructor(ob?: Partial<MenuGroup>) {
        super(ob);

        this.items = [];

        if (!ob)
            return;

        if (ob.title !== undefined) { this.title = ob.title; }
        if (ob.items !== undefined) { ob.items.forEach((o) => this.items.push(new MenuItem(o))); }
    }
}
