import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AdminSession,
  Credentials,
  Oauth2AccessToken} from '@michel.freiha/ng-sdk';

import { DiscoveryService, NYM_DISCOVERY_SERVICE } from '@nymos/discovery';

const httpOptions = {
  headers: new HttpHeaders({
    apikey: window.localStorage.getItem('apikey') //'dash-tenant1',
  })
};

const httpOptions1 = {
  headers: new HttpHeaders({
    apikey: window.localStorage.getItem('apikey'),
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SigninService {
  constructor(
    private http: HttpClient,
    @Inject(NYM_DISCOVERY_SERVICE) private serviceDiscovery: DiscoveryService
  ) {
    console.log(serviceDiscovery.getUrl());
    let apikey = window.localStorage.getItem('apikey');
    setTimeout(() => {
      apikey = window.localStorage.getItem('apikey');
    }, 2000);
    console.log(apikey);
  }

  initiateAuthFlow(credentials: Credentials): Observable<any> {
    return this.http
      .post<any>(
        this.serviceDiscovery.getUrl() + 'v1/admins/sessions',
        credentials,
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          response => Object.assign(new AdminSession(), response);
          response.customToken = response.custom_token;
          return response;
        }),

        catchError(this.handleError)
      );
  }

  continueAuthWithMobileVerification(id: any, code: any): Observable<any> {
    return this.http
      .put<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${id}:continueWithMobileVerification?code=${code}`,
        '',
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          response => Object.assign(new AdminSession(), response);
          response.customToken = response.custom_token;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  signinWithCustomToken(
    token: any,
    provider: any
  ): Observable<Oauth2AccessToken> {
    return this.http
      .post<any>(
        this.serviceDiscovery.getUrl() +
          `v1/oauth2/token:signinWithCustomToken?custom_token=${token}&custom_token_provider=${provider}`,
        '',
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey'),
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        }
      )
      .pipe(
        map(response => {
          response => Object.assign(new Oauth2AccessToken(), response);
          response.accessToken = response.access_token;
          response.accountId = response.account_id;
          response.expiresIn = response.expires_in;
          response.refreshToken = response.refresh_token;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  continueAuthWithPasswordChange(
    id: any,
    passwordChange: any
  ): Observable<any> {
    passwordChange.old_password = passwordChange.oldPassword;
    passwordChange.new_password = passwordChange.newPassword;

    return this.http
      .put<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${id}:continueWithPasswordChange`,
        passwordChange,
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          response => Object.assign(new AdminSession(), response);
          response.customToken = response.custom_token;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  passwordChangeByLink(id: any, passwordChange: any): Observable<any> {
    // passwordChange.old_password = passwordChange.oldPassword;
    passwordChange.password = passwordChange.newPassword;
    return this.http
      .post<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${id}:changePassword`,
        passwordChange,
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          response => Object.assign(new AdminSession(), response);
          response.customToken = response.custom_token;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  resendMobileOtp(id: any): Observable<any> {
    console.log(id);
    return this.http
      .put<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${id.id}:resendOTP`,
        '',
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          console.log(response);
          /* response => Object.assign(new AdminSession(), response)
          response.customToken = response.custom_token; */
          return response;
        }),
        catchError(this.handleError)
      );
  }

  forgetPassword(email: any): Observable<any> {
    return this.http
      .post<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${email}:forgotPassword`,
        '',
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          console.log(response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkKeyExpiration(id: any): Observable<any> {
    return this.http
      .get<any>(
        this.serviceDiscovery.getUrl() +
          `v1/admins/sessions/${id}:checkExpiration`,
        {
          headers: new HttpHeaders({
            apikey: window.localStorage.getItem('apikey')
          })
        }
      )
      .pipe(
        map(response => {
          console.log(response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getTenant(): Observable<any> {
    return this.http.get<any>(this.serviceDiscovery.getUrl() + 'v1/tenantsettings', {
      headers: new HttpHeaders({
        apikey: window.localStorage.getItem('apikey')
      })
    })
  }

  private handleError(error: HttpErrorResponse) {
    // console.log(error.error.detail)
    /*  if (error.error instanceof ErrorEvent) {
       console.error('An error occurred:', error.error.message);
     } else {
       console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
     } */

    if (error) {
      return throwError(error);
    } else {
      return throwError('Wrong Username or Password');
    }
  }
}
