import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  CanColor,
  CanColorCtor,
  mixinColor,
  ThemePalette,
} from '@angular/material/core';

import {
  ChannelColorPipe,
} from '@nymos/accounts/core';


export class ChannelListingItemBase {
  constructor(public _elementRef: ElementRef) { }
}

export const _ChannelListingItemMixinBase: CanColorCtor & typeof ChannelListingItemBase =
  mixinColor(ChannelListingItemBase);


@Component({
  selector: 'nym-channel-listing-item',
  templateUrl: './channel-listing-item.component.html',
  styleUrls: ['./channel-listing-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelListingItemComponent extends _ChannelListingItemMixinBase
  implements OnInit, OnDestroy, CanColor {

  private _channel: string;

  @HostBinding('class.nym-channel-listing-item')
  protected get classes(): boolean { return true; }

  @Input()
  public get channel(): string { return this._channel; }
  public set channel(value: string) { this._setChannel(value); }

  constructor(
    elementRef: ElementRef,
  ) {
    super(elementRef);
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }

  private _setChannel(value: string): void {
    this.color = new ChannelColorPipe().transform(value) as ThemePalette;
    this._channel = value;
  }

}
