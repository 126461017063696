import {
    CoreNote,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class FailFromApi {
    public static readonly type: string = '[Notes API] Fail';
    constructor(public payload: { problem: Problem }) { }
}

export class LoadNotesFromPage {
    public static readonly type: string = '[Page] Load notes';
    constructor(public criteria: SearchCriteria) { }
}

export class LoadUserNotesFromPage {
    public static readonly type: string = '[Page] Load notes';
    constructor(public criteria: SearchCriteria) { }
}

export class LoadCardNotesFromPage {
    public static readonly type: string = '[Page] Load notes';
    constructor(public criteria: SearchCriteria) { }
}

export class AddNoteFromPage {
    public static readonly type: string = '[Page] Add note';
    constructor(public id: string, public note: CoreNote) { }
}
