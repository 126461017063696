import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  Subscription,
} from 'rxjs';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  AgentAccount,
  AgentBusiness,
  BusinessLegalForm,
  BusinessLegalFormType,
  CoreUploadsService,
  FileUploadRef,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  Account,
  Attachment,
} from '@nymos/accounts/core';

import {
  ImageService,
} from '../../../services/image.service';


@Component({
  selector: 'nym-agent-business-edit',
  templateUrl: './agent-business-edit.component.html',
  styleUrls: ['./agent-business-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class AgentBusinessEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subscription: Subscription = new Subscription();
  private _form: FormGroup;

  public imageOne: FileUploadRef;
  public imageOneUrl: string;

  public imageTwo: FileUploadRef;
  public imageTwoUrl: string;

  protected doc3Error:boolean;

  @HostBinding('class.nym-agent-business-edit')
  protected get classes(): boolean { return true; }

  protected legalFormOptions: string[] = Object.values(BusinessLegalFormType);

  protected get attachments(): Attachment[] {
    return this.account && this.account.business && this.account.business.attachments || [];
  }

  @Input()
  public account: Account;

  @Input()
  public problem: Problem;

  @Input()
  public businessInfoError: boolean;

  protected get form(): FormGroup { return this._form; }
  public get valid(): boolean { return this._form.valid; }

  public get businessType(): AbstractControl { return this.form.get('businessType'); }
  public get legalForm(): AbstractControl { return this.form.get('legalForm'); }

  public get agentBusiness(): AgentBusiness {
    return new AgentBusiness({
      type: this.businessType.value,
      legalForm: new BusinessLegalForm({
        type: this.legalForm.value,
        imageOne: this.imageOne,
        imageTwo: this.imageTwo,
      }),
    });
  }

  constructor(
    private _fb: FormBuilder,
    private _service: CoreUploadsService,
    private _cd: ChangeDetectorRef,
    private _ph: ProblemHandler,
    private _imageService: ImageService,
  ) {
    this._form = this._fb.group({
      businessType: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(256),
      ]],
      legalForm: ['', [
        Validators.required,
      ]],
    });
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {

    const accountChanges = changes['account'];

    if (this.problem) {
      this._ph.handle(this.problem, this.form);
    }

    if (this.businessInfoError) {
      this.validateAllFormFields(this.form);
    }

    if (accountChanges) {
      this._setForm(accountChanges.currentValue);
    }

    if(!this.imageOne || !this.imageTwo || !this.imageOneUrl || !this.imageTwoUrl )
      this.doc3Error= true;
    else
      this.doc3Error=false;
  }

  protected uploadImageOne$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageOne(ref)));
  }

  protected uploadImageTwo$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageTwo(ref)));
  }

  private _setForm(account: AgentAccount): void {
    const business = account && account.business;
    if (!business)
      return;

    this._form.patchValue({
      businessType: business.type || '',
      legalForm: business.legalForm.type || '',
    });

    const legalForm = business.legalForm;

    if (legalForm) {
      this._setImageOne(legalForm.imageOne);
      this._setImageTwo(legalForm.imageTwo);
    }
  }

  private _setImageOne(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageOne = ref;
    this.imageOneUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageTwo(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageTwo = ref;
    this.imageTwoUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  protected clearImageOne$$(): void {
    this.imageOne = null;
  }

  protected clearImageTwo$$(): void {
    this.imageTwo = null;
  }

  validateAllFormFields(formGroup: FormGroup): void {       
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);      
    if (control instanceof FormControl) {      
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) { 
      this.validateAllFormFields(control);     
    }
  });
}
}
