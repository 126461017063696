import {
    Problem,
} from '@michel.freiha/ng-sdk';


export class ClearThrottlingFromDevtoolsPage {
    public static readonly type: string = '[Devtools Page] Clear throttling';
    constructor() { }
}

export class FailFromApi {
    public static readonly type: string = '[Throttling API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
