// tslint:disable:component-class-suffix
// tslint:disable:no-host-metadata-property

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import {
  CdkStepper,
} from '@angular/cdk/stepper';

import {
  coerceNumberProperty,
} from '@angular/cdk/coercion';

import {
  MatHorizontalStepper,
  MatStepper,
  matStepperAnimations as animations,
} from '@angular/material/stepper';

@Component({
  moduleId: module.id,
  selector: 'thm-kyc-stepper',
  templateUrl: './kyc-stepper.component.html',
  styleUrls: ['./kyc-stepper.component.scss'],
  exportAs: 'kycStepper',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'aria-orientation': 'horizontal',
    'role': 'tablist',
  },
  animations: [
    animations.horizontalStepTransition,
  ],
  providers: [
    { provide: MatStepper, useExisting: KycStepper },
    { provide: CdkStepper, useExisting: KycStepper },
  ],
})

export class KycStepper extends MatHorizontalStepper {

  @HostBinding('class.thm-kyc-stepper')
  protected get classes(): boolean { return true; }

  @HostBinding('class.thm-kyc-stepper-label-position-end')
  protected get classesEnd(): boolean { return this.labelPosition === 'end'; }

  public labelPosition: 'bottom' | 'end' = 'end';

}
