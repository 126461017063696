import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import {
  BACKSPACE,
} from '@angular/cdk/keycodes';

import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  CreditCardService,
} from '@nymos/dashboard/shared';

@Component({
  selector: 'nym-pan-filter',
  templateUrl: './pan-filter.component.html',
  styleUrls: ['./pan-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanFilterComponent implements OnInit {

  private readonly TOTAL_LENGTH: number = 16;
  private readonly BLOCK_LENGTH: number = 4;

  private _form: FormGroup;

  @HostBinding('class.nym-pan-filter')
  protected get classes(): boolean { return true; }

  @ViewChildren('input')
  protected inputs: QueryList<ElementRef>;

  @Output('submit') // tslint:disable-line:no-output-rename
  protected submitEmitter: EventEmitter<string> = new EventEmitter();

  protected get form(): FormGroup { return this._form; }
  protected get blocks(): FormArray { return this.form.get('blocks') as FormArray; }

  @Input()
  public set pan(value: string) { this._setForm(value); }
  public get pan(): string { return this._isValid() ? this._getPan('-') : null; }

  public get valid(): boolean { return this._isValid(); }
  public get empty(): boolean { return this._isEmpty(); }
  public get network(): string { return this._getNetwork(); }



  constructor(
    private _fb: FormBuilder,
    private _cc: CreditCardService,
  ) {

    const validators = [
      Validators.required,
      Validators.maxLength(this.BLOCK_LENGTH),
      Validators.minLength(this.BLOCK_LENGTH),
      Validators.pattern('[0-9]{4}'),
    ];

    this._form = this._fb.group({
      blocks: this._fb.array([
        ['', validators],
        ['', validators],
        ['', validators],
        ['', validators],
      ]),
    });
  }

  public ngOnInit(): void { }

  public clear(): void {
    this.pan = '';

    const inputs = this.inputs.toArray();
    inputs[0].nativeElement.focus();
  }

  public hasError(errorCode: 'partial' | 'invalid'): boolean {
    const pan = this._getPan();

    if (errorCode === 'partial' && pan.length < this.TOTAL_LENGTH) {
      for (let control of this.blocks.controls) {
        if ((control.dirty || control.touched) && !control.valid)
          return true;
      }
    }

    if (errorCode === 'invalid' && pan.length === this.TOTAL_LENGTH)
      return !this._cc.valid(this.pan);

    return false;
  }

  protected onEnter(index: number, event: KeyboardEvent): void {
    if (!this._isValid())
      return;

    this.submitEmitter.emit(this.pan);
  }

  protected onBackspace(index: number, event: KeyboardEvent): void {

    const inputs = this.inputs.toArray();

    const prev = inputs[index - 1];
    const curr = inputs[index];

    this._back(curr, prev, event);
  }

  protected trackInput(index: number): void {

    const inputs = this.inputs.toArray();

    const curr = inputs[index];
    const next = inputs[index + 1];

    this._next(curr, next);
  }

  private _setForm(pan: string): void {

    pan = pan || '';

    const blocks = [];
    const next = pan.split('-');

    for (let i = 0; i < 4; i++)
      blocks.push(next[i] ? next[i] : '');

    this._form.patchValue({ blocks: blocks });
  }

  private _getPan(separator: string = ''): string {
    return this.blocks.getRawValue().join(separator);
  }

  private _isValid(): boolean {
    return this._form.valid && this._cc.valid(this._getPan());
  }

  private _isEmpty(): boolean {
    return (this._getPan().length === 0);
  }

  private _getNetwork(): string {
    const card = this._cc.card(this._getPan());
    return card ? card.type : null;
  }

  private _back(curr: ElementRef, prev: ElementRef, event: KeyboardEvent): void {
    if (!prev || curr.nativeElement.value.length > 0)
      return;

    prev.nativeElement.focus();
  }

  private _next(curr: ElementRef, next: ElementRef): void {
    if (!next || curr.nativeElement.value.length !== this.BLOCK_LENGTH)
      return;

    if (curr.nativeElement.selectionStart !== curr.nativeElement.selectionEnd)
      return;

    if (curr.nativeElement.selectionStart !== this.BLOCK_LENGTH)
      return;

    next.nativeElement.focus();
    next.nativeElement.select();
  }

}
