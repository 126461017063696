import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'channelIcon',
})
export class ChannelIconPipe implements PipeTransform {

  public transform(channel: string): string {

    switch (channel) {
      case 'active':
        return 'done';
      case 'verified':
        return 'verified_user';
      case 'pending':
        return 'access_time';
      case 'incomplete':
        return 'error_outline';
      case 'rejected':
        return 'highlight_off';
      case 'blocked':
        return 'block';
      default:
        return '';
    }
  }

}


