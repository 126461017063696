import {
    CoreNote,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class SearchFromChannelsPage {
    public static readonly type: string = '[Channels Results Page] Search accounts';
    constructor(public criteria?: SearchCriteria) { }
}

export class SearchFromResultsPage {
    public static readonly type: string = '[Search Results Page] Search accounts by query';
    constructor(public query: string) { }
}

export class LoadMoreFromChannelsPage {
    public static readonly type: string = '[Search Results Page] Load more accounts';
    constructor(public criteria?: SearchCriteria) { }
}

export class GetAccountFromApi {
    public static readonly type: string = '[Account API] Get account';
    constructor(public id: string) { }
}

export class GetAccountFromExistsGuard {
    public static readonly type: string = '[Account Guard] Get account';
    constructor(public id: string) { }
}

export class FailFromApi {
    public static readonly type: string = '[Account API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
