import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'creditcard',
})
export class CreditCardPipe implements PipeTransform {
    public transform(input: { last4?: string, first6?: string }, digits: 4 | 8 | 16 = 16): any {

        if (!input || !input.last4)
            return '';

        if (digits === 4)
            return input.last4;

        if (digits === 8)
            return ('**** ' + input.last4);

        const first6 = input.first6 + '******';

        return (first6.slice(0, 4) + ' ' + first6.slice(4, 6) + '** **** ' + input.last4);
    }
}
