import {
    TemplateRef,
} from '@angular/core';

export type StrategyFn = (templateRef?: TemplateRef<any>) => void;

export interface Strategies {
    [key: string]: StrategyFn;
}

export enum Strategy {
    SHOW = 'show',
    HIDE = 'hide',
}
