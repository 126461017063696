/* tslint:disable:member-ordering */

import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    AddProblemFromErrorHandler,
} from './problems.actions';

import {
    Problem,
} from '@michel.freiha/ng-sdk';


export interface ProblemsStateModel {
    problem: Problem;
}

const stateDefaults: ProblemsStateModel = {
    problem: undefined,
};

@State<ProblemsStateModel>({
    name: 'problems',
    defaults: stateDefaults,
})
export class ProblemsState {

    @Selector()
    public static problem(state: ProblemsStateModel): Problem {
        return state.problem;
    }

    constructor(
    ) { }

    @Action(AddProblemFromErrorHandler)
    public addProblem(ctx: StateContext<ProblemsStateModel>, { problem }: any): any {
        return ctx.patchState({ problem: problem });
    }
}
