import {
    Inject,
    Injectable,
    InjectionToken,
    Optional,
} from '@angular/core';

import {
    HttpClient,
} from '@angular/common/http';

import {
    from,
    Observable,
} from 'rxjs';

import {
    Device,
    DevicesService,
    EnrollDeviceRequest,
    EnrollDeviceResponse,
} from '@michel.freiha/ng-sdk';

import {
    Session,
} from '../utils/session.util';

import { RsaPair } from '../model/rsa-pair.model';


/*
0:
Server: EnrollPriv + BackendPub + BackendPriv
Device: EnrollPub + DevicePub + DevicePriv

1: Request
Device: uses the EnrollPub to encrypt and send the DevicePub to server
Server: decrypts the request using the EnrollPriv. Get the DevicePub as result.

Server: EnrollPriv + BackendPub + BackendPriv + (DevicePub)
Device: EnrollPub + DevicePub + DevicePriv

2: Response
Server: uses the DevicePub to encrypt and send the BackendPub to the device
Device: decrypts the response using DevicePriv. Get the BackendPub as result

Server: EnrollPriv + BackendPub + BackendPriv + (DevicePub)
Device: EnrollPub + DevicePub + DevicePriv + (BackendPub)
*/

export const ENCRYPTION_DEVICE = new InjectionToken<Device>('NymEncryption-Device');
export const ENCRYPTION_ENROLL_PUBLIC_KEY = new InjectionToken<string>('NymEncryption-EnrollPublicKey');


@Injectable({
    providedIn: 'root',
    deps: [
        [DevicesService],
        [HttpClient],
        [ENCRYPTION_ENROLL_PUBLIC_KEY],
    ],
})
export class EncryptionService {

    private _e2eeSession: Session;

    constructor(
        private _devicesService: DevicesService,
        private _http: HttpClient,
        @Inject(ENCRYPTION_ENROLL_PUBLIC_KEY) private enrollPubPemLocation: string,
    ) { }

    public enroll(device: Device, e2ePair: RsaPair): Observable<RsaPair> {
        return from(this._enroll(device, e2ePair));
    }

    public encrypt(text: string): string {
        return this._e2eeSession.encrypt(text);
    }

    public decrypt(text: string): string {
        return this._e2eeSession.decrypt(text);
    }

    private async _enroll(device: Device, e2ePair: RsaPair): Promise<RsaPair> {

        if (!e2ePair) {
            e2ePair = await this._registerDevice(device);
        }

        if (!this._e2eeSession) {
            this._e2eeSession = new Session(e2ePair);
        }

        return e2ePair;
    }

    private async _registerDevice(device: Device): Promise<RsaPair> {
        let enrollPubPem = await this._http.get(this.enrollPubPemLocation, { responseType: 'text' }).toPromise();
        let enrollSession = new Session({ publicPem: enrollPubPem });

        let devicePair = new RsaPair();

        let request = new EnrollDeviceRequest();
        request.encPublicKey = enrollSession.encrypt(devicePair.publicPem);
        request.encPublicKeyFingerprint = enrollSession.encrypt(devicePair.fingerprint);
        request.device = device;

        // TODO(@leandro): Fix fingerprint
        let response: EnrollDeviceResponse;
        try {
            response = await this._devicesService.enroll(request).toPromise();
        } catch (e) {
            const matches = /.*\'(.+)\'.+\'(.+)\'/g.exec(e.detail);
            request.encPublicKeyFingerprint = enrollSession.encrypt(matches[1]);
            response = await this._devicesService.enroll(request).toPromise();
        }

        let backendSession = new Session({ privatePem: devicePair.privatePem });
        let backendPubPem = backendSession.decrypt(response.encPublicKey);

        let e2ePair = new RsaPair({ publicPem: backendPubPem, privatePem: devicePair.privatePem });
        return e2ePair;
    }
}
