export enum Env {
    Dev = 'dev',
    Stag = 'stag',
    Prod = 'prod',
}

export const ENV_PARAM: string = '{env}';
export const NAME_PARAM: string = '{name}';
export const PROJECT_PARAM: string = '{project}';
export const DOMAIN_PARAM: string = '{domain}';

export const ENV_NYMOS_GAE_PROJECT: string = 'GOOGLE_CLOUD_PROJECT';
export const ENV_NYMOS_GAE_SERVICE: string = 'GAE_SERVICE';

export const ENV_NYMOS_K8S_DOMAIN = 'NYMOS_K8S_DOMAIN';
export const ENV_NYMOS_K8S_PROJECT = 'NYMOS_K8S_PROJECT';
export const ENV_NYMOS_K8S_ENV = 'NYMOS_K8S_ENV';
export const ENV_NYMOS_K8S_NAME = 'NYMOS_K8S_NAME';
export const ENV_NYMOS_K8S_MICSRVC_CONN = 'NYMOS_K8S_MICSRVC_CONN';
export const ENV_NYMOS_K8S_WEB_APP_ID: string = 'NYMOS_K8S_WEB_APP_ID';
export const ENV_NYMOS_K8S_WEB_CONSUMER_KEYS: string = 'NYMOS_K8S_WEB_CONSUMER_KEYS';

export interface DiscoveryService {
    isProduction: boolean;
    isStaging: boolean;
    isDev: boolean;

    projectId: string;
    serviceId: string;
    serviceEnv: Env;

    getDomain(serviceId: string): string;
    getUrl(serviceId?: string): string;
}
