import {
    animate,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const EXPANSION_ANIMATION_TIMING = '320ms ease-in-out';

export const animations: {
    readonly expansion: AnimationTriggerMetadata;
} = {
    expansion: trigger('expansion', [
        state('collapsed, void',
            style({ width: '40px', backgroundColor: 'transparent', border: '1px solid transparent', margin: 0 }),
        ),
        state('expanded',
            style({ width: '*', backgroundColor: '*' , border: '*', margin: '*' }),
        ),
        transition('expanded => collapsed, void => collapsed', [
            animate(EXPANSION_ANIMATION_TIMING),
        ]),
        transition('collapsed => expanded',
            animate(EXPANSION_ANIMATION_TIMING),
        ),
    ]),
};
