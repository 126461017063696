import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Select, Store,
} from '@ngxs/store';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Credentials,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  Auth,
  Init,
} from '../../../store/auth/auth.actions';

import {
  AuthState,
} from '../../../store/auth/auth.state';
import { SigninService } from '../../../services/signin.service';


@Component({
  selector: 'nym-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class SignInComponent implements OnInit, OnDestroy {
  public loading: boolean;
  private _subscription: Subscription = new Subscription();

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public get username(): AbstractControl {
    return this.form.get('username');
  }
  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public get problem$(): Observable<Problem> {
    return this._problem$;
  }

  public loginError: any = '';
  public loginErrorMsg: any = '';
  public partailLink: any = '';
  public isAuth: boolean = false;
  public isFromSubmitted: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
    private _signinService: SigninService,
    private _chRef: ChangeDetectorRef,
  ) {
    this._store.dispatch(new Init());

    this.form = this._fb.group({
      username: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      password: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this._subscription.add(this._problem$.subscribe((problem) => {
      console.log('in problem');
      if (problem) {
        this.loginError = problem;
        this.loading = false;
        if (this.loginError.error && this.loginError.error.detail) {
          this.loginErrorMsg = this.loginError.error.detail;
        }
        if (this.loginError.error) {
          this._ph.handle(new Problem(this.loginError.error), this.form);
        }
        this._chRef.detectChanges();
      }
    }));

    this.partailLink = '/' + window.localStorage.getItem('apikey') + '/account/password/reset'

    this.getTenant();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {
    this.isAuth = true;
    this.loading = true;
    this.isFromSubmitted = true;
    this.form.setErrors(null);
    this.form
    if (this.form.invalid) {
      this.isAuth = false;
      this.loading = false;
      return;
    }
    console.log('form valid');
    const credentials = new Credentials({
      username: this.form.value['username'],
      password: this.form.value['password'],
    });

    // let res = this._signinService.signIn(credentials).subscribe((data) => {
    //     console.log(data);
    //     this._store.dispatch(new Authnew({data }));      
    //   }, (error) => console.log(error));

    let res = this._store.dispatch(new Auth({ credentials: credentials }));
    if (res) {
      this.isAuth = false;
      setTimeout(function () {
        this.loading = false;
        this.isAuth = true;
      }, 1000);
    }
  }

  public togglePassword(): void {
    this.passwordMode.toggle();
  }

  getTenant(): void {
    this._subscription.add(
      this._signinService.getTenant().subscribe(
        tenant => {
          localStorage['tenant'] = JSON.stringify(tenant);
        },
        error => {
          localStorage.removeItem('tenant');
        }
      )
    )
  }
}
