import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  CardActivity,
} from '@nymos/accounts/core';


const DEFAULT_COLUMNS = ['category', 'merchant', 'amount', 'payment', 'datetime', 'location', 'status' ];

@Component({
  selector: 'nym-card-activity-listing-table',
  templateUrl: './card-activity-listing-table.component.html',
  styleUrls: ['./card-activity-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardActivityListingTableComponent implements OnInit {

  @HostBinding('class.nym-card-activity-full-table')
  protected get classes(): boolean { return true; }

  @Input()
  public activities: CardActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public columns: string[] = DEFAULT_COLUMNS;

  protected get shimmer(): boolean { return this.loading && !(this.activities && this.activities.length); }

  constructor() { }

  public ngOnInit(): void { }
}
