import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    AdminProfile,
} from '@michel.freiha/ng-sdk';


@Pipe({ name: 'fullName' })
export class AdminFullNamePipe implements PipeTransform {

    public transform(profile: AdminProfile): any {
        if (!profile || !profile.firstName || !profile.lastName) {
            return '';
        }

        return profile.firstName + ' ' + profile.lastName;
    }
}
