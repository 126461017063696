/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    MonoTypeOperatorFunction,
    throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    AccountLimit as Limit,
    ExpirationDate,
    NymcardCmsCardInternal as CardInternal,
    NymcardCmsCardTermination as CardTermination,
    NymcardCmsCardTerminationReason as CardTerminationReason,
    NymcardsCardInternalService,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    Card,
} from '@nymos/accounts/core';

import {
    ActivateFromCardDetailsPage,
    ActivateFromCardSelectionPage,
    ActivateFromUserDetailsPage,
    FailFromApi,
    LoadCardsFromUserDetailsPage,
    LoadCardFromUserDetailsPage,
    RefreshCardsFromUserDetailsPage,
    ResumeFromCardDetailsPage,
    ResumeFromCardSelectionPage,
    ResumeFromUserDetailsPage,
    SuspendFromCardDetailsPage,
    SuspendFromCardSelectionPage,
    SuspendFromUserDetailsPage,
    TerminateFromCardDetailsPage,
    TerminateFromCardSelectionPage,
    TerminateFromUserDetailsPage,
} from './cards.actions';

import {
    Notifications,
} from './cards.notifications';


export interface CardsStateModel {
    items: { [id: string]: Card };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: CardsStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<CardsStateModel>({
    name: 'cards',
    defaults: stateDefaults,
})
export class CardsState {

    @Selector()
    public static cards(state: CardsStateModel): Card[] {
        const result = Object.keys(state.items).map(((id) => state.items[id]));
        return result;
    }

    public static card(id: string): any {
        return createSelector([CardsState], (state: CardsStateModel) => {
            return state.items[id];
        });
    }

    @Selector()
    public static problem(state: CardsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: CardsStateModel): boolean {
        return state.loading;
    }

    constructor(
        private _nc: NotificationCenter,
        private _cardService: NymcardsCardInternalService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<CardsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(ActivateFromUserDetailsPage)
    @Action(ActivateFromCardDetailsPage)
    @Action(ActivateFromCardSelectionPage)
    public activateCard(ctx: StateContext<CardsStateModel>, { accountId, cardId, cardLast4 }: any): any {
        this._nc.show(Notifications.Activating(cardLast4));
        return this._cardService.activateCard(accountId, cardId).pipe(this._update(ctx));
    }

    @Action(SuspendFromUserDetailsPage)
    @Action(SuspendFromCardDetailsPage)
    @Action(SuspendFromCardSelectionPage)
    public suspendCard(ctx: StateContext<CardsStateModel>, { accountId, cardId, cardLast4,note}: any): any {
        this._nc.show(Notifications.Suspending(cardLast4));
        return this._cardService.suspendCard(accountId,cardId,note).pipe(this._update(ctx));
    }

    @Action(ResumeFromUserDetailsPage)
    @Action(ResumeFromCardDetailsPage)
    @Action(ResumeFromCardSelectionPage)
    public resumeCard(ctx: StateContext<CardsStateModel>, { accountId, cardId, cardLast4,note}: any): any {
        this._nc.show(Notifications.Resuming(cardLast4));
        return this._cardService.resumeCard(accountId, cardId,note).pipe(this._update(ctx));
    }

    @Action(TerminateFromUserDetailsPage)
    @Action(TerminateFromCardDetailsPage)
    @Action(TerminateFromCardSelectionPage)
    public terminateCard(ctx: StateContext<CardsStateModel>, { accountId, cardId, cardLast4, note }: any): any {
        this._nc.show(Notifications.Terminating(cardLast4));
        const termination = new CardTermination({
            note: note,
            reason: note.category as CardTerminationReason,
        });
        return this._cardService.terminateCard(accountId, cardId, termination).pipe(this._update(ctx));
    }

    @Action(LoadCardsFromUserDetailsPage)
    public loadCards(ctx: StateContext<CardsStateModel>, { accountId }: any): any {
        ctx.setState({ ...stateDefaults, loading: true });
        return this._loadCards(ctx, accountId).pipe(
            finalize(() => { ctx.patchState({ loading: false }); }),
        );
    }

    @Action(LoadCardFromUserDetailsPage)
    public loadCard(ctx: StateContext<CardsStateModel>, { accountId ,cardId}: any): any {
        ctx.setState({ ...stateDefaults, loading: true });
        return this._loadCard(ctx,accountId,cardId).pipe(
            finalize(() => { ctx.patchState({ loading: false }); }),
        );
    }

    @Action(RefreshCardsFromUserDetailsPage)
    public refreshCards(ctx: StateContext<CardsStateModel>, { accountId }: any): any {
        return this._loadCards(ctx, accountId);
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<CardsStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    private _loadCards(
        ctx: StateContext<CardsStateModel>,
        accountId: string,
    ): any {
        return this._cardService.loadCards(accountId).pipe(
            tap((cards: CardInternal[]) => {
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    cards.forEach((index) => draft.items[index.internalId] = this._toCardFromInternal(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }


     private _loadCard(
        ctx: StateContext<CardsStateModel>,
        accountId: string,
        cardId:string,
    ): any {
        return this._cardService.loadCard(accountId,cardId).pipe(

          tap((internal: CardInternal) => {
          console.log("internal",internal);
             this._nc.dismiss();
                 ctx.setState(produce((draft) => {
                    draft.items[internal.internalId] = this._toCardFromInternal(internal);
                 }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }



    private _update(ctx: StateContext<CardsStateModel>): MonoTypeOperatorFunction<any> {

        ctx.patchState({ saving: true });

        return (source) =>
            source.pipe(
                tap((internal: CardInternal) => {
                    this._nc.dismiss();
                    ctx.setState(produce((draft) => {
                        draft.items[internal.internalId] = this._toCardFromInternal(internal);
                    }));
                }),

                catchError((problem) => {
                    return ctx.dispatch(new FailFromApi({ problem: problem }));
                }),

                finalize(() => {
                    ctx.patchState({ saving: false });
                }),
            );
    }

    private _toCardFromInternal(internal: CardInternal): Card {
        const id = internal.internalId;
        const accountId = internal.accountId;
        const created = internal.created;
        const modified = internal.modified;
        const product = internal.card.product.name;
        const productId = internal.card.product.id;
        const status = internal.card.status;
        const first6 = internal.card.first6;
        const last4 = internal.card.last4;
        const network = internal.card.network;
        const balance = internal.card.balance;

        const limit = new Limit({
            currency: internal.card.currency.toUpperCase(),
            totalAmount: internal.card.limit,
            reservedAmount: 0,
            spentAmount: internal.card.limit - internal.card.balance,
        });

        const expiration = new ExpirationDate({
            month: internal.card.expMonth,
            year: internal.card.expYear,
        });

        return new Card({
            id: id,
            accountId: accountId,
            created: created,
            modified: modified,
            name: product,
            productId: productId,
            status: status,
            first6: first6,
            last4: last4,
            network: network,
            balance:balance,
            limit: limit,
            expiration: expiration,
        });
    }
}
