import {
    Env,
    ENV_NYMOS_GAE_PROJECT,
    ENV_NYMOS_GAE_SERVICE,
} from '../discovery.service';

import {
    AbstractDiscoveryService,
} from '../base/base-discovery.service';

const DEFAULT_DOMAIN: string = '{name}-dot-{project}.appspot.com';
const DEFAULT_CONN: string = 'https://{domain}';



export class GaeDiscoveryService extends AbstractDiscoveryService {

    public get projectId(): string {
        if (this.map[ENV_NYMOS_GAE_PROJECT])
            return this.map[ENV_NYMOS_GAE_PROJECT];

        throw new Error(`${ENV_NYMOS_GAE_PROJECT} hasn't been set`);
    }

    public get serviceId(): string {
        if (this.map[ENV_NYMOS_GAE_SERVICE])
            return this.map[ENV_NYMOS_GAE_SERVICE];

        throw new Error(`${ENV_NYMOS_GAE_SERVICE} hasn't been set`);
    }

    public get serviceEnv(): Env {
        const projectId = this.projectId;

        if (projectId.indexOf('-dev') !== -1)
            return Env.Dev;

        if (projectId.indexOf('-stag') !== -1)
            return Env.Stag;

        if (projectId.indexOf('-prod') !== -1)
            return Env.Prod;

        return Env.Dev;
    }

    public getDomain(serviceId: string): string {
        return super.applyTemplates(serviceId, DEFAULT_DOMAIN);
    }

    public getUrl(serviceId?: string): string {
        return super.applyTemplates(serviceId || this.serviceId, DEFAULT_CONN);
    }
}
