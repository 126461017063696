import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  STEPPER_GLOBAL_OPTIONS,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  AgentAccount,
  ConstraintViolationProblem,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  ConfirmComponent,
  Dialog,
} from '@nymos/theme';

import {
  Note,
} from '@nymos/notes';

import {
  Account,
  StatusMenu,
  Texts,
} from '@nymos/accounts/core';

import {
  ChannelStatusMenuService,
} from '@nymos/accounts/data';

import {
  AccountsState,
} from '../../../core/store/accounts/accounts.state';

import {
  AccountsAgentKycState,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.state';

import {
  AccountsProvincesState,
} from '../../../core/store/accounts-provinces/accounts-provinces.state';

import {
  NavigateKycFromAgentCreationPage,
  SaveAddressFromAgentCreationPage,
  SaveBusinessFromAgentCreationPage,
  SaveDocumentsFromAgentCreationPage,
  SaveProfileFromAgentCreationPage,
  SubmitKycFromAgentCreationPage,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

import {
  AgentPersonalEditComponent,
} from '../../components/agent-personal-edit/agent-personal-edit.component';

import {
  AgentDocumentsEditComponent,
} from '../../components/agent-documents-edit/agent-documents-edit.component';

import {
  AgentAddressEditComponent,
} from '../../components/agent-address-edit/agent-address-edit.component';

import {
  AgentBusinessEditComponent,
} from '../../components/agent-business-edit/agent-business-edit.component';

import {
  AgentKycFlow,
} from '../../../core/models/agent-kyc-flow.model';


const STEPS = {
  'personal': 0,
  'documents': 1,
  'address': 2,
  'business': 3,
  'submit': 4,
};

@Component({
  selector: 'nym-agent-creation',
  templateUrl: './agent-creation.component.html',
  styleUrls: ['./agent-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true },
  }],
})
export class AgentCreationComponent implements OnInit, OnDestroy {

  private _subscription: Subscription = new Subscription();

  private _flow: AgentKycFlow;
  private _problem: Problem;
  private _selectedIndex: number;

  @HostBinding('class.nym-agent-creation')
  protected get classes(): boolean { return true; }

  @Select(AccountsAgentKycState.account)
  public account$: Observable<AgentAccount>;

  @Select(AccountsAgentKycState.summary)
  public summary$: Observable<Account>;

  @Select(AccountsAgentKycState.flow)
  public flow$: Observable<AgentKycFlow>;

  @ViewChild('personal', { static: true })
  protected personal: AgentPersonalEditComponent;

  @ViewChild('documents', { static: true })
  protected documents: AgentDocumentsEditComponent;

  @ViewChild('address', { static: true })
  protected address: AgentAddressEditComponent;

  @ViewChild('business', { static: true })
  protected business: AgentBusinessEditComponent;

  protected provinces$: Observable<string[]>;
  protected cities$: Observable<string[]>;

  @Select(AccountsAgentKycState.problem)
  public problem$: Observable<Problem>;

  protected notes$: Observable<Note[]>;

  protected accountId: string;

  protected personalInfoError:boolean;
  protected legalDocError:boolean;
  protected addressInfoError:boolean;
  protected businessInfoError:boolean;

  protected get flow(): AgentKycFlow { return this._flow; }
  protected get problem(): ConstraintViolationProblem { return this._problem; }
  protected get selectedIndex(): number { return this._selectedIndex; }

  protected get channel(): string { return 'new agent'; }

  protected get statusMenu(): StatusMenu { return this.channel && this._service.get(this.channel); }
  protected get statusesMenu(): StatusMenu[] { return this._service.all(); }

  constructor(
    private _store: Store,
    private _dialog: Dialog,
    private _cd: ChangeDetectorRef,
    private _service: ChannelStatusMenuService,
  ) {

    this.provinces$ = this._store.select(AccountsProvincesState.provinces);
  }

  public ngOnInit(): void {
    this._subscription.add(this.flow$.pipe(filter((r) => !!r)).subscribe((flow) => {
      this._flow = flow;
      this.personalInfoError=false;
      this.legalDocError=false;
      this.businessInfoError=false;
      this.addressInfoError=false;
      // NOTES(@leandro):
      // fixme: timeout set due to race condition between step::completed and stepper::selectedIndex
      // stepper::selectedIndex must be set after step::completed
      setTimeout(() => {
        this._selectedIndex = STEPS[flow.current];
        this._cd.markForCheck();
      }, 50);
    }));
    this._subscription.add(this.problem$.pipe(filter((p) => !!p)).subscribe((p) => {
      this._problem = p;
      this._cd.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected viewAttachments$$(event: any): void {
    const urls = event.attachments;
    const index = event.index;
    this._dialog.openPreview({ urls, index });
  }

  protected savePersonalInfo$$(): any {
    if (!this.personal.valid){
      this.personalInfoError= true;
      return;
    }
    else{
      this.personalInfoError= false;
    }

    return this._store.dispatch(new SaveProfileFromAgentCreationPage(this.personal.agentProfile));
  }

  protected saveLegalDocuments$$(): any {

    if (!this.documents.valid){
      this.legalDocError= true;
      return;
    }
    else{
      this.legalDocError= false;
    }

    if(!this.documents.imageBackUrl || !this.documents.imageFrontUrl ){
      return;
    }
   
    if(!this.documents.valid && this.documents.problem){
        return this._store.dispatch(new SaveDocumentsFromAgentCreationPage(     this.documents.agentDocuments));
    } 

    return this._store.dispatch(new SaveDocumentsFromAgentCreationPage(this.documents.agentDocuments));
  }

  protected saveAddressInfo$$(): any {

     if (!this.address.valid){
      this.addressInfoError= true;
      return;
    }
    else{
      this.addressInfoError= false;
    }

    if(!this.address.image1Url || !this.address.image2Url ){
      return;
    }

   if(!this.address.valid && this.address.problem){
        return this._store.dispatch(new SaveAddressFromAgentCreationPage(this.address.agentAddress));
    }

    return this._store.dispatch(new SaveAddressFromAgentCreationPage(this.address.agentAddress));
  }

  protected saveBusinessInfo$$(): any {

    if (!this.business.valid){
      this.businessInfoError= true;
      return;
    }
    else{
      this.businessInfoError= false;
    }

    if(!this.business.imageOneUrl || !this.business.imageTwoUrl ){
      return;
    }

    if(!this.business.valid && this.business.problem){
        return this._store.dispatch(new SaveBusinessFromAgentCreationPage(this.business.agentBusiness));
    }

    return this._store.dispatch(new SaveBusinessFromAgentCreationPage(this.business.agentBusiness));
  }

  protected submitForKyc$$(): any {
    const ref = this._dialog.openConfirm(ConfirmComponent, { text: Texts.AgentDialog.SubmitTitle });

    return ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
      return this._store.dispatch(new SubmitKycFromAgentCreationPage());
    });
  }

  protected selectionChange$$(event: StepperSelectionEvent): any {
    const flow = Object.keys(STEPS)[event.selectedIndex];
    return this._store.dispatch(new NavigateKycFromAgentCreationPage(flow));
  }

  protected provinceChange$$(province: string): void {
    this.cities$ = this._store.select(AccountsProvincesState.cities(province));
  }

  protected goToAddress(): void {
    this._selectedIndex = 2;
  }

}
