import {
    AccountChannelIndex,
    AgentAccountInternal,
    CoreAccountRef,
    CoreNote,
    UserAccountInternal,
    UserAccountVerification,
} from '@michel.freiha/ng-sdk';

import {
    AccountChannel,
    ChannelStatus,
} from '../models/account-channel.model';

import {
    AccountKyc,
} from '../models/account-kyc.model';


export class AccountChannelBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;
    private _index: AccountChannelIndex;

    public withUser(value: UserAccountInternal): AccountChannelBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountChannelBuilder {
        this._agent = value;
        return this;
    }

    public withIndex(value: AccountChannelIndex): AccountChannelBuilder {
        this._index = value;
        return this;
    }

    public build(): AccountChannel {

        let channel: ChannelStatus;
        let hasNotes: boolean;
        let documentType:any;
        let activeCards: number;
        let kyc: AccountKyc;
        if (!this._index && !this._user && !this._agent)
            return undefined;

        if (this._index) {
            channel = this._index.channel as ChannelStatus;
            documentType= this._index.documentType;
            activeCards = this._index.activeCards;
            hasNotes = (this._index.notes > 0);
            kyc = new AccountKyc({ verifications: this.getVerifications(this._index) });
        }

        if (this._user) {
            kyc = new AccountKyc({ verifications: this._user.verifications });

        } else if (this._agent) {
            // kyc = new AccountKyc({ verifications: this._agent.verifications });
        }


        return new AccountChannel({
            channel: channel,
            hasNotes: hasNotes,
            documentType:documentType,
            activeCards: activeCards,
            kyc: kyc,
        });
    }

    private getVerifications(index: AccountChannelIndex): UserAccountVerification[] {

        const verifications = [];

        if (index.authorId) {
            verifications.push(new UserAccountVerification({
                author: new CoreAccountRef({
                    id: index.authorId,
                    displayName: index.authorDisplayName,
                }),
                when: index.when as any,
                note: new CoreNote({
                    category: index.noteCategory,
                    title: index.noteTitle,
                    description: index.noteDescription,
                }),
            }));
        }

        return verifications;
    }

}
