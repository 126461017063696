import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  StubCreditCardComponent,
} from './ui/components/credit-card.component.stub';

import {
  StubCardResultItemComponent,
} from './ui/components/card-result-item.component.stub';

import {
  StubPanFilterComponent,
} from './ui/components/pan-filter.component.stub';

import {
  StubTokenCardComponent,
} from './ui/components/token-card.component.stub';


@NgModule({

  declarations: [
      StubCreditCardComponent,
      StubCardResultItemComponent,
      StubPanFilterComponent,
      StubTokenCardComponent,
  ],

  imports: [
      CommonModule,
  ],

  exports: [
      StubCreditCardComponent,
      StubCardResultItemComponent,
      StubPanFilterComponent,
      StubTokenCardComponent,
  ],

})
export class MockTokensModule { }
