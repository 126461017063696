// tslint:disable:variable-name

import {
    Notification,
} from '@nymos/dashboard/shared';

import {
    Texts,
} from '../../core/texts/devtools.texts';


export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly FetchingOtps: Notification = new Notification(Texts.Otp.FetchOtp);

    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
}
