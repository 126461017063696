import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  RouterModule,
} from '@angular/router';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import {
  NymAuthModule,
} from '@nymos/auth';

import {
  NymNotesModule,
} from '@nymos/notes';

import {
  ThemeModule,
} from '@nymos/theme';

import {
  NotificationModule,
} from './notification/notification.module';

import {
  CreditCardModule,
} from './credit-card/credit-card.module';

import {
  NumberOnlyDirective,
} from './utils/directives/number-only.directive';

import {
  CurrencyDirective,
} from './utils/directives/currency.directive';

import { 
  RemoveUnderScorePipe 
} from '../../../front-end/accounts/src/lib/core/pipes/remove-underscore.pipe';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

export const DIRECTIVES = [
  CurrencyDirective,
  NumberOnlyDirective,

];

export const PIPES = [
  RemoveUnderScorePipe,
];


@NgModule({

  declarations: [
    ...DIRECTIVES,
    ...PIPES,
  ],

  imports: [
    CommonModule,
  ],

  exports: [
    ...DIRECTIVES,
    ...PIPES,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NymAuthModule,
    CreditCardModule,
    NotificationModule,
    ThemeModule,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
      ],
    };
  }
}

