import {Component, Inject} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {MAT_SNACK_BAR_DATA,MatSnackBar} from '@angular/material/snack-bar';

@Component({
   selector: 'nym-user-message',
   templateUrl: './user-message.component.html',  
   styleUrls: ['./user-message.component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class UserMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string,public snackBar: MatSnackBar) { 
    }

 public dismiss() {
	this.snackBar.dismiss()
  }

}


