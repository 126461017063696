/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    AccountChannelIndexCollection,
    ClientOptions,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    Texts,
} from '../../texts/accounts.texts';

import {
    Account,
} from '../../models/account.model';

import {
    AccountBuilder,
} from '../../builders/account.builder';

import {
    AccountsTypesState,
} from '../accounts-types/accounts-types.state';

import {
    AccountsChannelsState,
} from '../accounts-channels/accounts-channels.state';

import {
    AccountsAgentKycState,
} from '../accounts-agent-kyc/accounts-agent-kyc.state';

import {
    AccountsProvincesState,
} from '../accounts-provinces/accounts-provinces.state';

import {
    AccountsProvincesNamingsState,
} from '../accounts-provinces-namings/accounts-provinces-namings.state';

import {
    AccountsCitiesNamingsState,
} from '../accounts-cities-namings/accounts-cities-namings.state';

import {
    AccountsCountriesState,
} from '../accounts-countries/accounts-countries.state';

import {
    FailFromApi,
    GetAccountFromApi,
    GetAccountFromExistsGuard,
    LoadMoreFromChannelsPage,
    SearchFromChannelsPage,
    SearchFromResultsPage,
} from './accounts.actions';

import {
    Notifications,
} from './accounts.notifications';

import {
    AccountsOperations,
} from '../accounts.operations';

export interface AccountsStateModel {
    indexes: { [id: string]: AccountChannelIndex };
    ids: string[];
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: AccountsStateModel = {
    indexes: {},
    ids: [],
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<AccountsStateModel>({
    name: 'accounts',
    defaults: stateDefaults,
    children: [
        AccountsAgentKycState,
        AccountsChannelsState,
        AccountsProvincesState,
        AccountsCountriesState,
        AccountsTypesState,
        AccountsProvincesNamingsState,
        AccountsCitiesNamingsState
    ],
})
export class AccountsState extends AccountsOperations<AccountsStateModel> {

    protected static uploadOptions: ClientOptions;

    @Selector()
    public static accounts(state: AccountsStateModel): any {
        const result = state.ids.map((id) => {
            return new AccountBuilder(this.uploadOptions)
                .withIndex(state.indexes[id])
                .build();
        });

        return result;
    }

    public static account(id: string): any {
        return createSelector([AccountsState], (state: AccountsStateModel) => {
            return new AccountBuilder(this.uploadOptions)
                .withIndex(state.indexes[id])
                // .withLimits(state.limits[id])
                .build();
        });
    }

    @Selector()
    public static problem(state: AccountsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: AccountsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static hasMore(state: AccountsStateModel): boolean {
        return !!(state.next && state.next.cursors && state.next.cursors.after);
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SearchFromResultsPage, { cancelUncompleted: true })
    public searchAccountsByQuery(ctx: StateContext<AccountsStateModel>, { query }: any): any {
        this.nc.show(Notifications.Searching);
        ctx.patchState({ ...stateDefaults, loading: true });

        return this.channelService.searchByQuery(query).pipe(

            tap((collection: AccountChannelIndexCollection) => {
                ctx.setState(produce((draft) => {
                    draft.ids = collection.data.map((index) => index.accountId);
                    collection.data.forEach((index) => {
                        draft.indexes[index.accountId] = index;
                    });
                }));
            }),

            finalize(() => {
                this.nc.dismiss();
                ctx.patchState({ loading: false });
            }),
        );
    }

    @Action(SearchFromChannelsPage, { cancelUncompleted: true })
    public searchAccounts(ctx: StateContext<AccountsStateModel>, { criteria }: any): any {

        if(criteria.sortBy)
        this.nc.show(Notifications.Sorting);
        else
        this.nc.show(Notifications.Searching);

        criteria = new SearchCriteria(criteria);
        ctx.patchState({ ...stateDefaults, next: criteria, loading: true });

        return this._searchAccounts(ctx, criteria, true).pipe(

            finalize(() => {
                this.nc.dismiss();
                ctx.patchState({ loading: false });
            }),
        );
    }

    @Action(LoadMoreFromChannelsPage)
    public loadMoreAccounts(ctx: StateContext<AccountsStateModel>): any {
        this.nc.show(Notifications.LoadingMore);

        const state = ctx.getState();
        const criteria = state.next;

        if (!criteria)
            throw new Problem({
                title: Texts.Action.UnableToLoadMoreTitle,
                detail: Texts.Action.UnableToLoadMoreDetail,
            });

        return this._searchAccounts(ctx, criteria, false).pipe(

            finalize(() => {
                this.nc.dismiss();
            }),
        );
    }

    @Action(GetAccountFromExistsGuard)
    @Action(GetAccountFromApi)
    public loadAccount(ctx: StateContext<AccountsStateModel>, { id }: any): any {
        return this.loadChannelIndex(ctx, id).pipe(
            tap((index) => {
                ctx.setState(produce((draft) => {
                    draft.indexes[id] = index;
                }));
            }),
        );
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<AccountsStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this.nc.show(Notifications.Failure);
    }

    private _searchAccounts(ctx: StateContext<AccountsStateModel>, criteria: SearchCriteria, reset: boolean = true): any {
        return this.channelService.search(criteria).pipe(

            tap((collection: AccountChannelIndexCollection) => {

                this.nc.dismiss();
                ctx.setState(produce((draft) => {

                    if (reset)
                        draft.ids = [];

                    collection.data.forEach((index) => {
                        draft.ids.push(index.accountId);
                        draft.indexes[index.accountId] = index;
                    });

                    draft.next.cursors = collection.paging && collection.paging.cursors;
                }));
            }),

            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
        );
    }

}
