import {
    AgentAccountInternal,
    ClientOptions,
    ProofOfAddress,
    ProofOfResidence,
    UserAccountInternal,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from '../models/attachment.model';

import {
    AccountAddress,
} from '../models/account-address.model';


export class AccountAddressBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;

    constructor(private _options: ClientOptions) { }

    public withUser(value: UserAccountInternal): AccountAddressBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountAddressBuilder {
        this._agent = value;
        return this;
    }

    public build(): AccountAddress {

        let apartmentRoom: string;
        let building: string;
        let street: string;
        let city: string;
        let district: string;
        let state: string;
        let postalCode: string;
        let countryCode: string;
        let attachments: Attachment[];

        if (!this._user && !this._agent)
            return undefined;

        if (this._user && this._user.userAccount && this._user.userAccount.address) {
            const address = this._user.userAccount.address;
            if (address) {
                apartmentRoom = address.apartmentRoom;
                building = address.building;
                city = address.city;
                countryCode = address.countryCode;
                district = address.district;
                postalCode = address.postalCode;
                state = address.state;
                street = address.street;
                attachments = this._getAttachments(address.proofOfResidence, this._user.userAccount.id);
            }

        } else if (this._agent && this._agent.agentAccount && this._agent.agentAccount.address) {
            const address = this._agent.agentAccount.address;
            if (address) {
                apartmentRoom = address.apartmentRoom;
                building = address.building;
                city = address.city;
                countryCode = address.countryCode;
                district = address.district;
                postalCode = address.postalCode;
                state = address.state;
                street = address.street;
                attachments = this._getAttachments(address.proofOfAddress, this._agent.agentAccount.id);
            }
        } else {
            return undefined;
        }

        return new AccountAddress({
            apartmentRoom: apartmentRoom,
            building: building,
            city: city,
            countryCode: countryCode,
            district: district,
            postalCode: postalCode,
            state: state,
            street: street,
            attachments: attachments,
        });
    }

    private _getAttachments(documents: ProofOfAddress | ProofOfResidence, accountId: string): Attachment[] {

        const files = documents && documents.images || [];
        const baseUrl = this._options.basePathFactory.make();

        const attachments = files.map((o) => new Attachment({
            id: o.id,
            name: o.fileName,
            size: o.size,
            contentType: o.contentType,
            url: `${baseUrl}/uploads/v1/accounts/${accountId}/files/${o.id}/browse`,
        }));

        return attachments;
    }

}
