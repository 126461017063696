import {
    AgentAccountInternal,
    ClientOptions,
    UserAccountInternal,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from '../models/attachment.model';

import {
    AccountDocument,
    DocumentType,
} from '../models/account-document.model';

export class AccountAdditionalDocumentBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;

    constructor(private _options: ClientOptions) { }

    public withUser(value: UserAccountInternal): AccountAdditionalDocumentBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountAdditionalDocumentBuilder {
        this._agent = value;
        return this;
    }

    public build(): AccountDocument {

        let documents: any;

        if (!this._user && !this._agent)
            return undefined;

        if (this._user) {
            documents = this._user.userAccount.documents;

        } else if (this._agent) {
            return undefined;
        }

        const type = this.getDocumentType(documents);
        const info = this.getDocumentInfo(documents, type);

        if (!type || !info)
            return undefined;

        const attachments = this._getDocumentAttachments(info, (this._user || this._agent).id);

        return new AccountDocument({
            type: type,
            info: info,
            attachments: attachments,
        });
    }

    private getDocumentType(documents: any): DocumentType {
        if (!documents)
            return undefined;

        if (documents.visa)
            return 'visa';

        if (documents.residencyCard)
            return 'residencyCard';

        return undefined;

    }

    private getDocumentInfo(documents: any, type: DocumentType): any {
        switch (type) {
            
            case 'visa':
                return documents.visa;

            case 'residencyCard':
                return documents.residencyCard;

            default:
                return undefined;
        }
    }

    private _getDocumentAttachments(info: any, accountId: string): Attachment[] {

        let attachments: Attachment[] = [];

        if (info.imageFront)
            attachments.push(this._createAttachment(info.imageFront, accountId));

        if (info.imageBack)
            attachments.push(this._createAttachment(info.imageBack, accountId));

        return attachments;
    }

    private _createAttachment(file: any, accountId: string): Attachment {
        const baseUrl = this._options.basePathFactory.make();
        const attachment = new Attachment({
            id: file.id,
            name: file.fileName,
            contentType: file.contentType,
            size: file.size,
            url: `${baseUrl}/uploads/v1/accounts/${accountId}/files/${file.id}/browse`,
        });

        return attachment;
    }

}
