

const LOZENGES_MAX = 2;
const NAME_BASE_WIDTH = 240;
const NAME_MAX_WIDTH = 100;

interface Dimension { width?: number; height?: number; }

export class AdminRoleShimmer implements Dimension {

    public get header(): number { return 56; }
    public get height(): number { return 48; }

    constructor();
    constructor(random: boolean = true) { }

    public row(index: number, offset: number | boolean = 0): number {
        if (index === 0) {
            return 0 + this._offset(this.header, offset);
        }

        return (this.header + (index - 1) * this.height) + this._offset(this.height, offset);
    }

    private _offset(height: number, offset: number | boolean): number {
        return typeof offset === 'number'
                ? this._center(height, offset)
                : this._border(height, offset);
    }

    private _center(height: number, offset: number): number {
        return (height - offset) / 2;
    }

    private _border(height: number, end: boolean): number {
        return end === true ? height : 0;
    }
}
