// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Loading: Notification = new Notification('Loading admin accounts...');

    public static readonly Saving: Notification = new Notification('Saving...');

    public static readonly Created: Notification = new Notification('Admin account created');

    public static readonly Updated: Notification = new Notification('Admin account updated');

    public static readonly Failure: Notification = new Notification('Something went wrong');

    public static readonly Resetting: Notification = new Notification('Resetting passcode...');

    public static readonly Reset: Notification = new Notification('The instructions were sent to your email');
}


