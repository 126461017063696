import {
    Credentials,
    CustomToken,
    Oauth2AccessToken,
    PasswordChange,
    Problem,
} from '@michel.freiha/ng-sdk';



export class Init {
    public static readonly type: string = '[Auth] Init auth';
}

export class Auth {
    public static readonly type: string = '[Auth] Authenticate';
    constructor(public payload: { credentials: Credentials }) { }
}

export class SignIn {
    public static readonly type: string = '[Auth] Sign in';
    constructor(public payload: { customToken: CustomToken }) { }
}

export class SignOut {
    public static readonly type: string = '[Auth] Sign out';
}

export class ChangePassword {
    public static readonly type: string = '[Auth] Change password';
    constructor(public payload: { id: string; passwordChange: PasswordChange }) { }
}

export class ConfirmPasscode {
    public static readonly type: string = '[Auth] Confirm password';
    constructor(public payload: { id: string; code: string }) { }
}

export class RefreshToken {
    public static readonly type: string = '[Auth] Refresh token';
    constructor(public payload: { token: Oauth2AccessToken }) { }
}

export class AuthFail {
    public static readonly type: string = '[Auth] Flow fail';
    constructor(public payload: { problem: Problem }) { }
}
