
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  RouterModule,
} from '@angular/router';

import {
  MatRippleModule,
} from '@angular/material/core';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatListModule,
} from '@angular/material/list';

import {
  MatSidenavModule,
} from '@angular/material/sidenav';

import {
  ThemeComponentsMenuModule,
} from '../../components/menu/menu.module';

import {
  ThemeComponentsProfileModule,
} from '../../components/profile/profile.module';

import {
  ThemeComponentsScrollbarModule,
} from '../../components/scrollbar/scrollbar.module';


import { AppLayoutComponent } from './containers/app-layout/app-layout.component';

export const MATERIAL_MODULES = [
  MatIconModule,
  MatListModule,
  MatRippleModule,
  MatSidenavModule,
];

export const THEME_MODULES = [
  ThemeComponentsMenuModule,
  ThemeComponentsProfileModule,
  ThemeComponentsScrollbarModule,
];

@NgModule({
  declarations: [
    AppLayoutComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    ...THEME_MODULES,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
  ],

  exports: [
    AppLayoutComponent,
  ],
})
export class ThemeAppLayoutModule { }
