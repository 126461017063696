// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    ProvinceNaming,
} from '../../lib/core/models/province-naming.model';

class Factory extends ModelFactory<ProvinceNaming> {
    constructor() {
        super(null, (m: ProvinceNaming) => m.ENValue);
    }
}


@Injectable({
    providedIn: 'root',
})
export class ProvinceMapDatastore extends MemoryBackend<ProvinceNaming> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): ProvinceNaming {
        return this.doLoad(id);
    }

    public all(): ProvinceNaming[] {
        return this.values();
    }

    protected afterInit(): any {
        this.doCreate(new ProvinceNaming({ ENValue: 'Erbil', ARValue:'أربيل'}));
        this.doCreate(new ProvinceNaming({ ENValue: 'Anbar', ARValue:'الانبار'})); 
        this.doCreate(new ProvinceNaming({ ENValue: 'Babylon', ARValue:'بابل'}));    
        this.doCreate(new ProvinceNaming({ ENValue: 'Baghdad/Karkh', ARValue:'بغداد/الكرخ'})); 
        this.doCreate(new ProvinceNaming({ ENValue: 'Baghdad/Rusafa', ARValue:'بغداد/الرصافة'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Basra ', ARValue:'البصرة'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Dohuk', ARValue:'دهوك'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Qadisiyah', ARValue:'القادسية'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Diyala', ARValue:'ديالى'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Dhi Qar', ARValue:'ذي قار'})); 
        this.doCreate(new ProvinceNaming({ ENValue: 'Sulaymaniyah', ARValue:'السليمانية'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Salah al-Din', ARValue:'صلاح الدين'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Kirkuk', ARValue:'كركوك'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Karbala', ARValue:'كربلاء'}));  
        this.doCreate(new ProvinceNaming({ ENValue: 'Muthanna', ARValue:'المثنى'})); 
        this.doCreate(new ProvinceNaming({ ENValue: 'Maysan', ARValue:'ميسان'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Najaf', ARValue:'النجف'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Ninewa', ARValue:'نينوى'}));   
        this.doCreate(new ProvinceNaming({ ENValue: 'Wasit', ARValue:'واسط'}));
    }
}
