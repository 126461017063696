import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  Note,
} from '@nymos/notes/core';

@Component({
  selector: 'nym-note-info',
  templateUrl: './note-info.component.html',
  styleUrls: ['./note-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NoteInfoComponent implements OnInit {

  @HostBinding('class.nym-note-info')
  protected get classes(): boolean { return true; }

  @Input()
  public note: Note;

  constructor() { }

  public ngOnInit(): void { }

}
