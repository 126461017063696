
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  LoaderComponent,
} from './containers/loader/loader.component';

import {
  LoaderEmptyDirective,
} from './components/loader-empty/loader-empty.directive';


export const MATERIAL_MODULES = [
  MatProgressBarModule,
];

@NgModule({
  declarations: [
    LoaderComponent,
    LoaderEmptyDirective,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    LoaderComponent,
    LoaderEmptyDirective,
  ],
})
export class ThemeComponentsLoaderModule { }
