enum NotificationType {
  Info = 'thm-notification-info',
  Warning = 'thm-notification-warning',
  Error = 'thm-notification-error',
}

export class Notification {

  public static readonly Info: NotificationType = NotificationType.Info;
  public static readonly Warning: NotificationType = NotificationType.Warning;
  public static readonly Error: NotificationType = NotificationType.Error;

  constructor(
    public message: string,
    public button?: string,
    public action?: any,
    public type: NotificationType = NotificationType.Info,
    public config: any = { autoHide: true, duration: 5000 },
  ) { }
}
