import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Admin,
  FileUploadRef,
  UserAccountVerificationStatus,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  Account,
  Card,
  CardActivity,
  Wallet,
  WalletActivity,
} from '@nymos/accounts/core';

import {
    NewProvince,
} from '../../../../../lib/core/models/new-province.model';


@Component({
  selector: 'nym-user-details-content',
  templateUrl: './user-details-content.component.html',
  styleUrls: ['./user-details-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsContentComponent implements OnInit {

  public isUserType:any;


  @HostBinding('class.nym-user-details-content')
  protected get classes(): boolean { return true; }

  @Output('manageCard') // tslint:disable-line:no-output-rename
  protected manageCardEmitter: EventEmitter<any> = new EventEmitter();

  @Output('manageWalletActivity') // tslint:disable-line:no-output-rename
  protected manageWalletActivityEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewCard') // tslint:disable-line:no-output-rename
  protected viewCardEmitter: EventEmitter<Card> = new EventEmitter();

  @Output('viewCards') // tslint:disable-line:no-output-rename
  protected viewCardsEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewCardActivities') // tslint:disable-line:no-output-rename
  protected viewCardActivitiesEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewWalletActivities') // tslint:disable-line:no-output-rename
  protected viewWalletActivitiesEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachmentsEmitter: EventEmitter<FileUploadRef[]> = new EventEmitter();

  @Output('viewTopup') // tslint:disable-line:no-output-rename
  protected viewTopupEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewTopupRefund') // tslint:disable-line:no-output-rename
  protected viewTopupRefundEmitter: EventEmitter<any> = new EventEmitter();

  @Output('loadWallet') // tslint:disable-line:no-output-rename
  protected loadWalletEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public refundPermission:boolean;

  @Input()
  public accountLoading: boolean;

  @Input()
  public cards: Card[];

  @Input()
  public cardsLoading: boolean;

  @Input()
  public cardActivities: CardActivity[];

  @Input()
  public cardActivitiesLoading: boolean;

  @Input()
  public provincesnew$: NewProvince[];

  @Input()
  public wallet: Wallet;

  @Input()
  public walletLoading: boolean;

  @Input()
  public walletProblem: Problem;

  @Input()
  public walletActivities: WalletActivity[];

  @Input()
  public walletActivitiesLoading: boolean;

  protected get firstWalletActivities(): WalletActivity[] {
    return this.walletActivitiesLoading ? this.walletActivities : this.walletActivities.slice(0, 10);
  }

  protected get firstCards(): Card[] {
    return this.cardsLoading ? this.cards : this.cards.slice(0, 5);
  }

  protected get firstCardActivities(): CardActivity[] {
    return this.cardActivitiesLoading ? this.cardActivities : this.cardActivities.slice(0, 10);
  }

  protected get status(): string { return this.account && this.account.channel.channel; }

  constructor() { }

  public ngOnInit(): void { 
   this.isUserType = {"name":'user'}
   }

  protected manageCard(event: any): void {
    this.manageCardEmitter.emit(event);
  }

  protected manageWalletActivity(event: any): void {
    this.manageWalletActivityEmitter.emit(event);
  }

  protected viewCard(card: Card): void {
    this.viewCardEmitter.emit(card);
  }

  protected viewCards(): void {
    this.viewCardsEmitter.emit();
  }

  public isKyc2Approved(): boolean {
    return this.account.verificationStatus === UserAccountVerificationStatus.Kyc2Approved; 
  }

  protected viewCardActivities(): void {
    this.viewCardActivitiesEmitter.emit();
  }

  protected viewWalletActivities(): void {
    this.viewWalletActivitiesEmitter.emit();
  }

  protected viewAttachments(event: any): void {
    this.viewAttachmentsEmitter.emit(event);
  }

  protected viewTopup(event: any): void {
    this.viewTopupEmitter.emit(event);
  }
  
  protected viewTopupRefund(event: any): void {
    this.viewTopupRefundEmitter.emit(event);
  }

  protected loadWallet(): void {
    this.loadWalletEmitter.emit();
  }

}
