
import {
    NewCity,
} from './new-city.model';

export class NewProvince {
    public key: string;
    public en:string;
    public ar:string;
    public cities: NewCity[];

    constructor(ob?: Partial<NewProvince>) {
        this.key = undefined;
        this.en = undefined;
        this.ar = undefined;
        this.cities = undefined;

        if (!ob) { return; }

        if (ob.key !== undefined) { this.key = ob.key; }
        if (ob.en !== undefined) { this.en = ob.en; }
        if (ob.ar !== undefined) { this.ar = ob.ar; }

        if (ob.cities !== undefined) { this.cities = ob.cities; }
    }
}
