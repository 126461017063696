import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  BulkIssuance,
} from '@nymos/bulkcards/core';


@Component({
  selector: 'nym-issuance-listing-table',
  templateUrl: './issuance-listing-table.component.html',
  styleUrls: ['./issuance-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuanceListingTableComponent implements OnInit {

  @HostBinding('class.nym-issuance-full-table')
  protected get classes(): boolean { return true; }

  @Output()
  protected clone: EventEmitter<BulkIssuance> = new EventEmitter();

  @Input()
  public issuances: BulkIssuance[] = [];

  @Input()
  public columns: string[];

  @Input()
  public loading: boolean;

  constructor() {   }

  public ngOnInit(): void {
    this.issuances = this.issuances.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
   }

  public clone$$(issuance: BulkIssuance): void {
    this.clone.emit(issuance);
  }

}
