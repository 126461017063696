import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  Store,
} from '@ngxs/store';

import {
  RouterStateSerializer,
} from '@ngxs/router-plugin';

import {
  ACCESS_TOKEN_STORAGE_KEY,
  ACCOUNTCHANNELS_SEARCH_OPTIONS,
  ACCOUNTS_OPTIONS,
  ADMINS_OPTIONS,
  ADMINS_SEARCH_OPTIONS,
  AGENTS_OPTIONS,
  AGENTS_SEARCH_OPTIONS,
  CORE_OPTIONS,
  DEVICES_OPTIONS,
  DEVTOOLS_OPTIONS,
  NOTES_SEARCH_OPTIONS,
  NOTIFICATIONS_DEVTOOLS_OPTIONS,
  NymAccountchannelsSearchModule,
  NymAccountsModule,
  NymAdminsModule,
  NymAdminsSearchModule,
  NymAgentsModule,
  NYMCARDS_OPTIONS,
  NYMCARDS_SEARCH_OPTIONS,
  NymCoreModule,
  NymDevicesModule,
  NymDevtoolsModule,
  NymNotesSearchModule,
  NymNotificationsDevtoolsModule,
  NymNymcardsModule,
  NymNymcardsSearchModule,
  NymOauth2Module,
  NymOauth2RefreshModule,
  NymOauth2ScopeModule,
  NymPasscodesModule,
  NymUploadsModule,
  NymUsersModule,
  NymWalletsModule,
  OAUTH2_OPTIONS,
  OAUTH2_SCOPE_OPTIONS,
  PASSCODES_OPTIONS,
  UPLOADS_OPTIONS,
  USERS_OPTIONS,
  WALLETS_OPTIONS,
  NymAgentsSearchModule,
} from '@michel.freiha/ng-sdk';

import {
  NYM_DISCOVERY_SERVICE,
  NymDiscoveryModule,
} from '@nymos/discovery';

import {
  ENCRYPTION_DEVICE,
  ENCRYPTION_ENROLL_PUBLIC_KEY,
  NymEncryptionModule,
} from '@nymos/encryption';

import {
  NymNotesModule,
} from '@nymos/notes';

import {
  NymProblemsModule,
} from '@nymos/problems';

import {
  NymAuthModule,
} from '@nymos/auth';

import {
  accountchannelsSearchOptionsFactory,
  accountsOptionsFactory,
  adminsOptionsFactory,
  adminsSearchOptionsFactory,
  agentsOptionsFactory,
  agentsSearchOptionsFactory,
  coreOptionsFactory,
  devicesOptionsFactory,
  devtoolsOptionsFactory,
  encryptionDeviceFactory,
  notesSearchOptionsFactory,
  notificationsDevtoolsOptionsFactory,
  nymcardsOptionsFactory,
  nymcardsSearchOptionsFactory,
  oauth2OptionsFactory,
  oauth2ScopesOptionsFactory,
  passcodesOptionsFactory,
  uploadsOptionsFactory,
  usersOptionsFactory,
  walletsOptionsFactory,
} from './nymos.sdk';

import {
  CustomRouterStateSerializer,
} from './router.serializer';


@NgModule({
  imports: [
    CommonModule,

    // Backend
    NymCoreModule.forRoot(),
    NymDevicesModule.forRoot(),
    NymUploadsModule.forRoot(),
    NymOauth2Module.forRoot(),
    NymOauth2RefreshModule.forRoot(),
    NymOauth2ScopeModule.forRoot(),
    NymAccountsModule.forRoot(),
    NymAdminsModule.forRoot(),
    NymAdminsSearchModule.forRoot(),
    NymUsersModule.forRoot(),
    NymAgentsModule.forRoot(),
    NymAgentsSearchModule.forRoot(),
    NymAccountchannelsSearchModule.forRoot(),
    NymDevtoolsModule.forRoot(),
    NymNotesSearchModule.forRoot(),
    NymNotificationsDevtoolsModule.forRoot(),
    NymNymcardsModule.forRoot(),
    NymNymcardsSearchModule.forRoot(),
    NymWalletsModule.forRoot(),
    NymPasscodesModule.forRoot(),

    // Framework
    NymAuthModule.forRoot(),
    NymDiscoveryModule.forRoot(),
    NymEncryptionModule,
    NymProblemsModule.forRoot(),
    NymNotesModule.forRoot(),
  ],
  declarations: [],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule,
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        { provide: CORE_OPTIONS, useFactory: coreOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: OAUTH2_OPTIONS, useFactory: oauth2OptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: OAUTH2_SCOPE_OPTIONS, useFactory: oauth2ScopesOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: DEVICES_OPTIONS, useFactory: devicesOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: UPLOADS_OPTIONS, useFactory: uploadsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: ACCOUNTS_OPTIONS, useFactory: accountsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: ADMINS_OPTIONS, useFactory: adminsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: ADMINS_SEARCH_OPTIONS, useFactory: adminsSearchOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: USERS_OPTIONS, useFactory: usersOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: AGENTS_OPTIONS, useFactory: agentsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: ACCOUNTCHANNELS_SEARCH_OPTIONS, useFactory: accountchannelsSearchOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: DEVTOOLS_OPTIONS, useFactory: devtoolsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: NOTIFICATIONS_DEVTOOLS_OPTIONS, useFactory: notificationsDevtoolsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: NYMCARDS_OPTIONS, useFactory: nymcardsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: NOTES_SEARCH_OPTIONS, useFactory: notesSearchOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: AGENTS_SEARCH_OPTIONS, useFactory: agentsSearchOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: NYMCARDS_SEARCH_OPTIONS, useFactory: nymcardsSearchOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: WALLETS_OPTIONS, useFactory: walletsOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: PASSCODES_OPTIONS, useFactory: passcodesOptionsFactory, deps: [Store, NYM_DISCOVERY_SERVICE] },
        { provide: ACCESS_TOKEN_STORAGE_KEY, useValue: 'auth.token' },
        { provide: ENCRYPTION_ENROLL_PUBLIC_KEY, useValue: 'assets/keys/enroll.pub' },
        { provide: ENCRYPTION_DEVICE, useFactory: encryptionDeviceFactory, deps: [Store] },
      ],
    };
  }
}

