/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    StateContext,
    Store,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    MonoTypeOperatorFunction,
    throwError,
    timer,
} from 'rxjs';

import {
    catchError,
    concatMap,
    filter,
    finalize,
    map,
    take,
    tap,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    AccountChannelIndexCollection,
    AccountsInternalService,
    AgentsInternalService,
    AgentsSearchService,
    ClientOptions,
    NymcardsLimitInternalService,
    PasscodesInternalService,
    Problem,
    SearchCriteria,
    SearchFilter,
    SearchOperator,
    UPLOADS_OPTIONS,
    UsersInternalService,
} from '@michel.freiha/ng-sdk';

import {
    AccountchannelsSearchService,
} from '@michel.freiha/ng-sdk';

import {
    FailFromApi,
    GetAccountFromApi,
} from './accounts/accounts.actions';

import {
    Notification,
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    ChannelStatus,
} from '../models/account-channel.model';

export class AccountsOperations<T> {

    protected static uploadOptions: ClientOptions;

    constructor(
        protected store: Store,
        protected nc: NotificationCenter,
        protected agentService: AgentsInternalService,
        protected agentSearchService: AgentsSearchService,
        protected accountService: AccountsInternalService,
        protected userService: UsersInternalService,
        protected passcodeService: PasscodesInternalService,
        protected channelService: AccountchannelsSearchService,
        protected limitsService: NymcardsLimitInternalService,
        @Inject(UPLOADS_OPTIONS) options: ClientOptions,
    ) {
        AccountsOperations.uploadOptions = options;
    }

    protected loadChannelIndex(ctx: StateContext<T>, id: any): any {
        const criteria = new SearchCriteria({
            filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [id] })],
        });

        return this.channelService.search(criteria).pipe(
            map((collection: AccountChannelIndexCollection) => {
                let data = collection.data;
                if (data.length > 1) {
                    data = data.filter((o) => o.accountId === id);
                }

                if (data.length !== 1) {
                    throw new Problem({ detail: `Found ${data.length} account channels for ${id}.` });
                }

                const index = data[0];
                return index;
            }),
        );
    }

    protected loadLimitInternal(ctx: StateContext<T>, id: any): any {
        return this.limitsService.loadLimits(id);
    }

    protected updateItem(
        ctx: StateContext<T>,
        id: string,
        notification: Notification,
    ): MonoTypeOperatorFunction<any> {

        if(notification)
        this.nc.show(notification);

        const idx = this.store.selectSnapshot((state) => state.accounts.indexes[id]);

        if (idx) {
            ctx.setState(produce((draft) => { draft.indexes[id] = idx; }));
        }

        return (source) =>
            source.pipe(
                tap(({ internal, index, limits }: any) => {
                    ctx.setState(produce((draft) => {
                        draft.indexes[id] = index;
                        draft.internals[id] = internal;
                        draft.limits[id] = limits;
                    }));
                }),

                catchError((problem) => {
                    // Let global handler catch it
                    return throwError(problem);
                }),

                finalize(() => {
                    this.nc.dismiss();
                }),
            );
    }

    protected refreshItem(
        ctx: StateContext<T>,
        id: string,
    ): MonoTypeOperatorFunction<any> {

        return (source) =>
            source.pipe(
                tap(({ internal, index, limits }: any) => {
                    ctx.setState(produce((draft) => {
                        draft.indexes[id] = index;
                        draft.internals[id] = internal;
                        draft.limits[id] = limits;
                    }));
                }),

                catchError((problem) => {
                    // Let global handler catch it
                    return throwError(problem);
                }),
            );
    }

    protected updateChannel(
        ctx: StateContext<T>,
        id: string,
        predicate: (index: AccountChannelIndex) => boolean,
        action: new (id: string) => any,
    ): MonoTypeOperatorFunction<any> {

        return (source) =>
            source.pipe(
                tap(() => {
                    this.pollChannelUntil(ctx, id, predicate, action);
                }),

                catchError((problem) => {
                    return ctx.dispatch(new FailFromApi({ problem: problem }));
                }),
            );
    }

    protected pollChannelUntil(
        ctx: StateContext<T>,
        id: string,
        predicate: (index: AccountChannelIndex) => boolean,
        action: new (id: string) => any,
    ): any {
        return timer(0, 500)
            .pipe(
                concatMap(() => this.loadChannelIndex(ctx, id)),
                take(10),
                filter((index: AccountChannelIndex) => predicate(index)),
                take(1),
            )
            .subscribe(() => ctx.dispatch(new action(id)));
    }

}
