import {
    AgentAccountInternal,
    BusinessLegalFormType,
    ClientOptions,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from '../models/attachment.model';

import {
    AccountBusiness,
} from '../models/account-business.model';


export class AccountBusinessBuilder {

    private _agent: AgentAccountInternal;

    constructor(private _options: ClientOptions) { }

    public withAgent(value: AgentAccountInternal): AccountBusinessBuilder {
        this._agent = value;
        return this;
    }

    public build(): AccountBusiness {

        let type: string;
        let legalForm: BusinessLegalFormType;
        let attachments: Attachment[];

        if (!this._agent)
            return undefined;

        const business = this._agent.agentAccount.business;

        if (business) {
            type = business.type;
            legalForm = business.legalForm.type;
            attachments = this._getDocumentAttachments(business.legalForm, this._agent.agentAccount.id);
        }

        return new AccountBusiness({
            type: type,
            legalForm: legalForm,
            attachments: attachments,
        });
    }

    private _getDocumentAttachments(info: any, accountId: string): Attachment[] {

        let attachments: Attachment[] = [];

        if (info.imageOne)
            attachments.push(this._createAttachment(info.imageOne, accountId));

        if (info.imageTwo)
            attachments.push(this._createAttachment(info.imageTwo, accountId));

        return attachments;
    }

    private _createAttachment(file: any, accountId: string): Attachment {
        const baseUrl = this._options.basePathFactory.make();
        const attachment = new Attachment({
            id: file.id,
            name: file.fileName,
            contentType: file.contentType,
            size: file.size,
            url: `${baseUrl}/uploads/v1/accounts/${accountId}/files/${file.id}/browse`,
        });

        return attachment;
    }

}
