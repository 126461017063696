import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

import {
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  Notification,
  NotificationCenter,
} from '@nymos/dashboard/shared';

import {
  Texts,
} from '@nymos/accounts/core';

const MINIMUM_LENGTH = 3;

@Component({
  selector: 'nym-search-homepage',
  templateUrl: './search-homepage.component.html',
  styleUrls: ['./search-homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHomepageComponent implements OnInit {

  @HostBinding('class.nym-search-homepage')
  protected get classes(): boolean { return true; }

  constructor(
    private _nc: NotificationCenter,
    private _store: Store,
  ) { }

  public ngOnInit(): void { }

  protected search(query: string): void {
    if (!query)
      return;

    if (query.length < MINIMUM_LENGTH) {
      this._nc.show(new Notification(Texts.UserAction.UnableToSearch));
      return;
    }

    this._store.dispatch(new Navigate(['/accounts/search'], { query: query }));
  }

}
