import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Account,
  Wallet,
} from '@nymos/accounts/core';


@Component ({
  selector: 'nym-user-wallet-info',
  templateUrl: './user-wallet-info.component.html',
  styleUrls: ['./user-wallet-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWalletInfoComponent implements OnInit {

  @HostBinding('class.nym-user-wallet-info')
  protected get classes(): boolean { return true; }

  @Output()
  protected topup: EventEmitter<any> = new EventEmitter();

  @Output()
  protected topuprefund: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public wallet: Wallet;

  @Input() isUserType:any;

  @Input() refundPermission:boolean;

  @Input() walletStatus:boolean;

  @Input()
  public loading: boolean;

  constructor() { }

  public ngOnInit(): void { }

  protected topup$$(): void {
    this.topup.emit();
  }

  protected topuprefund$$(): void {
    this.topuprefund.emit();
  }

}
