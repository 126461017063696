import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    MatButtonModule,
} from '@angular/material/button';

import {
    MatIconModule,
} from '@angular/material/icon';

import {
    MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';

import {
    MatRippleModule,
} from '@angular/material/core';

import {
    MatTooltipModule,
} from '@angular/material/tooltip';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import {
    ThemePipesModule,
} from '../../../pipes/pipes.module';

import {
    ThumbnailComponent,
} from './containers/thumbnail/thumbnail.component';


export const MATERIAL_MODULES = [
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatTooltipModule,
];


@NgModule({
    declarations: [
        ThumbnailComponent,
    ],

    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        FlexLayoutModule,
        ThemePipesModule,
    ],

    exports: [
        ThumbnailComponent,
    ],
})
export class ThemeComponentsThumbnailModule { }
