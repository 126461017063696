import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  ActivatedRoute,
} from '@angular/router';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  Notification,
  NotificationCenter,
  SearchBuilder,
} from '@nymos/dashboard/shared';

import {
  Texts,
} from '@nymos/accounts/core';

const MINIMUM_LENGTH = 3;

@Component({
  selector: 'nym-content-layout',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent implements OnInit, OnDestroy {

  private _sb: SearchBuilder = new SearchBuilder();
  private _subscription: Subscription = new Subscription();

  @HostBinding('class.nym-content-layout')
  protected get classes(): boolean { return true; }

  protected router$: Observable<any>;

  protected get param(): string { return this._sb.criteria.query; }

  constructor(
    private _nc: NotificationCenter,
    private _ar: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    private _store: Store,
  ) {
    this.router$ = this._store.select((state) => state.router.state);
  }

  public ngOnInit(): void {
    this._subscription.add(this.router$.subscribe((router) => {
      this._sb.params = router.queryParams;
      this._cd.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected search(query: string): void {
    if (!query)
      return;

    if (query.length < MINIMUM_LENGTH) {
      this._nc.show(new Notification(Texts.UserAction.UnableToSearch));
      return;
    }

    this._store.dispatch(new Navigate(['./search'], { query: query }, { relativeTo: this._ar }));
  }

}
