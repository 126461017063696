export function isPlainObject(value: any): boolean {
    if (Object.prototype.toString.call(value) !== '[object Object]') {
        return false;
    } else {
        let prototype = Object.getPrototypeOf(value);
        return prototype === null || prototype === Object.prototype;
    }
}

export function isString(value: any): value is string {
    return !!value && typeof value === 'string';
}

export function isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
}

export function isPromise(promise: any): boolean {
    return Object.prototype.toString.call(promise) === '[object Promise]';
}

export function isEmpty(value: any): boolean {
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    return !value;
}

export function transformStringToArray(value: any): string[] {
    if (isString(value)) {
        return [value];
    }
    return value;
}

export function firstScope(a: string | string[], b: string | string[]): any {
    a = transformStringToArray(a) || [];
    b = transformStringToArray(b) || [];
    return b.find((s) => a.indexOf(s) >= 0);
}

export function hasScopes(a: string | string[], b: string | string[]): any {
    return isEmpty(b) || !!(firstScope(a, b));
}

export function hasAllScopes(a: string | string[], b: string | string[]): any {
    a = transformStringToArray(a) || [];
    b = transformStringToArray(b) || [];

    for (let i=0;i<b.length;i++){
        if (a.indexOf(b[i]) == -1)
            return false;
    }
    return true;
}
