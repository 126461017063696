// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '@nymos/accounts/core';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Crediting: StringFn = ((money) => new Notification(Texts.TopupAction.Crediting(money)));
    public static readonly Debiting: StringFn = ((money) => new Notification(Texts.TopupAction.Debiting(money)));
    public static readonly DebitingWallet: StringFn = ((money) => new Notification(Texts.TopupAction.DebitingWallet(money)));
    public static readonly Transfering: StringFn = ((money) => new Notification(Texts.TopupAction.Transfering(money)));
    public static readonly Refunding: StringFn = ((money) => new Notification(Texts.TopupAction.Refunding(money)));
    public static readonly Approving: Notification = new Notification(Texts.TopupAction.Approving);
    public static readonly Declining: Notification = new Notification(Texts.TopupAction.Declining);
    public static readonly Cancelling: Notification = new Notification(Texts.TopupAction.Cancelling);
    public static readonly Retrying: Notification = new Notification(Texts.TopupAction.Retrying);
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
}


