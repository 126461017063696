import {
  Pipe,
  PipeTransform,
} from '@angular/core';

import {
  Status,
} from '../models/wallet-activity.model';

@Pipe({
  name: 'walletActivityColor',
})
export class WalletActivityColorPipe implements PipeTransform {

  public transform(status: Status): string {

    switch (status) {
      case 'pending':
      case 'processing':
      case 'cancelling':
        return 'yellow';

      case 'rejected':
      case 'cancelled':
      case 'declined':
      case 'error':
        return 'red';

      case 'settled':
        return 'green';

      default:
        return 'menu';
    }
  }

}
