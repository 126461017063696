import {
    Card,
    Network,
} from '@michel.freiha/ng-sdk';

import {
    ModelFactory,
} from '@nymos/backend';

import {
    MockToken,
} from './token.mock';


export class MockCard extends ModelFactory<Card> {

    constructor() {
        super(MockCard.create, (m: Card) => m.id);
    }

    public static create(id?: string): Card {

        const index = id ? id : 'mock##id';

        const last4 = '1234';

        const network = Network.Visa;

        const card = new Card({
            'id': index,
            'last4': last4,
            'network': network,
            'tokens': [MockToken.create(index)],
        });

        return card;
    }
}
