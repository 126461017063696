import {
    NymcardCardCreateBulkDetails,
    Problem,
} from '@michel.freiha/ng-sdk';


export class LoadFromHomePage {
    public static readonly type: string = '[Home Page] Load bulk issuances';
    constructor() { }
}

export class LoadMoreFromHomePage {
    public static readonly type: string = '[Home Page] Load more bulk issuances';
    constructor() { }
}

export class IssueFromHomePage {
    public static readonly type: string = '[Home Page] Issue new bulk';
    constructor(public details: NymcardCardCreateBulkDetails) { }
}

export class FailFromApi {
    public static readonly type: string = '[Bulk Issuance API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
