import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    BusinessLegalFormType,
} from '@michel.freiha/ng-sdk';

import {
    Texts,
} from '../texts/accounts.texts';

@Pipe({ name: 'legalFormText' })
export class LegalFormTextPipe implements PipeTransform {

    public transform(legalForm: string): string {

        switch (legalForm as BusinessLegalFormType) {

            case BusinessLegalFormType.RentalAgreement:
                return Texts.LegalForm.RentalAgreement;

            case BusinessLegalFormType.RegistrationForm:
                return Texts.LegalForm.RegistrationForm;

            default:
                return '';
        }
    }
}
