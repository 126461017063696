// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    Province,
} from '../../lib/core/models/province.model';

class Factory extends ModelFactory<Province> {
    constructor() {
        super(null, (m: Province) => m.name);
    }
}


@Injectable({
    providedIn: 'root',
})
export class AccountProvinceDatastore extends MemoryBackend<Province> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): Province {
        return this.doLoad(id);
    }

    public all(): Province[] {
        return this.values();
    }

    protected afterInit(): any {
        this.doCreate(new Province({
            name: 'Anbar',
            cities: [
                'Aana',
                'Akashat',
                'Al Boasaf',
                'Al Furat',
                'Al Hamiria',
                'Al Hibaria',
                'Al Huqlania',
                'Al Jazira',
                'Al Jiba',
                'Al kasra',
                'Al Mohammadi',
                'Al Naeimia',
                'Al Rahalia',
                'Al Ramana',
                'Al Riyhana',
                'Al Sakra',
                'Al Saqlawia',
                'Al Sharqiya',
                'Al Sijar',
                'Al Tharthar',
                'Al Waleed',
                'Al Zawia',
                'Amiriya',
                'Arar',
                'Baghdadi',
                'Braunah',
                'Falluja',
                'Habbaniyah',
                'Haditha',
                'Hasybah',
                'Heet',
                'Kabisa',
                'Karabla',
                'Karma',
                'Khalidiya',
                'Nukhayb',
                'Obeidi',
                'Qaim',
                'Ramadi',
                'Rawa',
                'Rutbah',
                'Sagirah',
                'Zikhekha'
                ],
        }));

        this.doCreate(new Province({
            name: 'Babylon',
            cities: [
                'Al Iskandaria',
                'Al Qasim',
                'Al-Mudhatia',
                'Hilla',
                'Mahmudiyah',
                'Mhaweel',
                'Musayib',
            ],
        }));

        this.doCreate(new Province({
            name: 'Baghdad/Karkh',
            cities: [
                'Abu Ghraib',
                'Al Adel Neighborhood',
                'Al Amel Neighborhood',
                'Al Dwlaey',
                'Al Dwra',
                'Al Hurya',
                'Al Iskan',
                'Al Jamea Neighborhood',
                'Al Khadra Neighborhood',
                'Al Salihia',
                'Al Shoulla',
                'Al Shurta Neighborhood',
                'Al Slam',
                'Amreya',
                'Atefiyah',
                'Aweirij',
                'Bayaa',
                'Ghazaliya',
                'Green Zone',
                'Haifa Street',
                'Harthiya',
                'Jihad Neighborhood',
                'Kadhimiya',
                'Karada Mariam',
                'Mahmudiyah',
                'Mansour',
                'Qadisiyah',
                'Saydiya',
                'Sweib',
                'Yarmouk',
            ],
        }));

        this.doCreate(new Province({
            name: 'Baghdad/Rusafa',
            cities: [
                'Abu Nawas',
                'Al Adhamiya',
                'Al Arasat',
                'Al Baladiat',
                'Al Bnwk',
                'Al Hbybya',
                'Al Hindia',
                'Al Jadriya',
                'Al Kmalya',
                'Al Kryeaat',
                'Al Mashtl',
                'Al Mghrb',
                'Al Shaab',
                'Al Slykh',
                'Al Zafaraniya',
                'Alaptaoyen',
                'Awr',
                'Bab Al Moazam',
                'Bghdad Al Jadyda',
                'Camp Sarah',
                'Fadhiliyah',
                'Ghadir',
                'Idrissi',
                'Karrada',
                'Khilani',
                'Medical City',
                'Mohandeseen',
                'Neighborhood July 14',
                'Obeidi',
                'Palestine Street',
                'Qahira Neighborhood',
                'Rashid Camp',
                'Rashid Street',
                'Rustamiyah',
                'Sadoun Street',
                'Sadr City',
                'Sbe Abkar',
                'Sheikh Omar',
                'Shorja',
                'Sinak',
                'Waziriya',
                'Zayouna',
            ],
        }));

        this.doCreate(new Province({
            name: 'Basra',
            cities: [
                '5 Mile',
                'Abbasiyah',
                'Al Abla',
                'Al Andalus',
                'Al Ashar',
                'Al Asmai',
                'Al Buradieia',
                'Al Faw',
                'Al Gomhoria Neighborhood',
                'Al Hakimia',
                'Al Jameiat',
                'Al Janina',
                'Al Jazayir Neighborhood',
                'Al Jazirah',
                'Al Jubaila',
                'Al khadra',
                'Al Kufaat neighborhood',
                'Al Muaqil',
                'Al Qabla',
                'Al Qasim',
                'Al Rafidain',
                'Al Resala Neighborhood',
                'Al Sahat',
                'Al Tahsinia',
                'Al Taliah',
                'Al Tawisa',
                'Al Wahda',
                'Albikr Walahrar',
                'Arabian Gulf',
                'Briha',
                'Dawr Al Naft',
                'Karma Ali',
                'Minawi Pasha',
                'Muwafaqiyah',
                'Nujaibiya',
                'Zubayr',
            ],
        }));

        this.doCreate(new Province({
            name: 'Dhi Qar',
            cities: [
                'Al Akyka',
                'Al Batha',
                'Al Dwaya',
                'Al Fadlya',
                'Al Fhwd',
                'Al Fjr',
                'Al Ghraf',
                'Al Islah',
                'Al Jbaysh',
                'Al Manar',
                'Al Nasr',
                'Al Rifaey',
                'Al Shatra',
                'Al Tar',
                'Awr',
                'Krma Bani Saeed',
                'Qadaa Alnasrya',
                'Qalea Sakar',
                'Sayed Dakhil',
                'Swq Al Shywkh',
            ],
        }));

        this.doCreate(new Province({
            name: 'Diyala',
            cities: [
                'Al Abbara',
                'Al Azim',
                'Al Ghalibia',
                'Al Kufry',
                'Al Mansuria',
                'Baladrouz',
                'Baquba',
                'Buhraz',
                'Dali Abbas',
                'Hibhib',
                'Jalawla',
                'Kanaan',
                'Khalis',
                'Khan Bani Saad',
                'Khanaqin',
                'Mandali',
                'Muqdadiya',
                'Qarah Taba',
                'Saadia',
            ],
        }));

        this.doCreate(new Province({
            name: 'Dohuk',
            cities: [
                'Amadiyah',
                'Aqrah',
                'Bamerni',
                'Bardarash',
                'Deiralock',
                'Dohuk',
                'Mankish',
                'Sarsank',
                'Shekhan',
                'Smail',
                'Solaf',
                'Zakho',
                'Zawaytah',
            ],
        }));

        this.doCreate(new Province({
            name: 'Erbil',
            cities: [
                'Askan',
                'Ayn Qawah',
                'Bakhtiari',
                'Eskry Neighborhood',
                'Hay Al Arab',
                'Irbil',
                'Juman',
                'Mannara',
                'Mohandiseen Neighborhood',
                'Muntakawh',
                'Nissan kolan',
                'Qalaat',
                'Quesnijq',
                'Rawanduz',
                'Ronaki 7',
                'Shaqlawa',
                'Shorash',
                'Suran',
                'Tirawa',
                'Wazidan'            ],
        }));

        this.doCreate(new Province({
            name: 'Karbala',
            cities: [
                'Ain tamr',
                'Al Hur',
                'Al Husseiniya',
                'Al Jadwal',
                'Al Khayrat',
                'Algharbi',
                'Aoun',
                'Karbala',
                'Rahhaliyah',
                'Touirij',
            ],
        }));

        this.doCreate(new Province({
            name: 'Kirkuk',
            cities: [
                '1 Huziran',
                'Ahmed Agha',
                'Al Askari',
                'Al Asraa Walmafqudin',
                'Al Baath',
                'Al Dbs',
                'Al Dubbat',
                'Al Khadraa',
                'Al Mas',
                'Al Muealimin',
                'Al Nasr',
                'Al Nour',
                'Al Orouba',
                'Al Qalaa',
                'Al Wasti',
                'Amam Qasim',
                'Buriadi',
                'Daquq',
                'Grnata',
                'Hawija',
                'Kirkuk',
                'Qwria',
                'Shorja',
                'Shuhadaa',
            ],
        }));

        this.doCreate(new Province({
            name: 'Maysan',
            cities: [
                'Al Adel',
                'Al Dabysat',
                'Al Khayr',
                'Al khlaa',
                'Al Masharah',
                'Al Maymuna',
                'Al Qadiriyah',
                'Al Sabunjia',
                'Al Salam',
                'Al Saray',
                'Al Shabana',
                'Alamara',
                'Ali Al Gharbi',
                'Ali Al Sharqi',
                'Almajar Al Kabir',
                'Bani Hashim',
                'Kumet',
                'Mahmoudiyah',
                'Qalat Salih',
                'Sayed Ahmed Rifai',
                'Uzair',
            ],
        }));

        this.doCreate(new Province({
            name: 'Muthanna',
            cities: [
                'Busyyah',
                'Daraji',
                'Hilal',
                'Karama',
                'Khader',
                'Majd',
                'Najmi',
                'Rumaythah',
                'Salman',
                'Samawah',
                'Suwayrah',
                'Warka',
            ],
        }));

        this.doCreate(new Province({
            name: 'Najaf',
            cities: [
                'Abbasia',
                'Al Haidariyah',
                'Al Hira',
                'Alhurya',
                'Kufa',
                'Manathirah',
                'Mashkhab',
                'Najaf',
                'Qadisiyah',
                'Ridawiyah',
                'Shabakiyah',
            ],
        }));

        this.doCreate(new Province({
            name: 'Ninewa',
            cities: [
                'Al Arabi',
                'Al Jamiah',
                'Al Muthanna',
                'Al Qwosh',
                'Al Sinaa',
                'Al Zuhour',
                'Baaj',
                'Badush',
                'Bartala',
                'Bashika',
                'Fayda',
                'Hadar',
                'Hadbaa',
                'Hammam Al Alil',
                'Makhmour',
                'Mosul',
                'Nabi Younis',
                'Nujaifi',
                'Qairawan',
                'Qayyarah',
                'Rabia',
                'Sheikhan',
                'Shoura',
                'Sinjar',
                'Talafar',
                'Talkif',
                'Zamar',
            ],
        }));

        this.doCreate(new Province({
            name: 'Qadisiyah',
            cities: [
                'Afak',
                'Al Budair',
                'Daghara',
                'Diwaniyah',
                'Ghamas',
                'Hamzah',
                'Muhannawiyah',
                'Nefer',
                'Salahiyah',
                'Shafaia',
                'Shamiya',
                'Shinafiyah',
                'Sudair',
                'Sumer',
                'Sunnya',
            ],
        }));

        this.doCreate(new Province({
            name: 'Salah Al Din',
            cities: [
                'Baiji',
                'Balad',
                'Dhuluiyah',
                'Dujail',
                'Samarra',
                'Sharqat',
                'Tikrit',
                'Tuzkhermatu',
            ],
        }));

        this.doCreate(new Province({
            name: 'Sulaymaniyah',
            cities: [
                'Bakra Joe',
                'Bazian',
                'Chamchamal',
                'Darbandikan',
                'Dukan',
                'Halabja',
                'Kalar',
                'Khanaqin',
                'Khurmal',
                'Kifri',
                'Penguin',
                'Qara Dag',
                'Rania',
                'Sayed Sadiq',
                'Shara Zur',
                'Sharbarees',
                'Sieh Setan',
                'Tangro',
                'Wara Mawa',
            ],
        }));

        

        this.doCreate(new Province({
            name: 'Wasit',
            cities: [
                'Ahrar',
                'Al Hay',
                'Al Saouira',
                'Aziziyah',
                'Badra',
                'Bashaer',
                'Dabouni',
                'Jassan',
                'Kut',
                'Muwaffaqiyah',
                'Numaniyah',
                'Shehaymiyah',
                'Sheikh Saad',
                'Taj Aldin',
                'Zubaydiyah',
                'Zurbatiya',
            ],
        }));

        //  this.doCreate(new Province({
        //     name: 'القادسية',
        //     cities: [
        //          "آل بدير",
        //           "الديوانية",
        //           "السنية",
        //           "الشافعية",
        //           "الدغارة",
        //           "الشامية",
        //           "المهناوية",
        //           "الصلاحية",
        //           "الحمزة",
        //           "السدير",
        //           "الشنافية",
        //           "سومر",
        //           "عفك",
        //           "غماس",
        //           "نفرر"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'صلاح الدين',
        //     cities: [
        //          "الدجيل",
        //           "الشرقاط",
        //           "الضلوعية",
        //           "بيجي",
        //           "بلد",
        //           "تكريت",
        //           "سامراء",
        //           "طوزخرماتو"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'كربلاء',
        //     cities: [
        //          "الحر",
        //           "الحسينية",
        //           "الرحالية",
        //           "الجدول الغربي",
        //           "الخيرات",
        //           "طويريج",
        //           "عون",
        //           "كربلاء",
        //           "عين تمر"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'نينوى',
        //     cities: [
        //          "الشورة",
        //           "القيارة",
        //           "الشيخان",
        //           "القوش",
        //           "البعاج",
        //           "القيروان",
        //           "النجيفي",
        //           "الحدباء",
        //           "الصناعة",
        //           "بعشيقة",
        //           "بادوش",
        //           "برطلة",
        //           "تلكيف",
        //           "تلعفر",
        //           "حضر",
        //           "حمام العليل",
        //           "حي المثنى",
        //           "حي الزهور",
        //           "حي الجامعة",
        //           "حي العربي",
        //           "ربيعة",
        //           "زمار",
        //           "سنجار",
        //           "شيخان",
        //           "فايدة",
        //           "مخمور",
        //           "موصل",
        //           "نبي يونس"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'أربيل',
        //     cities: [
        //          "أربيل",
        //           "القلعة",
        //           "الإسكان",
        //           "بختياري",
        //           "جومان",
        //           "جولي",
        //           "حي العرب",
        //           "حي عسكري",
        //           "حي المهندسين",
        //           "راوندوز",
        //           "روناكي",
        //           "سوران",
        //           "سبعة نيسان",
        //           "شقلاوة",
        //           "شورش",
        //           "طيراوة",
        //           "عين كاوة",
        //           "كويسنجق",
        //           "كولان",
        //           "منارة",
        //           "منتكاوا",
        //           "وةزيدان"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'أربيل',
        //     cities: [
        //          "أربيل",
        //           "القلعة",
        //           "الإسكان",
        //           "بختياري",
        //           "جومان",
        //           "جولي",
        //           "حي العرب",
        //           "حي عسكري",
        //           "حي المهندسين",
        //           "راوندوز",
        //           "روناكي",
        //           "سوران",
        //           "سبعة نيسان",
        //           "شقلاوة",
        //           "شورش",
        //           "طيراوة",
        //           "عين كاوة",
        //           "كويسنجق",
        //           "كولان",
        //           "منارة",
        //           "منتكاوا",
        //           "وةزيدان"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'ديالى',
        //     cities: [
        //           "العبارة",
        //           "المقدادية",
        //           "السعدية",
        //           "الخالص",
        //           "العظيم",
        //           "المنصورية",
        //           "الغالبية",
        //           "الكفري",
        //           "بعقوبة",
        //           "بهرز",
        //           "بلدروز",
        //           "جلولاء",
        //           "حبحب",
        //           "خان بني سعد",
        //           "خانقين",
        //           "دلي عباس",
        //           "قرة تبة",
        //           "كنعان",
        //           "مندلي"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'ذي قار',
        //     cities: [
        //            "البطحاء",
        //           "الإصلاح",
        //           "اور",
        //           "الشطرة",
        //           "الغراف",
        //           "الدواية",
        //           "الرفاعي",
        //           "النصر",
        //           "الفجر",
        //           "الفضلية",
        //           "الطار",
        //           "الجبايش",
        //           "الفهود",
        //           "المنار",
        //           "سيد دخيل",
        //           "سوق الشيوخ",
        //           "عقيقة",
        //           "قضاء الناصرية",
        //           "قلعة سكر",
        //           "كرمة بني سعيد"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'ذي قار',
        //     cities: [
        //            "البطحاء",
        //           "الإصلاح",
        //           "اور",
        //           "الشطرة",
        //           "الغراف",
        //           "الدواية",
        //           "الرفاعي",
        //           "النصر",
        //           "الفجر",
        //           "الفضلية",
        //           "الطار",
        //           "الجبايش",
        //           "الفهود",
        //           "المنار",
        //           "سيد دخيل",
        //           "سوق الشيوخ",
        //           "عقيقة",
        //           "قضاء الناصرية",
        //           "قلعة سكر",
        //           "كرمة بني سعيد"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'البصرة',
        //     cities: [
        //            "5 ميل",
        //           "البكر والاحرار",
        //           "الخضراء",
        //           "العشار",
        //           "البراضعية",
        //           "التحسينية",
        //           "العباسية",
        //           "الابلة",
        //           "المعقل",
        //           "الحكيمية",
        //           "الجمعيات",
        //           "الجنينة",
        //           "الطويسة",
        //           "القبلة",
        //           "النجيبية",
        //           "الموفقية",
        //           "الاصمعي",
        //           "الجبيلة",
        //           "الاندلس",
        //           "الطليعة",
        //           "الخليج العربي",
        //           "الجزيرة",
        //           "الساحات",
        //           "الزبير",
        //           "الفاو",
        //           "بريهة",
        //           "حي الرسالة",
        //           "حي الكفاءات",
        //           "حي الجمهورية",
        //           "حي الجزائر",
        //           "حي القاسم",
        //           "حي الوحدة",
        //           "حي الرافدين",
        //           "دور النفط",
        //           "كرمة علي",
        //           "مناوي باشا"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'الانبار',
        //     cities: [
        //            "الرمادي",
        //           "الفلوجة",
        //           "القائم",
        //           "الحبانية",
        //           "الخالدية",
        //           "الوليد",
        //           "الساجرية",
        //           "الرطبة",
        //           "البغدادي",
        //           "الكرابلة",
        //           "النخيب",
        //           "النعيمية",
        //           "السجر",
        //           "الرحالية",
        //           "الصكرة",
        //           "الجبة",
        //           "الحميرية",
        //           "الجزيرة",
        //           "الرمانة",
        //           "الهبارية",
        //           "الكسرة",
        //           "الصقلاوية",
        //           "العبيدي",
        //           "العامرية",
        //           "الكرمة",
        //           "الحقلانية",
        //           "الثرثار",
        //           "الزاوية",
        //           "الريحانة",
        //           "الفرات",
        //           "المحمدي",
        //           "البوعساف",
        //           "بروانة",
        //           "حديثة",
        //           "حصيبة الشرقية",
        //           "راوة",
        //           "زخيخة",
        //           "عكاشات",
        //           "عانة",
        //           "عرعر",
        //           "كبيسة",
        //           "هيت"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'السليمانية',
        //     cities: [
        //            "بكرة جو",
        //           "بازيان",
        //           "بنجوين",
        //           "تانجرو",
        //           "جمجمال",
        //           "حلبجة",
        //           "خورمال",
        //           "خانقين",
        //           "دوكان",
        //           "دربنديخان",
        //           "رانية",
        //           "سيه سيتان",
        //           "سيد صادق",
        //           "شارة زور",
        //           "شاربارييز",
        //           "قرة داغ",
        //           "كلار",
        //           "كفري",
        //           "سليمانية",
        //           "ورة ماوة"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'بغداد\/الرصافة',
        //     cities: [
        //            "أبو نؤاس",
        //           "السنك",
        //           "الشورجة",
        //           "الخلاني",
        //           "البتاويين",
        //           "الشيخ عمر",
        //           "المهندسين",
        //           "الادريسي",
        //           "الاعظمية",
        //           "الوزيرية",
        //           "المغرب",
        //           "الكريعات",
        //           "البنوك",
        //           "اور",
        //           "الشعب",
        //           "الصليخ",
        //           "الحبيبية",
        //           "الغدير",
        //           "المشتل",
        //           "الكمالية",
        //           "العبيدي",
        //           "الفضيلية",
        //           "الكرادة",
        //           "الجادرية",
        //           "العرصات الهندية",
        //           "الزعفرانية",
        //           "الرستمية",
        //           "البلديات",
        //           "باب المعظم",
        //           "بغداد الجديدة",
        //           "حي 14 تموز",
        //           "حي القاهرة",
        //           "زيونة",
        //           "سبع ابكار",
        //           "شارع الرشيد",
        //           "شارع السعدون",
        //           "شارع فلسطين",
        //           "كمب سارة",
        //           "مدينة الطب",
        //           "مدينة الصدر",
        //           "معسكر الرشيد"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'بابل',
        //     cities: [
        //            "القاسم",
        //           "المسيب",
        //           "المدحتية",
        //           "الحلة",
        //           "المحاويل",
        //           "الإسكندرية"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'واسط',
        //     cities: [
        //           "الكوت",
        //           "الشيخ سعد",
        //           "الصويرة",
        //           "الزبيدية",
        //           "الشحيمية",
        //           "العزيزية",
        //           "الدبوني",
        //           "النعمانية",
        //           "الاحرار",
        //           "الحي",
        //           "الموفقية",
        //           "البشائر",
        //           "بدرة",
        //           "تاج الدين",
        //           "جصان",
        //           "زرباطية"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'النجف',
        //     cities: [
        //           "الحيدرية",
        //           "الشبكية",
        //           "الرضوية",
        //           "الكوفة",
        //           "العباسية",
        //           "الحرية",
        //           "المناذرة",
        //           "الحيرة",
        //           "القادسية",
        //           "النجف",
        //           "المشخاب"
        //         ],
        // }));


        // this.doCreate(new Province({
        //     name: 'بغداد\/الكرخ',
        //     cities: [
        //           "الصالحية",
        //           "المنطقة الخضراء",
        //           "الحارثية",
        //           "المنصور",
        //           "القادسية",
        //           "الإسكان",
        //           "اليرموك",
        //           "العامرية",
        //           "الغزالية",
        //           "السيدية",
        //           "الدورة",
        //           "البياع",
        //           "الكاظمية",
        //           "العطيفية",
        //           "الشعلة",
        //           "الحرية",
        //           "الدولعي",
        //           "السلام",
        //           "السويب",
        //           "حي الخضراء",
        //           "حي الجامعة",
        //           "حي العدل",
        //           "حي الجهاد",
        //           "حي العامل",
        //           "حي الشرطة",
        //           "شارع حيفا",
        //           "عويريج",
        //           "ابو غريب",
        //           "المحمودية",
        //           "كرادة مريم"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'دهوك',
        //     cities: [
        //           "العمادية",
        //           "بردرش",
        //           "بامرني",
        //           "ديرةلوك",
        //           "زاويتة",
        //           "زاخو",
        //           "سرسنك",
        //           "سميل",
        //           "سولاف",
        //           "شيخان",
        //           "عقرة",
        //           "دهوك",
        //           "مانكيش"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'كركوك',
        //     cities: [
        //           "1 حزيران",
        //           "الواسطي",
        //           "العسكري",
        //           "العروبة",
        //           "الشهداء",
        //           "الشورجة",
        //           "احمد اغا",
        //           "القلعة",
        //           "امام قاسم",
        //           "الماس",
        //           "الاسرى والمفقودين",
        //           "المعلمين",
        //           "الضباط",
        //           "الخضراء",
        //           "الحويجة",
        //           "الدبس",
        //           "بريادي",
        //           "حي البعث",
        //           "حي النصر",
        //           "حي النور",
        //           "داقوق",
        //           "غرناطة",
        //           "كركوك",
        //           "قورية"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'المثنى',
        //     cities: [
        //           "السماوة",
        //           "السويرة",
        //           "الرميثة",
        //           "الهلال",
        //           "النجمي",
        //           "المجد",
        //           "السلمان",
        //           "الخضر",
        //           "الدراجي",
        //           "الوركاء",
        //           "الكرامة",
        //           "بصية"
        //         ],
        // }));

        // this.doCreate(new Province({
        //     name: 'ميسان',
        //     cities: [
        //             "العمارة",
        //               "الكحلاء",
        //               "المشرح",
        //               "المجر الكبير",
        //               "الخير",
        //               "العدل",
        //               "العزير",
        //               "الميمونة",
        //               "السلام",
        //               "القادرية",
        //               "السراي",
        //               "الصابونجية",
        //               "الشبانة",
        //               "الدبيسات",
        //               "بني هاشم",
        //               "سيد احمد الرفاعي",
        //               "علي الغربي",
        //               "علي الشرقي",
        //               "قلعة صالح",
        //               "كميت"
        //         ],
        // }));
    }
}
