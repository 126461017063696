import {
  Directive,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'section, thm-section, .thm-section, [thm-section], [thmSection]',
})
export class SectionDirective {

  @HostBinding('class.thm-section')
  protected get classes(): boolean { return true; }

  constructor() { }

}
