import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    RoleGuard,
} from './guards/role.guard';

import {
    RoleListingComponent,
} from './ui/containers/role-listing/role-listing.component';

import {
    RoleComparisonComponent,
} from './ui/containers/role-comparison/role-comparison.component';

import {
    AccessManagementComponent,
} from './ui/containers/access-management/access-management.component';


export const routes: Routes = [
    { path: 'roles', component: RoleListingComponent },
    { path: 'roles/compare', component: RoleComparisonComponent, canActivate: [RoleGuard] },
    { path: 'access', component: AccessManagementComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminsRoutingModule { }
