// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    CityNaming,
} from '../../lib/core/models/city-naming.model';

class Factory extends ModelFactory<CityNaming> {
    constructor() {
        super(null, (m: CityNaming) => m.ENValue);
    }
}


@Injectable({
    providedIn: 'root',
})
export class CityMapDatastore extends MemoryBackend<CityNaming> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): CityNaming {
        return this.doLoad(id);
    }

    public all(): CityNaming[] {
        return this.values();
    }

    protected afterInit(): any {
        this.doCreate(new CityNaming({ ENValue: '7 Nissan', ARValue:'سبعة نيسان'}));
        this.doCreate(new CityNaming({ ENValue: 'Askan', ARValue:'الإسكان'}));
        this.doCreate(new CityNaming({ ENValue: 'Ayn Qawah', ARValue:'عين كاوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Bakhtiari', ARValue:'بختياري'}));
        this.doCreate(new CityNaming({ ENValue: 'Eskry Neighborhood', ARValue:'حي عسكري'}));
        this.doCreate(new CityNaming({ ENValue: 'Hay Al Arab', ARValue:'حي العرب'}));
        this.doCreate(new CityNaming({ ENValue: 'Irbil', ARValue:'أربيل'}));
        this.doCreate(new CityNaming({ ENValue: 'Juman', ARValue:'جومان'}));
        this.doCreate(new CityNaming({ ENValue: 'Kolan', ARValue:'كولان'}));
        this.doCreate(new CityNaming({ ENValue: 'Mannara', ARValue:'منارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Mohandiseen Neighborhood', ARValue:'حي المهندسين'}));
        this.doCreate(new CityNaming({ ENValue: 'Muntakawh', ARValue:'منتكاوا'}));
        this.doCreate(new CityNaming({ ENValue: 'Qalaat', ARValue:'القلعة '}));
        this.doCreate(new CityNaming({ ENValue: 'Quesnijq', ARValue:'كويسنجق '}));
        this.doCreate(new CityNaming({ ENValue: 'Rawanduz', ARValue:'راوندوز'}));
        this.doCreate(new CityNaming({ ENValue: 'Ronaki', ARValue:'روناكي'}));
        this.doCreate(new CityNaming({ ENValue: 'Shaqlawa', ARValue:'شقلاوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Shorash', ARValue:'شورش'}));
        this.doCreate(new CityNaming({ ENValue: 'Suran', ARValue:'سوران'}));
        this.doCreate(new CityNaming({ ENValue: 'Tirawa', ARValue:'طيراوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Wazidan', ARValue:'وةزيدان'}));
        this.doCreate(new CityNaming({ ENValue: 'Aana', ARValue:'عانة'}));
        this.doCreate(new CityNaming({ ENValue: 'Akashat', ARValue:'عكاشات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Boasaf', ARValue:'البوعساف'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Furat', ARValue:'الفرات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hamaria', ARValue:'الحميرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hibaria', ARValue:'الهبارية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Huqlania', ARValue:'الحقلانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jazira', ARValue:'الجزيرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jiba', ARValue:'الجبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Kasra', ARValue:'الكسرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mohammadi', ARValue:'المحمدي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Naeimia', ARValue:'النعيمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Rahalia', ARValue:'الرحالية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Ramana', ARValue:'الرمانة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Riyhana', ARValue:'الريحانة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sakra', ARValue:'الصكرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Saqlawia', ARValue:'الصقلاوية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sharqiya', ARValue:'الشرقية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sijar', ARValue:'السجر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Tharthar', ARValue:'الثرثار'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Waleed', ARValue:'الوليد'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Zawia', ARValue:'الزاوية'}));
        this.doCreate(new CityNaming({ ENValue: 'Amiriya', ARValue:'العامرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Arar', ARValue:'عرعر'}));
        this.doCreate(new CityNaming({ ENValue: 'Baghdadi', ARValue:'البغدادي'}));
        this.doCreate(new CityNaming({ ENValue: 'Braunah', ARValue:'بروانة'}));
        this.doCreate(new CityNaming({ ENValue: 'Falluja', ARValue:'الفلوجة'}));
        this.doCreate(new CityNaming({ ENValue: 'Habaniyah', ARValue:'الحبانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Haditha', ARValue:'حديثة'}));
        this.doCreate(new CityNaming({ ENValue: 'Hasybah', ARValue:'حصيبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Heet', ARValue:'هيت'}));
        this.doCreate(new CityNaming({ ENValue: 'Kabisa', ARValue:'كبيسة'}));
        this.doCreate(new CityNaming({ ENValue: 'Karabla ', ARValue:'الكرابلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Karma', ARValue:'الكرمة'}));
        this.doCreate(new CityNaming({ ENValue: 'Khalidiya', ARValue:'الخالدية'}));
        this.doCreate(new CityNaming({ ENValue: 'Nukhayb', ARValue:'النخيب'}));
        this.doCreate(new CityNaming({ ENValue: 'Obeidi', ARValue:'العبيدي'}));
        this.doCreate(new CityNaming({ ENValue: 'Qaim', ARValue:'القائم'}));
        this.doCreate(new CityNaming({ ENValue: 'Ramadi', ARValue:'الرمادي'}));
        this.doCreate(new CityNaming({ ENValue: 'Rawa', ARValue:'راوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Rutbah', ARValue:'الرطبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Sagirah', ARValue:'الساجرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Zikhakh', ARValue:'زخيخة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Iskandaria', ARValue:'الإسكندرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qasim', ARValue:'القاسم'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mudhatia', ARValue:'المدحتية'}));
        this.doCreate(new CityNaming({ ENValue: 'Hilla', ARValue:'الحلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Mhaweel', ARValue:'المحاويل'}));
        this.doCreate(new CityNaming({ ENValue: 'Musayib', ARValue:'المسيب'}));
        this.doCreate(new CityNaming({ ENValue: 'Abu Ghraib', ARValue:'ابو غريب'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Adel Neighborhood', ARValue:'حي العدل'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Amel Neighborhood', ARValue:'حي العامل'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Dwlaey', ARValue:'الدولعي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Dwra', ARValue:'الدورة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hurya', ARValue:'الحرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Iskan', ARValue:'الإسكان'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jamea Neighborhood', ARValue:'حي الجامعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khadra Neighborhood', ARValue:'حي الخضراء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Salihia', ARValue:'الصالحية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Shoulla', ARValue:'الشعلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Shurta Neighborhood', ARValue:'حي الشرطة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Slam', ARValue:'السلام'}));
        this.doCreate(new CityNaming({ ENValue: 'Amreya', ARValue:'العامرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Atefiyah', ARValue:'العطيفية'}));
        this.doCreate(new CityNaming({ ENValue: 'Aweirij', ARValue:'عويريج'}));
        this.doCreate(new CityNaming({ ENValue: 'Bayaa', ARValue:'البياع'}));
        this.doCreate(new CityNaming({ ENValue: 'Ghazaliya', ARValue:'الغزالية'}));
        this.doCreate(new CityNaming({ ENValue: 'Green Zone', ARValue:'المنطقة الخضراء'}));
        this.doCreate(new CityNaming({ ENValue: 'Haifa Street', ARValue:'شارع حيفا'}));
        this.doCreate(new CityNaming({ ENValue: 'Harthiya', ARValue:'الحارثية'}));
        this.doCreate(new CityNaming({ ENValue: 'Jihad Neighborhood', ARValue:'حي الجهاد'}));
        this.doCreate(new CityNaming({ ENValue: 'Kadhimiya', ARValue:'الكاظمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Karada Mariam', ARValue:'كرادة مريم'}));
        this.doCreate(new CityNaming({ ENValue: 'Mahmudiyah', ARValue:'المحمودية'}));
        this.doCreate(new CityNaming({ ENValue: 'Mansour', ARValue:'المنصور'}));
        this.doCreate(new CityNaming({ ENValue: 'Qadisiyah', ARValue:'القادسية'}));
        this.doCreate(new CityNaming({ ENValue: 'Saydiya', ARValue:'السيدية'}));
        this.doCreate(new CityNaming({ ENValue: 'Sweib', ARValue:'السويب'}));
        this.doCreate(new CityNaming({ ENValue: 'Yarmouk', ARValue:'اليرموك'}));
        this.doCreate(new CityNaming({ ENValue: 'Abu Nawas', ARValue:'أبو نؤاس'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Adhamiya', ARValue:'الاعظمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Aptaoyen', ARValue:'البتاويين'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Arasat Al Hindia', ARValue:'العرصات الهندية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Baladiat', ARValue:'البلديات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Bnwk', ARValue:'البنوك'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hbybya', ARValue:'الحبيبية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jadriya', ARValue:'الجادرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Kmalya', ARValue:'الكمالية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Kryeaat', ARValue:'الكريعات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mashtl', ARValue:'المشتل'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mghrb', ARValue:'المغرب'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Shaab', ARValue:'الشعب'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Slykh', ARValue:'الصليخ'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Zafaraniya', ARValue:'الزعفرانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Awr', ARValue:'اور'}));
        this.doCreate(new CityNaming({ ENValue: 'Bab Al Moazam', ARValue:'باب المعظم'}));
        this.doCreate(new CityNaming({ ENValue: 'Bghdad Al Jadyda', ARValue:'بغداد الجديدة'}));
        this.doCreate(new CityNaming({ ENValue: 'Camp Sarah', ARValue:'كمب سارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Fadhiliyah', ARValue:'الفضيلية'}));
        this.doCreate(new CityNaming({ ENValue: 'Ghadir', ARValue:'الغدير'}));
        this.doCreate(new CityNaming({ ENValue: 'Idrissi', ARValue:'الادريسي'}));
        this.doCreate(new CityNaming({ ENValue: 'Karrada', ARValue:'الكرادة'}));
        this.doCreate(new CityNaming({ ENValue: 'Khilani', ARValue:'الخلاني'}));
        this.doCreate(new CityNaming({ ENValue: 'Medical City', ARValue:'مدينة الطب'}));
        this.doCreate(new CityNaming({ ENValue: 'Mohandeseen', ARValue:'المهندسين'}));
        this.doCreate(new CityNaming({ ENValue: 'Neighborhood July 14', ARValue:'حي 14 تموز'}));
        this.doCreate(new CityNaming({ ENValue: 'Obeidi', ARValue:'العبيدي'}));
        this.doCreate(new CityNaming({ ENValue: 'Palestine Street', ARValue:'شارع فلسطين'}));
        this.doCreate(new CityNaming({ ENValue: 'Qahira Neighborhood', ARValue:'حي القاهرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Rashid Camp', ARValue:'معسكر الرشيد'}));
        this.doCreate(new CityNaming({ ENValue: 'Rashid Street', ARValue:'شارع الرشيد'}));
        this.doCreate(new CityNaming({ ENValue: 'Rustamiyah', ARValue:'الرستمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Sadoun Street', ARValue:'شارع السعدون'}));
        this.doCreate(new CityNaming({ ENValue: 'Sadr City', ARValue:'مدينة الصدر'}));
        this.doCreate(new CityNaming({ ENValue: 'Sbe Abkar', ARValue:'سبع ابكار'}));
        this.doCreate(new CityNaming({ ENValue: 'Sheikh Omar', ARValue:'الشيخ عمر'}));
        this.doCreate(new CityNaming({ ENValue: 'Shoraja', ARValue:'الشورجة'}));
        this.doCreate(new CityNaming({ ENValue: 'Sinak', ARValue:'السنك'}));
        this.doCreate(new CityNaming({ ENValue: 'Waziriya', ARValue:'الوزيرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Zayouna', ARValue:'زيونة'}));
        this.doCreate(new CityNaming({ ENValue: '5 Mile', ARValue:'5 ميل'}));
        this.doCreate(new CityNaming({ ENValue: 'Abbasiyah', ARValue:'العباسية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Abla', ARValue:'الابلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Andalus', ARValue:'الاندلس'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Ashar', ARValue:'العشار'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Asmai', ARValue:'الاصمعي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Bikr Walahrar', ARValue:'البكر والاحرار'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Buradieia', ARValue:'البراضعية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Faw', ARValue:'الفاو'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Gomhoria Neighborhood', ARValue:'حي الجمهورية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hakimia', ARValue:'الحكيمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jameiat', ARValue:'الجمعيات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Janina', ARValue:'الجنينة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jazayir Neighborhood', ARValue:'حي الجزائر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jazirah', ARValue:'الجزيرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jubaila', ARValue:'الجبيلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khadra', ARValue:'الخضراء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Kufaat Neighborhood', ARValue:'حي الكفاءات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Muaqil', ARValue:'المعقل'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qabla', ARValue:'القبلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qasim', ARValue:'القاسم'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Rafidain', ARValue:'حي الرافدين'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Resala Neighborhood', ARValue:'حي الرسالة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sahat', ARValue:'الساحات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Tahsinia', ARValue:'التحسينية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Taliah', ARValue:'الطليعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Tawisa', ARValue:'الطويسة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Wahda', ARValue:'حي الوحدة'}));
        this.doCreate(new CityNaming({ ENValue: 'Arabian Gulf', ARValue:'الخليج العربي'}));
        this.doCreate(new CityNaming({ ENValue: 'Briha', ARValue:'بريهة'}));
        this.doCreate(new CityNaming({ ENValue: 'Dawr Al Naft', ARValue:'دور النفط'}));
        this.doCreate(new CityNaming({ ENValue: 'Karma Ali', ARValue:'كرمة علي'}));
        this.doCreate(new CityNaming({ ENValue: 'Minawi Pasha', ARValue:'مناوي باشا'}));
        this.doCreate(new CityNaming({ ENValue: 'Muwafaqiyah', ARValue:'الموفقية'}));
        this.doCreate(new CityNaming({ ENValue: 'Nujaibiya', ARValue:'النجيبية'}));
        this.doCreate(new CityNaming({ ENValue: 'Zubayr', ARValue:'الزبير'}));
        this.doCreate(new CityNaming({ ENValue: 'Amadiyah', ARValue:'العمادية'}));
        this.doCreate(new CityNaming({ ENValue: 'Aqrah', ARValue:'عقرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Bamerni', ARValue:'بامرني'}));
        this.doCreate(new CityNaming({ ENValue: 'Bardarash', ARValue:'بردرش'}));
        this.doCreate(new CityNaming({ ENValue: 'Deiralock', ARValue:'ديرةلوك'}));
        this.doCreate(new CityNaming({ ENValue: 'Dohuk', ARValue:'دهوك'}));
        this.doCreate(new CityNaming({ ENValue: 'Mankish', ARValue:'مانكيش'}));
        this.doCreate(new CityNaming({ ENValue: 'Sarsank', ARValue:'سرسنك'}));
        this.doCreate(new CityNaming({ ENValue: 'Shekhan', ARValue:'شيخان'}));
        this.doCreate(new CityNaming({ ENValue: 'Smail', ARValue:'سميل'}));
        this.doCreate(new CityNaming({ ENValue: 'Solaf', ARValue:'سولاف'}));
        this.doCreate(new CityNaming({ ENValue: 'Zakho', ARValue:'زاخو'}));
        this.doCreate(new CityNaming({ ENValue: 'Zawaytah', ARValue:'زاويتة'}));
        this.doCreate(new CityNaming({ ENValue: 'Afak', ARValue:'عفك'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Budair', ARValue:'آل بدير'}));
        this.doCreate(new CityNaming({ ENValue: 'Daghara', ARValue:'الدغارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Diwaniyah', ARValue:'الديوانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Ghamas', ARValue:'غماس'}));
        this.doCreate(new CityNaming({ ENValue: 'Hamza', ARValue:'الحمزة'}));
        this.doCreate(new CityNaming({ ENValue: 'Muhannawiyah', ARValue:'المهناوية'}));
        this.doCreate(new CityNaming({ ENValue: 'Nefer', ARValue:'نفرر'}));
        this.doCreate(new CityNaming({ ENValue: 'Salahiyah', ARValue:'الصلاحية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shafaia', ARValue:'الشافعية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shamiya', ARValue:'الشامية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shinafiyah', ARValue:'الشنافية'}));
        this.doCreate(new CityNaming({ ENValue: 'Sudair', ARValue:'السدير'}));
        this.doCreate(new CityNaming({ ENValue: 'Sumar', ARValue:'سومر'}));
        this.doCreate(new CityNaming({ ENValue: 'Sunnya', ARValue:'السنية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Abbara', ARValue:'العبارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Azim', ARValue:'العظيم'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Ghalibia', ARValue:'الغالبية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Kufry', ARValue:'الكفري'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mansuria', ARValue:'المنصورية'}));
        this.doCreate(new CityNaming({ ENValue: 'Baladrouz', ARValue:'بلدروز'}));
        this.doCreate(new CityNaming({ ENValue: 'Baquba', ARValue:'بعقوبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Buhraz', ARValue:'بهرز'}));
        this.doCreate(new CityNaming({ ENValue: 'Dali Abbas', ARValue:'دلي عباس'}));
        this.doCreate(new CityNaming({ ENValue: 'Hibhib', ARValue:'حبحب'}));
        this.doCreate(new CityNaming({ ENValue: 'Jalawla', ARValue:'جلولاء'}));
        this.doCreate(new CityNaming({ ENValue: 'Kanaan', ARValue:'كنعان'}));
        this.doCreate(new CityNaming({ ENValue: 'Khalis', ARValue:'الخالص'}));
        this.doCreate(new CityNaming({ ENValue: 'Khan Bani Saad', ARValue:'خان بني سعد'}));
        this.doCreate(new CityNaming({ ENValue: 'Khanaqin', ARValue:'خانقين'}));
        this.doCreate(new CityNaming({ ENValue: 'Mandali', ARValue:'مندلي'}));
        this.doCreate(new CityNaming({ ENValue: 'Muqdadiya', ARValue:'المقدادية'}));
        this.doCreate(new CityNaming({ ENValue: 'Qarah Taba', ARValue:'قرة تبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Saadia', ARValue:'السعدية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Akyka', ARValue:'عقيقة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Batha', ARValue:'البطحاء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Dwaya', ARValue:'الدواية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Fadlya', ARValue:'الفضلية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Fjr', ARValue:'الفجر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Ghraf', ARValue:'الغراف'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Islah', ARValue:'الإصلاح'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Manar', ARValue:'المنار'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Nasr', ARValue:'النصر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Rifaey', ARValue:'الرفاعي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Shatra', ARValue:'الشطرة'}));
        this.doCreate(new CityNaming({ ENValue: 'AL Tar', ARValue:'الطار'}));
        this.doCreate(new CityNaming({ ENValue: 'Alfhwd', ARValue:'الفهود'}));
        this.doCreate(new CityNaming({ ENValue: 'Aljbaysh', ARValue:'الجبايش'}));
        this.doCreate(new CityNaming({ ENValue: 'Awr', ARValue:'اور'}));
        this.doCreate(new CityNaming({ ENValue: 'Krma Bani Saeed', ARValue:'كرمة بني سعيد'}));
        this.doCreate(new CityNaming({ ENValue: 'Qadaa Al Nasrya', ARValue:'قضاء الناصرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Qalea Sakar', ARValue:'قلعة سكر'}));
        this.doCreate(new CityNaming({ ENValue: 'Sayed Dakhil', ARValue:'سيد دخيل'}));
        this.doCreate(new CityNaming({ ENValue: 'Swq Al Shywkh', ARValue:'سوق الشيوخ'}));
        this.doCreate(new CityNaming({ ENValue: 'Bakra Joe', ARValue:'بكرة جو'}));
        this.doCreate(new CityNaming({ ENValue: 'Bazian', ARValue:'بازيان'}));
        this.doCreate(new CityNaming({ ENValue: 'Chamchamal', ARValue:'جمجمال'}));
        this.doCreate(new CityNaming({ ENValue: 'Darbandikan', ARValue:'دربنديخان'}));
        this.doCreate(new CityNaming({ ENValue: 'Dukan', ARValue:'دوكان'}));
        this.doCreate(new CityNaming({ ENValue: 'Halabja', ARValue:'حلبجة'}));
        this.doCreate(new CityNaming({ ENValue: 'Kalar', ARValue:'كلار'}));
        this.doCreate(new CityNaming({ ENValue: 'Khanaqin', ARValue:'خانقين'}));
        this.doCreate(new CityNaming({ ENValue: 'Khurmal', ARValue:'خورمال'}));
        this.doCreate(new CityNaming({ ENValue: 'Kifri', ARValue:'كفري'}));
        this.doCreate(new CityNaming({ ENValue: 'Penguin', ARValue:'بنجوين'}));
        this.doCreate(new CityNaming({ ENValue: 'Qara Dag', ARValue:'قرة داغ'}));
        this.doCreate(new CityNaming({ ENValue: 'Rania', ARValue:'رانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Sayed Sadiq', ARValue:'سيد صادق'}));
        this.doCreate(new CityNaming({ ENValue: 'Shara Zur', ARValue:'شارة زور'}));
        this.doCreate(new CityNaming({ ENValue: 'Sharbarees', ARValue:'شاربارييز'}));
        this.doCreate(new CityNaming({ ENValue: 'Sieh Setan', ARValue:'سيه سيتان'}));
        this.doCreate(new CityNaming({ ENValue: 'Sulaymaniyah', ARValue:'سليمانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Tangro', ARValue:'تانجرو'}));
        this.doCreate(new CityNaming({ ENValue: 'Wara Mawa', ARValue:'ورة ماوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Baiji', ARValue:'بيجي'}));
        this.doCreate(new CityNaming({ ENValue: 'Balad', ARValue:'بلد'}));
        this.doCreate(new CityNaming({ ENValue: 'Dhuluiyah', ARValue:'الضلوعية'}));
        this.doCreate(new CityNaming({ ENValue: 'Dujail', ARValue:'الدجيل'}));
        this.doCreate(new CityNaming({ ENValue: 'Samarra', ARValue:'سامراء'}));
        this.doCreate(new CityNaming({ ENValue: 'Sharqat', ARValue:'الشرقاط'}));
        this.doCreate(new CityNaming({ ENValue: 'Tikrit', ARValue:'تكريت'}));
        this.doCreate(new CityNaming({ ENValue: 'Tuzkhermatu', ARValue:'طوزخرماتو'}));
        this.doCreate(new CityNaming({ ENValue: '1 Huziran', ARValue:'1 حزيران'}));
        this.doCreate(new CityNaming({ ENValue: 'Ahmed Agha', ARValue:'احمد اغا'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Askari', ARValue:'العسكري'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Asraa Walmafqudin', ARValue:'الاسرى والمفقودين'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Baath', ARValue:'حي البعث'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Dubbat', ARValue:'الضباط'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khadraa', ARValue:'الخضراء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Mas', ARValue:'الماس'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Muealimin', ARValue:'المعلمين'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Nasr', ARValue:'حي النصر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Nour', ARValue:'حي النور'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Orouba', ARValue:'العروبة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qalaa', ARValue:'القلعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Wasti', ARValue:'الواسطي'}));
        this.doCreate(new CityNaming({ ENValue: 'Aldbs', ARValue:'الدبس'}));
        this.doCreate(new CityNaming({ ENValue: 'Amam Qasim', ARValue:'امام قاسم'}));
        this.doCreate(new CityNaming({ ENValue: 'Buriadi', ARValue:'بريادي'}));
        this.doCreate(new CityNaming({ ENValue: 'Daquq', ARValue:'داقوق'}));
        this.doCreate(new CityNaming({ ENValue: 'Grnata', ARValue:'غرناطة'}));
        this.doCreate(new CityNaming({ ENValue: 'Hawija', ARValue:'الحويجة'}));
        this.doCreate(new CityNaming({ ENValue: 'Kirkuk', ARValue:'كركوك'}));
        this.doCreate(new CityNaming({ ENValue: 'Qwria', ARValue:'قورية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shorja', ARValue:'الشورجة'}));
        this.doCreate(new CityNaming({ ENValue: 'Shunhadaa', ARValue:'الشهداء'}));
        this.doCreate(new CityNaming({ ENValue: 'Ain Tamr', ARValue:'عين تمر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Gharbi', ARValue:'الغربي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hur', ARValue:'الحر'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Husseiniya', ARValue:'الحسينية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jadwal', ARValue:'الجدول'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khayrat', ARValue:'الخيرات'}));
        this.doCreate(new CityNaming({ ENValue: 'Aoun', ARValue:'عون '}));
        this.doCreate(new CityNaming({ ENValue: 'Karbala', ARValue:'كربلاء'}));
        this.doCreate(new CityNaming({ ENValue: 'Rahhaliyah', ARValue:'الرحالية'}));
        this.doCreate(new CityNaming({ ENValue: 'Touirij', ARValue:'طويريج'}));
        this.doCreate(new CityNaming({ ENValue: 'Busyyah', ARValue:'بصية'}));
        this.doCreate(new CityNaming({ ENValue: 'Daraji', ARValue:'الدراجي'}));
        this.doCreate(new CityNaming({ ENValue: 'Hilal', ARValue:'الهلال'}));
        this.doCreate(new CityNaming({ ENValue: 'Karama', ARValue:'الكرامة'}));
        this.doCreate(new CityNaming({ ENValue: 'Khader', ARValue:'الخضر'}));
        this.doCreate(new CityNaming({ ENValue: 'Majd', ARValue:'المجد'}));
        this.doCreate(new CityNaming({ ENValue: 'Najmi', ARValue:'النجمي'}));
        this.doCreate(new CityNaming({ ENValue: 'Rumaythah', ARValue:'الرميثة'}));
        this.doCreate(new CityNaming({ ENValue: 'Salman', ARValue:'السلمان'}));
        this.doCreate(new CityNaming({ ENValue: 'Samawah', ARValue:'السماوة'}));
        this.doCreate(new CityNaming({ ENValue: 'Suwayrah', ARValue:'السويرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Warka', ARValue:'الوركاء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Adel', ARValue:'العدل'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Amara', ARValue:'العمارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Dabysat', ARValue:'الدبيسات'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khayr', ARValue:'الخير'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Khlaa', ARValue:'الكحلاء'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Majar Al Kabir', ARValue:'المجر الكبير'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Masharah', ARValue:'المشرح'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Maymuna', ARValue:'الميمونة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qadiriyah', ARValue:'القادرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sabunjina', ARValue:'الصابونجية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Salam', ARValue:'السلام'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Saray', ARValue:'السراي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Shabana', ARValue:'الشبانة'}));
        this.doCreate(new CityNaming({ ENValue: 'Ali Al Gharbi', ARValue:'علي الغربي'}));
        this.doCreate(new CityNaming({ ENValue: 'Ali Al Shariq', ARValue:'علي الشرقي'}));
        this.doCreate(new CityNaming({ ENValue: 'Bani Hashim', ARValue:'بني هاشم'}));
        this.doCreate(new CityNaming({ ENValue: 'Kumet', ARValue:'كميت'}));
        this.doCreate(new CityNaming({ ENValue: 'Mahmoudiyah', ARValue:'المحمودية'}));
        this.doCreate(new CityNaming({ ENValue: 'Qalat Salih', ARValue:'قلعة صالح'}));
        this.doCreate(new CityNaming({ ENValue: 'Sayed Ahmed Rifai', ARValue:'سيد احمد الرفاعي'}));
        this.doCreate(new CityNaming({ ENValue: 'Uzair', ARValue:'العزير'}));
        this.doCreate(new CityNaming({ ENValue: 'Abbasia', ARValue:'العباسية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Haidariyah', ARValue:'الحيدرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hurya', ARValue:'الحرية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hyra', ARValue:'الحيرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qadsyh', ARValue:'القادسية'}));
        this.doCreate(new CityNaming({ ENValue: 'Kufa', ARValue:'الكوفة'}));
        this.doCreate(new CityNaming({ ENValue: 'Manathirah', ARValue:'المناذرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Mashkhab', ARValue:'المشخاب'}));
        this.doCreate(new CityNaming({ ENValue: 'Najaf', ARValue:'النجف'}));
        this.doCreate(new CityNaming({ ENValue: 'Ridawiyah', ARValue:'الرضوية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shabakiyah', ARValue:'الشبكية'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Arabi', ARValue:'حي العربي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Jamiah', ARValue:'حي الجامعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Muthanna', ARValue:'حي المثنى'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Qwosh', ARValue:'القوش'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Sinaa', ARValue:'الصناعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Zuhour', ARValue:'حي الزهور'}));
        this.doCreate(new CityNaming({ ENValue: 'Baaj', ARValue:'البعاج'}));
        this.doCreate(new CityNaming({ ENValue: 'Badush', ARValue:'بادوش'}));
        this.doCreate(new CityNaming({ ENValue: 'Bartala', ARValue:'برطلة'}));
        this.doCreate(new CityNaming({ ENValue: 'Bashika', ARValue:'بعشيقة'}));
        this.doCreate(new CityNaming({ ENValue: 'Fayda', ARValue:'فايدة'}));
        this.doCreate(new CityNaming({ ENValue: 'Hadar', ARValue:'حضر'}));
        this.doCreate(new CityNaming({ ENValue: 'Hadbaa', ARValue:'الحدباء'}));
        this.doCreate(new CityNaming({ ENValue: 'Hammam Al Alil', ARValue:'حمام العليل'}));
        this.doCreate(new CityNaming({ ENValue: 'Makhmour', ARValue:'مخمور'}));
        this.doCreate(new CityNaming({ ENValue: 'Mosul', ARValue:'موصل'}));
        this.doCreate(new CityNaming({ ENValue: 'Nabi Younis', ARValue:'نبي يونس'}));
        this.doCreate(new CityNaming({ ENValue: 'Nujaifi', ARValue:'النجيفي'}));
        this.doCreate(new CityNaming({ ENValue: 'Qairawan', ARValue:'القيروان'}));
        this.doCreate(new CityNaming({ ENValue: 'Qayyarah', ARValue:'القيارة'}));
        this.doCreate(new CityNaming({ ENValue: 'Rabia', ARValue:'ربيعة'}));
        this.doCreate(new CityNaming({ ENValue: 'Sheikhan', ARValue:'الشيخان'}));
        this.doCreate(new CityNaming({ ENValue: 'Shoura', ARValue:'الشورة'}));
        this.doCreate(new CityNaming({ ENValue: 'Sinjar', ARValue:'سنجار'}));
        this.doCreate(new CityNaming({ ENValue: 'Talafar', ARValue:'تلعفر'}));
        this.doCreate(new CityNaming({ ENValue: 'Talkif', ARValue:'تلكيف'}));
        this.doCreate(new CityNaming({ ENValue: 'Zamar', ARValue:'زمار'}));
        this.doCreate(new CityNaming({ ENValue: 'Ahrar', ARValue:'الاحرار'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Hay', ARValue:'الحي'}));
        this.doCreate(new CityNaming({ ENValue: 'Al Saouira', ARValue:'الصويرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Aziziyah', ARValue:'العزيزية'}));
        this.doCreate(new CityNaming({ ENValue: 'Badra', ARValue:'بدرة'}));
        this.doCreate(new CityNaming({ ENValue: 'Bashaer', ARValue:'البشائر'}));
        this.doCreate(new CityNaming({ ENValue: 'Dabouni', ARValue:'الدبوني'}));
        this.doCreate(new CityNaming({ ENValue: 'Jassan', ARValue:'جصان'}));
        this.doCreate(new CityNaming({ ENValue: 'Kut', ARValue:'الكوت'}));
        this.doCreate(new CityNaming({ ENValue: 'Muwaffaqiyah', ARValue:'الموفقية'}));
        this.doCreate(new CityNaming({ ENValue: 'Numaniyah', ARValue:'النعمانية'}));
        this.doCreate(new CityNaming({ ENValue: 'Shehaymiyah', ARValue:'الشحيمية'}));
        this.doCreate(new CityNaming({ ENValue: 'Sheikh Saad', ARValue:'الشيخ سعد'}));
        this.doCreate(new CityNaming({ ENValue: 'Taj Aldin', ARValue:'تاج الدين'}));
        this.doCreate(new CityNaming({ ENValue: 'Zubaydiyah', ARValue:'الزبيدية'}));
        this.doCreate(new CityNaming({ ENValue: 'Zurbatiya', ARValue:'زرباطية'}));
    }
}
