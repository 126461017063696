/* tslint:disable:member-ordering */

import {
    Action,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    catchError,
    finalize,
} from 'rxjs/operators';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    DevtoolsService,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    ClearThrottlingFromDevtoolsPage,
    FailFromApi,
} from './throttling.actions';

import {
    Notifications,
} from './throttling.notifications';


export interface ThrottlingStateModel {
    cleaning: boolean;
    problem: Problem;
}

const stateDefaults: ThrottlingStateModel = {
    cleaning: undefined,
    problem: undefined,
};

@State<ThrottlingStateModel>({
    name: 'throttling',
    defaults: stateDefaults,
})
export class ThrottlingState {

    @Selector()
    public static cleaning(state: ThrottlingStateModel): boolean {
        return state.cleaning;
    }

    @Selector()
    public static problem(state: ThrottlingStateModel): Problem {
        return state.problem;
    }

    constructor(
        private _nc: NotificationCenter,
        private _devtoolsService: DevtoolsService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<ThrottlingStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<ThrottlingStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    @Action(ClearThrottlingFromDevtoolsPage)
    public clearThrottling(ctx: StateContext<ThrottlingStateModel>): any {
        this._nc.show(Notifications.ClearingThrottling);
        ctx.patchState({ cleaning: true });

        return this._devtoolsService.clearThrottling().pipe(
            catchError((problem) => {
                return ctx.dispatch(new FailFromApi({ problem: problem }));
            }),
            finalize(() => {
                ctx.patchState({ cleaning: false });
            }),
        );
    }
}
