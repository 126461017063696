import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';


@Component({
  selector: 'nym-wallet-activity-listing-header',
  templateUrl: './wallet-activity-listing-header.component.html',
  styleUrls: ['./wallet-activity-listing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletActivityListingHeaderComponent implements OnInit {

  @HostBinding('class.nym-wallet-activity-listing-header')
  protected get classes(): boolean { return true; }

  @Input()
  public title: string;

  @Input()
  public description: string;

  constructor() { }

  public ngOnInit(): void { }

}
