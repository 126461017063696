import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { Observable,Subject, BehaviorSubject, Subscription } from 'rxjs';

import {
  Account,
} from '@nymos/accounts/core';

import { AccountsUsersState } from '../../../core/store/accounts-users/accounts-users.state';

import { SetStatusLoadingFromStatusMenu } from '../../../core/store/accounts-users/accounts-users.actions';

import {
  CoreAccount
} from '@michel.freiha/ng-sdk';

import {
  ScopesChecker,
} from '@nymos/auth';

import {
  StatusMenu,
} from '@nymos/accounts/core';

import {
  Select,
  Store,
} from '@ngxs/store';


interface ActionStatus {
  action: string;
  status: string;
}

interface StatusMenuMap {
  [name: string]: StatusMenu;
}

@Component({
  selector: 'nym-status-menu',
  templateUrl: './status-menu.component.html',
  styleUrls: ['./status-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StatusMenuComponent implements OnInit, OnChanges {

  private _map: StatusMenuMap = {};
  private _actionStatus: ActionStatus[] = [];
  private _actionStatusScopes: string[] = [];
  private _userProfile={};
  private statusLoading$: Observable<any>;
  private statusLoading:boolean;


  @HostBinding('class.nym-status-menu')
  protected get classes(): boolean { return true; }

  @Output()
  protected next: EventEmitter<string> = new EventEmitter();

  @Input()
  public status: StatusMenu;

  @Input()
  public statuses: StatusMenu[];

  

  @Input()
  public disabled: boolean = false;

  private s1: Subscription = new Subscription();

  protected get isDisabled(): boolean {
    return this.disabled || this.status && !this.status.next || !this._sc.isAuthedOnly(this._actionStatusScopes);
  }
  @Input()
  public account: Account;

   protected get incAgentProfile(): CoreAccount { return this.account && this.account.kyc2SubmittedBy; }

   protected get actionStatus(): ActionStatus[] { return this._actionStatus; }


  constructor(
    private _sc: ScopesChecker,
    private _store: Store,
  ) { }

  public ngOnInit(): void {
     this._store.selectSnapshot((state) =>{
         if(state.profile && state.profile.admin)
            this._userProfile = state.profile.admin.profile;
      })

     this.statusLoading$ = this._store.select(AccountsUsersState.statusLoading);

     this.s1=this.statusLoading$.subscribe((statusLoading) => {
      this.statusLoading = statusLoading;
      if(this.statusLoading === false ){
      this.disabled=false;
       return this._store.dispatch(new SetStatusLoadingFromStatusMenu(true));
       this.s1.unsubscribe();
    }
    });
    }

  public ngOnChanges(changes: SimpleChanges): void {
    const statusChanges = changes['status'];
    const statusesChanges = changes['statuses'];

     if(this.account && this.account.channel.channel !== "pending" && statusChanges){
    this.disabled=false;
    }

    if (statusChanges && statusChanges.currentValue) {
      this._actionStatus = Object.keys(statusChanges.currentValue.next).map((action) => ({ action: action, status: this.status.next[action] }));
      this._actionStatusScopes = this.actionStatus.map((n) => n.status).reduce((array, o) => [...array, ...this.scopes(o)], []);
    }

    if (statusesChanges && statusesChanges.currentValue) {
      this._map = statusesChanges.currentValue.reduce((map, o) => { map[o.name] = o; return map; }, {});
    }
  }

  protected next$$(action: string): void {

    if(this.account && this.account.channel.channel === "pending" && this.account.channel.documentType.toString() === "KYC2" && action === "verified"){
    this.disabled=true;
    }
    
    this.next.emit(action);
  }

  protected color(name: string): string {
    return this._map[name] && this._map[name].color || 'primary';
  }

  protected scopes(name: string): string[] {
    return this._map[name] && this._map[name].scopes || [];
  }

}
