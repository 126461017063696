import {
    Injectable,
} from '@angular/core';

import {
    CanActivate,
} from '@angular/router';


import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    AuthState,
} from '../store/auth/auth.state';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _store: Store,
    ) {  }

    public canActivate(): boolean {
        console.log('called auth gaurd')
        const partial_url = window.location.href.split('/')[3];
        const token = this._store.selectSnapshot(AuthState.accessToken);
        const authed = (token !== undefined);

        if (!authed) {
            if(partial_url != ''){
                this._store.dispatch(new Navigate([partial_url+'/account/signin']));

            } else {
                this._store.dispatch(new Navigate(['/account/account/signin']));
            }
        }

        return authed;
    }
}
