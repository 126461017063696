export class Result {
    public comment: string = '';
    public option: string = '';

    constructor(ob?: Partial<Result>) {

        if (!ob)
            return;

        if (ob.option !== undefined) { this.option = ob.option; }
        if (ob.comment !== undefined) { this.comment = ob.comment; }
    }
}
