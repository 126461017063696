import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AnimationEvent,
} from '@angular/animations';

import {
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

import {
  Navigate,
} from '@ngxs/router-plugin';


type expandedState = 'expanded' | 'collapsed';

@Component({
  selector: 'thm-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  
})
export class LogoutComponent implements OnInit, OnChanges {

  @HostBinding('class.thm-logout')
  protected get classes(): boolean { return true; }

  public display = 0;


  constructor(private _store: Store) { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  public signOut(): void {
    localStorage.removeItem('auth.token');
    var partial_url = window.localStorage.getItem('apikey')
    this._store.dispatch(new Navigate([partial_url+'/account/signin']));
  }

  public toggleClick(val): void {
    
    if(val == 0){
      val = 1
    } else {
      val = 0
    }

    this.display = val;

  }


}
