import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    HomepageComponent,
} from './ui/containers/homepage/homepage.component';
import { SearchGuard } from '@nymos/dashboard/shared';
import { ListingComponent } from './ui/containers/listing/listing.component';


export const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
    },
    {
        path: 'search',
        component: ListingComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TokensRoutingModule { }
