import {
    NgModule,
} from '@angular/core';

import {
    SelectedDirective,
 } from './selected.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        SelectedDirective,
    ],

    imports: [],

    exports: [
        SelectedDirective,
    ],

})
export class ThemeDirectivesSelectedModule { }
