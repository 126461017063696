import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'channelColor',
})
export class ChannelColorPipe implements PipeTransform {

  public transform(channel: string): string {

    switch (channel) {
      case 'active':
        return 'green';
      case 'verified':
        return 'blue';
      case 'pending':
        return 'yellow';
      case 'incomplete':
        return 'purple';
      case 'rejected':
        return 'red';
      case 'blocked':
        return 'menu';
      default:
        return 'default';
    }
  }

}
