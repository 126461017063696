import 'hammerjs';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import {
  CanShimmer,
  CanShimmerCtor,
  mixinShimmer,
} from '../../../../../core/core';

export class ShimmerBase {
  constructor(public _elementRef: ElementRef, public _renderer: Renderer2) { }
}

export const _ShimmerMixinBase:
  CanShimmerCtor & typeof ShimmerBase =
  mixinShimmer(ShimmerBase);

@Component({
  selector: `thm-shimmer, span[thmShimmer]`, // tslint:disable-line: component-selector
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss'],
  inputs: ['thmShimmer', 'thmShimmerShape'], // tslint:disable-line:no-inputs-metadata-property
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ShimmerComponent extends _ShimmerMixinBase
  implements OnInit, CanShimmer {

  @HostBinding('class.thm-shimmer')
  protected get classes(): boolean { return true; }

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
  ) {
    super(elementRef, renderer);
  }

  public ngOnInit(): void { }

}
