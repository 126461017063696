import {
    Params,
} from '@angular/router';

import {
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class LoadWalletsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load wallets';
    constructor(public accountId: string) { }
}

export class LoadWalletsFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Load wallets';
    constructor(public accountId: string) { }
}

export class RefreshWalletsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Refresh wallets';
    constructor(public accountId: string) { }
}

export class RefreshWalletsFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Refresh wallets';
    constructor(public accountId: string) { }
}


export class FailFromApi {
    public static readonly type: string = '[Wallet API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
