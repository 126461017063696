import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'thm-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {

  @HostBinding('class.thm-card')
  protected get classes(): boolean { return true; }

  @Output('retry') // tslint:disable-line:no-output-rename
  protected retryEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  @HostBinding('class.thm-card-loading')
  public loading: boolean;

  @Input()
  public error: any;

  constructor() { }

  public ngOnInit(): void { }

  protected retry(): void {
    this.retryEmitter.emit();
  }

}
