import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  MatSelectChange,
} from '@angular/material/select';

@Component({
  selector: 'nym-user-listing-header',
  templateUrl: './user-listing-header.component.html',
  styleUrls: ['./user-listing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserListingHeaderComponent implements OnInit {

  @HostBinding('class.nym-user-listing-header')
  protected get classes(): boolean { return true; }

  @Output()
  protected create: EventEmitter<string> = new EventEmitter();

  @Output()
  protected filter: EventEmitter<any> = new EventEmitter();

  @Output()
  protected documentFilter: EventEmitter<any> = new EventEmitter();

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public types: string[];

  public documenttypes = ['KYC1', 'KYC2'];

  public selected: string[];

  constructor() { }

  public ngOnInit(): void { }

  protected create$$(type: string): void {
    this.create.emit(type);
  }

  protected filter$$(event: MatSelectChange): void {
    this.filter.emit(event.value);
  }

  protected documentFilter$$(event: MatSelectChange): void {
    this.documentFilter.emit(event.value);
  }

}
