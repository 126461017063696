import {
    Admin,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class LoadAdminsFromListingPage {
    public static readonly type: string = '[Admins] Load admin';
    constructor() { }
}

export class SearchAdminsFromListingPage {
    public static readonly type: string = '[Admins] Search admin';
    constructor(public payload?: { criteria: SearchCriteria }) { }
}

export class LoadMoreAdminsFromListingPage {
    public static readonly type: string = '[Admins] Load more admins';
}

export class RefreshAdminFromEditDialog {
    public static readonly type: string = '[Admin Edit Dialog] Refresh admin';
    constructor(public payload: { id: string }) { }
}

export class CreateAdminFromEditDialog {
    public static readonly type: string = '[Admin Edit Dialog] Create new admin';
    constructor(public payload: { data: Admin }) { }
}

export class UpdateAdminFromEditDialog {
    public static readonly type: string = '[Admin Edit Dialog] Update existing admin';
    constructor(public payload: { id: string; data: Admin }) { }
}

export class UpdateAdminStatusFromEditDialog {
    public static readonly type: string = '[Admins] Update admin status';
    constructor(public payload: { id: string; data: Admin }) { }
}

export class ResetAdminPasscodeFromEditDialog {
    public static readonly type: string = '[Admin Edit Dialog] Reset admin passcode';
    constructor(public payload: { id: string }) { }
}

export class FailFromApi {
    public static readonly type: string = '[Admin API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
