/* tslint:disable:no-host-metadata-property */
/* tslint:disable:no-inputs-metadata-property */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

import {
  MatProgressBar,
} from '@angular/material/progress-bar';

@Component({
  selector: 'thm-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'role': 'progressbar',
    'aria-valuemin': '0',
    'aria-valuemax': '100',
    '[attr.aria-valuenow]': '(mode === "indeterminate" || mode === "query") ? null : value',
    '[attr.mode]': 'mode',
  },
  inputs: ['color'],
})
export class ProgressBarComponent extends MatProgressBar {

  @HostBinding('class.mat-progress-bar')
  @HostBinding('class.thm-progress-bar')
  protected _classes: boolean = true;

}
