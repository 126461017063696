/* tslint:disable:member-ordering */

import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  produce,
} from 'immer';

import {
  throwError,
} from 'rxjs';

import {
  catchError,
  finalize,
  tap,
} from 'rxjs/operators';

import {
  CoreAccountRef,
  CoreNote,
  CoreNoteLink,
  Note as CoreNoteInternal,
  NoteIndex,
  NoteIndexCollection,
  NotesSearchService,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  AccountsInternalService,
} from '@michel.freiha/ng-sdk';

import {
  NotificationCenter,
} from '@nymos/dashboard/shared';

import {
  AddNoteFromPage,
  FailFromApi,
  LoadNotesFromPage,
  LoadUserNotesFromPage,
  LoadCardNotesFromPage
} from './notes.actions';

import {
  Note,
} from '../../models/note.model';

import {
  NoteBuilder,
} from '../../builders/note.builder';

import {
  Notifications,
} from './notes.notifications';


export interface NotesStateModel {
  items: { [id: string]: CoreNoteInternal };
  indexes: { [id: string]: { ids: string[] } };
  userIndexes: { [id: string]: { ids: string[] } };
  cardIndexes: { [id: string]: { ids: string[] } };
  loading: boolean;
  saving: boolean;
  problem: Problem;
}

const stateDefaults: NotesStateModel = {
  items: {},
  indexes:{},
  userIndexes: {},
  cardIndexes: {},
  loading: undefined,
  saving: undefined,
  problem: undefined,
};

@State<NotesStateModel>({
  name: 'notes',
  defaults: stateDefaults,
})
export class NotesState {
  public static notes(id: string): any {
    return createSelector([NotesState], (state: NotesStateModel) => {
      const items = state.indexes[id].ids.map((idx) => new NoteBuilder().withNoteInternal(state.items[idx]).build());
      const results = items.sort((a: Note, b: Note) => b.created.getTime() - a.created.getTime());
      return results;
    });
  }

  public static userNotes(id: string): any {
    return createSelector([NotesState], (state: NotesStateModel) => {
      const items = state.userIndexes[id].ids.map((idx) => new NoteBuilder().withNoteInternal(state.items[idx]).build());
      const results = items.sort((a: Note, b: Note) => b.created.getTime() - a.created.getTime());
      return results;
    });
  }

  public static cardNotes(id: string): any {
    return createSelector([NotesState], (state: NotesStateModel) => {
      const items = state.cardIndexes[id].ids.map((idx) => new NoteBuilder().withNoteInternal(state.items[idx]).build());
      const results = items.sort((a: Note, b: Note) => b.created.getTime() - a.created.getTime());
      return results;
    });
  }

  @Selector()
  public static problem(state: NotesStateModel): Problem {
    return state.problem;
  }

  @Selector()
  public static loading(state: NotesStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static saving(state: NotesStateModel): boolean {
    return state.saving;
  }

  constructor(
    private _nc: NotificationCenter,
    private _accountsService: AccountsInternalService,
    private _notesSearchService: NotesSearchService,
  ) { }

  @Action(FailFromApi)
  public fail(ctx: StateContext<NotesStateModel>, { payload: { problem } }: FailFromApi): void {
    ctx.patchState({ problem: problem });
  }

  @Action(LoadNotesFromPage)
  public loadNotes(ctx: StateContext<NotesStateModel>, { criteria }: LoadNotesFromPage): any {
    ctx.setState({ ...stateDefaults, loading: true });

    return this._notesSearchService.searchNotes(criteria).pipe(
      tap((collection: NoteIndexCollection) => {
        ctx.setState(produce((draft: NotesStateModel) => {
          collection.data.forEach((o) => {
            const internal = this._toInternal(o);
            draft.items[o.id] = internal;

            const indexes = internal.links.map((l) => l.entityId);
            indexes.forEach((idx) => {
              draft.indexes[idx] = draft.indexes[idx] ? draft.indexes[idx] : { ids: [] };
              draft.indexes[idx].ids.push(internal.id);
            });
          });
        }));
      }),

      catchError((problem) => {
        // Let global handler catch it
        return throwError(problem);
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(LoadUserNotesFromPage)
  public loadUserNotes(ctx: StateContext<NotesStateModel>, { criteria }: LoadUserNotesFromPage): any {
    ctx.setState({ ...stateDefaults, loading: true });

    return this._notesSearchService.searchNotes(criteria).pipe(
      tap((collection: NoteIndexCollection) => {
        ctx.setState(produce((draft: NotesStateModel) => {
          collection.data.forEach((o) => {
            if(o.links && (o.links.toLowerCase().indexOf("card")== -1)){
            const internal = this._toInternal(o);
            draft.items[o.id] = internal;

            const userIndexes = internal.links.map((l) => l.entityId);
            userIndexes.forEach((idx) => {
              draft.userIndexes[idx] = draft.userIndexes[idx] ? draft.userIndexes[idx] : { ids: [] };
              draft.userIndexes[idx].ids.push(internal.id);
            });

            }
          });
        }));
      }),

      catchError((problem) => {
        // Let global handler catch it
        return throwError(problem);
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(LoadCardNotesFromPage)
  public loadCardNotes(ctx: StateContext<NotesStateModel>, { criteria }: LoadCardNotesFromPage): any {
    ctx.setState({ ...stateDefaults, loading: true });

    return this._notesSearchService.searchNotes(criteria).pipe(
      tap((collection: NoteIndexCollection) => {
        ctx.setState(produce((draft: NotesStateModel) => {
          collection.data.forEach((o) => {
             if(o.links && (o.links.toLowerCase().indexOf("card")!= -1)){
            const internal = this._toInternal(o);
            draft.items[o.id] = internal;

            const cardIndexes = internal.links.map((l) => l.entityId);
            cardIndexes.forEach((idx) => {
              draft.cardIndexes[idx] = draft.cardIndexes[idx] ? draft.cardIndexes[idx] : { ids: [] };
              draft.cardIndexes[idx].ids.push(internal.id);
            });

          }
          });
        }));
      }),

      catchError((problem) => {
        // Let global handler catch it
        return throwError(problem);
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(AddNoteFromPage)
  public addNewNote(ctx: StateContext<NotesStateModel>, { id, note }: AddNoteFromPage): any {

    ctx.patchState({ saving: true });

    this._nc.show(Notifications.Saving);

    return this._accountsService.createAccountNote(id, note).pipe(
      tap((internal: CoreNoteInternal) => {
        ctx.setState(produce((draft: NotesStateModel) => {
          draft.items[internal.id] = internal;

          const indexes = internal.links.map((l) => l.entityId);
          indexes.forEach((idx) => {
            draft.indexes[idx] = draft.indexes[id] ? draft.indexes[idx] : { ids: [] };
            draft.indexes[idx].ids.push(internal.id);
          });

        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi({ problem: problem }));
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  private _toLinks(links: string): CoreNoteLink[] {
    if (!links)
      return [];

    return links.split(' ').map((l) => {
      const link = l.split(':');
      return { entityKind: link[0], entityId: link[1] };
    });
  }

  private _toInternal(noteIndex: NoteIndex): CoreNoteInternal {
    return new CoreNoteInternal({
      id: noteIndex.id,
      note: new CoreNote({
        category: noteIndex.category,
        title: noteIndex.title,
        description: noteIndex.description,
      }),
      author: new CoreAccountRef({
        id: noteIndex.authorId,
        type: noteIndex.authorType,
        email: noteIndex.authorEmail,
        mobile: noteIndex.authorMobile,
        displayName: noteIndex.authorName,
      }),
      account: new CoreAccountRef({
        id: noteIndex.accountId,
      }),
      links: this._toLinks(noteIndex.links),
      created: new Date(noteIndex.created),
      modified: new Date(noteIndex.modified),
    });
  }
}
