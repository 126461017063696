import {
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class LoadCardActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load card activities';
    constructor(public accountId: string) { }
}

export class LoadMoreCardActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load more cards';
}

export class LoadCardActivitiesFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Load card activities';
    constructor(public cardId: string) { }
}

export class LoadMoreCardActivitiesFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Load more cards';
}

export class RefreshCardActivitiesFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Refresh card activities';
    constructor(public accountId: string) { }
}

export class RefreshCardActivitiesFromUserDetailsPageNew {
    public static readonly type: string = '[User Details Page] Refresh card activities';
    constructor(public accountId: string) { }
}

export class FailFromApi {
    public static readonly type: string = '[Card Activities API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
