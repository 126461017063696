import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  MatTableDataSource,
} from '@angular/material/table';

import {
  SelectionModel,
} from '@angular/cdk/collections';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminRole,
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  AdminScope,
} from '../../../sdk/models/admin-scope.model';

import {
  ScopesState,
} from '../../../store/scopes/scopes.state';

import {
  RolesState,
} from '../../../store/roles/roles.state';

import {
  UpdateRoleFromEditDialog,
} from '../../../store/roles/roles.actions';


@Component({
  selector: 'nym-role-comparison',
  templateUrl: './role-comparison.component.html',
  styleUrls: ['./role-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleComparisonComponent implements OnInit, OnDestroy {

  @HostBinding('class.nym-role-comparison')
  protected get classes(): boolean { return true; }

  private _subscription: Subscription = new Subscription();

  private _selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  private _dataSource: MatTableDataSource<AdminScope> = new MatTableDataSource();
  private _roles: AdminRoleInternal[] = [];


  @Select(RolesState.selected)
  public _roles$: Observable<AdminRoleInternal[]>;

  @Select(ScopesState.scopes)
  public _scopes$: Observable<AdminScope[]>;

  public get roles(): AdminRoleInternal[] {
    return this._roles;
  }

  public set roles(roles: AdminRoleInternal[]) {
    this._roles = roles;

    this._roles.forEach((r) =>
      r.role.scopes.map((s) => this._selection.select(this._createKey(r.id, s))),
    );
  }

  public get selection(): SelectionModel<string> {
    return this._selection;
  }

  public get dataSource(): MatTableDataSource<AdminScope> {
    return this._dataSource;
  }

  public get displayedColumns(): string[] {
    return ['permission', ...this._roles.map((r) => r.id)];
  }

  constructor(
    private _store: Store,
  ) { }

  public ngOnInit(): void {
    this._subscription.add(this._roles$.subscribe((roles) => {
      this.roles = roles;
    }));

    this._subscription.add(this._scopes$.subscribe((scopes) => {
      this._dataSource = new MatTableDataSource(scopes);
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public toggle(role: AdminRoleInternal, permission: AdminScope): void {
    this._selection.toggle(this._createKey(role.id, permission.id));

    const keys = this._selection.selected.filter((e) => e.indexOf(role.id) >= 0);
    const scopes = keys.map((e) => e.replace(this._createKey(role.id, ''), ''));

    const data = new AdminRole({
      id: role.role.id,
      name: role.role.name,
      color: role.role.color,
      category: role.role.category,
      scopes: scopes,
      status: role.role.status,
    });

    this._store.dispatch(new UpdateRoleFromEditDialog({ id: role.id, data: data }));
  }

  public isSelected(role: AdminRoleInternal, permission: AdminScope): boolean {
    return this._selection.isSelected(this._createKey(role.id, permission.id));
  }

  private _createKey(roleId: string, permissionId: string): string {
    return roleId + '-' + permissionId;
  }

}
