import {
    PrivateKey,
    PublicKey,
    RSA,
} from '../utils/rsa.util';

export class RsaPair {

    public publicPem: string;
    public privatePem: string;

    public get publicKey(): PublicKey { return  RSA.publicKeyFromPem(this.publicPem); }
    public get privateKey(): PrivateKey { return  RSA.privateKeyFromPem(this.privatePem); }
    public get fingerprint(): string { return  RSA.fingerprint(this.publicKey); }

    constructor(ob?: Partial<RsaPair>) {

        this.publicPem = undefined;
        this.privatePem = undefined;

        if (!ob) {
            return this._new();
        }

        if (ob.publicPem !== undefined) { this.publicPem = ob.publicPem; }
        if (ob.privatePem !== undefined) { this.privatePem = ob.privatePem; }

    }

    private _new(): any {
        const pair = RSA.pair();
        this.publicPem = RSA.publicKeyToPem(pair.publicKey);
        this.privatePem = RSA.privateKeyToPem(pair.privateKey);
        return;
    }
}
