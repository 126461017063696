import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  SelectionModel,
} from '@angular/cdk/collections';

import {
  AdminInternal,
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  AdminShimmer,
} from '../../../sdk/shimmers/admin.shimmer';

@Component({
  selector: 'nym-admin-listing-table',
  templateUrl: './admin-listing-table.component.html',
  styleUrls: ['./admin-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminListingTableComponent implements OnInit {

  @HostBinding('class.nym-admin-listing-table')
  protected get classes(): boolean { return true; }

  private _selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  private _displayedColumns: string[] = ['avatar', 'name', 'roles', 'buttons'];

  @Input()
  public admins: AdminInternal[];

  @Input()
  public loading: boolean;

  @Input()
  public hasMore: boolean;

  @Input()
  public roles: AdminRoleInternal[];

  @Input()
  public shimmers: AdminShimmer[] = Array.from(Array(5), () => new AdminShimmer());

  @Output('loadMore') // tslint:disable-line:no-output-rename
  public loadMoreEmitter: EventEmitter<any> = new EventEmitter();

  @Output('edit') // tslint:disable-line:no-output-rename
  public editEmitter: EventEmitter<AdminInternal> = new EventEmitter();

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<string[]> = new EventEmitter();

  public get selection(): SelectionModel<string> { return this._selection; }

  public get displayedColumns(): string[] { return this._displayedColumns; }

  constructor(
    private _cd: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void { }


  public loadMore(): void {
    this.loadMoreEmitter.emit();
  }

  public edit(admin: AdminInternal): void {
    this.editEmitter.emit(admin);
  }

  public masterSelected(): boolean {
    return this.selection.hasValue() && this._isAllSelected();
  }

  public someSelected(): boolean {
    return this.selection.hasValue() && !this._isAllSelected();
  }

  public masterToggle(): void {
    this._isAllSelected() ?
      this.selection.clear() :
      this.admins.forEach((element) => this.selection.select(element.id));
    this.selectEmitter.emit(this._selection.selected);
    this._cd.markForCheck();
  }

  public toggle(admin: AdminInternal): void {
    this._selection.toggle(admin.id);
    this.selectEmitter.emit(this._selection.selected);
    this._cd.markForCheck();
  }

  public getRoles(roles: string[] | string): AdminRoleInternal[] {
    if (!roles)
      return [];

    const ids = typeof roles !== 'string' ? roles : roles.split('|');
    return this.roles.filter((r) => ids.indexOf(r.id) >= 0);
  }

  private _isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.admins.length;
    return numSelected === numRows;
  }

}
