export class Balance {
    public amount: number;
    public currency: string;

    constructor(ob?: Partial<Balance>) {

        this.amount = undefined;
        this.currency = undefined;

        if (!ob) { return; }

        if (ob.amount !== undefined) { this.amount = ob.amount; }
        if (ob.currency !== undefined) { this.currency = ob.currency; }
    }
}
