import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  SelectionModel,
} from '@angular/cdk/collections';

import {
  MatSort,
} from '@angular/material/sort';

import {
  AdminInternal,
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  ADMIN_ROLE_FILTER,
} from '../../../sdk/filters/admin-role.filter';

@Component({
  selector: 'nym-admin-role-list',
  templateUrl: './admin-role-list.component.html',
  styleUrls: ['./admin-role-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRoleListComponent implements OnInit {

  @HostBinding('class.nym-admin-role-list')
  protected get classes(): boolean { return true; }

  private _selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  private _displayedColumns: string[] = ['checkbox', 'role', 'icon'];

  @Input()
  public roles: AdminRoleInternal[];

  @Input()
  public set admin(admin: AdminInternal) { this._selectRoles(admin); }

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<string[]> = new EventEmitter();

  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  public get predicate(): any { return ADMIN_ROLE_FILTER; }

  public get selection(): SelectionModel<string> { return this._selection; }

  public get displayedColumns(): string[] { return this._displayedColumns; }

  public ngOnInit(): void { }

  public toggle(role: AdminRoleInternal): void {
    this._selection.toggle(role.id);
    this.selectEmitter.emit(this._selection.selected);
  }

  public masterSelected(): boolean {
    return this.selection.hasValue() && this._isAllSelected();
  }

  public someSelected(): boolean {
    return this.selection.hasValue() && !this._isAllSelected();
  }

  public masterToggle(): void {
    this.masterSelected() ?
      this._selection.clear() :
      this.roles.forEach((r) => this._selection.select(r.id));

    this.selectEmitter.emit(this._selection.selected);
  }

  private _selectRoles(data: AdminInternal): void {
    if (!data)
      return;

    data.admin.roles.map((r) => this._selection.select(r));
  }

  private _isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.roles.length;
    return numSelected === numRows;
  }
}
