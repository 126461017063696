import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'thm-creditcard',
  templateUrl: './creditcard.component.html',
  styleUrls: ['./creditcard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CreditcardComponent implements OnInit {

  @HostBinding('class.thm-creditcard')
  protected get classes(): boolean { return true; }

  @Input()
  public number: string;

  @Input()
  public network: string;

  @Input()
  public validThrough:string;
  
  @Input()
  public cardHolderName:string;

  

  @Input()
  public art: string = 'creditcard:default';

  constructor() { }

  public ngOnInit(): void { 
  console.log("art",this.number,this.art);

  }

}
