import {
  NgModule,
} from '@angular/core';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  DragDropModule,
} from '@angular/cdk/drag-drop';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatCheckboxModule,
} from '@angular/material/checkbox';

import {
  MatRippleModule,
} from '@angular/material/core';

import {
  MatDialogModule,
} from '@angular/material/dialog';

import {
  MatDividerModule,
} from '@angular/material/divider';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatMenuModule,
} from '@angular/material/menu';

import {
  MatSortModule,
} from '@angular/material/sort';

import {
  MatTableModule,
} from '@angular/material/table';

import {
  MatTooltipModule,
} from '@angular/material/tooltip';


import {
  MatElevationModule,
} from '@michel.freiha/material';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  TimeAgoPipe,
} from 'time-ago-pipe';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  AdminsRoutingModule,
} from './admins.routing';

import {
  AdminsState,
} from './store/admins/admins.state';

import {
  RolesState,
} from './store/roles/roles.state';

import {
  RolesSelectedState,
} from './store/roles-selected/roles-selected.state';

import {
  ScopesState,
} from './store/scopes/scopes.state';

import {
  RoleIconPipe,
} from './pipes/role-icon.pipe';

import {
  AdminFullNamePipe,
} from './pipes/admin-fullname.pipe';

import {
  RoleEditComponent,
} from './ui/containers/role-edit/role-edit.component';

import {
  RoleComparisonComponent,
} from './ui/containers/role-comparison/role-comparison.component';

import {
  RoleListingComponent,
} from './ui/containers/role-listing/role-listing.component';

import {
  AccessManagementComponent,
} from './ui/containers/access-management/access-management.component';

import {
  AdminEditComponent,
} from './ui/containers/admin-edit/admin-edit.component';

import {
  AdminEditFormComponent,
} from './ui/components/admin-edit-form/admin-edit-form.component';

import {
  AdminInfoComponent,
} from './ui/components/admin-info/admin-info.component';

import {
  AdminInfoItemComponent,
} from './ui/components/admin-info-item/admin-info-item.component';

import {
  AdminActionsComponent,
} from './ui/components/admin-actions/admin-actions.component';

import {
  AdminRoleListComponent,
} from './ui/components/admin-role-list/admin-role-list.component';

import {
  RoleAsideComponent,
} from './ui/components/role-aside/role-aside.component';

import {
  RoleAsideItemComponent,
} from './ui/components/role-aside-item/role-aside-item.component';

import {
  RoleEditMenuComponent,
} from './ui/components/role-edit-menu/role-edit-menu.component';

import {
  AdminListingTableComponent,
} from './ui/components/admin-listing-table/admin-listing-table.component';

import {
  RoleAsideHeaderComponent,
} from './ui/components/role-aside-header/role-aside-header.component';

import {
  RoleListingTableComponent,
} from './ui/components/role-listing-table/role-listing-table.component';

import {
  RoleEditFormComponent,
} from './ui/components/role-edit-form/role-edit-form.component';

import {
  RolePermissionListComponent,
} from './ui/components/role-permission-list/role-permission-list.component';



export const MATERIAL_MODULES = [
  DragDropModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatElevationModule,
];

export const PIPES = [
  AdminFullNamePipe,
  RoleIconPipe,
  TimeAgoPipe,
];


@NgModule({
  declarations: [
    ...PIPES,
    AdminEditComponent,
    AccessManagementComponent,
    RoleComparisonComponent,
    RoleEditComponent,
    RoleListingComponent,
    // Components
    AdminActionsComponent,
    AdminEditFormComponent,
    AdminInfoComponent,
    AdminInfoItemComponent,
    AdminListingTableComponent,
    AdminRoleListComponent,
    RoleAsideComponent,
    RoleAsideItemComponent,
    RoleAsideHeaderComponent,
    RoleEditFormComponent,
    RoleEditMenuComponent,
    RoleListingTableComponent,
    RolePermissionListComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    SharedModule,
    ReactiveFormsModule,

    AdminsRoutingModule,
    NgxsModule.forFeature([
      AdminsState,
      RolesState,
      RolesSelectedState,
      ScopesState,
    ]),
  ],

  entryComponents: [
    RoleEditComponent,
    AdminEditComponent,
  ],
})
export class AdminsModule { }
