// tslint:disable: member-ordering

import {
    Injectable,
} from '@angular/core';

import {
    emptyOptions,
    MemoryBackend,
    ModelFactory,
} from '@nymos/backend';

import {
    NewProvince,
} from '../../lib/core/models/new-province.model';

class Factory extends ModelFactory<NewProvince> {
    constructor() {
        super(null, (m: NewProvince) => m.key);
    }
}


@Injectable({
    providedIn: 'root',
})
export class AccountProvinceNewDatastore extends MemoryBackend<NewProvince> {

    constructor(
    ) {
        super(emptyOptions, new Factory());

        this.afterInit();
    }

    public get(id: string): NewProvince {
        return this.doLoad(id);
    }

    public all(): NewProvince[] {
        return this.values();
    }

    protected afterInit(): any {
          this.doCreate(new NewProvince({
    "en": "Kirkuk",
    "cities": [
      {
        "key": "kirkuk",
        "en": "Kirkuk",
        "ar": "كركوك"
      },
      {
        "key": "al_baath",
        "en": "Al Baath",
        "ar": "حي البعث"
      },
      {
        "key": "al_wasti",
        "en": "Al Wasti",
        "ar": "الواسطي"
      },
      {
        "key": "grnata",
        "en": "Grnata",
        "ar": "غرناطة"
      },
      {
        "key": "al_nasr",
        "en": "Al Nasr",
        "ar": "النصر"
      },
      {
        "key": "al_askari",
        "en": "Al Askari",
        "ar": "العسكري"
      },
      {
        "key": "al_orouba",
        "en": "Al Orouba",
        "ar": "العروبة"
      },
      {
        "key": "al_nour",
        "en": "Al Nour",
        "ar": "حي النور"
      },
      {
        "key": "shuhadaa",
        "en": "Shuhadaa",
        "ar": "الشهداء"
      },
      {
        "key": "shorja",
        "en": "Shorja",
        "ar": "الشورجة"
      },
      {
        "key": "ahmed_agha",
        "en": "Ahmed Agha",
        "ar": "احمد اغا"
      },
      {
        "key": "al_qalaa",
        "en": "Al Qalaa",
        "ar": "القلعة"
      },
      {
        "key": "amam_qasim",
        "en": "Amam Qasim",
        "ar": "امام قاسم"
      },
      {
        "key": "qwria",
        "en": "Qwria",
        "ar": "قورية"
      },
      {
        "key": "buriadi",
        "en": "Buriadi",
        "ar": "بريادي"
      },
      {
        "key": "al_mas",
        "en": "Al Mas",
        "ar": "الماس"
      },
      {
        "key": "al_asraa_walmafqudin",
        "en": "Al Asraa Walmafqudin",
        "ar": "الاسرى والمفقودين"
      },
      {
        "key": "al_muealimin",
        "en": "Al Muealimin",
        "ar": "المعلمين"
      },
      {
        "key": "al_dubbat",
        "en": "Al Dubbat",
        "ar": "الضباط"
      },
      {
        "key": "1_huziran",
        "en": "1 Huziran",
        "ar": "1 حزيران"
      },
      {
        "key": "al_khadraa",
        "en": "Al Khadraa",
        "ar": "الخضراء"
      },
      {
        "key": "hawija",
        "en": "Hawija",
        "ar": "الحويجة"
      },
      {
        "key": "daquq",
        "en": "Daquq",
        "ar": "داقوق"
      },
      {
        "key": "al_dbs",
        "en": "Al Dbs",
        "ar": "الدبس"
      }
    ],
    "key": "kirkuk",
    "ar": "كركوك"
  }));
  this.doCreate(new NewProvince({
    "en": "Babylon",
    "cities": [
      {
        "key": "al_qasim",
        "en": "Al Qasim",
        "ar": "القاسم"
      },
      {
        "key": "musayib",
        "en": "Musayib",
        "ar": "المسيب"
      },
      {
        "key": "al_mudhatia",
        "en": "Al-Mudhatia",
        "ar": "المدحتية"
      },
      {
        "key": "hilla",
        "en": "Hilla",
        "ar": "الحلة"
      },
      {
        "key": "mhaweel",
        "en": "Mhaweel",
        "ar": "المحاويل"
      },
      {
        "key": "al_iskandaria",
        "en": "Al Iskandaria",
        "ar": "الإسكندرية"
      }
    ],
    "key": "babylon",
    "ar": "بابل"
  }));
  this.doCreate(new NewProvince({
    "en": "Diyala",
    "cities": [
      {
        "key": "baquba",
        "en": "Baquba",
        "ar": "بعقوبة"
      },
      {
        "key": "buhraz",
        "en": "Buhraz",
        "ar": "بهرز"
      },
      {
        "key": "khan_bani_saad",
        "en": "Khan Bani Saad",
        "ar": "خان بني سعد"
      },
      {
        "key": "kanaan",
        "en": "Kanaan",
        "ar": "كنعان"
      },
      {
        "key": "al_abbara",
        "en": "Al Abbara",
        "ar": "العبارة"
      },
      {
        "key": "muqdadiya",
        "en": "Muqdadiya",
        "ar": "المقدادية"
      },
      {
        "key": "dali_abbas",
        "en": "Dali Abbas",
        "ar": "دلي عباس"
      },
      {
        "key": "baladrouz",
        "en": "Baladrouz",
        "ar": "بلدروز"
      },
      {
        "key": "mandali",
        "en": "Mandali",
        "ar": "مندلي"
      },
      {
        "key": "khanaqin",
        "en": "Khanaqin",
        "ar": "خانقين"
      },
      {
        "key": "jalawla",
        "en": "Jalawla",
        "ar": "جلولاء"
      },
      {
        "key": "saadia",
        "en": "Saadia",
        "ar": "السعدية"
      },
      {
        "key": "khalis",
        "en": "Khalis",
        "ar": "الخالص"
      },
      {
        "key": "hibhib",
        "en": "Hibhib",
        "ar": "حبحب"
      },
      {
        "key": "al_azim",
        "en": "Al Azim",
        "ar": "العظيم"
      },
      {
        "key": "al_mansuria",
        "en": "Al Mansuria",
        "ar": "المنصورية"
      },
      {
        "key": "al_ghalibia",
        "en": "Al Ghalibia",
        "ar": "الغالبية"
      },
      {
        "key": "al_kufry",
        "en": "Al Kufry",
        "ar": "الكفري"
      },
      {
        "key": "qarah_taba",
        "en": "Qarah Taba",
        "ar": "قرة تبة"
      }
    ],
    "key": "diyala",
    "ar": "ديالى"
  }));
  this.doCreate(new NewProvince({
    "en": "Baghdad/Rusafa",
    "cities": [
      {
        "key": "sinak",
        "en": "Sinak",
        "ar": "السنك"
      },
      {
        "key": "rashid_street",
        "en": "Rashid Street",
        "ar": "شارع الرشيد"
      },
      {
        "key": "shorja",
        "en": "Shorja",
        "ar": "الشورجة"
      },
      {
        "key": "khilani",
        "en": "Khilani",
        "ar": "الخلاني"
      },
      {
        "key": "abu_nawas",
        "en": "Abu Nawas",
        "ar": "أبو نؤاس"
      },
      {
        "key": "alaptaoyen",
        "en": "Alaptaoyen",
        "ar": "البتاويين"
      },
      {
        "key": "sadoun_street",
        "en": "Sadoun Street",
        "ar": "شارع السعدون"
      },
      {
        "key": "sheikh_omar",
        "en": "Sheikh Omar",
        "ar": "الشيخ عمر"
      },
      {
        "key": "bab_al_moazam",
        "en": "Bab Al Moazam",
        "ar": "باب المعظم"
      },
      {
        "key": "medical_city",
        "en": "Medical City",
        "ar": "مدينة الطب"
      },
      {
        "key": "palestine_street",
        "en": "Palestine Street",
        "ar": "شارع فلسطين"
      },
      {
        "key": "mohandeseen",
        "en": "Mohandeseen",
        "ar": "المهندسين"
      },
      {
        "key": "neighborhood_july_14",
        "en": "Neighborhood July 14",
        "ar": "حي 14 تموز"
      },
      {
        "key": "idrissi",
        "en": "Idrissi",
        "ar": "الادريسي"
      },
      {
        "key": "al_adhamiya",
        "en": "Al Adhamiya",
        "ar": "الاعظمية"
      },
      {
        "key": "waziriya",
        "en": "Waziriya",
        "ar": "الوزيرية"
      },
      {
        "key": "al_mghrb",
        "en": "Al Mghrb",
        "ar": "المغرب"
      },
      {
        "key": "qahira_neighborhood",
        "en": "Qahira Neighborhood",
        "ar": "حي القاهرة"
      },
      {
        "key": "al_kryeaat",
        "en": "Al Kryeaat",
        "ar": "الكريعات"
      },
      {
        "key": "sbe_abkar",
        "en": "Sbe Abkar",
        "ar": "سبع ابكار"
      },
      {
        "key": "al_bnwk",
        "en": "Al Bnwk",
        "ar": "البنوك"
      },
      {
        "key": "awr",
        "en": "Awr",
        "ar": "اور"
      },
      {
        "key": "al_shaab",
        "en": "Al Shaab",
        "ar": "الشعب"
      },
      {
        "key": "al_slykh",
        "en": "Al Slykh",
        "ar": "الصليخ"
      },
      {
        "key": "sadr_city",
        "en": "Sadr City",
        "ar": "مدينة الصدر"
      },
      {
        "key": "al_hbybya",
        "en": "Al Hbybya",
        "ar": "الحبيبية"
      },
      {
        "key": "zayouna",
        "en": "Zayouna",
        "ar": "زيونة"
      },
      {
        "key": "ghadir",
        "en": "Ghadir",
        "ar": "الغدير"
      },
      {
        "key": "bghdad_al_jadyda",
        "en": "Bghdad Al Jadyda",
        "ar": "بغداد الجديدة"
      },
      {
        "key": "al_mashtl",
        "en": "Al Mashtl",
        "ar": "المشتل"
      },
      {
        "key": "al_kmalya",
        "en": "Al Kmalya",
        "ar": "الكمالية"
      },
      {
        "key": "obeidi",
        "en": "Obeidi",
        "ar": "العبيدي"
      },
      {
        "key": "fadhiliyah",
        "en": "Fadhiliyah",
        "ar": "الفضيلية"
      },
      {
        "key": "karrada",
        "en": "Karrada",
        "ar": "الكرادة"
      },
      {
        "key": "al_jadriya",
        "en": "Al Jadriya",
        "ar": "الجادرية"
      },
      {
        "key": "al_arasat_al_hindia",
        "en": "Al Arasat Al Hindia",
        "ar": "العرصات الهندية "
      },
      {
        "key": "camp_sarah",
        "en": "Camp Sarah",
        "ar": "كمب سارة"
      },
      {
        "key": "al_zafaraniya",
        "en": "Al Zafaraniya",
        "ar": "الزعفرانية"
      },
      {
        "key": "rashid_camp",
        "en": "Rashid Camp",
        "ar": "معسكر الرشيد"
      },
      {
        "key": "rustamiyah",
        "en": "Rustamiyah",
        "ar": "الرستمية"
      },
      {
        "key": "al_baladiat",
        "en": "Al Baladiat",
        "ar": "البلديات"
      }
    ],
    "key": "baghdad_rusafa",
    "ar": "بغداد/الرصافة"
  }));
  this.doCreate(new NewProvince({
    "en": "Sulaymaniyah",
    "cities": [
      {
        "key": "bakra_joe",
        "en": "Bakra Joe",
        "ar": "بكرة جو"
      },
      {
        "key": "bazian",
        "en": "Bazian",
        "ar": "بازيان"
      },
      {
        "key": "tangro",
        "en": "Tangro",
        "ar": "تانجرو"
      },
      {
        "key": "qara_dag",
        "en": "Qara Dag",
        "ar": "قرة داغ"
      },
      {
        "key": "sieh_setan",
        "en": "Sieh Setan",
        "ar": "سيه سيتان"
      },
      {
        "key": "shara_zur",
        "en": "Shara Zur",
        "ar": "شارة زور"
      },
      {
        "key": "halabja",
        "en": "Halabja",
        "ar": "حلبجة"
      },
      {
        "key": "wara_mawa",
        "en": "Wara Mawa",
        "ar": "ورة ماوة"
      },
      {
        "key": "sayed_sadiq",
        "en": "Sayed Sadiq",
        "ar": "سيد صادق"
      },
      {
        "key": "khurmal",
        "en": "Khurmal",
        "ar": "خورمال"
      },
      {
        "key": "penguin",
        "en": "Penguin",
        "ar": "بنجوين"
      },
      {
        "key": "sharbarees",
        "en": "Sharbarees",
        "ar": "شاربارييز"
      },
      {
        "key": "rania",
        "en": "Rania",
        "ar": "رانية"
      },
      {
        "key": "dukan",
        "en": "Dukan",
        "ar": "دوكان"
      },
      {
        "key": "darbandikan",
        "en": "Darbandikan",
        "ar": "دربنديخان"
      },
      {
        "key": "kalar",
        "en": "Kalar",
        "ar": "كلار"
      },
      {
        "key": "kifri",
        "en": "Kifri",
        "ar": "كفري"
      },
      {
        "key": "chamchamal",
        "en": "Chamchamal",
        "ar": "جمجمال"
      },
      {
        "key": "khanaqin",
        "en": "Khanaqin",
        "ar": "خانقين"
      },
      {
        "key": "sulaymaniyah",
        "en": "Sulaymaniyah",
        "ar": "السليمانية"
      }
    ],
    "key": "sulaymaniyah",
    "ar": "السليمانية"
  }));
  this.doCreate(new NewProvince({
    "en": "Qadisiyah",
    "cities": [
      {
        "key": "diwaniyah",
        "en": "Diwaniyah",
        "ar": "الديوانية"
      },
      {
        "key": "sunnya",
        "en": "Sunnya",
        "ar": "السنية"
      },
      {
        "key": "shafaia",
        "en": "Shafaia",
        "ar": "الشافعية"
      },
      {
        "key": "daghara",
        "en": "Daghara",
        "ar": "الدغارة"
      },
      {
        "key": "shamiya",
        "en": "Shamiya",
        "ar": "الشامية"
      },
      {
        "key": "ghamas",
        "en": "Ghamas",
        "ar": "غماس"
      },
      {
        "key": "muhannawiyah",
        "en": "Muhannawiyah",
        "ar": "المهناوية"
      },
      {
        "key": "salahiyah",
        "en": "Salahiyah",
        "ar": "الصلاحية"
      },
      {
        "key": "afak",
        "en": "Afak",
        "ar": "عفك"
      },
      {
        "key": "al_budair",
        "en": "Al Budair",
        "ar": "آل بدير"
      },
      {
        "key": "sumer",
        "en": "Sumer",
        "ar": "سومر"
      },
      {
        "key": "nefer",
        "en": "Nefer",
        "ar": "نفرر"
      },
      {
        "key": "hamzah",
        "en": "Hamzah",
        "ar": "الحمزة"
      },
      {
        "key": "sudair",
        "en": "Sudair",
        "ar": "السدير"
      },
      {
        "key": "shinafiyah",
        "en": "Shinafiyah",
        "ar": "الشنافية"
      }
    ],
    "key": "qadisiyah",
    "ar": "القادسية"
  }));
  this.doCreate(new NewProvince({
    "en": "Karbala",
    "cities": [
      {
        "key": "karbala",
        "en": "Karbala",
        "ar": "كربلاء"
      },
      {
        "key": "aoun",
        "en": "Aoun",
        "ar": "عون "
      },
      {
        "key": "al_hur",
        "en": "Al Hur",
        "ar": "الحر"
      },
      {
        "key": "al_husseiniya",
        "en": "Al Husseiniya",
        "ar": "الحسينية"
      },
      {
        "key": "ain_tamr",
        "en": "Ain tamr",
        "ar": "عين تمر"
      },
      {
        "key": "rahhaliyah",
        "en": "Rahhaliyah",
        "ar": "الرحالية"
      },
      {
        "key": "touirij",
        "en": "Touirij",
        "ar": "طويريج"
      },
      {
        "key": "al_jadwal",
        "en": "Al Jadwal",
        "ar": "الجدول"
      },
      {
        "key": "algharbi",
        "en": "Algharbi",
        "ar": "الغربي"
      },
      {
        "key": "al_khayrat",
        "en": "Al Khayrat",
        "ar": "الخيرات"
      }
    ],
    "key": "karbala",
    "ar": "كربلاء"
  }));
  this.doCreate(new NewProvince({
    "en": "Ninewa",
    "cities": [
      {
        "key": "mosul",
        "en": "Mosul",
        "ar": "موصل"
      },
      {
        "key": "al_muthanna",
        "en": "Al Muthanna",
        "ar": "حي المثنى"
      },
      {
        "key": "al_zuhour",
        "en": "Al Zuhour",
        "ar": "حي الزهور"
      },
      {
        "key": "al_jamiah",
        "en": "Al Jamiah",
        "ar": "حي الجامعة"
      },
      {
        "key": "al_arabi",
        "en": "Al Arabi",
        "ar": "حي العربي"
      },
      {
        "key": "nujaifi",
        "en": "Nujaifi",
        "ar": "النجيفي"
      },
      {
        "key": "hadbaa",
        "en": "Hadbaa",
        "ar": "الحدباء"
      },
      {
        "key": "nabi_younis",
        "en": "Nabi Younis",
        "ar": "نبي يونس"
      },
      {
        "key": "al_sinaa",
        "en": "Al Sinaa",
        "ar": "الصناعة"
      },
      {
        "key": "shoura",
        "en": "Shoura",
        "ar": "الشورة"
      },
      {
        "key": "qayyarah",
        "en": "Qayyarah",
        "ar": "القيارة"
      },
      {
        "key": "bashika",
        "en": "Bashika",
        "ar": "بعشيقة"
      },
      {
        "key": "badush",
        "en": "Badush",
        "ar": "بادوش"
      },
      {
        "key": "makhmour",
        "en": "Makhmour",
        "ar": "مخمور"
      },
      {
        "key": "bartala",
        "en": "Bartala",
        "ar": "برطلة"
      },
      {
        "key": "sheikhan",
        "en": "Sheikhan",
        "ar": "الشيخان"
      },
      {
        "key": "talkif",
        "en": "Talkif",
        "ar": "تلكيف"
      },
      {
        "key": "talafar",
        "en": "Talafar",
        "ar": "تلعفر"
      },
      {
        "key": "fayda",
        "en": "Fayda",
        "ar": "فايدة"
      },
      {
        "key": "al_qwosh",
        "en": "Al Qwosh",
        "ar": "القوش"
      },
      {
        "key": "rabia",
        "en": "Rabia",
        "ar": "ربيعة"
      },
      {
        "key": "zamar",
        "en": "Zamar",
        "ar": "زمار"
      },
      {
        "key": "sinjar",
        "en": "Sinjar",
        "ar": "سنجار"
      },
      {
        "key": "baaj",
        "en": "Baaj",
        "ar": "البعاج"
      },
      {
        "key": "qairawan",
        "en": "Qairawan",
        "ar": "القيروان"
      },
      {
        "key": "hadar",
        "en": "Hadar",
        "ar": "حضر"
      },
      {
        "key": "hammam_al_alil",
        "en": "Hammam Al Alil",
        "ar": "حمام العليل"
      }
    ],
    "key": "ninewa",
    "ar": "نينوى"
  }));
  this.doCreate(new NewProvince({
    "en": "Baghdad/Karkh",
    "cities": [
      {
        "key": "haifa_street",
        "en": "Haifa Street",
        "ar": "شارع حيفا"
      },
      {
        "key": "al_salihia",
        "en": "Al Salihia",
        "ar": "الصالحية"
      },
      {
        "key": "karada_mariam",
        "en": "Karada Mariam",
        "ar": "كرادة مريم"
      },
      {
        "key": "green_zone",
        "en": "Green Zone",
        "ar": "المنطقة الخضراء"
      },
      {
        "key": "harthiya",
        "en": "Harthiya",
        "ar": "الحارثية"
      },
      {
        "key": "mansour",
        "en": "Mansour",
        "ar": "المنصور"
      },
      {
        "key": "qadisiyah",
        "en": "Qadisiyah",
        "ar": "القادسية"
      },
      {
        "key": "al_iskan",
        "en": "Al Iskan",
        "ar": "الإسكان"
      },
      {
        "key": "yarmouk",
        "en": "Yarmouk",
        "ar": "اليرموك"
      },
      {
        "key": "amreya",
        "en": "Amreya",
        "ar": "العامرية"
      },
      {
        "key": "al_khadra_neighborhood",
        "en": "Al Khadra Neighborhood",
        "ar": "حي الخضراء"
      },
      {
        "key": "al_jamea_neighborhood",
        "en": "Al Jamea Neighborhood",
        "ar": "حي الجامعة"
      },
      {
        "key": "al_adel_neighborhood",
        "en": "Al Adel Neighborhood",
        "ar": "حي العدل"
      },
      {
        "key": "ghazaliya",
        "en": "Ghazaliya",
        "ar": "الغزالية"
      },
      {
        "key": "jihad_neighborhood",
        "en": "Jihad Neighborhood",
        "ar": "حي الجهاد"
      },
      {
        "key": "al_amel_neighborhood",
        "en": "Al Amel Neighborhood",
        "ar": "حي العامل"
      },
      {
        "key": "saydiya",
        "en": "Saydiya",
        "ar": "السيدية"
      },
      {
        "key": "al_dwra",
        "en": "Al Dwra",
        "ar": "الدورة"
      },
      {
        "key": "bayaa",
        "en": "Bayaa",
        "ar": "البياع"
      },
      {
        "key": "al_shurta_neighborhood",
        "en": "Al Shurta Neighborhood",
        "ar": "حي الشرطة"
      },
      {
        "key": "kadhimiya",
        "en": "Kadhimiya",
        "ar": "الكاظمية"
      },
      {
        "key": "atefiyah",
        "en": "Atefiyah",
        "ar": "العطيفية"
      },
      {
        "key": "al_shoulla",
        "en": "Al Shoulla",
        "ar": "الشعلة"
      },
      {
        "key": "al_hurya",
        "en": "Al Hurya",
        "ar": "الحرية"
      },
      {
        "key": "al_dwlaey",
        "en": "Al Dwlaey",
        "ar": "الدولعي"
      },
      {
        "key": "al_slam",
        "en": "Al Slam",
        "ar": "السلام"
      },
      {
        "key": "aweirij",
        "en": "Aweirij",
        "ar": "عويريج"
      },
      {
        "key": "sweib",
        "en": "Sweib",
        "ar": "السويب"
      },
      {
        "key": "abu_ghraib",
        "en": "Abu Ghraib",
        "ar": "ابو غريب"
      },
      {
        "key": "mahmudiyah",
        "en": "Mahmudiyah",
        "ar": "المحمودية"
      }
    ],
    "key": "baghdad_karkh",
    "ar": "بغداد/الكرخ"
  }));
  this.doCreate(new NewProvince({
    "en": "Maysan",
    "cities": [
      {
        "key": "alamara",
        "en": "Alamara",
        "ar": "العمارة"
      },
      {
        "key": "kumet",
        "en": "Kumet",
        "ar": "كميت"
      },
      {
        "key": "al_khlaa",
        "en": "Al khlaa",
        "ar": "الكحلاء"
      },
      {
        "key": "al_masharah",
        "en": "Al Masharah",
        "ar": "المشرح"
      },
      {
        "key": "bani_hashim",
        "en": "Bani Hashim",
        "ar": "بني هاشم"
      },
      {
        "key": "ali_al_gharbi",
        "en": "Ali Al Gharbi",
        "ar": "علي الغربي"
      },
      {
        "key": "ali_al_sharqi",
        "en": "Ali Al Sharqi",
        "ar": "علي الشرقي"
      },
      {
        "key": "almajar_al_kabir",
        "en": "Almajar Al Kabir",
        "ar": "المجر الكبير"
      },
      {
        "key": "al_khayr",
        "en": "Al Khayr",
        "ar": "الخير"
      },
      {
        "key": "al_adel",
        "en": "Al Adel",
        "ar": "العدل"
      },
      {
        "key": "qalat_salih",
        "en": "Qalat Salih",
        "ar": "قلعة صالح"
      },
      {
        "key": "uzair",
        "en": "Uzair",
        "ar": "العزير"
      },
      {
        "key": "al_maymuna",
        "en": "Al Maymuna",
        "ar": "الميمونة"
      },
      {
        "key": "al_salam",
        "en": "Al Salam",
        "ar": "السلام"
      },
      {
        "key": "sayed_ahmed_rifai",
        "en": "Sayed Ahmed Rifai",
        "ar": "سيد احمد الرفاعي"
      },
      {
        "key": "al_qadiriyah",
        "en": "Al Qadiriyah",
        "ar": "القادرية"
      },
      {
        "key": "al_saray",
        "en": "Al Saray",
        "ar": "السراي"
      },
      {
        "key": "mahmoudiyah",
        "en": "Mahmoudiyah",
        "ar": "المحمودية"
      },
      {
        "key": "al_sabunjia",
        "en": "Al Sabunjia",
        "ar": "الصابونجية"
      },
      {
        "key": "al_shabana",
        "en": "Al Shabana",
        "ar": "الشبانة"
      },
      {
        "key": "al_dabysat",
        "en": "Al Dabysat",
        "ar": "الدبيسات"
      }
    ],
    "key": "maysan",
    "ar": "ميسان"
  }));
  this.doCreate(new NewProvince({
    "en": "Dhi Qar",
    "cities": [
      {
        "key": "qadaa_alnasrya",
        "en": "Qadaa Alnasrya",
        "ar": "قضاء الناصرية"
      },
      {
        "key": "al_batha",
        "en": "Al Batha",
        "ar": "البطحاء"
      },
      {
        "key": "al_islah",
        "en": "Al Islah",
        "ar": "الإصلاح"
      },
      {
        "key": "sayed_dakhil",
        "en": "Sayed Dakhil",
        "ar": "سيد دخيل"
      },
      {
        "key": "awr",
        "en": "Awr",
        "ar": "اور"
      },
      {
        "key": "al_shatra",
        "en": "Al Shatra",
        "ar": "الشطرة"
      },
      {
        "key": "al_ghraf",
        "en": "Al Ghraf",
        "ar": "الغراف"
      },
      {
        "key": "al_dwaya",
        "en": "Al Dwaya",
        "ar": "الدواية"
      },
      {
        "key": "al_rifaey",
        "en": "Al Rifaey",
        "ar": "الرفاعي"
      },
      {
        "key": "al_nasr",
        "en": "Al Nasr",
        "ar": "النصر"
      },
      {
        "key": "qalea_sakar",
        "en": "Qalea Sakar",
        "ar": "قلعة سكر"
      },
      {
        "key": "al_fjr",
        "en": "Al Fjr",
        "ar": "الفجر"
      },
      {
        "key": "swq_al_shywkh",
        "en": "Swq Al Shywkh",
        "ar": "سوق الشيوخ"
      },
      {
        "key": "al_akyka",
        "en": "Al Akyka",
        "ar": "عقيقة"
      },
      {
        "key": "al_fadlya",
        "en": "Al Fadlya",
        "ar": "الفضلية"
      },
      {
        "key": "al_tar",
        "en": "Al Tar",
        "ar": "الطار"
      },
      {
        "key": "krma_bani_saeed",
        "en": "Krma Bani Saeed",
        "ar": "كرمة بني سعيد"
      },
      {
        "key": "al_jbaysh",
        "en": "Al Jbaysh",
        "ar": "الجبايش"
      },
      {
        "key": "al_fhwd",
        "en": "Al Fhwd",
        "ar": "الفهود"
      },
      {
        "key": "al_manar",
        "en": "Al Manar",
        "ar": "المنار"
      }
    ],
    "key": "dhi_qar",
    "ar": "ذي قار"
  }));
  this.doCreate(new NewProvince({
    "en": "Erbil",
    "cities": [
      {
        "key": "irbil",
        "en": "Irbil",
        "ar": "أربيل"
      },
      {
        "key": "quesnijq",
        "en": "Quesnijq",
        "ar": "كويسنجق"
      },
      {
        "key": "suran",
        "en": "Suran",
        "ar": "سوران"
      },
      {
        "key": "shaqlawa",
        "en": "Shaqlawa",
        "ar": "شقلاوة"
      },
      {
        "key": "juman",
        "en": "Juman",
        "ar": "جومان"
      },
      {
        "key": "ayn_qawah",
        "en": "Ayn Qawah",
        "ar": "عين كاوة"
      },
      {
        "key": "qalaat",
        "en": "Qalaat",
        "ar": "القلعة"
      },
      {
        "key": "rawanduz",
        "en": "Rawanduz",
        "ar": "راوندوز"
      },
      {
        "key": "shorash",
        "en": "Shorash",
        "ar": "شورش"
      },
      {
        "key": "askan",
        "en": "Askan",
        "ar": "الإسكان"
      },
      {
        "key": "mannara",
        "en": "Mannara",
        "ar": "منارة"
      },
      {
        "key": "tirawa",
        "en": "Tirawa",
        "ar": "طيراوة"
      },
      {
        "key": "hay_al_arab",
        "en": "Hay Al Arab",
        "ar": "حي العرب"
      },
      {
        "key": "7_nissan",
        "en": "7 Nissan",
        "ar": "سبعة نيسان"
      },
      {
        "key": "ronaki",
        "en": "Ronaki",
        "ar": "روناكي"
      },
      {
        "key": "kolan",
        "en": "kolan",
        "ar": "كولان"
      },
      {
        "key": "eskry_neighborhood",
        "en": "Eskry Neighborhood",
        "ar": "حي عسكري"
      },
      {
        "key": "bakhtiari",
        "en": "Bakhtiari",
        "ar": "بختياري"
      },
      {
        "key": "wazidan",
        "en": "Wazidan",
        "ar": "وةزيدان"
      },
      {
        "key": "mohandiseen_neighborhood",
        "en": "Mohandiseen Neighborhood",
        "ar": "حي المهندسين"
      },
      {
        "key": "muntakawh",
        "en": "Muntakawh",
        "ar": "منتكاوا"
      }
    ],
    "key": "erbil",
    "ar": "أربيل"
  }));
  this.doCreate(new NewProvince({
    "en": "Basra",
    "cities": [
      {
        "key": "albikr_walahrar",
        "en": "Albikr Walahrar",
        "ar": "البكر والاحرار"
      },
      {
        "key": "al_resala_neighborhood",
        "en": "Al Resala Neighborhood",
        "ar": "حي الرسالة"
      },
      {
        "key": "al_kufaat_neighborhood",
        "en": "Al Kufaat neighborhood",
        "ar": "حي الكفاءات"
      },
      {
        "key": "al_khadra",
        "en": "Al khadra",
        "ar": "الخضراء"
      },
      {
        "key": "al_ashar",
        "en": "Al Ashar",
        "ar": "العشار"
      },
      {
        "key": "dawr_al_naft",
        "en": "Dawr Al Naft",
        "ar": "دور النفط"
      },
      {
        "key": "al_buradieia",
        "en": "Al Buradieia",
        "ar": "البراضعية"
      },
      {
        "key": "minawi_pasha",
        "en": "Minawi Pasha",
        "ar": "مناوي باشا"
      },
      {
        "key": "al_gomhoria_neighborhood",
        "en": "Al Gomhoria Neighborhood",
        "ar": "حي الجمهورية"
      },
      {
        "key": "al_tahsinia",
        "en": "Al Tahsinia",
        "ar": "التحسينية"
      },
      {
        "key": "abbasiyah",
        "en": "Abbasiyah",
        "ar": "العباسية"
      },
      {
        "key": "al_abla",
        "en": "Al Abla",
        "ar": "الابلة"
      },
      {
        "key": "al_jazayir_neighborhood",
        "en": "Al Jazayir Neighborhood",
        "ar": "حي الجزائر"
      },
      {
        "key": "karma_ali",
        "en": "Karma Ali",
        "ar": "كرمة علي"
      },
      {
        "key": "al_muaqil",
        "en": "Al Muaqil",
        "ar": "المعقل"
      },
      {
        "key": "5_mile",
        "en": "5 Mile",
        "ar": "5 ميل"
      },
      {
        "key": "briha",
        "en": "Briha",
        "ar": "بريهة"
      },
      {
        "key": "al_hakimia",
        "en": "Al Hakimia",
        "ar": "الحكيمية"
      },
      {
        "key": "al_jameiat",
        "en": "Al Jameiat",
        "ar": "الجمعيات"
      },
      {
        "key": "al_janina",
        "en": "Al Janina",
        "ar": "الجنينة"
      },
      {
        "key": "al_tawisa",
        "en": "Al Tawisa",
        "ar": "الطويسة"
      },
      {
        "key": "al_qabla",
        "en": "Al Qabla",
        "ar": "القبلة"
      },
      {
        "key": "nujaibiya",
        "en": "Nujaibiya",
        "ar": "النجيبية"
      },
      {
        "key": "muwafaqiyah",
        "en": "Muwafaqiyah",
        "ar": "الموفقية"
      },
      {
        "key": "al_asmai",
        "en": "Al Asmai",
        "ar": "الاصمعي"
      },
      {
        "key": "al_jubaila",
        "en": "Al Jubaila",
        "ar": "الجبيلة"
      },
      {
        "key": "al_andalus",
        "en": "Al Andalus",
        "ar": "الاندلس"
      },
      {
        "key": "al_taliah",
        "en": "Al Taliah",
        "ar": "الطليعة"
      },
      {
        "key": "arabian_gulf",
        "en": "Arabian Gulf",
        "ar": "الخليج العربي"
      },
      {
        "key": "al_jazirah",
        "en": "Al Jazirah",
        "ar": "الجزيرة"
      },
      {
        "key": "al_qasim",
        "en": "Al Qasim",
        "ar": "القاسم"
      },
      {
        "key": "al_wahda",
        "en": "Al Wahda",
        "ar": "حي الوحدة"
      },
      {
        "key": "al_rafidain",
        "en": "Al Rafidain",
        "ar": "حي الرافدين"
      },
      {
        "key": "al_sahat",
        "en": "Al Sahat",
        "ar": "الساحات"
      },
      {
        "key": "zubayr",
        "en": "Zubayr",
        "ar": "الزبير"
      },
      {
        "key": "al_faw",
        "en": "Al Faw",
        "ar": "الفاو"
      }
    ],
    "key": "basra",
    "ar": "البصرة"
  }));
  this.doCreate(new NewProvince({
    "en": "Muthanna",
    "cities": [
      {
        "key": "samawah",
        "en": "Samawah",
        "ar": "السماوة"
      },
      {
        "key": "suwayrah",
        "en": "Suwayrah",
        "ar": "السويرة"
      },
      {
        "key": "rumaythah",
        "en": "Rumaythah",
        "ar": "الرميثة"
      },
      {
        "key": "hilal",
        "en": "Hilal",
        "ar": "الهلال"
      },
      {
        "key": "najmi",
        "en": "Najmi",
        "ar": "النجمي"
      },
      {
        "key": "majd",
        "en": "Majd",
        "ar": "المجد"
      },
      {
        "key": "salman",
        "en": "Salman",
        "ar": "السلمان"
      },
      {
        "key": "busyyah",
        "en": "Busyyah",
        "ar": "بصية"
      },
      {
        "key": "khader",
        "en": "Khader",
        "ar": "الخضر"
      },
      {
        "key": "daraji",
        "en": "Daraji",
        "ar": "الدراجي"
      },
      {
        "key": "warka",
        "en": "Warka",
        "ar": "الوركاء"
      },
      {
        "key": "karama",
        "en": "Karama",
        "ar": "الكرامة"
      }
    ],
    "key": "muthanna",
    "ar": "المثنى"
  }));
  this.doCreate(new NewProvince({
    "en": "Dohuk",
    "cities": [
      {
        "key": "zawaytah",
        "en": "Zawaytah",
        "ar": "زاويتة"
      },
      {
        "key": "mankish",
        "en": "Mankish",
        "ar": "مانكيش"
      },
      {
        "key": "sarsank",
        "en": "Sarsank",
        "ar": "سرسنك"
      },
      {
        "key": "zakho",
        "en": "Zakho",
        "ar": "زاخو"
      },
      {
        "key": "smail",
        "en": "Smail",
        "ar": "سميل"
      },
      {
        "key": "amadiyah",
        "en": "Amadiyah",
        "ar": "العمادية"
      },
      {
        "key": "bardarash",
        "en": "Bardarash",
        "ar": "بردرش"
      },
      {
        "key": "aqrah",
        "en": "Aqrah",
        "ar": "عقرة"
      },
      {
        "key": "shekhan",
        "en": "Shekhan",
        "ar": "شيخان"
      },
      {
        "key": "bamerni",
        "en": "Bamerni",
        "ar": "بامرني"
      },
      {
        "key": "deiralock",
        "en": "Deiralock",
        "ar": "ديرةلوك"
      },
      {
        "key": "solaf",
        "en": "Solaf",
        "ar": "سولاف"
      },
      {
        "key": "dohuk",
        "en": "Dohuk",
        "ar": "دهوك"
      }
    ],
    "key": "dohuk",
    "ar": "دهوك"
  }));
  this.doCreate(new NewProvince({
    "en": "Wasit",
    "cities": [
      {
        "key": "kut",
        "en": "Kut",
        "ar": "الكوت"
      },
      {
        "key": "sheikh_saad",
        "en": "Sheikh Saad",
        "ar": "الشيخ سعد"
      },
      {
        "key": "al_saouira",
        "en": "Al Saouira",
        "ar": "الصويرة"
      },
      {
        "key": "zubaydiyah",
        "en": "Zubaydiyah",
        "ar": "الزبيدية"
      },
      {
        "key": "shehaymiyah",
        "en": "Shehaymiyah",
        "ar": "الشحيمية"
      },
      {
        "key": "aziziyah",
        "en": "Aziziyah",
        "ar": "العزيزية"
      },
      {
        "key": "taj_aldin",
        "en": "Taj Aldin",
        "ar": "تاج الدين"
      },
      {
        "key": "dabouni",
        "en": "Dabouni",
        "ar": "الدبوني"
      },
      {
        "key": "numaniyah",
        "en": "Numaniyah",
        "ar": "النعمانية"
      },
      {
        "key": "ahrar",
        "en": "Ahrar",
        "ar": "الاحرار"
      },
      {
        "key": "badra",
        "en": "Badra",
        "ar": "بدرة"
      },
      {
        "key": "zurbatiya",
        "en": "Zurbatiya",
        "ar": "زرباطية"
      },
      {
        "key": "jassan",
        "en": "Jassan",
        "ar": "جصان"
      },
      {
        "key": "al_hay",
        "en": "Al Hay",
        "ar": "الحي"
      },
      {
        "key": "muwaffaqiyah",
        "en": "Muwaffaqiyah",
        "ar": "الموفقية"
      },
      {
        "key": "bashaer",
        "en": "Bashaer",
        "ar": "البشائر"
      }
    ],
    "key": "wasit",
    "ar": "واسط"
  }));
  this.doCreate(new NewProvince({
    "en": "Najaf",
    "cities": [
      {
        "key": "najaf",
        "en": "Najaf",
        "ar": "النجف"
      },
      {
        "key": "al_haidariyah",
        "en": "Al Haidariyah",
        "ar": "الحيدرية"
      },
      {
        "key": "shabakiyah",
        "en": "Shabakiyah",
        "ar": "الشبكية"
      },
      {
        "key": "ridawiyah",
        "en": "Ridawiyah",
        "ar": "الرضوية"
      },
      {
        "key": "kufa",
        "en": "Kufa",
        "ar": "الكوفة"
      },
      {
        "key": "abbasia",
        "en": "Abbasia",
        "ar": "العباسية"
      },
      {
        "key": "alhurya",
        "en": "Alhurya",
        "ar": "الحرية"
      },
      {
        "key": "manathirah",
        "en": "Manathirah",
        "ar": "المناذرة"
      },
      {
        "key": "al_hira",
        "en": "Al Hira",
        "ar": "الحيرة"
      },
      {
        "key": "qadisiyah",
        "en": "Qadisiyah",
        "ar": "القادسية"
      },
      {
        "key": "mashkhab",
        "en": "Mashkhab",
        "ar": "المشخاب"
      }
    ],
    "key": "najaf",
    "ar": "النجف"
  }));
  this.doCreate(new NewProvince({
    "en": "Salah Al Din",
    "cities": [
      {
        "key": "samarra",
        "en": "Samarra",
        "ar": "سامراء"
      },
      {
        "key": "tikrit",
        "en": "Tikrit",
        "ar": "تكريت"
      },
      {
        "key": "baiji",
        "en": "Baiji",
        "ar": "بيجي"
      },
      {
        "key": "tuzkhermatu",
        "en": "Tuzkhermatu",
        "ar": "طوزخرماتو"
      },
      {
        "key": "dujail",
        "en": "Dujail",
        "ar": "الدجيل"
      },
      {
        "key": "sharqat",
        "en": "Sharqat",
        "ar": "الشرقاط"
      },
      {
        "key": "balad",
        "en": "Balad",
        "ar": "بلد"
      },
      {
        "key": "dhuluiyah",
        "en": "Dhuluiyah",
        "ar": "الضلوعية"
      }
    ],
    "key": "salah_al_din",
    "ar": "صلاح الدين"
  }));
  this.doCreate(new NewProvince({
    "en": "Anbar",
    "cities": [
      {
        "key": "ramadi",
        "en": "Ramadi",
        "ar": "الرمادي"
      },
      {
        "key": "falluja",
        "en": "Falluja",
        "ar": "الفلوجة"
      },
      {
        "key": "qaim",
        "en": "Qaim",
        "ar": "القائم"
      },
      {
        "key": "heet",
        "en": "Heet",
        "ar": "هيت"
      },
      {
        "key": "zikhekha",
        "en": "Zikhekha",
        "ar": "زخيخة"
      },
      {
        "key": "haditha",
        "en": "Haditha",
        "ar": "حديثة"
      },
      {
        "key": "habbaniyah",
        "en": "Habbaniyah",
        "ar": "الحبانية"
      },
      {
        "key": "khalidiya",
        "en": "Khalidiya",
        "ar": "الخالدية"
      },
      {
        "key": "al_waleed",
        "en": "Al Waleed",
        "ar": "الوليد"
      },
      {
        "key": "sagirah",
        "en": "Sagirah",
        "ar": "الساجرية"
      },
      {
        "key": "rutbah",
        "en": "Rutbah",
        "ar": "الرطبة"
      },
      {
        "key": "akashat",
        "en": "Akashat",
        "ar": "عكاشات"
      },
      {
        "key": "baghdadi",
        "en": "Baghdadi",
        "ar": "البغدادي"
      },
      {
        "key": "karabla",
        "en": "Karabla",
        "ar": "الكرابلة"
      },
      {
        "key": "kabisa",
        "en": "Kabisa",
        "ar": "كبيسة"
      },
      {
        "key": "nukhayb",
        "en": "Nukhayb",
        "ar": "النخيب"
      },
      {
        "key": "al_naeimia",
        "en": "Al Naeimia",
        "ar": "النعيمية"
      },
      {
        "key": "al_sijar",
        "en": "Al Sijar",
        "ar": "السجر"
      },
      {
        "key": "al_rahalia",
        "en": "Al Rahalia",
        "ar": "الرحالية"
      },
      {
        "key": "al_sakra",
        "en": "Al Sakra",
        "ar": "الصكرة"
      },
      {
        "key": "al_jiba",
        "en": "Al Jiba",
        "ar": "الجبة"
      },
      {
        "key": "al_hamiria",
        "en": "Al Hamiria",
        "ar": "الحميرية"
      },
      {
        "key": "al_jazira",
        "en": "Al Jazira",
        "ar": "الجزيرة"
      },
      {
        "key": "al_ramana",
        "en": "Al Ramana",
        "ar": "الرمانة"
      },
      {
        "key": "al_hibaria",
        "en": "Al Hibaria",
        "ar": "الهبارية"
      },
      {
        "key": "al_kasra",
        "en": "Al kasra",
        "ar": "الكسرة"
      },
      {
        "key": "al_saqlawia",
        "en": "Al Saqlawia",
        "ar": "الصقلاوية"
      },
      {
        "key": "obeidi",
        "en": "Obeidi",
        "ar": "العبيدي"
      },
      {
        "key": "aana",
        "en": "Aana",
        "ar": "عانة"
      },
      {
        "key": "rawa",
        "en": "Rawa",
        "ar": "راوة"
      },
      {
        "key": "amiriya",
        "en": "Amiriya",
        "ar": "العامرية"
      },
      {
        "key": "braunah",
        "en": "Braunah",
        "ar": "بروانة"
      },
      {
        "key": "karma",
        "en": "Karma",
        "ar": "الكرمة"
      },
      {
        "key": "al_huqlania",
        "en": "Al Huqlania",
        "ar": "الحقلانية"
      },
      {
        "key": "al_tharthar",
        "en": "Al Tharthar",
        "ar": "الثرثار"
      },
      {
        "key": "al_zawia",
        "en": "Al Zawia",
        "ar": "الثرثار"
      },
      {
        "key": "al_riyhana",
        "en": "Al Riyhana",
        "ar": "الريحانة"
      },
      {
        "key": "al_furat",
        "en": "Al Furat",
        "ar": "الفرات"
      },
      {
        "key": "al_mohammadi",
        "en": "Al Mohammadi",
        "ar": "المحمدي"
      },
      {
        "key": "hasybah",
        "en": "Hasybah",
        "ar": "حصيبة"
      },
      {
        "key": "al_sharqiya",
        "en": "Al Sharqiya",
        "ar": "الشرقية"
      },
      {
        "key": "arar",
        "en": "Arar",
        "ar": "عرعر"
      },
      {
        "key": "al_boasaf",
        "en": "Al Boasaf",
        "ar": "البوعساف"
      }
    ],
    "key": "anbar",
    "ar": "الانبار"
  }));
}
}
