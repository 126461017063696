import { MenuItem as Model } from './menu-item.model';

const url: string = '/your/path/to/route';

export class MockMenuItem {

    public static create(): Model {
        return new Model({
            title: 'Title',
            icon: 'home',
            url: '',
            action: 'callback',
        });
    }

}
