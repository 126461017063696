import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { MenuGroup } from '../../models/menu-group.model';

@Component({
  selector: 'thm-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuGroupComponent implements OnInit {

  @HostBinding('class.thm-menu-group')
  protected get classes(): boolean { return true; }

  isChildren:boolean;
  selectedIndex:any;
  public selectedChild1:any='';

  @Input()
  public group: MenuGroup;

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();
  
  
  constructor() { }

  public ngOnInit(): void { }

  public onNavigation(action: any): void {
    this.navigationEmitter.emit(action);
  }

  public onChildren(children:any,index):void{
    this.selectedChild1 = children.title;

     if(children.children){
           this.selectedIndex=index;
           this.isChildren=!this.isChildren;
     }
  }

  public onChildrenselect(children:any):void{
    this.selectedChild1 = children.title;
  }
}
