// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '@nymos/accounts/core';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Activating: StringFn = ((last4) => new Notification(Texts.CardAction.Activating(last4)));
    public static readonly Resuming: StringFn = ((last4) => new Notification(Texts.CardAction.Resuming(last4)));
    public static readonly Suspending: StringFn = ((last4) => new Notification(Texts.CardAction.Suspending(last4)));
    public static readonly Terminating: StringFn = ((last4) => new Notification(Texts.CardAction.Terminating(last4)));
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
}


