import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';

import {
  ActivatedRoute,
  Params,
} from '@angular/router';

import {
  Observable,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  ActivateTokenReason,
  ActivateTokenReasonCode,
  Card,
  DeleteTokenReason,
  DeleteTokenReasonCode,
  ResumeTokenReason,
  ResumeTokenReasonCode,
  SuspendTokenReason,
  SuspendTokenReasonCode,
  Token,
} from '@michel.freiha/ng-sdk';

import {
  Dialog,
} from '@nymos/theme';

import {
  ActivateToken,
  DeleteToken,
  Enquire,
  ResumeToken,
  SuspendToken,
} from '../../../store/cards/cards.actions';

import {
  CardsState,
} from '../../../store/cards/cards.state';

import {
  Texts,
} from '../../../texts/tokens.texts';

import {
  TokenConfirmComponent,
} from '../../components/token-confirm/token-confirm.component';


@Component({
  selector: 'nym-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  // @NOTES(leandro): Set as Default because it's refreshing the element
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListingComponent implements OnInit {


  @HostBinding('class.nym-listing')
  protected get classes(): boolean { return true; }

  @Select(CardsState.cards)
  public cards$: Observable<Card[]>;

  protected pan: string;

  constructor(
    private _ar: ActivatedRoute,
    private _store: Store,
    private _dialog: Dialog,
  ) {
    const router = this._store.selectSnapshot((state) => state.router.state);
    this._init(router.queryParams);
  }

  public ngOnInit(): void {
  }

  protected enquire(pan: string): void {
    if (!pan)
      return;

    const load = new Enquire({ pan: pan });
    const navigate = new Navigate(['.'], { pan: pan }, { relativeTo: this._ar });

    this._store.dispatch([navigate, load]);
  }

  protected activate({ card, token }: { card: Card, token: Token }): void {

    const text = Texts.Activate(token);
    const options = Object.values(ActivateTokenReasonCode);

    const ref = this._dialog.openConfirm(TokenConfirmComponent, {
      text: text,
      confirm: { label: 'Activate' },
      cancel: { label: 'Cancel' },
      options: options,
    });
    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;

      const code = <ActivateTokenReasonCode>result.option;
      const reason = new ActivateTokenReason({ code: code, comment: result.comment });
      this._store.dispatch(new ActivateToken({ card, token, reason }));
    });
  }

  protected resume({ card, token }: { card: Card, token: Token }): void {

    const text = Texts.Resume(token);
    const options = Object.values(ResumeTokenReasonCode);

    const ref = this._dialog.openConfirm(TokenConfirmComponent, {
      text: text,
      confirm: { label: 'Resume' },
      cancel: { label: 'Cancel' },
      options: options,
    });
    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;

      const code = <ResumeTokenReasonCode>result.option;
      const reason = new ResumeTokenReason({ code: code, comment: result.comment });
      this._store.dispatch(new ResumeToken({ card, token, reason }));
    });
  }

  protected suspend({ card, token }: { card: Card, token: Token }): void {

    const text = Texts.Suspend(token);
    const options = Object.values(SuspendTokenReasonCode);

    const ref = this._dialog.openConfirm(TokenConfirmComponent, {
      text: text,
      confirm: { label: 'Suspend' },
      cancel: { label: 'Cancel' },
      options: options,
    });
    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;

      const code = <SuspendTokenReasonCode>result.option;
      const reason = new SuspendTokenReason({ code: code, comment: result.comment });
      this._store.dispatch(new SuspendToken({ card, token, reason }));
    });
  }

  protected delete({ card, token }: { card: Card, token: Token }): void {

    const text = Texts.Delete(token);
    const hint = Texts.DeleteHint;
    const options = Object.values(DeleteTokenReasonCode);

    const ref = this._dialog.openConfirm(TokenConfirmComponent, {
      text: text,
      confirm: { label: 'Delete' },
      cancel: { label: 'Cancel' },
      options: options,
    });
    ref.afterClosed().subscribe((result) => {
      if (!result)
        return;

      const code = <DeleteTokenReasonCode>result.option;
      const reason = new DeleteTokenReason({ code: code, comment: result.comment });
      this._store.dispatch(new DeleteToken({ card, token, reason }));
    });
  }

  private _init(params: Params): void {
    this.pan = params.pan;
    this.enquire(this.pan);
  }

}
