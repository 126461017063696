import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Store,
} from '@ngxs/store';

import {
  SearchFilter,
  SearchOperator,SortDirection
} from '@michel.freiha/ng-sdk';

import {
  SearchSnapshot,
} from '@nymos/dashboard/shared';

import {
  LoadMoreFromChannelsPage,
  SearchFromChannelsPage,
} from '../../../core/store/accounts/accounts.actions';

import {
  SearchResultsComponent,
} from '../search-results/search-results.component';

import {
  Dialog,
} from '@nymos/theme';


@Component({
  selector: 'nym-channel-results',
  templateUrl: './channel-results.component.html',
  styleUrls: ['./channel-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelResultsComponent
  extends SearchResultsComponent
  implements OnInit, OnDestroy {

  private _channel: string;
  private currentSnap: SearchSnapshot;

  @HostBinding('class.nym-search-results')
  protected get classes(): boolean { return true; }

  protected get channel(): string { return this._channel; }

  constructor(
    protected _store: Store,
    protected _dialog: Dialog,
    protected _cd: ChangeDetectorRef,
  ) {
    super(_store, _dialog);
  }

  public ngOnInit(): void {
    this._subscription.add(this.router$.subscribe((router) => {
      if (!router.params.channel)
        return;

      this._channel = router.params.channel;
      this.currentSnap= undefined;

      const filter = new SearchFilter({
        field: 'channel',
        operator: SearchOperator.AnyOf,
        values: [this._channel],
      });

      this._sb.addFilter(filter);
      this._cd.markForCheck();
    }));

    this._subscription.add(this.search$.subscribe((snap) => {
      this.currentSnap=snap;
      this.loadBySort(snap,undefined,undefined);
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected loadBySort(snap: SearchSnapshot,sortBy: string,orderBy:SortDirection): void {

    if (snap.isEmpty)
      return;

    snap.criteria["sortBy"]= sortBy;
    snap.criteria["orderBy"]= orderBy;
    
    this._store.dispatch(new SearchFromChannelsPage(snap.criteria))
  }

  public sortLoad(params:any): void {

      this.loadBySort(this.currentSnap,params.col,params.direction);
  }

  protected loadMore(): void {
    this._store.dispatch(new LoadMoreFromChannelsPage());
  }
}
