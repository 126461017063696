import {
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    CreditCardService,
} from './services/credit-card.service';


export const creditCardServiceFactory = (
    service: CreditCardService,
) => {
    return service || new CreditCardService();
};

export const creditCardProvider: Provider = {
    provide: CreditCardService,
    useFactory: creditCardServiceFactory,
    deps: [
        [new Optional(), new SkipSelf(), CreditCardService],
    ],
};
