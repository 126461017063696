import * as faker from 'faker';

import {
    Injectable,
    LOCALE_ID,
    Inject,
} from '@angular/core';

import {
  CurrencyPipe,
} from '@angular/common';

import {
    filter,
} from 'rxjs/operators';

import {
    Store,
} from '@ngxs/store';

import {
    CoreNote,
    NymcardCmsWalletTransactionDetails as TransactionDetails,
    SearchCriteria,
    SearchFilter,
    Problem,
    SearchOperator
} from '@michel.freiha/ng-sdk';


import {
    ConfirmComponent,
    Dialog,
    DialogRef,
} from '@nymos/theme';

import {
    NoteConfirmComponent,
    RefundConfirmComponent,
    NotesState,
    LoadUserNotesFromPage,
    LoadCardNotesFromPage

} from '@nymos/notes';


import {
    Card,
    Texts,
    RefreshAccountFromUserDetailsPage,
    AccountsUsersState
} from '@nymos/accounts/core';



@Injectable({
    providedIn: 'root',
})
export class CardHandler {

    private _ref: DialogRef<any, any>;
    private _id :string;

    constructor(
        private _dialog: Dialog,
        private _store: Store,
        @Inject(LOCALE_ID) protected locale: string,

    ) {}

    

    public activate(card: Card, action: new (accountId: string, cardId: string, cardLast4: string) => any): any {

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.CardDialog.ActivateTitle(card.last4),
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(card.accountId, card.id, card.last4));
        });
    }

    public resume(card: Card, action: new (accountId: string, cardId: string, cardLast4: string,note: CoreNote) => any): any {

            this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.CardDialog.ResumeTitle(card.last4),
            hint: Texts.CardDialog.ResumeHint,
            cancel: { label: 'Cancel' },
            confirm: { label: 'Resume' },
        });


        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(card.accountId,card.id,card.last4,note))
        });

    }

    public suspend(card: Card, 
                   action: new (accountId: string, cardId: string, cardLast4: string, note: CoreNote) => any): any {

            this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.CardDialog.SuspendTitle(card.last4),
            hint: Texts.CardDialog.SuspendHint,
            cancel: { label: 'Cancel' },
            confirm: { label: 'Suspend' },
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(card.accountId,card.id,card.last4,note))
        });
    }


    public terminate(
        card: Card,
        loadCard:new (accountId: string, walletId: string,transactionId:string,cardId:string, transaction:TransactionDetails,money:string )=>any,
        transferCard:new (accountId: string, walletId: string,transactionId:string,cardId:string, transaction:TransactionDetails,money:string )=>any,
        action: new (accountId: string, cardId: string, cardLast4: string, note: CoreNote) => any,
    ): any {

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.CardDialog.TerminateTitle(card.last4),
            hint: Texts.CardDialog.TerminateHint,
            cancel: { label: 'Cancel' },
            confirm: { label: 'Terminate' },
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
             return this._store.dispatch(new action(card.accountId, card.id, card.last4, note)).subscribe((res)=>{
                 this._id = faker.random.uuid();
                 const money = new CurrencyPipe(this.locale).transform(card.balance, card.limit.currency)
                 const walletId = card.accountId;
                 const transactionId = this._id
               
                    const transaction = new TransactionDetails({
                    amount: card.balance,
                    currency: card.limit.currency.toLowerCase(),
                    description: Texts.TopupDialog.TransferBetweenWallets(money),
                  })
                  
                   this._store.dispatch(new loadCard(card.accountId,card.accountId, transactionId,card.id,transaction,money)).subscribe((res)=>{
                   this._store.dispatch(new transferCard(card.accountId,card.accountId,transactionId,
                   card.id,transaction,money))
                })
              
            })
        });
    }

       public refund(
        card: Card,
        loadCard:new (accountId: string, walletId: string,transactionId:string,cardId:string, transaction:TransactionDetails,money:string )=>any,
        transferCard:new (accountId: string, walletId: string,transactionId:string,cardId:string, transaction:TransactionDetails,money:string )=>any,
    ): any {

        this._ref = this._dialog.openConfirm(RefundConfirmComponent, {
            text: Texts.CardDialog.RefundTitle("****"+card.last4),
            hint: Texts.CardDialog.RefundHint,
            cancel: { label: 'Cancel' },
            confirm: { label: 'Refund' },
            balance: card.balance
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: any) => {

                 this._id = faker.random.uuid();
                 const money = new CurrencyPipe(this.locale).transform(note.amount, card.limit.currency)
                 const walletId = card.accountId;
                 const transactionId = this._id
               
                    const transaction = new TransactionDetails({
                    amount: note.amount,
                    currency: card.limit.currency.toLowerCase(),
                    description: Texts.TopupDialog.TransferBetweenWalletsOnRefund(money),
                  })

                   this._store.dispatch(new loadCard(card.accountId,card.accountId, transactionId,card.id,transaction,money)).subscribe((res)=>{
                   this._store.dispatch(new transferCard(card.accountId,card.accountId,transactionId,
                   card.id,transaction,money))
                })
       });
    }

}
