import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  Note,
} from '@nymos/notes/core';

@Component({
  selector: 'nym-note-listing',
  templateUrl: './note-listing.component.html',
  styleUrls: ['./note-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NoteListingComponent implements OnInit {

  @HostBinding('class.nym-note-listing')
  protected get classes(): boolean { return true; }

  @Input()
  public notes: Note[];

  constructor() { }

  public ngOnInit(): void { }

}
