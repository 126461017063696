import {
  NgModule,
} from '@angular/core';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  NotesState,
} from './notes/notes.state';


export const STATES_MODULES = [NotesState];

@NgModule({
  imports: [
    // TODO(@leandro): extract notifications from shared
    // NotificationModule,
    NgxsModule.forFeature(STATES_MODULES),
  ],
  exports: [NgxsModule],
})
export class StoreModule { }
