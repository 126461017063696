

export class AdminScope {

    public id: string;
    public name: string;

    constructor(ob?: Partial<AdminScope>) {

        this.id = undefined;
        this.name = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }

        if (ob.name !== undefined) { this.name = ob.name; }

    }
}

export const PERMISSIONS = [
    new AdminScope({ id: 'p:accounts:read', name: 'Accounts Read' }),
    new AdminScope({ id: 'p:accounts:write', name: 'Accounts Write' }),
    new AdminScope({ id: 'p:acl:read', name: 'Acl Read' }),
    new AdminScope({ id: 'p:acl:write', name: 'Acl Write' }),
    new AdminScope({ id: 'p:admins:read', name: 'Admins Read' }),
    new AdminScope({ id: 'p:admins:write', name: 'Admins Write' }),
    new AdminScope({ id: 'p:transactions:read', name: 'Transactions Read' }),
    new AdminScope({ id: 'p:transactions:write', name: 'Transactions Write' }),
    new AdminScope({ id: 'p:uploads:write', name: 'Uploads Manage' }),
];
