import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';


import {
    SessionGuard,
} from './guards/session.guard';

import {
    AuthComponent,
} from './views/layouts/auth/auth.component';

import {
    SignInComponent,
} from './views/containers/sign-in/sign-in.component';

import {
    PasswordChangeComponent,
} from './views/containers//password-change/password-change.component';

import {
    MobileVerificationComponent,
} from './views/containers/mobile-verification/mobile-verification.component';

import { ResetPasswordComponent } from './views/containers/reset-password/reset-password.component';

export const routes: Routes = [
    {
        path: ':id/account',
        component: AuthComponent,
        children: [
            { path: 'signin', component: SignInComponent },
            { path: 'password/reset', component: ResetPasswordComponent },
            { path: 'password/change', component: PasswordChangeComponent, canActivate: [SessionGuard] },
            { path: ':rid', component: PasswordChangeComponent },
            { path: 'challenge/ipp', component: MobileVerificationComponent, canActivate: [SessionGuard] },
            // { path: 'challenge/ipp', component: MobileVerificationComponent},
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }
