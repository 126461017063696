import { MenuItem } from './menu-item.model';
import { MenuGroup } from './menu-group.model';

export class Menu {
    public items: MenuItem[];

    constructor(ob?: Partial<Menu>) {

        this.items = [];

        if (!ob)
            return;

        if (ob.items !== undefined) {
            ob.items.forEach((o) => {

                switch (o.type) {
                    case 'group':
                        return this.items.push(new MenuGroup(o));

                    case 'item':
                    default:
                        return this.items.push(new MenuItem(o));
                }

            });
        }
    }
}
