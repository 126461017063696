import * as faker from 'faker';

import {
    Token,
    TokenStatus,
} from '@michel.freiha/ng-sdk';

import {
    ModelFactory,
} from '@nymos/backend';

export class FakerToken extends ModelFactory<Token> {

    constructor() {
        super(FakerToken.create, (m: Token) => m.id);
    }

    public static create(id?: string): Token {

        const index = id ? id : 'fake##' + faker.phone.phoneNumber('########-####-####-####-############');

        const status = faker.random.arrayElement([
            TokenStatus.Active,
            TokenStatus.Active,
            TokenStatus.Active,
            TokenStatus.Active,
            TokenStatus.Suspended,
            TokenStatus.Inactive,
            TokenStatus.Deleted,
        ]);

        const token = new Token({
            'id': index,
            'status': status,
        });

        return token;
    }
}

