
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import {
  PerfectScrollbarComponent,
} from './containers/perfect-scrollbar/perfect-scrollbar.component';


export const MATERIAL_MODULES = [];

@NgModule({
  declarations: [
    PerfectScrollbarComponent,
  ],

  imports: [
    CommonModule,
    PerfectScrollbarModule,
  ],

  exports: [
    PerfectScrollbarComponent,
  ],
})
export class ThemeComponentsScrollbarModule { }
