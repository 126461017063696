/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    forkJoin,
} from 'rxjs';

import {
    catchError,
    finalize,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    NymcardAccountLimitsInternal,
    PasscodeResetRequest,
    UserAccountUpdateRequest,
    Problem,
    UserAccountInternal,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    AccountBuilder,
} from '../../builders/account.builder';

import {
    AccountsOperations,
} from '../accounts.operations';

import {
    FailFromApi,
    BlockAccountFromUserDetailsPage,
    GetAccountFromUserDetailsPage,
    GetAccountFromUserExistsGuard,
    RefreshAccountFromUserApi,
    RefreshAccountFromUserDetailsPage,
    RejectAccountFromUserDetailsPage,
    ResetPasscodeFromUserDetailsPage,
    UnblockAccountFromUserDetailsPage,
    VerifyAccountFromUserDetailsPage,
    UpdateAccountFromUserEditDetailsPage,
    SetStatusLoadingFromStatusMenu
} from './accounts-users.actions';

import {
    Notifications,
} from './accounts-users.notifications';


export interface AccountsUsersStateModel {
    limits: { [id: string]: NymcardAccountLimitsInternal };
    internals: { [id: string]: UserAccountInternal };
    indexes: { [id: string]: AccountChannelIndex };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    statusLoading:boolean;
}

const stateDefaults: AccountsUsersStateModel = {
    limits: {},
    internals: {},
    indexes: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    statusLoading:undefined
};

@State<AccountsUsersStateModel>({
    name: 'users',
    defaults: stateDefaults,
})
export class AccountsUsersState extends AccountsOperations<any> {

    public static account(id: string): any {
        return createSelector([AccountsUsersState], (state: AccountsUsersStateModel) => {
            // TODO(@leandro): Return specific view model for user accounts
            return new AccountBuilder(this.uploadOptions)
                .withIndex(state.indexes[id])
                .withUser(state.internals[id])
                .withLimits(state.limits[id])
                .build();
        });
    }

    @Selector()
    public static problem(state: AccountsUsersStateModel): Problem {
        return state.problem;
    }

     @Action(FailFromApi)
    public fail(ctx: StateContext<AccountsUsersStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this.nc.show(Notifications.Failure);
    }

    @Selector()
    public static loading(state: AccountsUsersStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static statusLoading(state: AccountsUsersStateModel): boolean {
        return state.statusLoading;
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsUsersStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(GetAccountFromUserDetailsPage)
    @Action(GetAccountFromUserExistsGuard)
    public loadUser(ctx: StateContext<AccountsUsersStateModel>, { id }: any): any {

        const update = this.updateItem(ctx, id, Notifications.Loading);

        ctx.patchState({ loading: true });
        return this._loadUser(ctx, id).pipe(update).pipe(
            finalize(() => ctx.patchState({ loading: false })),
        );
    }

    @Action(RefreshAccountFromUserDetailsPage)
    @Action(RefreshAccountFromUserApi)
    public refreshUser(ctx: StateContext<AccountsUsersStateModel>, { id }: any): any {
        const refresh = this.refreshItem(ctx, id);
        return this._loadUser(ctx, id).pipe(refresh);
    }

    @Action(ResetPasscodeFromUserDetailsPage)
    public resetPasscode(ctx: StateContext<AccountsUsersStateModel>, { id, note }: any): any {

        this.nc.show(Notifications.PasscodeReset);

        return this.passcodeService.resetPasscode(id, new PasscodeResetRequest({ note: note })).pipe(
            finalize(() => this.nc.show(Notifications.PasscodeReseted)),
        );
    }

    @Action(VerifyAccountFromUserDetailsPage)
    public verifyUser(ctx: StateContext<AccountsUsersStateModel>, {id ,docType}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromUserApi);
        if(docType=='KYC1')
           return this.userService.approveUserAccount(id).pipe(update);
        else
          return this.userService.approveKyc2(id).pipe(
             catchError((problem) => {
                  return ctx.dispatch(new FailFromApi({ problem: problem }));
              }),
              finalize(() => {
               ctx.patchState({ loading: false})})
              
            );
    }

    @Action(UpdateAccountFromUserEditDetailsPage)
    public editUser(ctx: StateContext<AccountsUsersStateModel>,  {id, user}: any): any {
        return this.userService.
        update(id,new UserAccountUpdateRequest(user));
    }

    @Action(RejectAccountFromUserDetailsPage)
    public rejectUser(ctx: StateContext<AccountsUsersStateModel>, { id, note ,docType}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromUserApi);
         if(docType=='KYC1')
            return this.userService.rejectUserAccount(id, note).pipe(update);
        else
           return this.userService.rejectKyc2(id,note).pipe(update);
        
    }

    @Action(BlockAccountFromUserDetailsPage)
    public blockAccount(ctx: StateContext<AccountsUsersStateModel>, { id , note}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel === 'blocked', RefreshAccountFromUserApi);
        return this.accountService.blockAccount(id, note).pipe(update);
    }

    @Action(UnblockAccountFromUserDetailsPage)
    public unblockAccount(ctx: StateContext<AccountsUsersStateModel>, { id, note }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'blocked', RefreshAccountFromUserApi);
        return this.accountService.unblockAccount(id).pipe(update);
    }

    @Action(SetStatusLoadingFromStatusMenu)
    public setLoadingStatus(ctx: StateContext<AccountsUsersStateModel>,  flag: boolean): any {
        ctx.patchState({ statusLoading: flag["flag"]});
    }

    private _loadUser(ctx: StateContext<AccountsUsersStateModel>, id: string): any {
        return forkJoin({
            index: this.loadChannelIndex(ctx, id),
            internal: this._loadUserInternal(ctx, id),
            limits: this.loadLimitInternal(ctx, id),
        });
    }

    private _loadUserInternal(ctx: StateContext<AccountsUsersStateModel>, id: any): any {
        return this.userService.loadUserAccount(id);
    }

}
