// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Loading: Notification = new Notification('Loading scopes...');
}


