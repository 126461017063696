import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  AdminRoleInternal,
  SearchFilter,
  SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
  animations,
} from './role-aside-item.animations';

type expandedState = 'expanded' | 'collapsed';

@Component({
  selector: 'nym-role-aside-item',
  templateUrl: './role-aside-item.component.html',
  styleUrls: ['./role-aside-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    animations.headerExpansion,
    animations.bodyExpansion,
    animations.indicatorRotate,
  ],
})
export class RoleAsideItemComponent implements OnInit {

  @HostBinding('class.nym-role-aside-item')
  protected get classes(): boolean { return true; }

  private _expanded: boolean = false;

  @Input()
  public role: AdminRoleInternal;

  @Input()
  public loading: boolean;

  @Output('filter') // tslint:disable-line:no-output-rename
  public filterEmitter: EventEmitter<SearchFilter> = new EventEmitter();

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<AdminRoleInternal> = new EventEmitter();

  public get expanded(): boolean { return this._expanded; }

  public get expandedState(): expandedState { return this._expanded ? 'expanded' : 'collapsed'; }

  constructor() { }

  public ngOnInit(): void { }

  @HostListener('click', ['$event'])
  public select(event: Event): void {
    const filter = new SearchFilter({
      field: 'roles',
      operator: SearchOperator.AnyOf,
      values: [this.role.role.id],
    });

    this.filterEmitter.emit(filter);
    this.selectEmitter.emit(this.role);
  }

  public toggle(): void {
    this._expanded = !this._expanded;
  }
}
