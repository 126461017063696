import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  RouterModule,
} from '@angular/router';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatRippleModule,
} from '@angular/material/core';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatMenuModule,
} from '@angular/material/menu';

import {
  ProfileComponent,
} from './containers/profile/profile.component';

import {
  ProfilePhotoComponent,
} from './components/profile-photo/profile-photo.component';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatRippleModule,
];

@NgModule({

  declarations: [
    ProfileComponent,
    ProfilePhotoComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    FlexLayoutModule,
    RouterModule,
    CommonModule,
  ],

  exports: [
    ProfileComponent,
  ],

})
export class ThemeComponentsProfileModule { }
