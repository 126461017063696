import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  MessageComponent,
} from './containers/message/message.component';

export const MATERIAL_MODULES = [];

@NgModule({
  declarations: [
    MessageComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    MessageComponent,
  ],
})
export class ThemeComponentsMessageModule { }
