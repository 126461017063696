import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    MatIconModule,
} from '@angular/material/icon';

import {
    MatTooltipModule,
} from '@angular/material/tooltip';

import {
    FlexLayoutModule,
} from '@angular/flex-layout';

import {
    ThemePipesModule,
} from '../../../pipes/pipes.module';

import {
    ThemeComponentsShimmerModule,
} from '../shimmer/shimmer.module';

import {
    AvatarComponent,
} from './containers/avatar/avatar.component';

export const MATERIAL_MODULES = [
    MatIconModule,
    MatTooltipModule,
];


@NgModule({
    declarations: [
        AvatarComponent,
    ],

    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        FlexLayoutModule,
        ThemePipesModule,
        ThemeComponentsShimmerModule,
    ],

    exports: [
        AvatarComponent,
    ],
})
export class ThemeComponentsAvatarModule { }
