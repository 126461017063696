import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  AsideComponent,
} from './containers/aside/aside.component';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
];


@NgModule({
  declarations: [
    AsideComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    AsideComponent,
  ],
})
export class ThemeComponentsAsideModule { }
