import {
    NymcardCmsWalletTransactionApprovalStatus as WalletTransactionApprovalStatus,
    NymcardCmsWalletTransactionInternal as WalletTransactionInternal,
    NymcardCmsWalletTransactionType as WalletTransactionType,
} from '@michel.freiha/ng-sdk';

import {
    Texts,
} from '../texts/accounts.texts';

import {
    WalletActivity,
} from '../models/wallet-activity.model';


export class WalletActivityBuilder {

    private _internal: WalletTransactionInternal;

    public withInternal(value: WalletTransactionInternal): WalletActivityBuilder {
        this._internal = value;
        return this;
    }

    public build(): WalletActivity {

        const internal = this._internal;
        if (!internal)
            return undefined;

        const id = internal.internalId;
        const title = this._title();
        let receiverNumber = internal.receiver ? internal.receiver.mobile : '';
        const accountId = internal.senderAccountId;
        const walletId = internal.senderWalletId;
        const created = internal.created;
        const createdBy = internal.createdBy;
        const modified = internal.modified;
        const category = internal.type;
        const status = this._status();
        const reason = 'TODO';
        const source = this._source();
        const paymentMethod = this._paymentMethod();

        const amount = this._amount(); 
        const currency = internal.walletTransaction
            ? internal.walletTransaction.currency.toUpperCase()
            : internal.details.currency.toUpperCase();

        const fees = internal.walletTransaction.totalFees;
        const total = internal.walletTransaction.totalAmount;
        const reviewer = internal.approvedBy[0] || internal.rejectedBy[0];

        if(internal.type===WalletTransactionType.DebitFee){
            if(this._internal.references && this._internal.references.account && this._internal.references.account.mobile)
            receiverNumber = internal.references.account.mobile;
        }

        if(internal.type===WalletTransactionType.Debit){
            receiverNumber = internal.receiver.displayName;
        }

        if(internal.type===WalletTransactionType.Transfer){
              if(internal.sender && internal.receiver && 
                 internal.sender.type=='user' && internal.receiver.type=='agent'){
                     receiverNumber = internal.receiver.displayName +":"+"("+ internal.receiver.mobile+")" ;
                  }
              }
           

        return new WalletActivity({
            id: id,
            receiverNumber,
            title: title,
            accountId: accountId,
            walletId: walletId,
            created: created,
            createdBy: createdBy,
            modified: modified,
            category: category,
            paymentMethod: paymentMethod,
            currency: currency,
            amount: amount,
            fees: fees,
            total: total,
            source: source,
            status: status as any,
            reason: reason,
            reviewer: reviewer,
        });
    }

    private _title(): string {
        const internal = this._internal;
         
        switch (internal.type) {
            case WalletTransactionType.Credit:
                return Texts.WalletActivity.CreditTitle;
            case WalletTransactionType.Debit:
                return Texts.WalletActivity.DebitTitle;
            case WalletTransactionType.CardIssuance:
                return internal.cardProduct+" "+Texts.WalletActivity.CardIssuanceTitle+this.sendCard4(internal);
            case WalletTransactionType.CardTopUp:
                return this.filterCard(internal.receiverCard.productName)+" "+Texts.WalletActivity.CardReloadTitle +" ****"+internal.receiverCard.last4;
            case WalletTransactionType.Transfer:
                 if(internal.sender && internal.receiver && 
                    internal.sender.type=='user' && 
                    internal.receiver.type=='agent')
                   return Texts.WalletActivity.DebitTitle;
                 else
                return Texts.WalletActivity.TransferTitle;
            case WalletTransactionType.CardRefund:
                return "Card"+" ****"+internal.senderCard.last4 +
                " Balance Refund"; 
            case WalletTransactionType.DebitFee:
                return this.travelText();
            default:
                throw new Error(`Wallet Activity Type not known: ${internal.type}`);
        }
    }

    private filterCard(value):string{

         return value.toUpperCase()==="NEO PREPAID" ? "Virtual" : "Travel";
    }

    private sendCard4(value):string{

        if(value && value.receiverCard && value.receiverCard.last4)
         return " ****"+value.receiverCard.last4;
         else
         return "";
    }

    private travelText():string{

        if(this._internal.references && this._internal.references.card && this._internal.references.card.last4){
            return 'Travel Card ****'+this._internal.references.card.last4+' Issuance Fee';
        }
        else{
         return Texts.WalletActivity.TravelCardIssuanceFeeTitle;
            }
    }

    private _amount(): number {
        
        const internal = this._internal;

        if(internal.type === WalletTransactionType.DebitFee){
            return internal.walletTransaction.totalFees;
        }
        else{
            if(internal.walletTransaction){
             return internal.walletTransaction.amount
            }
            else{
                return internal.details.amount;
            }
        }

    }

    private _source(): string {
        const internal = this._internal;

        switch (internal.type) {
            case WalletTransactionType.Credit:
                return `${internal.sender.displayName}: ${internal.createdBy.displayName}`;
            case WalletTransactionType.Debit:
                return `${internal.receiver.displayName}: ${internal.createdBy.displayName}`;
            case WalletTransactionType.CardIssuance:
                return `${internal.sender.displayName}`;
            case WalletTransactionType.CardTopUp:
                return internal.receiverCard.productName.toUpperCase().includes("TRAVEL") ? "":`${internal.sender.displayName}`;
            case WalletTransactionType.Transfer:
                return `${internal.sender.displayName} (${internal.sender.mobile})`;
            case WalletTransactionType.DebitFee:
                return `${internal.sender.displayName}`;
            case WalletTransactionType.CardRefund:
                return `${internal.createdBy.displayName}:${internal.createdBy.type}`;
            default:
                throw new Error(`Wallet Activity Type not known: ${internal.type}`);

        }
    }

    private _paymentMethod(): string {
        const internal = this._internal;

        switch (internal.type) {
            case WalletTransactionType.Credit:
            case WalletTransactionType.Debit:
                return Texts.WalletActivity.CashPaymentMethod;

            case WalletTransactionType.CardIssuance:
            case WalletTransactionType.CardTopUp:
            case WalletTransactionType.DebitFee:
            case WalletTransactionType.CardRefund:
            case WalletTransactionType.Transfer:
                return Texts.WalletActivity.WalletPaymentMethod(internal.walletTransaction.currency.toUpperCase());


            default:
                throw new Error(`Wallet Activity Type not known: ${internal.type}`);
        }
    }

    private _status(): string {
        const internal = this._internal;

        switch (internal.approvalStatus) {
            case WalletTransactionApprovalStatus.Pending:
            case WalletTransactionApprovalStatus.Rejected:
                return internal.approvalStatus;
            case WalletTransactionApprovalStatus.Approved:
                return internal.status;
            default:
                throw new Error(`Wallet Activity Approval Status not known: ${internal.approvalStatus}`);
        }
    }

}
