import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardStatusColor',
})
export class CardStatusColorPipe implements PipeTransform {

  public transform(status: string): string {

    switch (status) {
      case 'activate':
      case 'resume':
      case 'active':
        return 'green';

      case 'inactive':
        return 'red';

      case 'suspend':
      case 'suspended':
        return 'yellow';

      case 'terminate':
      case 'terminated':
      default:
        return 'menu';
    }
  }

}


