import {
    NgModule,
} from '@angular/core';

import {
    FooterDirective,
} from './footer.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        FooterDirective,
    ],

    imports: [],

    exports: [
        FooterDirective,
    ],

})
export class ThemeDirectivesFooterModule { }
