import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Subscription,
} from 'rxjs';

import {
  AgentAccount,
  AgentDocuments,
  AgentNationalId,
  CoreUploadsService,
  FileUploadRef,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  ImageService,
} from '../../../services/image.service';


@Component({
  selector: 'nym-agent-documents-edit',
  templateUrl: './agent-documents-edit.component.html',
  styleUrls: ['./agent-documents-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})

export class AgentDocumentsEditComponent implements OnInit, OnChanges, OnDestroy {

  private _subscription: Subscription = new Subscription();
  private _form: FormGroup;

  protected maxDate: Date = new Date();

  public imageFront: FileUploadRef;
  public imageFrontUrl: string;

  public imageBack: FileUploadRef;
  public imageBackUrl: string;

  @HostBinding('class.nym-agent-documents-edit')
  protected get classes(): boolean { return true; }

  @Input()
  public account: AgentAccount;

  @Input()
  public problem: Problem;

  @Input()
  public legalDocError: boolean;

  protected doc1Error:boolean;

  protected get form(): FormGroup { return this._form; }

  public get valid(): boolean { return this._form.valid; }

  public get number(): AbstractControl { return this.form.get('number'); }
  public get issuer(): AbstractControl { return this.form.get('issuer'); }
  public get issuanceDate(): AbstractControl { return this.form.get('issuanceDate'); }

  public get agentDocuments(): AgentDocuments {
    return new AgentDocuments({
      nationalId: new AgentNationalId({
        number: this.number.value,
        authority: this.issuer.value,
        dateOfIssue: this.issuanceDate.value,
        imageFront: this.imageFront,
        imageBack: this.imageBack,
      }),
    });
  }

  constructor(
    private _fb: FormBuilder,
    private _service: CoreUploadsService,
    private _cd: ChangeDetectorRef,
    private _ph: ProblemHandler,
    private _imageService: ImageService,
  ) {
    this._form = this._fb.group({
      number: ['', [
        Validators.required,
        Validators.pattern(/^[0-9]{12}$/),
      ]],
      issuer: ['', [
        Validators.required,
      ]],
      issuanceDate: ['', [
        Validators.required,
      ]],
    });
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {

    const accountChanges = changes['account'];

    if (this.problem) {
      this._ph.handle(this.problem, this.form);
    }

    if (this.legalDocError) {
      this.validateAllFormFields(this.form);
    }

    if(!this.imageBackUrl || !this.imageFrontUrl || !this.imageFront || !this.imageBack )
      this.doc1Error= true;
    else
      this.doc1Error=false;

    if (accountChanges) {
      this._setForm(accountChanges.currentValue);
    }
  }

  protected uploadImageFront$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageFront(ref)));
  }

  protected uploadImageBack$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageBack(ref)));
  }

  private _setForm(account: AgentAccount): void {
    const document = account && account.documents && account.documents.nationalId;

    if (!document)
      return;

    this._form.patchValue({
      number: document.number || '',
      issuer: document.authority || '',
      issuanceDate: document.dateOfIssue || '',
    });

    this._setImageFront(document.imageFront);
    this._setImageBack(document.imageBack);
  }

  private _setImageFront(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageFront = ref;
    this.imageFrontUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageBack(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageBack = ref;
    this.imageBackUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  protected clearImageFront$$(): void {
    this.imageFront = null;
  }

  protected clearImageBack$$(): void {
    this.imageBack = null;
  }

  validateAllFormFields(formGroup: FormGroup): void {       
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);      
    if (control instanceof FormControl) {      
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) { 
      this.validateAllFormFields(control);     
    }
  });
}
}
