import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'nym-admin-info-item',
  templateUrl: './admin-info-item.component.html',
  styleUrls: ['./admin-info-item.component.scss'],
})
export class AdminInfoItemComponent implements OnInit {

  @HostBinding('class.nym-admin-info-item')
  protected get classes(): boolean { return true; }

  @Input()
  public icon: string;

  @Input()
  public info: string;

  constructor() { }

  public ngOnInit(): void {
  }

}
