import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Card,
  Token,
} from '@michel.freiha/ng-sdk';


@Component({
  selector: 'nym-card-result-item',
  templateUrl: './card-result-item.component.html',
  styleUrls: ['./card-result-item.component.scss'],
  // @NOTES(leandro): Set as Default because it's refreshing the element
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CardResultItemComponent implements OnInit {

  @HostBinding('class.nym-card-result-item')
  protected get classes(): boolean { return true; }

  @Output('activate') // tslint:disable-line:no-output-rename
  protected activateEmitter: EventEmitter<any> = new EventEmitter();

  @Output('resume') // tslint:disable-line:no-output-rename
  protected resumeEmitter: EventEmitter<any> = new EventEmitter();

  @Output('suspend') // tslint:disable-line:no-output-rename
  protected suspendEmitter: EventEmitter<any> = new EventEmitter();

  @Output('delete') // tslint:disable-line:no-output-rename
  protected deleteEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public card: Card;

  constructor() { }

  public ngOnInit(): void { }

  protected activate(token: Token): void {
    this.activateEmitter.emit({card: this.card, token: token});
  }

  protected resume(token: Token): void {
    this.resumeEmitter.emit({card: this.card, token: token});
  }

  protected suspend(token: Token): void {
    this.suspendEmitter.emit({card: this.card, token: token});
  }

  protected delete(token: Token): void {
    this.deleteEmitter.emit({card: this.card, token: token});
  }

}
