import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  AgentProfile,
  CoreAccount
} from '@michel.freiha/ng-sdk';

import {
  Account,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-kyc-agent-info',
  templateUrl: './kyc-agent-info.component.html',
  styleUrls: ['./kyc-agent-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KycAgentInfoComponent implements OnInit {

  @HostBinding('class.nym-kyc-agent-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  protected get agentProfile(): CoreAccount { return this.account && this.account.kyc2Agent; }

  protected get incAgentProfile(): CoreAccount { return this.account && this.account.kyc2SubmittedBy; }
  
  constructor() { }

  public ngOnInit(): void {   }

}
