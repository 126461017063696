/* tslint:disable: no-non-null-assertion */

import {
    Injectable,
    Injector,
    TemplateRef,
} from '@angular/core';

import {
    OverlayRef,
} from '@angular/cdk/overlay';

import {
    ComponentPortal,
    ComponentType,
    PortalInjector,
    TemplatePortal,
} from '@angular/cdk/portal';

import {
    MatDialogConfig as DialogConfig,
} from '@angular/material/dialog';

import {
    DialogContainer,
} from '../outlets/dialog.container';

import {
    DialogRef,
} from '../dialog.ref';

import {
    DialogService,
} from '../services/dialog.service';

import {
    CreateDialog,
} from '../create-dialog';

@Injectable()
export class ModalFactory implements CreateDialog {

    constructor(
        private _dialog: DialogService,
        private _injector: Injector,
    ) { }

    public createDialog<C extends DialogContainer, T, D, R>(
        overlay: OverlayRef,
        container: ComponentType<C>,
        content: ComponentType<T> | TemplateRef<T>,
        config?: DialogConfig<D>,
    ): DialogRef<T, R> {
        const containerInstance = this._attachDialogContainer<C>(overlay, config, container);
        const ref = this._attachDialogContent<C, T, R>(overlay, config, containerInstance, content);
        return ref;
    }

    private _attachDialogContainer<C extends DialogContainer>(
        overlay: OverlayRef,
        config: DialogConfig,
        container: ComponentType<C>,
    ): C {
        const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
        const injector = new PortalInjector(userInjector || this._injector, new WeakMap([
            [DialogConfig, config],
        ]));

        const containerPortal = new ComponentPortal(container, config.viewContainerRef, injector);
        const containerRef = overlay.attach<C>(containerPortal);

        return containerRef.instance;
    }

    private _attachDialogContent<C extends DialogContainer, T, R>(
        overlay: OverlayRef,
        config: DialogConfig,
        containerInstance: C,
        content: ComponentType<T> | TemplateRef<T>,
    ): DialogRef<T, R> {

        const ref = new DialogRef<T, R>(overlay, containerInstance, config.id);

        if (content instanceof TemplateRef) {
            const portal = new TemplatePortal<T>(content, null!, <any>{ $implicit: config.data, ref });
            containerInstance.attachTemplatePortal(portal);
        } else {
            const injector = this._dialog.createInjector<T>(config, ref, containerInstance);
            const portal = new ComponentPortal(content, undefined, injector);
            const contentRef = containerInstance.attachComponentPortal<T>(portal);
            ref.componentInstance = contentRef.instance;
        }

        ref.updateSize(config.width, config.height).updatePosition();

        return ref;
    }
}
