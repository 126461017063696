import {
    Injectable,
} from '@angular/core';

import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    NavigationExtras,
    Route,
    Router,
} from '@angular/router';

import {
    Observable,
} from 'rxjs';

import {
    Store,
} from '@ngxs/store';

import {
    Navigate,
} from '@ngxs/router-plugin';

import {
    firstScope,
    isPlainObject,
    transformStringToArray,
} from '../utils/function.util';

import {
    ScopesRouterData,
} from '../models/scopes-router-data.model';

import {
    ScopesChecker,
} from '../services/scopes.service';

import {
    ProfileState,
} from '../store/profile/profile.state';


interface NavigationParameters {
    commands: any[];
    extras?: NavigationExtras;
}

@Injectable()
export class ScopesGuard implements CanActivate, CanLoad, CanActivateChild {

    constructor(
        private _store: Store,
        private _router: Router,
        private _sc: ScopesChecker,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return this._hasScopes(route);
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return this._hasScopes(childRoute);
    }

    public canLoad(route: Route): Observable<boolean> | boolean {
        return this._hasScopes(route);
    }

    private _hasScopes(route: ActivatedRouteSnapshot | Route): Observable<boolean> | boolean {

        const pureScopes = !!route && route.data ? route.data['scopes'] as ScopesRouterData : {};
        let scopes: ScopesRouterData = this._transformScopes(pureScopes);

        if (this._isParameterAvailable(scopes.except)) {
            return this._validateScopesExcept(scopes);
        }

        if (this._isParameterAvailable(scopes.only)) {
            return this._validateScopesOnly(scopes);
        }

        this._handleRedirectToAnotherRoute(scopes.forwardTo);
        return true;
    }

    private _transformScopes(pureScopes: ScopesRouterData): any {
        let scopes = {
            ...pureScopes,
        };

        scopes.except = transformStringToArray(scopes.except);
        scopes.only = transformStringToArray(scopes.only);

        return scopes;
    }

    private _validateScopesExcept(scopes: ScopesRouterData): Observable<boolean> | boolean {
        return this._redirectScopesExcept(scopes);
    }

    private _validateScopesOnly(scopes: ScopesRouterData): Observable<boolean> | boolean {
        return this._redirectScopesOnly(scopes);
    }

    private _redirectScopesExcept(scopes: ScopesRouterData): boolean {

        const authed = this._sc.isAuthedExcept(scopes.except);
        if (!authed) {
            this._handleRedirectToAnotherRoute(scopes.redirectTo);
            return false;
        }

        if (scopes.only)
            return this._redirectScopesOnly(scopes);

        this._handleRedirectToAnotherRoute(scopes.forwardTo);
        return true;
    }

    private _redirectScopesOnly(scopes: ScopesRouterData): boolean {

        const authed = this._sc.isAuthedOnly(scopes.only);
        if (!authed) {
            this._handleRedirectToAnotherRoute(scopes.redirectTo);
            return false;
        }

        this._handleRedirectToAnotherRoute(scopes.forwardTo);
        return true;
    }

    private _handleRedirectToAnotherRoute(
        data: any,
    ): void {
        const path = this._getRedirection(data);

        if (!path)
            return;

        this._redirectToAnotherRoute(path);
    }

    private _getRedirection(
        data: any,
    ): any {

        if (!data)
            return undefined;

        if (isPlainObject(data) && !this._isRedirectionWithParameters(data)) {
            const a = this._store.selectSnapshot(ProfileState.scopes);
            const b = Object.keys(data);
            const keys = firstScope(a, b) || 'default';
            return data[keys];
        }

        return data;
    }

    private _redirectToAnotherRoute(path: string | any): any {

        if (this._isRedirectionWithParameters(path)) {
            const commands = path.navigation.commands as any[];
            const extras = path.navigation.extras as NavigationExtras;
            return this._router.navigate(commands, extras);
        }

        if (Array.isArray(path))
            return this._store.dispatch(new Navigate(path));

        return this._store.dispatch(new Navigate([path]));
    }

    private _isRedirectionWithParameters(object: any | NavigationParameters): boolean {
        return isPlainObject(object) && object.navigation && (!!object.navigation.commands || !!object.navigation.extras);
    }

    private _isParameterAvailable(permission: any): boolean {
        return !!(permission) && permission.length > 0;
    }

}
