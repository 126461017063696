import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    TitleCasePipe,
} from '@angular/common';


@Pipe({ name: 'textcase' })
export class StringTextCasePipe implements PipeTransform {
    public transform(value: string): string {
        if (!value)
            return value;

        return new TitleCasePipe().transform(value.replace(/_/g, ' ').trim());
    }
}
