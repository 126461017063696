
import {
    Inject,
    Injectable,
} from '@angular/core';

import {
    ClientOptions,
    UPLOADS_OPTIONS,
} from '@michel.freiha/ng-sdk';


@Injectable({
    providedIn: 'root',
})
export class ImageService {
    private _baseUrl: string;

    constructor(
        @Inject(UPLOADS_OPTIONS) private _options: ClientOptions,
    ) {
        this._baseUrl = this._options.basePathFactory.make();
    }

    public getImageUploadUrl(accountId: string, imageId: string): string {
        if (!accountId || !imageId)
            return;

        return `${this._baseUrl}/uploads/v1/accounts/${accountId}/files/${imageId}/browse`;
    }
}
