import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  ActivatedRoute,
} from '@angular/router';

import {
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  PanFilterComponent,
} from '../../components/pan-filter/pan-filter.component';


@Component({
  selector: 'nym-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit {

  @HostBinding('class.nym-homepage')
  protected get classes(): boolean { return true; }

  @ViewChild('filter', { static: true })
  protected filter: PanFilterComponent;

  constructor(
    private _ar: ActivatedRoute,
    private _store: Store,
  ) { }

  public ngOnInit(): void { }

  protected search(pan: string): void {
    if (!pan)
      return;

    this._store.dispatch(new Navigate(['./search'], { pan: pan }, { relativeTo: this._ar }));
  }

}
