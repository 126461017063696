type StringFn = () => string;

export class Attachment {

    public id: string;
    public name: string;
    public size: number;
    public contentType: string;
    public url: string;

    constructor(ob?: Partial<Attachment>) {

        this.id = undefined;
        this.name = undefined;
        this.size = undefined;
        this.contentType = undefined;
        this.url = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.size !== undefined) { this.size = ob.size; }
        if (ob.contentType !== undefined) { this.contentType = ob.contentType; }
        if (ob.url !== undefined) { this.url = ob.url; }

    }
}
