import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select,
} from '@ngxs/store';

import {
  Dialog,
  DIALOG_DATA,
} from '@nymos/theme';

import {
  Account,
  Card,
} from '@nymos/accounts/core';

import {
  CardsState,
} from '../../../core/store/cards/cards.state';

import {
  ActivateFromCardSelectionPage,
  ResumeFromCardSelectionPage,
  SuspendFromCardSelectionPage,
  TerminateFromCardSelectionPage,
  LoadCardFromUserDetailsPage,
 } from '../../../core/store/cards/cards.actions';

 import {
    DryRunTransferBetweenWalletFromUserDetailsPage,
    TransferBetweenWalletFromUserDetailsPage,
 } from '../../../core/store/wallet-activities/wallet-activities.actions';



import {
  CardHandler,
} from '../../../services/card-handler.service';

import {
  CardDetailsComponent,
} from '../card-details/card-details.component';


@Component({
  selector: 'nym-card-selection',
  templateUrl: './card-selection.component.html',
  styleUrls: ['./card-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardSelectionComponent implements OnInit {

  @HostBinding('class.nym-card-selection')
  protected get classes(): boolean { return true; }

  @Output('manageCard') // tslint:disable-line:no-output-rename
  protected manageCardEmitter: EventEmitter<any> = new EventEmitter();

  @Select(CardsState.cards)
  public cards$: Observable<Card[]>;

  public account: Account;

  constructor(
    private _dialog: Dialog,
    private _cardHandler: CardHandler,
    @Inject(DIALOG_DATA) data: Account,
  ) {
    this.account = data;
  }

  public ngOnInit(): void { }

  protected viewCard(card: Card): void {
    this._dialog.openAsFullwidth(CardDetailsComponent, { data: card });
  }

  protected manageCard({card, action}: any): any {
    // tslint:disable-next-line: switch-default
     switch (action) {
      case 'activate':
        return this._cardHandler.activate(card, ActivateFromCardSelectionPage);

      case 'resume':
        return this._cardHandler.resume(card, ResumeFromCardSelectionPage);

      case 'suspend':
        return this._cardHandler.suspend(card, SuspendFromCardSelectionPage);

      case 'terminate':
        return this._cardHandler.terminate(card,DryRunTransferBetweenWalletFromUserDetailsPage,
        TransferBetweenWalletFromUserDetailsPage,TerminateFromCardSelectionPage);

      case 'refund':   
       return this._cardHandler.refund(card,DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage);
    }
  }

}
