import {
    PipeTransform,
} from '@angular/core';

export class StatusMenu {

    public name: string;
    public next: { [action: string]: string};
    public color: string;
    public final: boolean;
    public scopes: string[];

    constructor(ob?: Partial<StatusMenu>) {

        this.name = undefined;
        this.next = undefined;
        this.color = undefined;
        this.final = undefined;
        this.scopes = [];

        if (!ob) { return; }

        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.next !== undefined) { this.next = ob.next; }
        if (ob.color !== undefined) { this.color = ob.color; }
        if (ob.final !== undefined) { this.final = ob.final; }
        if (ob.scopes !== undefined) { this.scopes = ob.scopes; }
    }
}


