import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  UserProfile,
} from '@michel.freiha/ng-sdk';

import {
  Account,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-user-personal-info',
  templateUrl: './user-personal-info.component.html',
  styleUrls: ['./user-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPersonalInfoComponent implements OnInit {

  @HostBinding('class.nym-user-personal-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  protected get profile(): UserProfile { return this.account && this.account.profile; }

  constructor() { }

  public ngOnInit(): void { }

}
