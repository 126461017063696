import {
    InjectionToken,
} from '@angular/core';

import {
    OverlayRef,
} from '@angular/cdk/overlay';

import {
    ESCAPE,
} from '@angular/cdk/keycodes';

import {
    MatDialogRef,
} from '@angular/material/dialog';

import {
    Observable,
    Subject,
} from 'rxjs';

import {
    filter,
} from 'rxjs/operators';

import {
    DialogContainer,
} from './outlets/dialog.container';


export const DIALOG_DATA = new InjectionToken<any>('ThmDialogData');

let uniqueId = 0;

export class DialogRef<T, R = any> extends MatDialogRef<T, R> {

    private _overrideClose: boolean;
    private _onAction: boolean = false;
    private _formValid: boolean = true;
    private readonly _submitted: Subject<void> = new Subject();

    public get onAction(): boolean { return this._onAction; }
    public set onAction(value: boolean) { this._setOnAction(value); }

    public get formValid(): boolean { return this._formValid; }
    public set formValid(value: boolean) { this._setFormValid(value); }

    public get overrideClose(): boolean { return this._overrideClose; }
    public set overrideClose(value: boolean) { this._setOverrideClose(value); }

    constructor(
        private _or: OverlayRef,
        private _ci: DialogContainer,
        readonly id: string = `thm-dialog-${uniqueId++}`,
    ) {
        super(_or, _ci, null, id);
        this.overrideClose = this.disableClose;

        _ci.closeButtonClicked.subscribe((event) => {
            this._attemptToClose();
        });

        _ci.submitButtonClicked.subscribe((event) => {
            if (this._onAction || !this._formValid)
                return;

            this._submitted.next();
        });

        _or.backdropClick().pipe(filter(() => !this.overrideClose)).subscribe(() => {
            this._attemptToClose();
        });

        // tslint:disable-next-line: deprecation
        _or.keydownEvents().pipe(filter((event) => event.keyCode === ESCAPE)).subscribe(() => {
            this._attemptToClose();
        });
    }

    public submitted(): Observable<void> {
        return this._submitted.asObservable();
    }

    private _attemptToClose(): void {
        if (this._onAction)
            return;

        this.close();
    }

    private _setOnAction(value: boolean): void {
        this._onAction = value;
        this._ci.loading = value;
    }

    private _setFormValid(value: boolean): void {
        this._formValid = value;
        // TODO (@leandro): fix afterCheck error
        this._ci.formValid = value;
    }

    private _setOverrideClose(value: boolean): void {
        this._overrideClose = value;
        this.disableClose = true;
    }
}
