import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { AdminRoleCategory } from '@michel.freiha/ng-sdk';


@Pipe({ name: 'icon' })
export class RoleIconPipe implements PipeTransform {

    public transform(category: AdminRoleCategory): any {

        switch (category) {
            case AdminRoleCategory.Manager:
                return 'person';
            case AdminRoleCategory.Finance:
                return 'attach_money';
            case AdminRoleCategory.Support:
                return 'build';
            case AdminRoleCategory.Marketing:
                return 'gps_fixed';
            case AdminRoleCategory.Systems:
                return 'computer';
            case AdminRoleCategory.Sales:
                return 'multiline_chart';
            case AdminRoleCategory.Partner:
                return 'group';
            case AdminRoleCategory.Dev:
                return 'code';
            case AdminRoleCategory.Qa:
                return 'bug_report';
            case AdminRoleCategory.Hr:
                return 'supervisor_account';
            case AdminRoleCategory.Generic:
            default:
                return 'view_carousel';
          }
    }
}
