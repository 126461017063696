import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  CardComponent,
} from './containers/card/card.component';

export const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [
    CardComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
  ],

  exports: [
    CardComponent,
  ],
})
export class ThemeComponentsCardModule { }
