import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    TokenStatus,
} from '@michel.freiha/ng-sdk';


@Pipe({ name: 'icon' })
export class TokenPipe implements PipeTransform {

    public transform(status: TokenStatus): any {

        switch (status) {
            case TokenStatus.Active:
                return 'check_circle_outline';
            case TokenStatus.Suspended:
                return 'access_time';
            case TokenStatus.Deleted:
                return 'block';
            case TokenStatus.Inactive:
            default:
                return 'remove_circle_outline';
        }
    }
}
