// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Loading: Notification = new Notification('Loading roles...');

    public static readonly Refreshing: Notification = new Notification('Refreshing roles...');

    public static readonly Saving: Notification = new Notification('Saving...');

    public static readonly Created: StringFn = ((name) => new Notification(`New role ${name} created`));

    public static readonly Updated: StringFn = ((name) => new Notification(`Role ${name} updated`));

    public static readonly Failure: Notification = new Notification('Something went wrong');
}


