import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  Color,
} from '../../models/color.model';

@Component({
  selector: 'thm-shimmer-loading',
  templateUrl: './shimmer-loading.component.html',
})
export class ShimmerLoadingComponent implements OnInit {

  private _ltrAnimation: string[] = ['-3; 1', '-2; 2', '-1; 3'];
  private _rtlAnimation: string[] = ['1; -3', '2; -2', '3; -1'];
  private _animationValues: string[];

  @HostBinding('class.thm-shimmer-loading')
  protected _classes: boolean = true;

  @Input()
  public id: string = Math.random().toString(36).substring(2);

  @Input()
  public animate: boolean = true;

  @Input()
  @HostBinding('style.width')
  public width: number;

  @Input()
  @HostBinding('style.height')
  public height: number;

  @Input()
  public speed: number = 2;

  @Input()
  public preserveAspectRatio: string = 'xMidYMid meet';

  @Input()
  public primary: Color = { rgb: '#d9d9d9', opacity: 1};

  @Input()
  public secondary: Color = { rgb: '#ecebeb', opacity: 1};

  @Input()
  public uniqueKey: string;

  @Input()
  public rtl: boolean;

  @Input()
  public style: any;

  public get animationValues(): string[] { return this._animationValues; }

  public get fillStyle(): any { return { fill: `url(${window.location.href}#${this.gradientId})` }; }

  public get clipStyle(): string { return `url(${window.location.href}#${this.clipId})`; }

  public get clipId(): string { return `clip-${this.id}`; }

  public get gradientId(): string { return `gradient-${this.id}`; }

  public ngOnInit(): void {
    this._animationValues = this.rtl ? this._rtlAnimation : this._ltrAnimation;
  }
}
