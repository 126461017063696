import {
    SafeStyle,
} from '@angular/platform-browser';

export class ImageFile {
    public raw: any;
    public base64: string;
    public rotation: SafeStyle;
    public orientation: number;

    constructor(ob?: Partial<ImageFile>) {
        this.raw = undefined;
        this.base64 = undefined;
        this.rotation = undefined;
        this.orientation = undefined;


        if (!ob) { return; }

        if (ob.raw !== undefined) { this.raw = ob.raw; }
        if (ob.base64 !== undefined) { this.base64 = ob.base64; }
        if (ob.rotation !== undefined) { this.rotation = ob.rotation; }
        if (ob.orientation !== undefined) { this.orientation = ob.orientation; }
    }
}

