import {
    Optional,
    Provider,
    SkipSelf,
} from '@angular/core';

import {
    HttpClient,
} from '@angular/common/http';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    SecureImageService,
} from './secure-image.service';

export const secureImageFactory = (
    service: SecureImageService,
    http: HttpClient,
    sanitizer: DomSanitizer,
) => {
    return service || new SecureImageService(http, sanitizer);
};

export const secureImageProvider: Provider = {
    provide: SecureImageService,
    useFactory: secureImageFactory,
    deps: [
        [new Optional(), new SkipSelf(), SecureImageService],
        [HttpClient],
        [DomSanitizer],
    ],
};
