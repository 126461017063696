import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  SelectionModel,
} from '@angular/cdk/collections';

import {
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  TableComponent,
} from '@nymos/theme';

import {
  ADMIN_ROLE_FILTER,
} from '../../../sdk/filters/admin-role.filter';

import {
  AdminRoleShimmer,
} from '../../../sdk/shimmers/admin-role.shimmer';


@Component({
  selector: 'nym-role-listing-table',
  templateUrl: './role-listing-table.component.html',
  styleUrls: ['./role-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleListingTableComponent implements OnInit {

  @HostBinding('class.nym-role-listing-table')
  protected get classes(): boolean { return true; }

  private readonly SELECTION: { min: number; max: number } = { min: 2, max: 8 };

  private _selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  private _displayedColumns: string[] = ['icon', 'role', 'buttons'];

  @Input()
  public shimmers: AdminRoleShimmer[] = Array.from(Array(5), () => new AdminRoleShimmer());

  @Input()
  public roles: AdminRoleInternal[];

  @Input()
  public set selected(roles: AdminRoleInternal[]) { this._setSelected(roles); }

  public get selectedCount(): number { return this.selection.selected.length; }

  @Input()
  public loading: boolean;

  @Output('select') // tslint:disable-line:no-output-rename
  public selectEmitter: EventEmitter<AdminRoleInternal[]> = new EventEmitter();

  @Output('edit') // tslint:disable-line:no-output-rename
  public editEmitter: EventEmitter<AdminRoleInternal> = new EventEmitter();

  @ViewChild('table', { static: true })
  public table: TableComponent<AdminRoleInternal>;

  public get predicate(): any { return ADMIN_ROLE_FILTER; }

  public get selection(): SelectionModel<string> { return this._selection; }

  public get displayedColumns(): string[] { return this._displayedColumns; }

  constructor() { }

  public ngOnInit(): void { }

  public query(query: string): void {
    this.table.query(query);
  }

  public edit(role: AdminRoleInternal): void {
    this.editEmitter.emit(role);
  }

  public masterSelected(): boolean {
    return this.selection.hasValue() && this._isAllSelected();
  }

  public someSelected(): boolean {
    return this.selection.hasValue() && !this._isAllSelected();
  }

  public noneSelected(): boolean {
    return !this.selection.hasValue();
  }

  public masterToggle(): void {
    this._isAllSelected() ?
      this.selection.clear() :
      this.roles.forEach((element) => this.selection.select(element.id));

    const roles = this._isAllSelected() ? this.roles : [];
    this.selectEmitter.emit(roles);
  }

  public toggle(role: AdminRoleInternal): void {
    this._selection.toggle(role.id);

    const roles = this.roles.filter((element) => this.selection.isSelected(element.id));
    this.selectEmitter.emit(roles);
  }

  private _setSelected(roles: AdminRoleInternal[]): void {
    roles.forEach((r) => this._selection.select(r.id));
  }

  private _isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.roles.length;
    return numSelected === numRows;
  }

}
