import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const ANIMATION_TIMING = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

export const thmDialogAnimation: {
    readonly dialogContainer: AnimationTriggerMetadata;
    readonly downloadingImage: AnimationTriggerMetadata;
    readonly slideDown: AnimationTriggerMetadata;

} = {
    dialogContainer: trigger('dialogContainer', [
        state('void, exit', style({ opacity: 0 })),
        transition('* => enter', [
            group([
                animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1 })),
                query('@slideDown', animateChild()),
            ]),
        ]),
        transition('* => void, * => exit', [
            group([
                animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 })),
                query('@slideDown', animateChild()),
            ]),
        ]),
    ]),

    downloadingImage: trigger('fade', [
        state('fadeOut', style({ opacity: 0 })),
        state('fadeIn', style({ opacity: 1 })),
        transition('* => fadeIn', animate(ANIMATION_TIMING)),
    ]),

    slideDown: trigger('slideDown', [
        state('void, exit', style({ transform: 'translateY(-100%)' })),
        state('enter', style({ transform: 'translateY(0)' })),
        transition('* => *', animate(ANIMATION_TIMING)),
    ]),
};
