import {
    CoreAccountRef,
} from '@michel.freiha/ng-sdk';

import {
    AccountKyc,
} from './account-kyc.model';

import {
    DocumentType,
} from './account-document.model';

export type ChannelStatus =
    | 'active'
    | 'verified'
    | 'pending'
    | 'incomplete'
    | 'rejected'
    | 'blocked'
    | undefined;

export class AccountChannel {

    public channel: ChannelStatus;
    public lastSeen: Date;
    public hasNotes: boolean;
    public activeCards: number;
    public kyc?: AccountKyc;
    public documentType?: DocumentType;
    public lastAction?: CoreAccountRef;
    public alsoViewing?: CoreAccountRef[];

    constructor(ob?: Partial<AccountChannel>) {

        this.channel = undefined;
        this.lastSeen = undefined;
        this.hasNotes = undefined;
        this.activeCards = undefined;
        this.kyc = undefined;
        this.documentType = undefined;
        this.lastAction = undefined;
        this.alsoViewing = [];

        if (!ob) { return; }

        if (ob.channel !== undefined) { this.channel = ob.channel; }
        if (ob.lastSeen !== undefined) { this.lastSeen = new Date(ob.lastSeen); }
        if (ob.hasNotes !== undefined) { this.hasNotes = ob.hasNotes; }
        if (ob.activeCards !== undefined) { this.activeCards = ob.activeCards; }
        if (ob.kyc !== undefined) { this.kyc = new AccountKyc(ob.kyc); }
        if (ob.documentType !== undefined) { this.documentType = ob.documentType; }
        if (ob.lastAction !== undefined) { this.lastAction = new CoreAccountRef(ob.lastAction); }
        if (ob.alsoViewing !== undefined) { ob.alsoViewing.forEach((o) => this.alsoViewing.push(new CoreAccountRef(o))); }

    }
}
