import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'nym-user-listing-loading',
  templateUrl: './user-listing-loading.component.html',
  styleUrls: ['./user-listing-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListingLoadingComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void { }

}
