import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminRoleInternal,
} from '@michel.freiha/ng-sdk';

import {
  Dialog,
} from '@nymos/theme';

import {
  RolesState,
} from '../../../store/roles/roles.state';

import {
  LoadRolesFromListingPage,
} from '../../../store/roles/roles.actions';

import {
  Select as SelectRoles,
} from '../../../store/roles-selected/roles-selected.actions';

import {
  RoleEditComponent,
  RoleEditConfig,
} from '../role-edit/role-edit.component';


@Component({
  selector: 'nym-role-listing',
  templateUrl: './role-listing.component.html',
  styleUrls: ['./role-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleListingComponent implements OnInit, OnDestroy {

  @HostBinding('class.nym-role-listing')
  protected get classes(): boolean { return true; }

  private _selected: AdminRoleInternal[];

  @Select(RolesState.roles)
  public roles$: Observable<AdminRoleInternal[]>;

  @Select(RolesState.loading)
  public loading$: Observable<boolean>;

  public get selected(): AdminRoleInternal[] { return this._selected; }

  constructor(
    private _store: Store,
    private _dialog: Dialog,
  ) {
    this.loadInitial();

    this._selected = this._store.selectSnapshot(RolesState.selected);
  }

  public ngOnInit(): void { }


  public ngOnDestroy(): void { }

  public loadInitial(): void {
    this._store.dispatch(new LoadRolesFromListingPage());
  }

  public refresh(): void {
    this._store.dispatch(new LoadRolesFromListingPage());
  }

  public addNew(): void {
    const text = 'Add new Role';
    this._dialog.openAsModal(RoleEditComponent, { ...RoleEditConfig, data: { text } });
  }

  public edit(role: AdminRoleInternal): void {
    const text = 'Manage Role';
    this._dialog.openAsModal(RoleEditComponent, { ...RoleEditConfig, data: { text, role } });
  }

  public select(roles: AdminRoleInternal[]): void {
    this._store.dispatch(new SelectRoles({ roles: roles }));
  }

}
