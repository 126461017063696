import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChildren,
  ChangeDetectorRef
} from '@angular/core';

import { AngularOtpLibModule } from 'angular-otp-box';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminSession,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  AuthState,
} from '../../../store/auth/auth.state';

import {
  ConfirmPasscode,
} from '../../../store/auth/auth.actions';

import { SigninService } from '../../../services/signin.service';

import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'nym-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class MobileVerificationComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  public loginError:any = '';
  public loginErrorMsg: any = '';
  public clickCount: any = 0;
  public btnDisable: any = false;
  public blockTime: any = '';
  public blockTime1: any = 300000;
  public blockTime2: any = 3600000;
  public firstTime: any = '';
  public thirdTime: any = '';
  public blockType: any = '';
  public firstclkTime: any = '';
  public thirdclkTime: any = '';
  public currentTime: any = '';
  public error;


  private _session: AdminSession;
  private _username: string;

  public settings = {
    length: 6,
    numbersOnly: true,
    timer: 12
  }

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public get username(): string {
    return this._username;
  }

  public form: FormGroup;

  public get code(): AbstractControl {
    return this.form.get('code');
  }


  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
    private _signinService : SigninService,
    private cd: ChangeDetectorRef,
    private _nc: MatSnackBar,
  ) {
    this._session = this._store.selectSnapshot(AuthState.session);
    this._username = this._store.selectSnapshot(AuthState.username);

    this.form = this.toFormGroup(this.formInput);
    console.log(this.form);
    /* this.form = this._fb.group(this.formInput);

    this.form = this._fb.group({
      code: [
        '',
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.minLength(6),
          Validators.pattern('[0-9]*'),
        ],
      ],
    }); */
  }

  toFormGroup(elements) {
    console.log(elements);
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$")]);
    });
    console.log(group);
    return new FormGroup(group);
   }

   keyUpEvent(event, index) {
    // var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    this.loginError = undefined;
    this.loginErrorMsg = '';
    let pos = index;

    // Replacing Value From OTP
    // const charcode = (event.which)?event.which: event.keycode
    // if(charcode > 31 && (charcode < 48 || charcode > 57)){
    //   this.error = "Please enter numbers";
    // }else{
    //   this.form.get(`input${index+1}`).setValue(event.key);
    // }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      // pos = index;
      this.error = 0;
      // return false;
    } else {
      this.form.get(`input${index+1}`).setValue(event.key);
      // return true;
    }
    // if(event.keyCode >= 48 && event.keyCode <= 57 ){
    //   this.form.get(`input${index+1}`).setValue(event.key);
    // }
    // if (event.keyCode >= 65 && event.keyCode <= 90){
    //   this.form.get(`input${index+1}`).setValue(event.key);
    // }
    // End Replacing Value from OTP

    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }
   }
  public ngOnInit(): void {

    this._subscription = this._problem$.subscribe((problem) => {
      console.log(problem);
      if(problem){
        this.loginError = problem;
        if(this.loginError.error && this.loginError.error.detail){
          this.loginErrorMsg = this.loginError.error.detail;
        }
        if(this.loginError.error){
          this._ph.handle(this.loginError.error, this.form, ['code']);
        }
      }
    });
      
  /*   this.currentTime = new Date();
    this.currentTime = Date.parse(this.currentTime);
    this.currentTime = (this.currentTime/1000).toFixed(1);

    this.thirdclkTime = Date.parse(window.localStorage.getItem('thirdClick'));
    this.thirdclkTime = (this.thirdclkTime/1000).toFixed(1);
    var timeDiff = this.currentTime - this.thirdclkTime;
    console.log(timeDiff);
    if(window.localStorage.getItem('blockType') && window.localStorage.getItem('blockType') == '1'){
      
      if(timeDiff <=  this.blockTime1){
        var rem =this.blockTime1 - (timeDiff*1000);
        console.log('remainingtime 1 -- '+ (this.blockTime1 - (timeDiff*1000)));
        if (window.localStorage.getItem('blockType') == '2') {
          this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
        } else if (window.localStorage.getItem('blockType') == '1') {
          this.loginErrorMsg = 'Your re-send button is disabled for 5 minute';
        }
        this.btnDisable = true;
        setTimeout(() => {
          this.loginErrorMsg = '';
          this.btnDisable = false;
          // this.clickCount = 0;
          this.blockTime = ''
          this.blockType = 2
      
          this.cd.detectChanges();
        }, rem)
      }
    } else if(window.localStorage.getItem('blockType') && window.localStorage.getItem('blockType') == '2'){
      if(timeDiff <=  this.blockTime1){
        var rem =this.blockTime2 - (timeDiff*1000);
        console.log('remainingtime 1 -- '+ (this.blockTime2 - (timeDiff*1000)));
        if (window.localStorage.getItem('blockType') == '2') {
          this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
        } else if (window.localStorage.getItem('blockType') == '1') {
          this.loginErrorMsg = 'Your re-send button is disabled for 5 minute';
        }
        this.btnDisable = true;
        setTimeout(() => {
          this.loginErrorMsg = '';
          this.btnDisable = false;
          // this.clickCount = 0;
          this.blockTime = ''
          this.blockType = 2
          this.cd.detectChanges();
        }, rem)
      }
      
    } */

  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {

    if(this.form.invalid){
      this.error = 0;
      this.loginError = undefined;
      this.loginErrorMsg = '';
      return;
    }

    var otp = '';
    for (const field in this.form.controls) {
      const control = this.form.get(field); 
      otp += control.value;
    }

    let id = this._session.id;
    let code = otp;//this.code.value;

    this._store.dispatch(new ConfirmPasscode({ id: id, code: code }));
  }

   public resendOtp():void{
    let id = this._session.id;
    console.log(id);
    this._signinService.resendMobileOtp({ id }).subscribe((res) => {
      console.log(res);
      this._nc.open('OTP set been resend, please check','', {duration: 5000});
    },
    (error) => {
      console.log(error);
      this.loginError = error;

      if(this.loginError.error.retry_after == '300'){
        
        this.loginErrorMsg = 'Your re-send button is disabled for 5 minute';
        this.btnDisable =true;
        this.cd.detectChanges();
        setTimeout(() => {
          this.loginErrorMsg = '';
          this.btnDisable = false;
          // this.clickCount = 0;
          // this.blockTime = ''
          this.cd.detectChanges();
        }, 300000)
      } else if(this.loginError.error.retry_after == '3600'){
        
        this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
        this.btnDisable =true;
        this.cd.detectChanges();
        setTimeout(() => {
          this.loginErrorMsg = '';
          this.btnDisable = false;
          // this.clickCount = 0;
          // this.blockTime = ''
          this.cd.detectChanges();
        }, 3600000)
      } else if(this.loginError.error.retry_after == '2592000'){
        
        this.loginErrorMsg = 'You’ve entered the wrong code several times, your account is permanently blocked, for support contact 0987654398';
        this.btnDisable =true;
        this.cd.detectChanges();
        
      }
      /* if (this.blockType == 2) {
        this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
      } else if (this.blockType == 1) {
        this.loginErrorMsg = 'Your re-send button is disabled for 5 minute';
      } */

      






    })
    /* console.log('asdasdasasd');
    let id = this._session.id;
    console.log(id);
    this._signinService.resendMobileOtp({ id }).subscribe((res) => {
      
    }) */
  }
 

/*   public resendOtp(): void {

    console.log('click - ' + this.clickCount);
    this.clickCount += 1;
    window.localStorage.setItem('resetClick', this.clickCount);
    if (this.clickCount == 1) {
      this.firstTime = new Date();
      window.localStorage.setItem('firstClick', this.firstTime);
    } else if (this.clickCount == 3) {
      
      this.thirdTime = new Date();
      window.localStorage.setItem('thirdClick', this.thirdTime);

      this.firstclkTime = Date.parse(window.localStorage.getItem('firstClick'));
      this.firstclkTime = (this.firstclkTime/1000).toFixed(1);

      this.thirdclkTime = Date.parse(window.localStorage.getItem('thirdClick'));
      this.thirdclkTime = (this.thirdclkTime/1000).toFixed(1);
      var timeDiff = this.thirdclkTime - this.firstclkTime;

      console.log(timeDiff);
      if(timeDiff && timeDiff <= 60){ //with in 1 hours
        if (this.blockType == '') {
          this.blockType = 1; // 1 for 5 min block
          this.blockTime = this.blockTime1;
          window.localStorage.setItem('blockType', this.blockType);
        } else
          if (this.blockType == 1) {
            this.blockType = 2; // 2 for 1 hours block
            this.blockTime = this.blockTime2;
            window.localStorage.setItem('blockType', this.blockType);
          }else
          if (this.blockType == 2) {
            this.blockType = 2; // 2 for 1 hours block
            this.blockTime = this.blockTime2;
            window.localStorage.setItem('blockType', this.blockType);
          }

      } else { 
         if(window.localStorage.getItem('blockType') && window.localStorage.getItem('blockType') == '1'){
           console.log('qqqqqqqqqqqq');
           this.blockType = 2; // 2 for 1 hours block
           window.localStorage.setItem('blockType', this.blockType);
          }
          this.blockTime = this.blockTime2;
      }
      this.btnDisable = true;
    }

    if (this.clickCount >= 3) {
      if (this.blockType == 2) {
        this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
      } else if (this.blockType == 1) {
        this.loginErrorMsg = 'Your re-send button is disabled for 5 minute';
      }

      setTimeout(() => {
        this.loginErrorMsg = '';
        this.btnDisable = false;
        this.clickCount = 0;
        this.blockTime = ''
        this.cd.detectChanges();
      }, this.blockTime)
      // return 0
    } else {
      let id = this._session.id;
      console.log(id);
      this._signinService.resendMobileOtp({ id }).subscribe((res) => {
        console.log(res);
      },
      (error) => {
        this.loginError = error;
        this.loginErrorMsg = 'Your re-send button is disabled for 1 hours';
      })
    }
  } */
}
