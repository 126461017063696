import {
    NgModule,
} from '@angular/core';

import {
    SizeDirective,
 } from './size.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        SizeDirective,
    ],

    imports: [],

    exports: [
        SizeDirective,
    ],

})
export class ThemeDirectivesSizeModule { }
