import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const animations: {
    readonly indicatorRotate: AnimationTriggerMetadata;
    readonly headerExpansion: AnimationTriggerMetadata;
    readonly bodyExpansion: AnimationTriggerMetadata;
} = {
    headerExpansion: trigger('headerExpansion', [
        state('collapsed, void',
            style({ height: '{{height}}', }), { params: { height: '48px' } }),
        state('expanded',
            style({ height: '{{height}}' }), { params: { height: '48px' } }),
        transition('expanded <=> collapsed, void => collapsed', group([
            query('@indicatorRotate', animateChild(), { optional: true }),
            animate(EXPANSION_PANEL_ANIMATION_TIMING),
        ])),
    ]),

    /** Animation that rotates the indicator arrow. */
    indicatorRotate: trigger('indicatorRotate', [
        state('collapsed, void', style({ transform: 'rotate(0deg)' })),
        state('expanded', style({ transform: 'rotate(180deg)' })),
        transition('expanded <=> collapsed, void => collapsed',
            animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ]),

    /** Animation that expands and collapses the panel content. */
    bodyExpansion: trigger('bodyExpansion', [
        state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
        state('expanded', style({ height: '*', visibility: 'visible' })),
        transition('expanded <=> collapsed, void => collapsed',
            animate(EXPANSION_PANEL_ANIMATION_TIMING),
        ),
    ]),
};
