import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import {
  Account,
  AccountAddress,
  AccountProfile,
  Attachment,
} from '@nymos/accounts/core';

import {
    NewProvince,
} from '../../../../lib/core/models/new-province.model';

import {
    NewCity,
} from '../../../../lib/core/models/new-city.model';

@Component({
  selector: 'nym-user-address-info',
  templateUrl: './user-address-info.component.html',
  styleUrls: ['./user-address-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAddressInfoComponent implements OnInit,OnChanges {

  @HostBinding('class.nym-user-address-info')
  protected get classes(): boolean { return true; }

  @Input()
  public provincesnew: NewProvince[];

  @Output()
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  private userState:NewProvince;

  private userCity:NewCity;

  protected get address(): AccountAddress { return this.account && this.account.address; }

  protected get profile(): AccountProfile { return this.account && this.account.profile; }

  protected get attachments(): Attachment[] {
    return this.account && this.account.address && this.account.address.attachments || [];
  }

  protected get urls(): string[] {
    return this.attachments.map((a) => a.url) || [];
  }

  constructor() { }

  public ngOnInit(): void {}

    public ngOnChanges(changes: SimpleChanges): void {
      if(this.provincesnew)
      this.updateAddress();     
  }

  protected viewAttachments$$(index: number): void {
    const attachments = this.attachments;
    this.viewAttachments.emit({ attachments, index });
  }

  private updateAddress(): void{

    if( this.provincesnew && this.account.address){

   this.provincesnew.forEach(obj => {
         if(obj["key"]==this.account.address.state){
          this.userState=obj;
             obj.cities.forEach(ob => {
       if(ob.key==this.account.address.city){
            this.userCity=ob;
           }
        });
         }
      });
  }
}
}
