
import {
    CoreNoteLink,
} from '@michel.freiha/ng-sdk';

export class Note {

    public id: string;
    public accountId: string;
    public created: Date;
    public title: string;
    public description: string;
    public extras: { [key: string]: string };
    public author: { id: string, name: string };
    public links: CoreNoteLink[];

    constructor(ob?: Partial<Note>) {

        this.id = undefined;
        this.accountId = undefined;
        this.created = undefined;
        this.title = undefined;
        this.description = undefined;
        this.extras = undefined;
        this.author = undefined;
        this.links = [];

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.title !== undefined) { this.title = ob.title; }
        if (ob.description !== undefined) { this.description = ob.description; }
        if (ob.extras !== undefined) { this.extras = ob.extras; }
        if (ob.author !== undefined) { this.author = ob.author; }
        if (ob.links !== undefined) { this.links = ob.links; }
    }
}
