import {
    Injectable,
} from '@angular/core';

import {
    CreditCard, CreditCardType,
} from '../models/credit-card.model';


let defaultFormat = /(\d{1,4})/g;
let cards: CreditCard[] = [
    // new CreditCard({
    //     type: CreditCardType.Maestro,
    //     patterns: [5018, 502, 503, 506, 56, 58, 639, 6220, 67],
    //     format: defaultFormat,
    //     length: [12, 13, 14, 15, 16, 17, 18, 19],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Forbrugsforeningen,
    //     patterns: [600],
    //     format: defaultFormat,
    //     length: [16],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Dankort,
    //     patterns: [5019],
    //     format: defaultFormat,
    //     length: [16],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
    new CreditCard({
        type: CreditCardType.Visa,
        patterns: [4],
        format: defaultFormat,
        length: [13, 16, 19],
        cvvLength: [3],
        luhn: true,
    }),
    new CreditCard({
        type: CreditCardType.Mastercard,
        patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
        format: defaultFormat,
        length: [16],
        cvvLength: [3],
        luhn: true,
    }),
    // new CreditCard({
    //     type: CreditCardType.Amex,
    //     patterns: [34, 37],
    //     format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    //     length: [15],
    //     cvvLength: [3, 4],
    //     luhn: true,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Dinersclub,
    //     patterns: [30, 36, 38, 39],
    //     format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    //     length: [14],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Discover,
    //     patterns: [60, 64, 65, 622],
    //     format: defaultFormat,
    //     length: [16],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Unionpay,
    //     patterns: [62, 88],
    //     format: defaultFormat,
    //     length: [16, 17, 18, 19],
    //     cvvLength: [3],
    //     luhn: false,
    // }),
    // new CreditCard({
    //     type: CreditCardType.Jcb,
    //     patterns: [35],
    //     format: defaultFormat,
    //     length: [16, 19],
    //     cvvLength: [3],
    //     luhn: true,
    // }),
];

@Injectable()
export class CreditCardService {

    public cards(): CreditCard[] {
        return cards;
    }

    public card(cc: string): CreditCard {
        if (!cc)
            return null;

        cc = cc.replace(/\D/g, '');

        for (const card of cards) {
            for (const pattern of card.patterns) {
                if (cc.startsWith(pattern.toString())) {
                    return card;
                }
            }
        }

        return null;
    }

    public valid(cc: string): boolean {
        if (!cc)
            return false;

        const card = this.card(cc);

        if (!card)
            return false;

        if (!card.luhn)
            return true;


        const digits = cc.replace(/\D/g, '').split('').reverse();

        let odd = true;
        let sum = 0;

        for (let i = 0; i < digits.length; i++) {
            let digit = parseInt(digits[i], 10);
            if ((odd = !odd)) {
                digit *= 2;
            }
            if (digit > 9) {
                digit -= 9;
            }
            sum += digit;
        }

        return sum % 10 === 0;
    }
}
