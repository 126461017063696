// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '../../texts/notes.texts';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Failure: Notification = new Notification(Texts.Notes.Failure);
    public static readonly Saving: Notification = new Notification(Texts.Notes.Saving);
}
