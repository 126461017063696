import {
    NgModule,
} from '@angular/core';

import {
    InfoDirective,
} from './info.directive';


export const MATERIAL_MODULES = [];

@NgModule({

    declarations: [
        InfoDirective,
    ],

    imports: [],

    exports: [
        InfoDirective,
    ],

})
export class ThemeDirectivesInfoModule { }
