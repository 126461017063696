import {
    Note as CoreNoteInternal,
} from '@michel.freiha/ng-sdk';

import {
    Note,
} from '../models/note.model';


export class NoteBuilder {
    private _internal: CoreNoteInternal;

    public withNoteInternal(value: CoreNoteInternal): NoteBuilder {
        this._internal = value;
        return this;
    }

    public build(): Note {

        const internal = this._internal;

        if (!internal)
            return undefined;

        const index = internal.id;
        const accountId = internal.id;
        const created = internal.created;
        const title = internal.note.title;
        const description = internal.note.description;
        const links= internal.links;
        const author = { id: internal.author.id, name: internal.author.displayName || internal.author.email };

        return new Note({
            id: index,
            accountId: accountId,
            created: created,
            title: title,
            description: description,
            links:links,
            author: author,
        });
    }
}
