import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  interval,
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Dialog,
} from '@nymos/theme';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Admin,
  CoreNote,
  UserAccountUpdateRequest,
  Problem,
  SearchCriteria,
  SearchFilter,
  SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
  Account,
  AccountsUsersState,
  UpdateAccountFromUserEditDetailsPage
} from '@nymos/accounts/core';

import {
  AccountsState
} from '@nymos/accounts/core';

import {MatSnackBar} from '@angular/material/snack-bar';


import {
  AccountsProvincesState,
} from '../../../core/store/accounts-provinces/accounts-provinces.state';

import {
  AccountsProvincesNamingsState,
} from '../../../core/store/accounts-provinces-namings/accounts-provinces-namings.state';

import {
  AccountsProvincesNewState,
} from '../../../core/store/accounts-provinces-new/accounts-provinces-new.state';

import {
  AccountsCitiesNamingsState,
} from '../../../core/store/accounts-cities-namings/accounts-cities-namings.state';

import {
  AccountsCountriesState,
} from '../../../core/store/accounts-countries/accounts-countries.state';

import {
  UserMessageComponent,
} from '../../containers/user-message/user-message.component';

import {
  Navigate,
} from '@ngxs/router-plugin';




@Component({
  selector: 'nym-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent implements OnInit, OnDestroy {

  private _subscription: Subscription = new Subscription();

  private _account: Account;

  protected provinces$: Observable<string[]>;
  protected provinceNamings$: Observable<string[]>;
  protected provinceNew$: Observable<string[]>;
  protected cityNamings$: Observable<string[]>;
  protected cities$: Observable<string[]>;
  protected newcities$: Observable<string[]>;
  protected countries$: Observable<string[]>;

  public isSuccess:boolean = false;

  @HostBinding('class.nym-user-edit')
  protected get classes(): boolean { return true; }

  protected account$: Observable<Account>;

  @Select(AccountsUsersState.loading)
  public accountLoading$: Observable<boolean>;

  protected accountId: string;

  constructor(
    private _store: Store,
    private _dialog: Dialog,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
  ) {
    const router = this._store.selectSnapshot((state) => state.router.state);
    this.provinces$ = this._store.select(AccountsProvincesState.provinces);
    this.provinceNew$ = this._store.select(AccountsProvincesNewState.newprovinces);
    this.countries$ = this._store.select(AccountsCountriesState.countries);
    this.provinceNamings$ = this._store.select(AccountsProvincesNamingsState.AccountsProvincesNamings);
    this.cityNamings$ = this._store.select(AccountsCitiesNamingsState.AccountsCitiesNamings);
    this.accountId = router.params.id;   
    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
  }


  public editUser(account:UserAccountUpdateRequest):void{
    if(account){
     this.isSuccess=true;
     this._store.dispatch(new UpdateAccountFromUserEditDetailsPage(  this.accountId,account)).subscribe((res)=>{
          this.isSuccess = false;;
          this.cd.markForCheck();
        if(res.problem && res.users.problem){
          this.openSnackBar(false)}
          else{
          let snackBarRef = this.openSnackBar(true)
               snackBarRef.afterDismissed().subscribe(() => {
                   this._store.dispatch(new Navigate(['accounts/'+this.accountId+'/user/details']));
             });
            }
      });  
  }
  /*setTimeout(() => {
                this._store.dispatch(new Navigate(['accounts/'+this.accountId+'/user/details']));
            }, 2500);*/
  }

 public openSnackBar(status:boolean):any {
       this.isSuccess=false;
        return this._snackBar.openFromComponent(UserMessageComponent, {
             duration:2500,
             verticalPosition: 'bottom',
             horizontalPosition: 'center',
             data:{"isSuccess":status},
          });
 }
 

  public ngOnInit(): void {
    this._subscription.add(this.account$.subscribe((account) => this._account = account));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected provinceChange$$(province: string): void {
    this.cities$ = this._store.select(AccountsProvincesState.cities(province));
  }

  protected provinceNewChange$$(newprovince: string): void {
    this.newcities$ = this._store.select(AccountsProvincesNewState.newcities(newprovince));
  }

   protected viewAttachments$$(event: any): void {
    const urls = event.attachments;
    const index = event.index;

    this._dialog.openPreview({ urls, index });
  }


}
