
export class ProvinceNaming {
    public ENValue: string;
    public ARValue: string;

    constructor(ob?: Partial<ProvinceNaming>) {
        this.ENValue = undefined;
        this.ARValue = undefined;
        if (!ob) { return; }

        if (ob.ENValue !== undefined) { this.ENValue = ob.ENValue; }
        if (ob.ARValue !== undefined) { this.ARValue = ob.ARValue; }
    }
}