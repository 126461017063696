export class Color {

    public rgb: string;
    public opacity: number;

    constructor(ob?: Partial<Color>) {

        if (!ob)
            return;

        if (ob.rgb !== undefined) { this.rgb = ob.rgb; }
        if (ob.opacity !== undefined) { this.opacity = ob.opacity; }
    }
}
