import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    AuthGuard,
    NymAuthModule,
} from '@nymos/auth';


const routes: Routes = [
    // { path: '', redirectTo: 'dashboard', pathMatch: 'full', },

    {
        path: '',
        loadChildren: () => import(`./console/console.module`).then((m) => m.ConsoleModule),
        canActivate: [AuthGuard],
    },

    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        NymAuthModule,
        RouterModule.forRoot(routes),
    ],

    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule { }
