import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

import {
  animations,
} from '../../animations/aside.animations';

type expandedState = 'expanded' | 'collapsed';

@Component({
  selector: 'thm-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    animations.headerExpansion,
    animations.bodyExpansion,
    animations.indicatorRotate,
  ],
})
export class AsideComponent implements OnInit, OnChanges {

  private _expanded: boolean = false;
  private _collapsable: boolean = false;

  @HostBinding('class.thm-aside')
  protected get classes(): boolean { return true; }

  @Input()
  public get collapsable(): boolean { return this._collapsable; }
  public set collapsable(value: boolean) { this._collapsable = coerceBooleanProperty(value); }

  public get state(): expandedState { return this._expanded ? 'expanded' : 'collapsed'; }

  constructor() { }

  public ngOnInit(): void {
    this._expanded = !this.collapsable;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const collapsable = changes['collapsable'];

    if (collapsable && !!collapsable.currentValue)
      this._expanded = true;
  }

  protected toggle(): void {
    if (!this._collapsable)
      return;

    this._expanded = !this._expanded;
  }

}
