
export class Province {
    public name: string;
    public cities: string[];

    constructor(ob?: Partial<Province>) {
        this.name = undefined;
        this.cities = undefined;
        if (!ob) { return; }

        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.cities !== undefined) { this.cities = ob.cities; }
    }
}
