import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'nym-channel-listing',
  templateUrl: './channel-listing.component.html',
  styleUrls: ['./channel-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelListingComponent implements OnInit {

  @HostBinding('class.nym-channel-listing')
  protected get classes(): boolean { return true; }

  @Input()
  public channels: string[];

  constructor() { }

  public ngOnInit(): void { }

}
