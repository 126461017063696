import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  CardActivity,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-card-activity-listing',
  templateUrl: './card-activity-listing.component.html',
  styleUrls: ['./card-activity-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardActivityListingComponent implements OnInit {

  @HostBinding('class.nym-card-activity-listing')
  protected get classes(): boolean { return true; }

  @Output()
  protected loadMore: EventEmitter<any> = new EventEmitter();

  @Input()
  public activities: CardActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public hasMore: boolean;

  @Input()
  public columns: string[];

  constructor() { }

  public ngOnInit(): void { }

  protected loadMore$$(): void {
    this.loadMore.emit();
  }

}
