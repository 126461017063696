/* tslint:disable:member-ordering */

import {
    produce,
} from 'immer';

import {
    throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    AdminInternal,
    CoreOauth2ScopesService,
    Oauth2Scope,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    Init,
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    AdminScope,
} from '../../sdk/models/admin-scope.model';

import {
    LoadScopesFromApi,
} from './scopes.actions';

import {
    Notifications,
} from './scopes.notifications';


export interface ScopesStateModel {
    items: { [id: string]: AdminScope };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: ScopesStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<ScopesStateModel>({
    name: 'scopes',
    defaults: stateDefaults,
})
export class ScopesState implements NgxsOnInit {

    @Selector()
    public static scopes(state: ScopesStateModel): AdminInternal[] {
        return Object.keys(state.items).map(((id) => state.items[id]));
    }

    @Selector()
    public static problem(state: ScopesStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: ScopesStateModel): boolean {
        return state.loading;
    }

    constructor(
        private _nc: NotificationCenter,
        private _scopesService: CoreOauth2ScopesService,
    ) { }

    public ngxsOnInit(ctx: StateContext<ScopesStateModel>): void {
        ctx.dispatch(new LoadScopesFromApi());
    }

    @Action(LoadScopesFromApi)
    public init(ctx: StateContext<ScopesStateModel>): any {

        this._nc.show(Notifications.Loading);
        ctx.patchState({ loading: true });

        return this._scopesService.loadOauth2Scopes().pipe(

            tap((scopes: Oauth2Scope[]) => {
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    scopes.forEach((o) => draft.items[o.id] = this._toAdminScope(o));
                }));
            }),

            catchError((problem) => {
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ loading: false });
            }),
        );
    }

    private _toAdminScope(scope: Oauth2Scope): any {
        return new AdminScope({
            id: scope.id,
            name: scope.description,
        });
    }

}
