import { Pipe, PipeTransform } from '@angular/core';
import { Texts } from '../texts/accounts.texts';

@Pipe({
  name: 'channelColumns',
})
export class ChannelColumnsPipe implements PipeTransform {

  public transform(channel: string): string[] {

    switch (channel) {
      case 'active':
          return ['users', 'channel', 'email', 'activeCards', /*lastActor,*/ 'joined', 'approvedBy', 'lastSeen', 'hasNotes'];
      case 'blocked':
        return ['users', 'channel', 'email', 'activeCards', /*lastActor,*/ 'joined', 'lastSeen', 'hasNotes'];
      case 'verified':
        return ['users', 'channel', 'email', 'joined', 'approvedBy', 'approvedAt', 'hasNotes','documentType'];
      case 'pending':
        return ['users', 'channel', 'email',  /*'alsoViewing',*/ 'joined', 'hasNotes','documentType'];
      case 'incomplete':
        return ['users', 'channel', 'joined', 'hasNotes'];
      case 'rejected':
        return ['users', 'channel', 'email', 'joined', 'rejectedBy', 'rejectedAt', 'rejectedNote', 'hasNotes'];
      default:
        return [];
    }
  }
}
