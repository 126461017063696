import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';


@Component({
  selector: 'nym-otp-listing-no-results',
  templateUrl: './otp-listing-no-results.component.html',
  styleUrls: ['./otp-listing-no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpListingNoResultsComponent implements OnInit {

  @HostBinding('class.nym-otp-listing-no-results')
  protected get classes(): boolean { return true; }

  constructor() { }

  public ngOnInit(): void { }

}
