import {
    CoreNote,
    Problem,
} from '@michel.freiha/ng-sdk';


export class GetAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Get account';
    constructor(public id: string) { }
}

export class GetAccountFromAgentExistsGuard {
    public static readonly type: string = '[Agent Guard] Get account';
    constructor(public id: string) { }
}

export class GetAccountFromAgentApi {
    public static readonly type: string = '[Agent API] Get account';
    constructor(public id: string) { }
}

export class RefreshAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Refresh account';
    constructor(public id: string) { }
}

export class RefreshAccountFromAgentApi {
    public static readonly type: string = '[Agent API] Refresh account';
    constructor(public id: string) { }
}

export class VerifyAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Verify account';
    constructor(public id: string) { }
}

export class RejectAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Reject account';
    constructor(public id: string, public note: CoreNote) { }
}

export class BlockAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Block account';
    constructor(public id: string, public note: CoreNote) { }
}

export class UnblockAccountFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details Page] Unblock account';
    constructor(public id: string) { }
}

export class ResetPasscodeFromAgentDetailsPage {
    public static readonly type: string = '[Agent Details] Reset passcode';
    constructor(public id: string, public note: CoreNote) { }
}

export class FailFromApi {
    public static readonly type: string = '[Agent API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
