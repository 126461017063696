import {
    Menu,
} from '../../menu/models/menu.model';

export class Profile {

    public name: string;
    public email: string;
    public photo: string;
    public menu: Menu;

    constructor(ob?: Partial<Profile>) {

        if (!ob)
            return;

        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.email !== undefined) { this.email = ob.email; }
        if (ob.photo !== undefined) { this.photo = ob.photo; }
        if (ob.menu !== undefined) { this.menu = new Menu(ob.menu); }
    }
}
