import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';

import {
    MobileVerification,
} from '@michel.freiha/ng-sdk';


const DEFAULT_COLUMNS = ['mobile', 'code', 'attempts', 'expires'];

@Component({
    selector: 'nym-otp-listing-table',
    templateUrl: './otp-listing-table.component.html',
    styleUrls: ['./otp-listing-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpListingTableComponent implements OnInit {

    @HostBinding('class.nym-otp-listing-table')
    protected get classes(): boolean { return true; }

    @Input()
    public otps: MobileVerification[];

    @Input()
    public loading: boolean;

    @Input()
    public columns: string[] = DEFAULT_COLUMNS;

    constructor(
    ) { }

    public ngOnInit(): void { }
}
