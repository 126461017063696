import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  NymcardAccountLimit as AccountLimit,
} from '@michel.freiha/ng-sdk';

import {
  Account,
} from '@nymos/accounts/core';



@Component({
  selector: 'nym-user-limit-info',
  templateUrl: './user-limit-info.component.html',
  styleUrls: ['./user-limit-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLimitInfoComponent implements OnInit {

  @HostBinding('class.nym-user-limit-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  @Input()
  public loading: boolean;

  @Input()
  public amount: number = 0;

  @Input()
  public mode: string = 'determinate';

  protected get limit(): AccountLimit { return this.yearly; }

  protected get addedPercent(): number { return (this.added / this.total) * 100; }
  protected get reservedPercent(): number { return (this.reserved / this.total) * 100; }

  protected get yearly(): AccountLimit {
    return this.account && this.account.limits && this.account.limits.yearly;
  }

  constructor() { }

  public ngOnInit(): void { }

  public get added(): number {
    if (!this.limit)
      return 0;

    return (this.limit.credits.used + this.limit.credits.reserved);
  }

  public get reserved(): number {
    if (!this.limit)
      return 0;

    return +this.amount + this.added;
  }

  public get remaining(): number {
    if (!this.limit)
      return NaN;

    return this.limit.credits.total - this.added;
  }

  public get total(): number {
    if (!this.limit)
      return NaN;

    return this.limit.credits.total;
  }

}
