import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  NgForm,
} from '@angular/forms';

import {
  Subscription,
} from 'rxjs';

import {
  DIALOG_DATA,
  DialogRef,
} from '../../dialog.ref';


@Component({
  selector: 'thm-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmComponent<R = boolean> implements OnInit, OnDestroy {

  @ViewChild('ngForm', { static: true })
  private _ngForm: NgForm;

  @HostBinding('class.thm-confirm')
  protected get classes(): boolean { return true; }

  protected form: FormGroup;
  protected subscription: Subscription = new Subscription();

  constructor(
    protected _fb: FormBuilder,
    protected _ref: DialogRef<any, R>,
    protected _cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected _locale: string,
    @Inject(DIALOG_DATA) protected data: any,
  ) {
    this.initForm(data);
  }

  public ngOnInit(): void {
    this.subscription.add(this._ref.submitted().subscribe(() => {

      if (this._ngForm) {
        this._ngForm.onSubmit(undefined);
        this._cd.markForCheck();
      }

      this.submit();
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // @Override
  protected initForm(data: any): void { }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    this._ref.close(<any>true);
  }
}
