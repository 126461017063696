import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

import {
  Observable,
} from 'rxjs';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  InitKycFromResultsPage,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

import {
  AccountsAgentKycState,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.state';

const IRAQ_NUMBER_PATTERN = /^\+?964|\D+$/g;


@Component({
  selector: 'nym-agent-creation-confirm',
  templateUrl: './agent-creation-confirm.component.html',
  styleUrls: ['./agent-creation-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ProblemHandler],
})
export class AgentCreationConfirmComponent extends ModalComponent implements OnInit {

  @HostBinding('class.nym-agent-creation-confirm')
  protected get classes(): boolean { return true; }

  @Select(AccountsAgentKycState.loading)
  public acting$: Observable<boolean>;

  @Select(AccountsAgentKycState.problem)
  public problem$: Observable<Problem>;

  protected get mobile(): AbstractControl { return this.form.get('mobile'); }

  constructor(
    protected _ph: ProblemHandler,
    protected _store: Store,
    protected _actions$: Actions,
    fb: FormBuilder,
    ref: DialogRef<any, any>,
    cd: ChangeDetectorRef,
    @Inject(DIALOG_DATA) data: any,
  ) {
    super(fb, ref, cd, null, data);
    this.initForm(this.data);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this._actions$.pipe(ofActionSuccessful(InitKycFromResultsPage)).subscribe(() => this._ref.close(true));

    this.subscription.add(this.problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form, [{ from: 'phone', to: 'mobile' }]);
    }));
  }

  // @Override
  protected initForm(data: any): void {
    this.form = this._fb.group({
      mobile: ['', [
        Validators.required,
        Validators.pattern(/^(\+964)?(7[3-9])[0-9]{8}$/),
      ]],
    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
      return;

    const mobile = `+964${this.mobile.value.replace(IRAQ_NUMBER_PATTERN, '')}`;
    this._store.dispatch(new InitKycFromResultsPage(mobile));
  }
}
