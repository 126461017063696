export class PasswordVisibility {

    public static get Visible(): PasswordVisibility {
        return new PasswordVisibility({
            show: true,
            icon: 'visibility_off',
            type: 'text',
        });
    }

    public static get Hidden(): PasswordVisibility {
        return new PasswordVisibility({
            show: false,
            icon: 'visibility',
            type: 'password',
        });
    }

    public show: boolean;
    public icon: string;
    public type: string;

    private constructor(ob?: Partial<PasswordVisibility>) {
        if (!ob)
            return;

        if (ob.show !== undefined) { this.show = ob.show; }
        if (ob.icon !== undefined) { this.icon = ob.icon; }
        if (ob.type !== undefined) { this.type = ob.type; }
    }

    public toggle(): void {
        const newState = this.show ? PasswordVisibility.Hidden : PasswordVisibility.Visible;

        this.show = newState.show;
        this.icon = newState.icon;
        this.type = newState.type;
    }
}
