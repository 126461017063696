/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    FailFromApi,
    LoadCardActivitiesFromCardDetailsPage,
    LoadCardActivitiesFromUserDetailsPage,
    LoadMoreCardActivitiesFromCardDetailsPage,
    LoadMoreCardActivitiesFromUserDetailsPage,
    RefreshCardActivitiesFromUserDetailsPage,
    RefreshCardActivitiesFromUserDetailsPageNew,
} from './card-activities.actions';

import {
    Notifications,
} from './card-activities.notifications';


import {
    NymcardsSearchService,
    NymcardTransactionIndex,
    NymcardTransactionIndexCollection,
    Problem,
    SearchCriteria,
    SearchFilter,
    SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    CardActivity,
    Texts,
} from '@nymos/accounts/core';


export interface CardActivitiesStateModel {
    items: { [id: string]: CardActivity };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: CardActivitiesStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<CardActivitiesStateModel>({
    name: 'card_activities',
    defaults: stateDefaults,
})
export class CardActivitiesState {

    @Selector()
    public static userActivities(state: CardActivitiesStateModel): CardActivity[] {
        const result = Object.keys(state.items).map(((id) => state.items[id]));
        return result;
    }

    public static cardActivities(cardId: string): any {
        return createSelector([CardActivitiesState], (state: CardActivitiesStateModel) => {
            const result = Object.values(state.items).filter((c) => c.cardId === cardId);
            return result;
        });
    }

    @Selector()
    public static problem(state: CardActivitiesStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: CardActivitiesStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static hasMore(state: CardActivitiesStateModel): boolean {
        return !!(state.next && state.next.cursors && state.next.cursors.after);
    }

    constructor(
        private _nc: NotificationCenter,
        private _searchService: NymcardsSearchService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<CardActivitiesStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(LoadCardActivitiesFromUserDetailsPage)
    public loadCardActivitiesByAccountId(ctx: StateContext<CardActivitiesStateModel>, { accountId }: any): any {
        const criteria = new SearchCriteria({
            filters: [
                new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [accountId] }),
            ],
        });

        ctx.setState({ ...stateDefaults, loading: true });
        return this._search(ctx, criteria).pipe(
            finalize(() => { ctx.patchState({ next: criteria, loading: false }); }),
        );
    }

    @Action(RefreshCardActivitiesFromUserDetailsPage)
    public refreshCardActivitiesByAccountId(ctx: StateContext<CardActivitiesStateModel>, { accountId }: any): any {
        const criteria = new SearchCriteria({
            filters: [
                new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [accountId] }),
            ],
        });

        return this._search(ctx, criteria);
    }

    @Action(RefreshCardActivitiesFromUserDetailsPageNew)
    public refreshCardActivitiesByAccountIdNew(ctx: StateContext<CardActivitiesStateModel>, { accountId }: any): any {
        const state = ctx.getState();
        const criteria = state.next;

        if (!criteria)
            throw new Problem({
                title: Texts.Action.UnableToLoadMoreTitle,
                detail: Texts.Action.UnableToLoadMoreDetail,
            });

        return this._searchService.searchNymcardTransactions(criteria).pipe(
            tap((collection: NymcardTransactionIndexCollection) => {
                criteria.cursors = collection.paging && collection.paging.cursors;

                ctx.setState(produce((draft) => {
                    collection.data.forEach((index) => draft.items[index.id] = this._fromIndex(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ next: criteria, loading: false });
            }),
        );
    }

    @Action(LoadCardActivitiesFromCardDetailsPage)
    public loadCardActivitiesByCardId(ctx: StateContext<CardActivitiesStateModel>, { cardId }: any): any {
        const criteria = new SearchCriteria({
            filters: [
                new SearchFilter({ field: 'card_id', operator: SearchOperator.AnyOf, values: [cardId] }),
            ],
        });

        ctx.setState({ ...stateDefaults, loading: true });
        return this._search(ctx, criteria).pipe(
            finalize(() => { ctx.patchState({ next: criteria, loading: false }); }),
        );
    }

    @Action(LoadMoreCardActivitiesFromCardDetailsPage)
    @Action(LoadMoreCardActivitiesFromUserDetailsPage)
    public loadMoreCardActivities(ctx: StateContext<CardActivitiesStateModel>): any {

        const state = ctx.getState();
        const criteria = state.next;

        if (!criteria)
            throw new Problem({
                title: Texts.Action.UnableToLoadMoreTitle,
                detail: Texts.Action.UnableToLoadMoreDetail,
            });

        ctx.patchState({ loading: true });

        return this._searchService.searchNymcardTransactions(criteria).pipe(
            tap((collection: NymcardTransactionIndexCollection) => {
                criteria.cursors = collection.paging && collection.paging.cursors;

                ctx.setState(produce((draft) => {
                    collection.data.forEach((index) => draft.items[index.id] = this._fromIndex(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),

            finalize(() => {
                ctx.patchState({ next: criteria, loading: false });
            }),
        );
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<CardActivitiesStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this._nc.show(Notifications.Failure);
    }

    private _search(ctx: StateContext<CardActivitiesStateModel>, criteria: SearchCriteria): any {
        return this._searchService.searchNymcardTransactions(criteria).pipe(
            tap((collection: NymcardTransactionIndexCollection) => {
                criteria.cursors = collection.paging && collection.paging.cursors;
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    collection.data.forEach((index) => draft.items[index.id] = this._fromIndex(index));
                }));
            }),

            catchError((problem) => {
                // Let global handler catch it
                return throwError(problem);
            }),
        );
    }

    private _fromIndex(index: NymcardTransactionIndex): CardActivity {

        const id = index.id;
        const accountId = index.accountId;
        const cardId = index.cardId;
        const created = index.created;
        const modified = index.modified;
        const category = index.category;
        const merchant = index.merchantName;
        const amount = index.amount;
        const currency = index.currency;
        const name = index.paymentMethod;
        const last4 = index.cardLast4;
        const status = index.status;
        const location = index.merchantAddress;
        const reason = status === 'declined' ? index.statusReason : '';

        return new CardActivity({
            id: id,
            accountId: accountId,
            cardId: cardId,
            created: created as any,
            modified: modified as any,
            category: category,
            merchant: merchant,
            amount: amount,
            currency: currency,
            name: name,
            last4: last4,
            status: status as any,
            location: location,
            reason: reason,
        });
    }
}
