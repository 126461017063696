import {
    Attachment,
} from './attachment.model';


export class AccountAddress {
    public apartmentRoom: string;
    public building: string;
    public street: string;
    public city: string;
    public district: string;
    public state: string;
    public postalCode: string;
    public countryCode: string;

    public attachments: Attachment[];

    constructor(ob?: Partial<AccountAddress>) {
        this.apartmentRoom = undefined;
        this.building = undefined;
        this.street = undefined;
        this.city = undefined;
        this.district = undefined;
        this.state = undefined;
        this.postalCode = undefined;
        this.countryCode = undefined;

        this.attachments = [];

        if (!ob) { return; }

        if (ob.apartmentRoom !== undefined) { this.apartmentRoom = ob.apartmentRoom; }
        if (ob.building !== undefined) { this.building = ob.building; }
        if (ob.street !== undefined) { this.street = ob.street; }
        if (ob.city !== undefined) { this.city = ob.city; }
        if (ob.district !== undefined) { this.district = ob.district; }
        if (ob.state !== undefined) { this.state = ob.state; }
        if (ob.postalCode !== undefined) { this.postalCode = ob.postalCode; }
        if (ob.countryCode !== undefined) { this.countryCode = ob.countryCode; }
        if (ob.attachments !== undefined) { ob.attachments.forEach((o) => this.attachments.push(new Attachment(o))); }
    }
}

