/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    ProvinceNaming,
} from '../../models/province-naming.model';

import {
    LoadAccountsProvincesNamingsFromApi,
} from './accounts-provinces-namings.actions';


export interface AccountsProvincesNamingsStateModel {
    loading: boolean;
    accountsprovincesNamings: string[];
}

const stateDefaults: AccountsProvincesNamingsStateModel = {
    loading: undefined,
    accountsprovincesNamings: []
};

@State<AccountsProvincesNamingsStateModel>({
    name: 'accountsprovincesNamings',
    defaults: stateDefaults,
})
export class AccountsProvincesNamingsState implements NgxsOnInit {

    @Selector()
    public static loading(state: AccountsProvincesNamingsStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static AccountsProvincesNamings(state: AccountsProvincesNamingsStateModel): string[] {
        return state.accountsprovincesNamings;
    }

    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsProvincesNamingsStateModel>): void {
        ctx.dispatch(new LoadAccountsProvincesNamingsFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsProvincesNamingsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountsProvincesNamingsFromApi)
    public load(ctx: StateContext<AccountsProvincesNamingsStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountsProvincesNamings().pipe(
            tap((AccountsprovincesNamingss: ProvinceNaming[]) => {
                ctx.setState(produce((draft) => {
                    draft.accountsprovincesNamings = AccountsprovincesNamingss.map((p) => p);
                }));
            }),
            finalize(() => {
              ctx.patchState({ loading: false });
            }),
        );
    }
}

