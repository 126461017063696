import {
    Injectable,
} from '@angular/core';

import {
    Observable,
    of,
} from 'rxjs';

import {
    ActivateTokenReason,
    Card,
    DeleteTokenReason,
    ResumeTokenReason,
    SuspendTokenReason,
    TokenStatus,
} from '@michel.freiha/ng-sdk';

import {
    MemoryCardDatastore,
} from '../datastore/cards.datastore';

import {
    MemoryTokenDatastore,
} from '../datastore/tokens.datastore';


@Injectable({
    providedIn: 'root',
})
export class IssuerService {

    constructor(
        private _cardsDatastore: MemoryCardDatastore,
        private _tokensDatastore: MemoryTokenDatastore,
    ) { }

    public searchCardsByPan(pan: string): Observable<Card[]> {
        const cards = this._cardsDatastore.fetchByPan(pan);
        cards.forEach((c) => c.tokens = this._tokensDatastore.fetchByCardId(c.id));
        return of(cards);
    }

    public activateToken(network: string, tokenId: string, reason: ActivateTokenReason): Observable<any> {
        this._update(tokenId, TokenStatus.Active);
        return of({});
    }

    public resumeToken(network: string, tokenId: string, reason: ResumeTokenReason): Observable<any> {
        this._update(tokenId, TokenStatus.Active);
        return of({});
    }

    public suspendToken(network: string, tokenId: string, reason: SuspendTokenReason): Observable<any> {
        this._update(tokenId, TokenStatus.Suspended);
        return of({});
    }

    public deleteToken(network: string, tokenId: string, reason: DeleteTokenReason): Observable<any> {
        this._update(tokenId, TokenStatus.Deleted);
        return of({});
    }

    private _update(tokenId: string, status: TokenStatus): void {
        const token = this._tokensDatastore.get(tokenId);
        token.status = status;
        this._tokensDatastore.update(token);
    }
}
