import {
  Constructor,
} from './constructor';

import {
  ElementRef,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';


interface Values {
  x: string;
  y: string;
  rx: string;
  ry: string;
  width: string;
  height: string;
}

export interface CanShimmer extends Values {
  thmShimmer: boolean;
  thmShimmerShape: string;
  loading: boolean;
}

/** @docs-private */
export type CanShimmerCtor = Constructor<CanShimmer>;

/** @docs-private */
export interface HasElementRef {
  _elementRef: ElementRef;
}

const FORMAT_ERROR = (value) =>
  `Shimmer unformatted (all values should be numbers).
    Received: ${value}
    Expected:
    - [circle|c]:{size}
    - [rect|r]:{width}:{height?}
    - [shape|s|g]:{x}:{y}:{rx}:{ry}:{width}:{height}`;


/** Mixin to augment a directive with a `color` property. */
export function mixinShimmer<T extends Constructor<HasElementRef>>(
  base: T,
): CanShimmerCtor & T {


  return class extends base {
    private _shimmer: string;
    private _loading: boolean;

    private _x: string;
    private _y: string;
    private _rx: string;
    private _ry: string;
    private _width: string;
    private _height: string;



    get thmShimmer(): boolean { return this._loading; }
    set thmShimmer(value: boolean) { this._loading = coerceBooleanProperty(value); }

    get thmShimmerShape(): string { return this._shimmer; }
    set thmShimmerShape(value: string) {

      if (!value) {
        return;
      }

      const values = this._toShimmer(value);
      if (!values) {
        console.error(FORMAT_ERROR(value));
        return;
      }

      if (value !== this._shimmer) {
        this._shimmer = value;
        this._x = values.x;
        this._y = values.y;
        this._rx = values.rx;
        this._ry = values.ry;
        this._width = values.width;
        this._height = values.height;
      }
    }

    get loading(): boolean { return this._loading; }
    get x(): string { return this._x; }
    get y(): string { return this._y; }
    get rx(): string { return this._rx; }
    get ry(): string { return this._ry; }
    get width(): string { return this._width; }
    get height(): string { return this._height; }

    private _toShimmer(value: string): Values {

      const DO = '0';
      const DR = '4';

      const values = value.split(':');

      if (values.slice(1).some((i) => isNaN(+i)))
        return undefined;

      if (values[0] === 'rect' || values[0] === 'r') {
        if (values.length === 2)
          return { x: DO, y: DO, rx: DR, ry: DR, width: values[1], height: values[1] };

        if (values.length === 3)
          return { x: DO, y: DO, rx: DR, ry: DR, width: values[1], height: values[2] };

        return undefined;
      }

      if (values[0] === 'circle' || values[0] === 'c') {
        if (values.length === 2) {
          const rounded = (+values[1] / 2).toString();
          return { x: DO, y: DO, rx: rounded, ry: rounded, width: values[1], height: values[1] };
        }

        return undefined;
      }

      if (values[0] === 'shape' || values[0] === 's' || values[0] === 'g') {
        if (values.length === 7) {
          return { x: values[1], y: values[2], rx: values[3], ry: values[4], width: values[5], height: values[6] };
        }
        return undefined;
      }

      return undefined;
    }

  };


}
