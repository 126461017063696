// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '@nymos/accounts/core';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
}
