import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Menu,
} from '../../models/menu.model';
import { CallCenterService } from '../../../../../../../../dashboard/front-end/call-center/src/lib/core/services/call-center.service';
import { Store } from '@ngxs/store';
import { SignOut } from '../../../../../../../../nymos/auth/src/lib/store/auth/auth.actions';

@Component({
  selector: 'thm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {

  @HostBinding('class.thm-menu')
  protected get classes(): boolean { return true; }

  @Input()
  public menu: Menu;

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private callCenterService: CallCenterService, private _store: Store) { }

  public ngOnInit(): void { }

  public onNavigation(action: any): void {
    this.navigationEmitter.emit(action);
  }

  public resetSearchQuery() {
    this.callCenterService.setSearchResultQuery('');
  }

  public logoutUser(): void {
    this._store.dispatch(new SignOut());
    window.localStorage.clear();
  }
}
