import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';


@Component({
    selector: 'nym-clear-throttling',
    templateUrl: './clear-throttling.component.html',
    styleUrls: ['./clear-throttling.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearThrottlingComponent implements OnInit {

    @HostBinding('class.nym-clear-throttling')
    protected get classes(): boolean { return true; }

    @Output()
    protected clearThrottling: EventEmitter<any> = new EventEmitter();

    @Input()
    public loading: boolean;

    public ngOnInit(): void {}

    public clearThrottling$$(): void {
        this.clearThrottling.emit();
    }
}
