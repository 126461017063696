import {
    DiscoveryService,
    DOMAIN_PARAM,
    Env,
    ENV_PARAM,
    NAME_PARAM,
    PROJECT_PARAM,
} from '../discovery.service';

export abstract class AbstractDiscoveryService implements DiscoveryService {


    public get isProduction(): boolean { return this.serviceEnv === Env.Prod; }
    public get isStaging(): boolean { return this.serviceEnv === Env.Stag; }
    public get isDev(): boolean { return this.serviceEnv === Env.Dev; }

    public abstract get projectId(): string;
    public abstract get serviceId(): string;
    public abstract get serviceEnv(): Env;

    constructor(
        protected map: any = {},
    ) { }

    public abstract getDomain(serviceId: string): string;
    public abstract getUrl(serviceId?: string): string;

    protected applyTemplates(serviceId: string, envValue: string): string {

        if (envValue.indexOf(NAME_PARAM) !== -1)
            envValue = envValue.replace(NAME_PARAM, serviceId);

        if (envValue.indexOf(PROJECT_PARAM) !== -1)
            envValue = envValue.replace(PROJECT_PARAM, this.projectId);

        if (envValue.indexOf(ENV_PARAM) !== -1)
            envValue = envValue.replace(ENV_PARAM, this.serviceEnv);

        if (envValue.indexOf(DOMAIN_PARAM) !== -1)
            envValue = envValue.replace(DOMAIN_PARAM, this.getDomain(serviceId));

        return envValue;
    }
}
